import { all, call, put, takeLatest } from "redux-saga/effects";
import { ReportPortfolioParams } from "src/core/http/service/reportPortfolio/types";
import { getListReportPortfolioApi } from "src/core/http/service/reportPortfolio";
import {
  getReportPortfolioFailed,
  getReportPortfolioStart,
  getReportPortfolioSuccess,
} from "../redux/reportPortfolio";

function* getReportPortfololio(
  action: ReturnType<typeof getReportPortfolioStart>
) {
  try {
    const params: ReportPortfolioParams = {
      accountCode: action.payload,
    };
    const res = yield call(getListReportPortfolioApi, params);
    yield put({
      type: getReportPortfolioSuccess.type,
      payload: {
        items: res,
      },
    });
  } catch (error: any) {
    yield put(getReportPortfolioFailed(error));
  }
}

function* watchGetReportPortfolio() {
  yield takeLatest(getReportPortfolioStart.type, getReportPortfololio);
}
export default function* getReportPortfolioSaga() {
  yield all([watchGetReportPortfolio()]);
}
