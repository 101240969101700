import { toast } from "react-toastify";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxData, ReduxStateType } from "src/redux-store/types";
import { ReportForControlParams } from "src/core/http/service/reportForControl/types";
import { ResponseForControl } from "src/domain/models/reportForControl";
import moment from "moment";

export type ReportForControlState = {
  listChannelForControl: ResponseForControl[];
  filter: ReportForControlParams;
  hasMore: boolean;
};

const initialState: ReduxData<ReportForControlState> = {
  data: {
    listChannelForControl: [],
    filter: {
      channelCode: "",
      transactionDate: moment(new Date()).format("DD/MM/YYYY"),
    },
    hasMore: true,
  },
  status: ReduxStateType.INIT,
};

const reportForControlSlice = createSlice({
  name: "reportForControl",
  initialState,
  reducers: {
    updateFilter: (state, action: PayloadAction<ReportForControlParams>) => {
      Object.assign(state.data.filter, action.payload);
    },
    resetFilter: (state) => {
      state.data.filter = initialState.data.filter;
    },
    resetListReportPortfolioRealize: (state) => {
      state.data.listChannelForControl =
        initialState.data.listChannelForControl;
    },
    getListForControlChannelStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    getListForControlChannelSuccess: (
      state,
      action: PayloadAction<ResponseForControl[]>
    ) => {
      state.status = ReduxStateType.SUCCESS;
      state.data.listChannelForControl = action.payload;
    },
    getListForControlChannelFailed: (state) => {
      state.status = ReduxStateType.ERROR;
      toast.error("Fetching Data Failed");
    },
  },
});

export const {
  updateFilter,
  resetFilter,
  resetListReportPortfolioRealize,
  getListForControlChannelStart,
  getListForControlChannelSuccess,
  getListForControlChannelFailed,
} = reportForControlSlice.actions;

export default reportForControlSlice.reducer;
