import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
interface ItemChannel {
  key: string | number;
  value: string | number;
  label: string;
}
interface InitialState {
  optionsChannelList: ItemChannel[];
  status: ReduxStateType;
}

const initialState: InitialState = {
  optionsChannelList: [],
  status: ReduxStateType.INIT,
};

const channelDetail = createSlice({
  name: "channelDetailSlice",
  initialState,
  reducers: {
    channelDetailStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    channelDetailSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
      state.optionsChannelList = action.payload;
    },
    channelDetailFailed: (state) => {
      state.status = ReduxStateType.ERROR;
      state.optionsChannelList = initialState.optionsChannelList;
    },
    resetChannelDetail: () => initialState,
  },
});

export const {
  channelDetailStart,
  channelDetailSuccess,
  channelDetailFailed,
  resetChannelDetail,
} = channelDetail.actions;

export default channelDetail.reducer;
