import { ReduxStateType } from "src/redux-store/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

export type FilterState = {
  referrerCode?: string;
  fromDate?: string;
  toDate?: string;
  shareCode?: string;
};

interface InitialState {
  filter: FilterState;
  status: ReduxStateType;
}

const initialState: InitialState = {
  filter: {
    referrerCode: "",
    fromDate: moment(new Date()).subtract(7, "days").format("DD/MM/YYYY"),
    toDate: moment(new Date()).format("DD/MM/YYYY"),
    shareCode: "",
  },
  status: ReduxStateType.INIT,
};

const filterOrderCommission = createSlice({
  name: "filterOrderCommissionSlice",
  initialState,
  reducers: {
    updateFilter: (state, action: PayloadAction<FilterState>) => {
      Object.assign(state.filter, action.payload);
    },
    resetFilter: (state) => {
      Object.assign(state.filter, initialState.filter);
    },
  },
});

export const { updateFilter, resetFilter } = filterOrderCommission.actions;

export default filterOrderCommission.reducer;
