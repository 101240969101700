import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
interface InitialState {
  status: ReduxStateType;
}
const initialState: InitialState = {
  status: ReduxStateType.INIT,
};

const postOrderActiveConnectVPS = createSlice({
  name: "postOrderActiveConnectVPSSlice",
  initialState,
  reducers: {
    postOrderActiveConnectVPSStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    postOrderActiveConnectVPSSuccess: (state) => {
      state.status = ReduxStateType.SUCCESS;
    },
    postOrderActiveConnectVPSFailed: (state) => {
      state.status = ReduxStateType.ERROR;
    },
  },
});

export const {
  postOrderActiveConnectVPSStart,
  postOrderActiveConnectVPSSuccess,
  postOrderActiveConnectVPSFailed,
} = postOrderActiveConnectVPS.actions;

export default postOrderActiveConnectVPS.reducer;
