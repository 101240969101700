import GavelIcon from "@mui/icons-material/Gavel";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SummarizeIcon from "@mui/icons-material/Summarize";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CloudIcon from "@mui/icons-material/Cloud";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ArticleIcon from "@mui/icons-material/Article";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import RecommendIcon from "@mui/icons-material/Recommend";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import FunctionsIcon from "@mui/icons-material/Functions";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import RuleIcon from "@mui/icons-material/Rule";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import SecurityIcon from "@mui/icons-material/Security";

import { roles } from "src/helper/const";

export const defaulActiveTab = {
  STOCK_TRADING: "StockTrading",
};

export const sideBarKey = [
  {
    activeTab: "StockTrading",
    icon: <GavelIcon fontSize="small" />,
    name: "ĐẶT LỆNH",
    subSideBar: [
      {
        path: "/command/stockTrading",
        subName: "Đặt lệnh tự động",
        roleSide: roles.ROLE_USER,
        subIcon: <GavelIcon fontSize="small" />,
      },
      {
        path: "/command/stockTradingHand",
        subName: "Đặt lệnh thủ công",
        roleSide: roles.ROLE_EDITOR,
        subIcon: <GavelIcon fontSize="small" />,
      },
      {
        path: "/management/connect",
        subName: "Quản lý kết nối",
        roleSide: roles.ROLE_EDITOR,
        subIcon: <CloudIcon fontSize="small" />,
      },
    ],
  },
  {
    activeTab: "ManagementMoney",
    icon: <CurrencyExchangeIcon fontSize="small" />,
    name: "NỘP/RÚT",
    subSideBar: [
      {
        path: "/transfer/money",
        subName: "Nộp / Rút tiền",
        roleSide: roles.ROLE_USER,
        subIcon: <CurrencyExchangeIcon fontSize="small" />,
      },
    ],
  },
  {
    activeTab: "ManagementAccount",
    icon: <ManageAccountsIcon fontSize="small" />,
    name: "QLTK",
    subSideBar: [
      {
        path: "/management/account",
        subName: "Quản lý tài khoản",
        roleSide: roles.ROLE_ADMIN,
        subIcon: <AccountCircleIcon fontSize="small" />,
      },
      {
        path: "/management/channel",
        subName: "Quản lý TK tổng",
        subIcon: <FunctionsIcon fontSize="small" />,
        roleSide: roles.ROLE_EDITOR,
      },
    ],
  },
  {
    activeTab: "ManagementFinance",
    icon: <AccountBalanceIcon fontSize="small" />,
    name: "DVTC",
    subSideBar: [
      {
        path: "/finance-service",
        subName: "Dịch vụ tài chính",
        roleSide: roles.ROLE_USER,
        subIcon: <AccountBalanceIcon fontSize="small" />,
      },
      {
        path: "/management/dividend",
        subName: "Quản lý cổ tức",
        roleSide: roles.ROLE_EDITOR,
        subIcon: <LocalActivityIcon fontSize="small" />,
      },
      {
        path: "/report/order-commission",
        subName: "Bảng tính hoa hồng",
        roleSide: roles.ROLE_USER,
        subIcon: <ArticleIcon fontSize="small" />,
      },
      {
        path: "/referral-account",
        subName: "Tài khoản giới thiệu",
        roleSide: roles.ROLE_USER,
        subIcon: <AccountCircleIcon fontSize="small" />,
      },
    ],
  },
  {
    activeTab: "ReportCustomer",
    icon: <AssessmentIcon fontSize="small" />,
    name: "TÀI SẢN",
    subSideBar: [
      {
        path: "/report/user-assets",
        subName: "Báo cáo tài sản",
        roleSide: roles.ROLE_USER,
        subIcon: <ArticleIcon fontSize="small" />,
      },
      {
        path: "/order-history",
        subName: "Lịch sử lệnh đặt của KH",
        subIcon: <CurrencyExchangeIcon fontSize="small" />,
        roleSide: roles.ROLE_USER,
      },
      {
        path: "/report/portfolio-realize",
        subName: "Lãi lỗ đã thực hiện",
        roleSide: roles.ROLE_USER,
        subIcon: <MonetizationOnIcon fontSize="small" />,
      },
      {
        path: "/report/portfolio",
        subName: "Lãi lỗ tạm tính",
        roleSide: roles.ROLE_USER,
        subIcon: <MonetizationOnIcon fontSize="small" />,
      },
    ],
  },

  {
    activeTab: "ReportAdmin",
    icon: <SummarizeIcon fontSize="small" />,
    name: "BÁO CÁO",
    subSideBar: [
      {
        path: "/asset-values-report",
        subName: "Báo cáo tài sản TK tổng",
        roleSide: roles.ROLE_EDITOR,
        subIcon: <ArticleIcon fontSize="small" />,
      },
      {
        path: "/report/synthetic",
        subName: "Báo cáo Tổng hợp",
        subIcon: <SummarizeIcon fontSize="small" />,
        roleSide: roles.ROLE_EDITOR,
      },
      {
        path: "/report/for-control",
        subName: "Báo cáo đối soát",
        roleSide: roles.ROLE_EDITOR,
        subIcon: <AssignmentIcon fontSize="small" />,
      },
      {
        path: "/report/portfolio-channel",
        subName: "Báo cáo danh mục TK tổng",
        roleSide: roles.ROLE_EDITOR,
        subIcon: <SummarizeIcon fontSize="small" />,
      },
      {
        path: "/report/category-customer",
        subName: "Báo cáo danh mục KH",
        roleSide: roles.ROLE_EDITOR,
        subIcon: <ConfirmationNumberIcon fontSize="small" />,
      },
      {
        path: "/report/fee-synthetic",
        subName: "Báo cáo tổng hợp hoa hồng",
        roleSide: roles.ROLE_EDITOR,
        subIcon: <SummarizeIcon fontSize="small" />,
      },
    ],
  },
  {
    activeTab: "Recommendations",
    icon: <RecommendIcon fontSize="small" />,
    name: "DM KN",
    subSideBar: [
      {
        path: "/recommendations",
        subName: "Danh mục khuyến nghị",
        roleSide: roles.ROLE_USER,
        subIcon: <RecommendIcon fontSize="small" />,
      },
    ],
  },
  {
    activeTab: "Setting2FA",
    icon: <SecurityIcon fontSize="small" />,
    name: "Cài đặt 2FA",
    isNew: false,
    subSideBar: [
      {
        path: "/settings-auth-2fa",
        subName: "Cài đặt 2FA",
        roleSide: roles.ROLE_USER,
        subIcon: <SecurityIcon fontSize="small" />,
      },
    ],
  },
  {
    activeTab: "Introduce",
    icon: <GroupAddIcon fontSize="small" />,
    name: "HỢP TÁC",
    isNew: true,
    subSideBar: [
      {
        path: "/introduce",
        subName: "MỘT LỜI GIỚI THIỆU TIỀN TRIỆU VỀ TAY",
        roleSide: roles.ROLE_USER,
        subIcon: <GroupAddIcon fontSize="small" />,
      },
    ],
  },
  {
    activeTab: "Policies",
    icon: <RuleIcon fontSize="small" />,
    name: "QUY CHẾ",
    isNew: true,
    subSideBar: [
      {
        path: "/policies",
        subName: "Chính sách và quy chế giao dịch EZTRADE",
        roleSide: roles.ROLE_USER,
        subIcon: <RuleIcon fontSize="small" />,
      },
    ],
  },
];
