import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux-store/store";

export const referralAccountState = (state: RootState) =>
  state.referralAccount.referralAccount;

export const getReferralAccountSelector = createSelector(
  referralAccountState,
  (state) => state
);
