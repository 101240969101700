import { getReportBuyingPowergApi } from "./../../../core/http/service/userAssets/index";
import { ReportBuyingPowerParams } from "src/domain/models/userAssets";
import {
  ShareHoldingParams,
  ShareHoldingResponse,
  ReportBuyingPowerResponse,
} from "./../../../domain/models/userAssets";
import { createSelector } from "@reduxjs/toolkit";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  getReportBuyingPowerStart,
  getReportBuyingPowerSuccess,
  getShareHoldingStart,
  getShareHoldingSuccess,
} from "../redux/userAssets";
import { userAssetsState } from "../redux/selector";
import { getShareHoldingApi } from "src/core/http/service/userAssets";

const userAssetsSelector = createSelector(userAssetsState, (state) => ({
  selectedAccountCode: state.data.selectedAccountCode,
  limit: state.data.limit,
  offset: state.data.offset,
}));

function* getShareHolding() {
  try {
    const { selectedAccountCode } = yield select(userAssetsSelector);

    const params: ShareHoldingParams = {
      accountCode: selectedAccountCode,
    };
    const data: ShareHoldingResponse = yield call(getShareHoldingApi, params);
    yield put({
      type: getShareHoldingSuccess.type,
      payload: data,
    });
  } catch (error: any) {}
}
function* getReportBuyingPower(
  action: ReturnType<typeof getReportBuyingPowerStart>
) {
  try {
    const { selectedAccountCode } = yield select(userAssetsSelector);

    const params: ReportBuyingPowerParams = {
      accountCode: selectedAccountCode,
      shareCode: "",
    };
    const data: ReportBuyingPowerResponse = yield call(
      getReportBuyingPowergApi,
      params
    );
    yield put({
      type: getReportBuyingPowerSuccess.type,
      payload: data,
    });
  } catch (error: any) {}
}

function* watchGetShareHolding() {
  yield takeLatest(getShareHoldingStart.type, getShareHolding);
}
function* watchGetReportBuyingPower() {
  yield takeLatest(getReportBuyingPowerStart.type, getReportBuyingPower);
}
export default function* getUserAssetsSaga() {
  yield all([watchGetShareHolding(), watchGetReportBuyingPower()]);
}
