import { createSlice } from "@reduxjs/toolkit";
import {
  optionsSide,
  optionsStatus,
} from "../pages/ListTrading/OrderBook/validate";
interface InitialState {
  inputFilterOrder: {
    side: OptionSelect | null;
    shareCode: OptionSelect | null;
    statusOrder: OptionSelect | null;
  };
}
interface OptionSelect {
  value: string | number;
  label: string | number;
}
const initialState: InitialState = {
  inputFilterOrder: {
    side: optionsSide[0],
    shareCode: null,
    statusOrder: optionsStatus[0],
  },
};

const filterOrder = createSlice({
  name: "filterOrderSlice",
  initialState,
  reducers: {
    updateFilterOrder: (state, action) => {
      Object.assign(state.inputFilterOrder, action.payload);
    },
    resetFilterOrder: () => initialState,
  },
});

export const { updateFilterOrder, resetFilterOrder } = filterOrder.actions;

export default filterOrder.reducer;
