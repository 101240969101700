import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";

const initialState = {
  data: {
    dataListAcc: [],
    optionsListAcc: [],
  },
  status: ReduxStateType.INIT,
};

const listAccountsStatic = createSlice({
  name: "listAccountsStaticSlice",
  initialState,
  reducers: {
    listAccountsStaticStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    listAccountsStaticSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
      state.data.dataListAcc = action.payload.dataListAcc;
      state.data.optionsListAcc = action.payload.optionsListAcc;
      // Chỉ immer 1 cấp nên phải viết từng thằng
    },
    listAccountsStaticFailed: (state) => {
      state.status = ReduxStateType.ERROR;
    },
  },
});

export const {
  listAccountsStaticStart,
  listAccountsStaticSuccess,
  listAccountsStaticFailed,
} = listAccountsStatic.actions;

export default listAccountsStatic.reducer;
