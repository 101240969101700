import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
interface ResetPasswordState {
  status: ReduxStateType;
}

const initialState: ResetPasswordState = {
  status: ReduxStateType.ERROR,
};

const resetPassword = createSlice({
  name: "resetPasswordSlice",
  initialState,
  reducers: {
    resetPasswordStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    resetPasswordSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
    },
    resetPasswordFailed: (state) => {
      state.status = ReduxStateType.ERROR;
    },
  },
});

export const { resetPasswordStart, resetPasswordSuccess, resetPasswordFailed } =
  resetPassword.actions;

export default resetPassword.reducer;
