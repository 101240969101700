import { combineReducers } from "redux";
import orderActiveConnectSlice from "./orderActiveConnect";
import channelListConnectSlice from "./channelListConnect";
import orderSessionConnectSlice from "./orderSessionConnect";
import postOrderActiveConnectVPSSlice from "./postOrderActiveConnectVPS";
import verifyOtpConnectSlice from "./verifyOtpConnect";
import formModalSlice from "./formModal";
import logOutConnectSlice from "./logOutConnect";

export default combineReducers({
  orderActiveConnect: orderActiveConnectSlice,
  postOrderActiveConnectVPS: postOrderActiveConnectVPSSlice,
  channelListConnect: channelListConnectSlice,
  orderSessionConnect: orderSessionConnectSlice,
  verifyOtpConnect: verifyOtpConnectSlice,
  formModal: formModalSlice,
  logOutConnect: logOutConnectSlice,
});
