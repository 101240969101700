import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
interface InitialState {
  status: ReduxStateType;
}
const initialState: InitialState = {
  status: ReduxStateType.INIT,
};

const addAccount = createSlice({
  name: "addAccountSlice",
  initialState,
  reducers: {
    addAccountStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    addAccountSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
    },
    addAccountFailed: (state) => {
      state.status = ReduxStateType.ERROR;
    },
  },
});

export const { addAccountStart, addAccountSuccess, addAccountFailed } =
  addAccount.actions;

export default addAccount.reducer;
