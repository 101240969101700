import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ItemAccount } from "src/domain/models/managementAccount";
import { ReduxData, ReduxStateType } from "src/redux-store/types";

export type AccountInfoStateType = {
  accountInfo: ItemAccount | null;
};

const initialState: ReduxData<AccountInfoStateType> = {
  data: {
    accountInfo: null,
  },
  status: ReduxStateType.INIT,
};

const accountInfoSlice = createSlice({
  name: "accountInfoSlice",
  initialState,
  reducers: {
    accountInfoStart: (state) => {},
    accountInfoSuccess: (state, action: PayloadAction<ItemAccount>) => {
      state.data.accountInfo = action.payload;
      state.status = ReduxStateType.SUCCESS;
    },
    accountInfoFailed: (state) => {
      state.status = ReduxStateType.ERROR;
    },
  },
});

export const { accountInfoStart, accountInfoSuccess, accountInfoFailed } =
  accountInfoSlice.actions;

export default accountInfoSlice.reducer;
