import {
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextField as MuiTextField,
} from "@mui/material";
import React from "react";
export type TextFieldProps = { width?: number | string } & (
  | StandardTextFieldProps
  | FilledTextFieldProps
  | OutlinedTextFieldProps
);
export default function TextField({
  width = "100%",
  size = "small",
  ...rest
}: TextFieldProps) {
  return (
    <MuiTextField
      style={{
        width: "100%",
      }}
      size={size}
      autoComplete="off"
      {...rest}
    />
  );
}
