import { lazy } from "react";
import { roles } from "src/helper/const";
import paths from "src/routes/pathRoutes";
const reportSyntheticLazy = lazy(() => import("./pages"));
export const reportSyntheticRoutes = [
  {
    page: reportSyntheticLazy,
    path: paths.reportSynthetic,
    roleSide: roles.ROLE_EDITOR,
  },
];
