import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  inputFormRegister: {
    accountCodeRegister: "",
    accountName: "",
    emailRegister: "",
    mobile: "",
    bankNumber: "",
    bankCode: "",
    referrerCode: "",
    checkValidate: false,
  },
};

const formRegisterSlice = createSlice({
  name: "formRegister",
  initialState,
  reducers: {
    updateInputFormRegister: (state, action) => {
      Object.assign(state.inputFormRegister, action.payload);
    },
    resetFormRegister: () => initialState,
  },
});

export const { updateInputFormRegister, resetFormRegister } =
  formRegisterSlice.actions;

export default formRegisterSlice.reducer;
