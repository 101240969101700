import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";

export type InitialState = {
  status: ReduxStateType;
};

const initialState: InitialState = {
  status: ReduxStateType.INIT,
};

const postFinancialsSuspend = createSlice({
  name: "postFinancialsSuspendSlice",
  initialState,
  reducers: {
    postFinancialsSuspendStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    postFinancialsSuspendSuccess: (state) => {
      state.status = ReduxStateType.SUCCESS;
    },
    postFinancialsSuspendFailed: (state) => {
      state.status = ReduxStateType.ERROR;
    },
  },
});

export const {
  postFinancialsSuspendStart,
  postFinancialsSuspendSuccess,
  postFinancialsSuspendFailed,
} = postFinancialsSuspend.actions;

export default postFinancialsSuspend.reducer;
