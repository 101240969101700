import { all } from "redux-saga/effects";
import orderActiveConnectSaga from "./orderActiveConnect";
import channelListConnectSaga from "./channelListConnect";
import orderSessionConnectSaga from "./orderSessionConnect";
import verifyOtpConnectSaga from "./verifyOtpConnect";
import logOutConnectSaga from "./logOutConnect";
import postOrderActiveConnectVPSSaga from "./postOrderActiveConnectVPS";

export default function* managementConnectSaga() {
  yield all([
    orderActiveConnectSaga(),
    channelListConnectSaga(),
    orderSessionConnectSaga(),
    verifyOtpConnectSaga(),
    logOutConnectSaga(),
    postOrderActiveConnectVPSSaga(),
  ]);
}
