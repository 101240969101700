// import { toast } from "react-toastify";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { getAccountInfoApi } from "src/core/http/service/staticInfo";
// import { logOutSuccess } from "src/modules/Auth/redux/auth";
import {
  accountInfoFailed,
  accountInfoStart,
  accountInfoSuccess,
} from "../redux/accountInfo";

function* accountInfoWork() {
  try {
    const data = yield call(getAccountInfoApi);
    yield put({
      type: accountInfoSuccess.type,
      payload: {
        ...data[0],
      },
    });
  } catch (error: any) {
    yield put({
      type: accountInfoFailed.type,
    });
    // toast.error("Lỗi xảy ra khi lấy thông tin account, mời đăng nhập lại");
    // yield put({
    //   type: logOutSuccess.type,
    // });
  }
}

function* watchlistAccountsStatic() {
  yield takeLatest(accountInfoStart.type, accountInfoWork);
}

export default function* listAccountsStaticSaga() {
  yield all([watchlistAccountsStatic()]);
}
