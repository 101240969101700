import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { shareWithdrawApi } from "src/core/http/service/stockTrading";
import { RootState } from "src/redux-store/store";
import {
  shareWithdrawHandFailed,
  shareWithdrawHandStart,
  shareWithdrawHandSuccess,
} from "../redux/shareWithdrawHand";

function* shareWithdrawHandStartWork() {
  const { accountCode, shareCode } = yield select(
    (state: RootState) => state.stockTradingHand.formOrderHand.inputForm
  );
  const params = {
    accountCode: accountCode?.value,
    shareCode: shareCode?.value,
    shareStatus: "02",
  };

  try {
    const res = yield call(shareWithdrawApi, params);
    yield put({
      type: shareWithdrawHandSuccess,
      payload: res,
    });
  } catch (error: any) {
    yield put({
      type: shareWithdrawHandFailed,
    });
  }
}

function* watchshareWithdrawHand() {
  yield takeLatest(shareWithdrawHandStart.type, shareWithdrawHandStartWork);
}

export default function* shareWithdrawHandSaga() {
  yield all([watchshareWithdrawHand()]);
}
