import { all } from "redux-saga/effects";
import listOrdersHandSaga from "./listOrdersHand";
import orderTradingHandSaga from "./orderTradingHand";
import getBuyInfoHandSaga from "./getBuyInfoHand";
import shareWithdrawHandSaga from "./shareWithdrawHand";
import reportBuyingPowerHandSaga from "./reportBuyingPowerHand";
import orderCancelHandSaga from "./orderCancelHand";
import deleteOrderHandSaga from "./deleteOrderHand";
import portfolioAccountsHandSaga from "./portfolioAccountsHand";
import importExcelOrderSaga from "./importExcelOrder";
import channelDetailHandSaga from "./channelDetailHand";

export default function* stockTradingHandSaga() {
  yield all([
    listOrdersHandSaga(),
    orderTradingHandSaga(),
    getBuyInfoHandSaga(),
    importExcelOrderSaga(),
    shareWithdrawHandSaga(),
    reportBuyingPowerHandSaga(),
    orderCancelHandSaga(),
    deleteOrderHandSaga(),
    portfolioAccountsHandSaga(),
    channelDetailHandSaga(),
  ]);
}
