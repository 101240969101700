import { FC, ReactNode } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

import Sidebar from "./Sidebar";
import Header from "./Header";

interface SidebarLayoutProps {
  children?: ReactNode;
}

const MainWrapper = styled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
        padding-left: ${theme.sidebar.width};
        z-index: 1;
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        height: calc(100vh - ${theme.header.height});
        flex: 1 1 auto;
        overflow: auto;
`
);

const SidebarLayout: FC<SidebarLayoutProps> = ({ children }) => {
  return (
    <>
      <Header />
      <Sidebar />
      <MainWrapper>
        <MainContent>{children}</MainContent>
      </MainWrapper>
    </>
  );
};

export default SidebarLayout;
