import { PortfolioItem } from "src/domain/models/channelPortfolio";
import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { createSelector } from "@reduxjs/toolkit";
import { getChannelPortfoliosApi } from "src/core/http/service/channelPortfolio";

import {
  getPortfoliosStart,
  getPortfoliosSuccess,
  callApiFailed,
} from "../redux/portfolio";

import { filterChannelPortfolioState } from "../redux/selector";
import { FilterState } from "../redux/filter";

const filterSelector = createSelector(filterChannelPortfolioState, (state) => ({
  filter: state.filter,
}));

function* getChannelPortfolios(action: ReturnType<typeof getPortfoliosStart>) {
  try {
    const { filter } = yield select(filterSelector);
    const params: FilterState = {
      ...filter,
    };
    const data: PortfolioItem[] = yield call(getChannelPortfoliosApi, params);
    yield put({
      type: getPortfoliosSuccess.type,
      payload: {
        items: data,
      },
    });
  } catch (error: any) {
    yield put(callApiFailed(error));
  }
}

function* watchGetPortfolios() {
  yield takeLatest(getPortfoliosStart.type, getChannelPortfolios);
}
export default function* getPortfoliosSaga() {
  yield all([watchGetPortfolios()]);
}
