import { Box } from "@mui/material";
import { createSelector } from "@reduxjs/toolkit";
import { useMemo, useRef } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import LogoEasyInvest from "src/assets/images/logo_easy_invest.png";
// import DevelopLogo from "src/assets/images/logo_develop.png";
import Button from "src/components/Button";
import TextField from "src/components/Forms/TextField";
import InputPassword from "src/components/InputPassword";
import Modal from "src/components/Modal";
import { enterCallBack, enterFocus, handleValidate } from "src/helper/validate";
import { ReduxStateType } from "src/redux-store/types";
import { authStart } from "../../redux/auth";
import { auth2faStart, resetAuth2fa } from "../../redux/auth2fa";
import { updateInputAuth } from "../../redux/formAuth";
import { updateInputFormForgetPass } from "../../redux/formForgetPass";
import { updateInputFormRegister } from "../../redux/formRegister";
import { registerStart } from "../../redux/register";
import { resetPasswordStart } from "../../redux/resetPassword";
import {
  auth2faSelector,
  formAuth,
  formForgetPass,
  formRegister,
  register,
  resetPassword,
} from "../../redux/selector";
import FormForGetPass from "../ForgetPass";
import FormRegister from "../Register";
import ModalOtp from "./ModalOtp";
import {
  TextError,
  TitleAction,
  // TitleBy,
  Wrap,
  WrapIcon,
  WrapLogin,
  WrapMain,
} from "./style";
import { errorCode, validateInputEmail } from "./validate";
import SettingsAuth2FA from "src/modules/SettingsAuth2FA/pages";

const styleModal = {
  "& .MuiPaper-root": {
    width: "520px",
    maxHeight: "100%",
    maxWidth: "520px",
    padding: "0 25px",
  },
};
const styleModalGuide = {
  "& .MuiPaper-root": {
    width: "1024px",
    maxHeight: "100%",
    maxWidth: "1024px",
    padding: 0,
  },
};
const registerSelector = createSelector(register, (state) => ({
  statusRegister: state.status,
}));
const resetPasswordSelector = createSelector(resetPassword, (state) => ({
  statusResetPassword: state.status,
}));
const formAuthSelector = createSelector(formAuth, (state) => ({
  accountCode: state.inputAuth.accountCode,
  password: state.inputAuth.password,
  showRegister: state.inputAuth.showRegister,
  showForgetPass: state.inputAuth.showForgetPass,
  checkValidateAuth: state.inputAuth.checkValidateAuth,
}));
const formRegisterSelector = createSelector(formRegister, (state) => ({
  accountCodeRegister: state.inputFormRegister.accountCodeRegister,
  accountName: state.inputFormRegister.accountName,
  emailRegister: state.inputFormRegister.emailRegister,
  mobile: state.inputFormRegister.mobile,
  bankNumber: state.inputFormRegister.bankNumber,
  bankCode: state.inputFormRegister.bankCode,
  referrerCode: state.inputFormRegister.referrerCode,
}));
const formForgetPassSelector = createSelector(formForgetPass, (state) => ({
  emailForgetPass: state.inputFormForgetPass.emailForgetPass,
}));

function Auth() {
  const dispatch = useDispatch();
  const {
    accountCode,
    password,
    showRegister,
    showForgetPass,
    checkValidateAuth,
  } = useSelector(formAuthSelector);
  const { accountCodeRegister, accountName, emailRegister } =
    useSelector(formRegisterSelector);
  const { is2faTele, is2faMail, isTeleRegister } = useSelector(auth2faSelector);
  const { emailForgetPass } = useSelector(formForgetPassSelector);
  const { statusRegister } = useSelector(registerSelector);
  const { statusResetPassword } = useSelector(resetPasswordSelector);

  const handleInput = (e: any, name: string) => {
    dispatch(
      updateInputAuth({
        [name]: e.target.value,
      })
    );
  };
  const handleSubmit = () => {
    if (accountCode && password) {
      dispatch(auth2faStart());
      dispatch(
        updateInputAuth({
          checkValidateAuth: false,
        })
      );
    } else {
      dispatch(
        updateInputAuth({
          checkValidateAuth: true,
        })
      );
    }
  };
  // hotkey
  const inputAccountCodeRef = useRef(null);
  const inputPasswordRef = useRef(null);

  // các hàm handle của Register
  const handleSubmitRegister = () => {
    if (accountCodeRegister && accountName && codeEmail === errorCode.SUCCESS) {
      if (accountCodeRegister.trim().length > 8)
        return toast.error("Tài khoản phải nhỏ hơn 9 ký tự");
      dispatch(
        updateInputFormRegister({
          checkValidate: false,
        })
      );
      dispatch(registerStart());
    } else {
      dispatch(
        updateInputFormRegister({
          checkValidate: true,
        })
      );
    }
  };
  const handleOpenRegister = () => {
    dispatch(
      updateInputAuth({
        showRegister: true,
      })
    );
  };
  const handleCloseRegister = () => {
    dispatch(
      updateInputAuth({
        showRegister: false,
      })
    );
  };
  // các hàm handle của forgetPass
  const handleOpenForgetPass = () => {
    dispatch(
      updateInputAuth({
        showForgetPass: true,
      })
    );
  };
  const handleCloseForgetPass = () => {
    dispatch(
      updateInputAuth({
        showForgetPass: false,
      })
    );
  };
  const handleSubmitForgetPass = () => {
    if (codeEmailForgetPass === errorCode.SUCCESS) {
      dispatch(
        updateInputFormForgetPass({
          checkValidate: false,
        })
      );
      dispatch(resetPasswordStart());
    } else {
      dispatch(
        updateInputFormForgetPass({
          checkValidate: true,
        })
      );
    }
  };

  const handleCloseModalOTP2fa = () => {
    batch(() => {
      dispatch(resetAuth2fa());
    });
  };
  const handleAcceptOTP2fa = () => {
    dispatch(authStart());
  };

  const handleCloseModalGuide = () => {
    dispatch(resetAuth2fa());
  };

  const codeEmail = useMemo(() => {
    return validateInputEmail(emailRegister);
  }, [emailRegister]);
  const codeEmailForgetPass = useMemo(() => {
    return validateInputEmail(emailForgetPass);
  }, [emailForgetPass]);

  const isShowModalOtp = () => {
    if (is2faMail) return true;
    if (is2faTele && isTeleRegister) return true;
    return false;
  };
  const isShowGuideTele = () => {
    if (!is2faMail) {
      if (is2faTele && !isTeleRegister) return true;
    }
    return false;
  };
  return (
    <Wrap>
      <WrapLogin>
        <WrapIcon>
          <img alt="Team Develop" src={LogoEasyInvest} style={{ width: 100 }} />
        </WrapIcon>
        <WrapMain>
          <Box style={{ position: "relative", marginTop: 5 }}>
            <TextField
              autoFocus
              label="Tài khoản"
              value={accountCode}
              inputRef={inputAccountCodeRef}
              onChange={(e) => handleInput(e, "accountCode")}
              error={
                handleValidate(checkValidateAuth, accountCode) ? true : false
              }
              onKeyDown={(e) => enterFocus(e, inputPasswordRef)}
            />
            <TextError>
              {handleValidate(checkValidateAuth, accountCode)}
            </TextError>
          </Box>
          <Box style={{ position: "relative", marginTop: 20 }}>
            <InputPassword
              label="Mật khẩu"
              ref={inputPasswordRef}
              value={password}
              onChange={(e) => handleInput(e, "password")}
              error={handleValidate(checkValidateAuth, password) ? true : false}
              onKeyDown={(e) => enterCallBack(e, handleSubmit)}
            />
            <TextError>{handleValidate(checkValidateAuth, password)}</TextError>
          </Box>
          <Box style={{ marginTop: 20 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
            >
              Đăng nhập
            </Button>
          </Box>
          <Box display="flex" marginTop="8px" justifyContent="space-between">
            <TitleAction onClick={handleOpenRegister}> Đăng ký</TitleAction>
            <TitleAction onClick={handleOpenForgetPass}>
              {" "}
              Quên mật khẩu
            </TitleAction>
          </Box>
          <Box display="flex" marginTop="8px" justifyContent="space-between">
            <TitleAction />
            <TitleAction> Hotline: 0384.213.520</TitleAction>
          </Box>
          {/* <Box display="flex" justifyContent="center" alignItems="center">
            <TitleBy>Develop by</TitleBy>
            <img
              alt="Team Develop"
              src={DevelopLogo}
              style={{ width: 50, marginLeft: 3 }}
            />
          </Box> */}
        </WrapMain>
      </WrapLogin>
      <ToastContainer />
      <Modal
        sx={styleModal}
        open={showRegister}
        isLoadingAccept={statusRegister === ReduxStateType.LOADING}
        minWidthButton="130px"
        closeText="Hủy"
        acceptText="Đăng ký"
        content={<FormRegister handleSubmitRegister={handleSubmitRegister} />}
        disableEnforceFocus
        onAccept={handleSubmitRegister}
        onClose={handleCloseRegister}
        title={"Đăng ký tài khoản"}
      />
      <Modal
        sx={styleModal}
        open={showForgetPass}
        isLoadingAccept={statusResetPassword === ReduxStateType.LOADING}
        minWidthButton="110px"
        closeText="Hủy"
        acceptText="Gửi"
        content={
          <FormForGetPass handleSubmitForgetPass={handleSubmitForgetPass} />
        }
        disableEnforceFocus
        onAccept={handleSubmitForgetPass}
        onClose={handleCloseForgetPass}
        title={"Lấy lại mật khẩu"}
      />
      {isShowModalOtp() && (
        <Modal
          sx={styleModal}
          open={isShowModalOtp()}
          acceptText="Xác thực"
          content={<ModalOtp />}
          disableEnforceFocus
          onAccept={handleAcceptOTP2fa}
          onClose={handleCloseModalOTP2fa}
          title={"Xác thực OTP"}
        />
      )}
      {isShowGuideTele() && (
        <Modal
          sx={styleModalGuide}
          open={isShowGuideTele()}
          content={<SettingsAuth2FA isShowGuide isGuideLogin />}
          onClose={handleCloseModalGuide}
          title={"Hướng dẫn đăng ký OTP trên Telegram"}
        />
      )}
    </Wrap>
  );
}

export default Auth;
