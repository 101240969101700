import { all } from "redux-saga/effects";
import listOrdersSaga from "./listOrders";
import orderTradingSaga from "./orderTrading";
import getBuyInfoSaga from "./getBuyInfo";
import shareWithdrawSaga from "./shareWithdraw";
import reportBuyingPowerSaga from "./reportBuyingPower";
import orderCancelSaga from "./orderCancel";
import portfolioAccountsSaga from "./portfolioAccounts";
import channelDetailSaga from "./channelDetail";

export default function* stockTradingSaga() {
  yield all([
    listOrdersSaga(),
    orderTradingSaga(),
    getBuyInfoSaga(),
    shareWithdrawSaga(),
    reportBuyingPowerSaga(),
    orderCancelSaga(),
    portfolioAccountsSaga(),
    channelDetailSaga(),
  ]);
}
