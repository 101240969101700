import financeHttp from "src/core/http/singleton/financeService";
import { GET_LIST_REPORT_PORTFOLIO } from "./urls";
import { ReportPortfolioParams } from "./types";
import { ResponseReportPortfolio } from "src/domain/models/reportPortfolio";

//lay danh sach lai lo cua KH
export const getListReportPortfolioApi = async (
  params: ReportPortfolioParams
): Promise<ResponseReportPortfolio> => {
  const res = await financeHttp.get<ResponseReportPortfolio>(
    GET_LIST_REPORT_PORTFOLIO,
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
