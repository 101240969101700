import { combineReducers } from "redux";
import switchControlSlice from "./switchControl";
import listOrdersSlice from "./listOrders";
import orderTradingSlice from "./orderTrading";
import formOrderSlice from "./formOrder";
import shareWithdrawSlice from "./shareWithdraw";
import getBuyInfoSlice from "./getBuyInfo";
import reportBuyingPowerSlice from "./reportBuyingPower";
import orderCancelSlice from "./orderCancel";
import portfolioAccountsSlice from "./portfolioAccounts";
import channelDetailSlice from "./channelDetail";
import filterOrderSlice from "./filterOrder";

export default combineReducers({
  switchControl: switchControlSlice,
  listOrders: listOrdersSlice,
  orderTrading: orderTradingSlice,
  formOrder: formOrderSlice,
  shareWithdraw: shareWithdrawSlice,
  getBuyInfo: getBuyInfoSlice,
  reportBuyingPower: reportBuyingPowerSlice,
  orderCancel: orderCancelSlice,
  portfolioAccounts: portfolioAccountsSlice,
  channelDetail: channelDetailSlice,
  filterOrder: filterOrderSlice,
});
