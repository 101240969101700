import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";

const initialState = {
  data: [],
  optionsChannelList: [],
  status: ReduxStateType.INIT,
};

const channelListStatic = createSlice({
  name: "channelListStaticSlice",
  initialState,
  reducers: {
    channelListStaticStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    channelListStaticSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
      const filterChannelActive = action.payload.filter(
        (item) => item.status === "ACTIVE"
      );
      const optionsChannelList = filterChannelActive.map((item) => ({
        ...item,
        value: item.channelCode,
        label: item.channelName + " - " + item.channelCode,
      }));
      state.data = action.payload;
      state.optionsChannelList = optionsChannelList;
    },
    channelListStaticFailed: (state) => {
      state.status = ReduxStateType.ERROR;
    },
  },
});

export const {
  channelListStaticStart,
  channelListStaticSuccess,
  channelListStaticFailed,
} = channelListStatic.actions;

export default channelListStatic.reducer;
