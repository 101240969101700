import { toast } from "react-toastify";
import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";
import { orderActiveConnectVPSApi } from "src/core/http/service/managementConnect";
import { PostOrderActiveConnectVPSParams } from "src/core/http/service/managementConnect/types";
import { errorCode } from "src/languages/errorCode";
import { resetFormModal } from "../redux/formModal";
import { orderSessionConnectStart } from "../redux/orderSessionConnect";
import {
  postOrderActiveConnectVPSFailed,
  postOrderActiveConnectVPSStart,
  postOrderActiveConnectVPSSuccess,
} from "../redux/postOrderActiveConnectVPS";
import { formModalSelector } from "../redux/selector";

function* postOrderActiveConnectVPSStartWork() {
  // store lấy giá trị mbsManageCode truyền lên
  yield delay(200);
  const { formConnectVPS } = yield select(formModalSelector);
  const { masterAccount, password, channelId, otp } = formConnectVPS;

  const params: PostOrderActiveConnectVPSParams = {
    masterAccount,
    password,
    channelId,
    otp,
  };

  try {
    yield call(orderActiveConnectVPSApi, params);
    toast.success("Xác thực thành công");
    yield put({
      type: postOrderActiveConnectVPSSuccess.type,
    });
    yield put({
      type: resetFormModal.type,
    });
    yield put({
      type: orderSessionConnectStart.type,
    });
  } catch (error: any) {
    yield put({
      type: postOrderActiveConnectVPSFailed.type,
    });
    const errMess = error?.response?.data?.code;
    toast.error(errorCode[errMess] ?? "Xác thực thất bại");
  }
}

function* watchPostOrderActiveConnectVPS() {
  yield takeLatest(
    postOrderActiveConnectVPSStart.type,
    postOrderActiveConnectVPSStartWork
  );
}

export default function* postOrderActiveConnectVPSSaga() {
  yield all([watchPostOrderActiveConnectVPS()]);
}
