import { toast } from "react-toastify";
import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";
import { changeInfoCustomerApi } from "src/core/http/service/layout/header";
import { getAccountInfoStaticStart } from "src/redux-store/commons/staticInfo/redux/getAccountInfoStatic";
import { RootState } from "src/redux-store/store";
import {
  postChangeInfoFailed,
  postChangeInfoStart,
  postChangeInfoSuccess,
} from "../redux/postChangeInfo";

function* postChangeInfoStartWork() {
  yield delay(200);
  const inputInformation = yield select(
    (state: RootState) => state.header.information.inputInformation
  );

  const params = {
    ...inputInformation,
  };
  delete params.checkValidateInfo;
  try {
    yield call(changeInfoCustomerApi, params);
    yield put({
      type: postChangeInfoSuccess.type,
    });
    yield put({
      type: getAccountInfoStaticStart.type,
    });
    toast.success("Cập nhật thông tin TK thành công", { autoClose: 4000 });
  } catch (error: any) {
    const errMess = error?.response?.data?.message;
    toast.error(errMess, { autoClose: 4000 });
    yield put({
      type: postChangeInfoFailed.type,
    });
  }
}

function* watchPostChangeInfo() {
  yield takeLatest(postChangeInfoStart.type, postChangeInfoStartWork);
}

export default function* postChangeInfoSaga() {
  yield all([watchPostChangeInfo()]);
}
