import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux-store/store";

export const userAssetsState = (state: RootState) =>
  state.userAssets.userAssetsSlice;

export const userAssetsSelector = createSelector(userAssetsState, (state) => ({
  dataShareHolding: state.data.shareHolding,
  selectedAccountCode: state.data.selectedAccountCode,
  reportBuyingPower: state.data.reportBuyingPower,
}));
