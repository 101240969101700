import basePathHttp from "src/core/http/singleton/basePathHttp";
import {
  ChannelDetailsRes,
  GetBuyInfoRes,
  ListOrdersAccountRes,
  PortfolioRes,
  ReportBuyingPowerRes,
  ShareWithdrawRes,
  StockTradingRes,
} from "src/domain/models/stocktrading";
import {
  ChannelDetailParams,
  GetBuyInfoParams,
  ListOrdersParams,
  ListOrdersUserParams,
  OrderCancelParams,
  orderTradingParams,
  PortfolioParams,
  ReportBuyingPowerParams,
  ShareWithdrawParams,
} from "./types";
import {
  channelDetail,
  getBuyInfo,
  listOrders,
  listOrdersUser,
  orderCancel,
  orderTrading,
  portfolio,
  reportBuyingPower,
  shareWithdraw,
} from "./urls";

export const listOrdersApi = async (
  params: ListOrdersParams
): Promise<ListOrdersAccountRes> => {
  const res = await basePathHttp.get<ListOrdersAccountRes>(listOrders, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const listOrdersUserApi = async (
  params: ListOrdersUserParams
): Promise<ListOrdersAccountRes> => {
  const res = await basePathHttp.get<ListOrdersAccountRes>(
    listOrdersUser,
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const orderTradingApi = async (
  params: orderTradingParams
): Promise<StockTradingRes> => {
  const res = await basePathHttp.post<StockTradingRes>(orderTrading, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const getBuyInfoApi = async (
  params: GetBuyInfoParams
): Promise<GetBuyInfoRes> => {
  const res = await basePathHttp.get<GetBuyInfoRes>(getBuyInfo, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const shareWithdrawApi = async (
  params: ShareWithdrawParams
): Promise<ShareWithdrawRes> => {
  const res = await basePathHttp.get<ShareWithdrawRes>(shareWithdraw, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const reportBuyingPowerApi = async (
  params: ReportBuyingPowerParams
): Promise<ReportBuyingPowerRes> => {
  const res = await basePathHttp.get<ReportBuyingPowerRes>(
    reportBuyingPower,
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const orderCancelApi = async (
  params: OrderCancelParams
): Promise<StockTradingRes> => {
  const res = await basePathHttp.delete<StockTradingRes>(orderCancel, {
    data: params,
  });
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const portfolioApi = async (
  params: PortfolioParams
): Promise<PortfolioRes> => {
  const res = await basePathHttp.get<PortfolioRes>(portfolio, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const channelDetailApi = async (
  params: ChannelDetailParams
): Promise<ChannelDetailsRes> => {
  const res = await basePathHttp.get<ChannelDetailsRes>(channelDetail, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
