import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxData, ReduxStateType } from "src/redux-store/types";
import moment from "moment";

export type ExportOrderHistoryState = {
  showModalCondition: boolean;
  exportDate: string;
};

const initialState: ReduxData<ExportOrderHistoryState> = {
  data: {
    showModalCondition: false,
    exportDate: moment().format("YYYY-MM-DD"),
  },
  status: ReduxStateType.INIT,
};

const exportExelSlice = createSlice({
  name: "exportExelOrder",
  initialState,
  reducers: {
    changeShowModalCondition: (
      state: ReduxData<ExportOrderHistoryState>,
      action: PayloadAction<boolean>
    ) => {
      state.data.showModalCondition = action.payload;
    },
    changeExportDate: (
      state: ReduxData<ExportOrderHistoryState>,
      action: PayloadAction<string>
    ) => {
      state.data.exportDate = action.payload;
    },
  },
});

export const { changeShowModalCondition, changeExportDate } =
  exportExelSlice.actions;

export default exportExelSlice.reducer;
