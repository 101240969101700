export const listCompany = [
  {
    value: "",
    label: "Tất cả",
  },
  {
    value: "MBS",
    label: "MBS",
  },
  {
    value: "TCBS",
    label: "TCBS",
  },
];
export const listStatus = [
  {
    value: "",
    label: "Tất cả",
  },
  {
    value: "ACTIVE",
    label: "Hoạt động",
  },
  {
    value: "INACTIVE",
    label: "Ngừng hoạt động",
  },
];

export const STATUS_INACTIVE = "INACTIVE";
export const STATUS_ACTIVE = "ACTIVE";
export const ACTION_UPDATE = "UPDATE";
export const ACTION_CREATE = "CREATE";
export const ERROR_INPUT = "Vui lòng nhập thông tin";
export const ERROR_INPUT_RATE = "Lãi suất lớn hơn 0";
export const DATE_DIFF = {
  SHOW_ALL: 365,
  SHOW_ACCOUNT: 91,
};

export const styleBorderTable = {
  top: { style: "thin", color: { argb: "000000" } },
  left: { style: "thin", color: { argb: "000000" } },
  bottom: { style: "thin", color: { argb: "000000" } },
  right: { style: "thin", color: { argb: "000000" } },
};
export const valueAccount = {
  ALL_ITEMS: "",
};

export const styleFontTable = { name: "Calibri (Body)" };

export const typePrice = (orderType: string) => {
  if (orderType === "ATC" || orderType === "ATO") return true;
  return false;
};
