import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";

interface InitialState {
  status: ReduxStateType;
}
const initialState: InitialState = {
  status: ReduxStateType.INIT,
};

const verifyOtpConnect = createSlice({
  name: "verifyOtpConnectSlice",
  initialState,
  reducers: {
    verifyOtpConnectStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    verifyOtpConnectSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
    },
    verifyOtpConnectFailed: (state) => {
      state.status = ReduxStateType.ERROR;
    },
  },
});

export const {
  verifyOtpConnectStart,
  verifyOtpConnectSuccess,
  verifyOtpConnectFailed,
} = verifyOtpConnect.actions;

export default verifyOtpConnect.reducer;
