const paths = {
  auth: "/",
  crypto: "/dashboards/Crypto",
  transactions: "management/transactions",
  managementAccount: "management/account",
  managementCustomer: "management/customer",
  financeService: "finance-service",
  channel: "management/channel",
  tranferMoney: "transfer/money",
  managementConnect: "management/connect",
  stockTrading: "command/stockTrading",
  stockTradingHand: "command/stockTradingHand",
  assetValueReport: "asset-values-report",
  userAssetsReport: "report/user-assets",
  orderHistory: "order-history",
  reportSynthetic: "report/synthetic",
  recommendations: "recommendations",
  reportPortfolioRealize: "report/portfolio-realize",
  reportCategoryCustomer: "report/category-customer",
  forControlReport: "report/for-control",
  dividend: "management/dividend",
  portfolioChannel: "report/portfolio-channel",
  reportPortfolio: "report/portfolio",
  reportOrderCommission: "report/order-commission",
  referralAccount: "referral-account",
  reportFeeSynthetic: "report/fee-synthetic",
  policies: "/policies",
  introduce: "/introduce",
  settingsAuth2FA: "/settings-auth-2fa",
};

export default paths;
