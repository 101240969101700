import HttpClient from "src/core/http";
export default class recommendationHttp extends HttpClient {
  private static classInstance?: recommendationHttp;
  private constructor() {
    super(process.env.REACT_APP_RESTFUL_BASE_URL || "");
  }
  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new recommendationHttp();
    }
    return this.classInstance;
  }
}
