import { toast } from "react-toastify";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { orderCancelApi } from "src/core/http/service/stockTrading";
import { OrderCancelParams } from "src/core/http/service/stockTrading/types";
import { RootState } from "src/redux-store/store";
import { listOrdersStart } from "../redux/listOrders";
import {
  orderCancelFailed,
  orderCancelStart,
  orderCancelSuccess,
  resetDeleteIDs,
} from "../redux/orderCancel";
import { reportBuyingPowerStart } from "../redux/reportBuyingPower";
import { listOrdersSelector } from "../redux/selector";

function* orderCancelStartWork() {
  const { deleteIDs } = yield select(
    (state: RootState) => state.stockTrading.orderCancel
  );
  const { dataListOrders } = yield select(listOrdersSelector);
  const params: OrderCancelParams = {
    ids: deleteIDs,
  };

  try {
    const res = yield call(orderCancelApi, params);
    const errorList = res?.errorList ?? [];
    const lengthErrorList = errorList.length;
    if (lengthErrorList === 0) {
      toast.success("Hủy lệnh thành công");
    } else if (lengthErrorList > 0) {
      errorList.forEach((itemForEach) => {
        const isItem = dataListOrders.find(
          (itemFind) => itemForEach === itemFind?.cId
        );
        toast.error(`Lệnh hủy bị lỗi ${isItem?.cOrderNo}`, { autoClose: 6000 });
      });
    }
    yield put({
      type: orderCancelSuccess,
    });
    yield put(resetDeleteIDs());
    yield put(listOrdersStart());
    yield put(reportBuyingPowerStart());
  } catch (error: any) {
    const errMess = error?.response?.data?.message;
    toast.error(errMess, { autoClose: 4000 });
    yield put({
      type: orderCancelFailed,
    });
  }
}

function* watchOrderCancel() {
  yield takeLatest(orderCancelStart.type, orderCancelStartWork);
}

export default function* orderCancelSaga() {
  yield all([watchOrderCancel()]);
}
