import { all, call, put, takeLatest } from "redux-saga/effects";
import { listAuthorizeApi } from "src/core/http/service/managementAccount";
import { ResListAuthorize } from "src/domain/models/managementAccount";
import {
  listAuthorizeFailed,
  listAuthorizeStart,
  listAuthorizeSuccess,
} from "../redux/listAuthorize";

function* listAuthorizeStartWork() {
  // store lấy giá trị mbsManageCode truyền lên
  const params = {
    accountCode: null,
    accountName: null,
    limit: 10000,
    offset: 0,
    status: "ACTIVE",
    roles: ["ROLE_USER"],
  };
  // Lấy tất các user đang active và các user khách được ủy quyền
  try {
    const res: ResListAuthorize[] = yield call(listAuthorizeApi, params);
    const optionList = res.map((item) => ({
      value: item?.accountCode,
      label: item?.accountCode + "-" + item?.accountName,
    }));
    yield put({
      type: listAuthorizeSuccess,
      payload: optionList,
    });
  } catch (error: any) {
    yield put({
      type: listAuthorizeFailed,
    });
  }
}

function* watchlistAuthorize() {
  yield takeLatest(listAuthorizeStart.type, listAuthorizeStartWork);
}

export default function* listAuthorizeSaga() {
  yield all([watchlistAuthorize()]);
}
