import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux-store/store";

export const FilterFinanceServiceState = (state: RootState) =>
  state.financeService.filterFinanceServiceSlice;
export const financeServiceSlelector = (state: RootState) =>
  state.financeService;

export const InsertFinanceServiceState = (state: RootState) =>
  state.financeService.insertFinanceServiceSlice;

export const ImportListGDKHQState = (state: RootState) =>
  state.financeService.importListGDKHQSlice;

export const controlFinancialModalSelector = createSelector(
  financeServiceSlelector,
  (state) => state.controlFinancialModalSlice.controlModal
);
