import { Box } from "@mui/system";
import React, { ReactNode } from "react";
import Typography from "../Typography";
import { BoxProps } from "@mui/material";
interface PropsBoxField extends BoxProps {
  labelWidth?: string;
  label?: string;
  children?: ReactNode;
  required?: boolean;
  row?: boolean;
}
function BoxField({
  label = "",
  labelWidth = "270px",
  children,
  required = false,
  row = true,
  ...rest
}: PropsBoxField) {
  return (
    <Box
      {...rest}
      width="100%"
      display={row && "flex"}
      alignItems={row && "center"}
    >
      <Typography sx={{ width: labelWidth, marginBottom: row ? null : 1 }}>
        {required ? (
          <Typography>
            {label} <span style={{ color: "red" }}> (*)</span>
          </Typography>
        ) : (
          label
        )}
      </Typography>
      {children}
    </Box>
  );
}

export default BoxField;
