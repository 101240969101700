import { combineReducers } from "redux";
import filterAccountSlice from "./filterAccount";
import addAccountSlice from "./addAccount";
import updateAccountSlice from "./updateAccount";
import filterTableSlice from "./filterTable";
import formAccountSlice from "./formAccount";
import listAuthorizeSlice from "./listAuthorize";

export default combineReducers({
  filterAccount: filterAccountSlice,
  addAccount: addAccountSlice,
  updateAccount: updateAccountSlice,
  filterTable: filterTableSlice,
  formAccount: formAccountSlice,
  listAuthorize: listAuthorizeSlice,
});
