import { all, call, put, takeLatest, select } from "redux-saga/effects";

import {
  getOrderCommissionStart,
  getOrderCommissionSuccess,
  getOrderCommissionFailed,
} from "../redux/getReportOrderCommission";

import { filterOrderCommissionSelector } from "../redux/selector";
import { FilterState } from "../redux/filter";
import { getListReportOrderCommissionApi } from "src/core/http/service/reportOrderCommission";
import { DATE_DIFF } from "src/modules/OrderHistory/const";
import { roles as rolesAccount } from "src/helper/const";
import { createSelector } from "@reduxjs/toolkit";
import { auth } from "src/modules/Auth/redux/selector";
import moment from "moment";
import { OrderCommissionItems } from "src/domain/models/reportOrderCommission";

const authSelector = createSelector(auth, (state) => ({
  roles: state.dataAuth.roles,
}));
function* getOrderCommission(
  action: ReturnType<typeof getOrderCommissionStart>
) {
  try {
    const { filter } = yield select(filterOrderCommissionSelector);
    const { roles } = yield select(authSelector);
    const fromDate = moment(filter?.fromDate, "DD/MM/YYYY").toDate();
    const toDate = moment(filter?.toDate, "DD/MM/YYYY").toDate();
    if (roles[0] !== rolesAccount.ROLE_ADMIN) {
      if (moment(toDate).diff(fromDate, "days") > DATE_DIFF.SHOW_ALL) {
        yield put(
          getOrderCommissionFailed("Từ ngày , đến ngày không vượt quá 365 ngày")
        );
        return;
      }
    }
    const params: FilterState = {
      ...filter,
    };
    const data: OrderCommissionItems[] = yield call(
      getListReportOrderCommissionApi,
      params
    );
    yield put({
      type: getOrderCommissionSuccess.type,
      payload: {
        items: data,
      },
    });
  } catch (error: any) {
    yield put(getOrderCommissionFailed(error));
  }
}

function* watchGetOrderCommission() {
  yield takeLatest(getOrderCommissionStart.type, getOrderCommission);
}
export default function* getOrderCommissionSaga() {
  yield all([watchGetOrderCommission()]);
}
