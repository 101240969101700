import { lazy } from "react";
import { roles } from "src/helper/const";
import paths from "src/routes/pathRoutes";
const UserAssetsLazy = lazy(() => import("./pages"));

export const userAssetsRoutes = [
  {
    page: UserAssetsLazy,
    path: paths.userAssetsReport,
    roleSide: roles.ROLE_USER,
  },
];
