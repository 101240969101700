import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
interface InitialState {
  data: {
    precision: number;
  };
  status: ReduxStateType;
}

const initialState: InitialState = {
  data: {
    precision: 0,
  },
  status: ReduxStateType.INIT,
};

const shareWithdrawHand = createSlice({
  name: "shareWithdrawHandSlice",
  initialState,
  reducers: {
    shareWithdrawHandStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    shareWithdrawHandSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
      state.data = action.payload;
    },
    shareWithdrawHandFailed: (state) => {
      state.status = ReduxStateType.ERROR;
      state.data = initialState.data;
    },
  },
});

export const {
  shareWithdrawHandStart,
  shareWithdrawHandSuccess,
  shareWithdrawHandFailed,
} = shareWithdrawHand.actions;

export default shareWithdrawHand.reducer;
