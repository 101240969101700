import basePathHttp from "src/core/http/singleton/basePathHttp";
import { ResHeader } from "src/domain/models/header";
import { ChangePassParams, InformationParams } from "./types";
import { changePass, information } from "./urls";

export const changeInfoCustomerApi = async (
  params: InformationParams
): Promise<ResHeader> => {
  const res = await basePathHttp.post<ResHeader>(information, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const changePasswordApi = async (
  params: ChangePassParams
): Promise<ResHeader> => {
  const res = await basePathHttp.post<ResHeader>(changePass, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
