import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { createSelector } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getAssetsValueStart,
  getAssetsValueSuccess,
  getAssetsValueFailed,
} from "../redux/assetsValueReport";

import {
  assetsValueReportState,
  listPortfolioValueReportState,
} from "../redux/selector";
import {
  ResponseDetailAssetValueReport,
  ResponseDetailPortfolio,
} from "src/domain/models/assetsValueReport";
import {
  getDetailAssetsApi,
  getListPortfolioApi,
} from "src/core/http/service/assetValueReport";
import {
  getListPortfolioFailed,
  getListPortfolioStart,
  getListPortfolioSuccess,
} from "../redux/portfolioAssetsValueReport";
import { errorDescription } from "src/languages/errorCode";

const getDetailAssetsValueReportSelector = createSelector(
  assetsValueReportState,
  (state) => ({
    masterAccount: state.data.masterAccount,
    account: state.data.account,
    id: state.data.id,
  })
);

const getListPortfolioAssetsValueReportSelector = createSelector(
  listPortfolioValueReportState,
  (state) => ({
    masterAccount: state.data.masterAccount,
    account: state.data.account,
    offset: state.data.offset,
    limit: state.data.limit,
  })
);

function* getDetailAssetsValueReport(
  action: ReturnType<typeof getAssetsValueStart>
) {
  try {
    const { masterAccount, account, id } = yield select(
      getDetailAssetsValueReportSelector
    );
    const params = {
      masterAccount: masterAccount,
      account: account,
      channelId: id,
    };
    const data: ResponseDetailAssetValueReport = yield call(
      getDetailAssetsApi,
      params
    );
    yield put({
      type: getAssetsValueSuccess.type,
      payload: {
        detailAssetValue: data,
      },
    });
  } catch (error: any) {
    yield put(getAssetsValueFailed(error.response.message));
    const errCode = error?.response?.data?.code;
    toast.error(errorDescription[errCode], { autoClose: 4000 });
  }
}

function* getListPortfolioAssetsValeReport(
  action: ReturnType<typeof getListPortfolioStart>
) {
  try {
    const { id } = yield select(getDetailAssetsValueReportSelector);
    const { masterAccount, account, offset, limit } = yield select(
      getListPortfolioAssetsValueReportSelector
    );
    const params = {
      masterAccount: masterAccount,
      account: account,
      channelId: id,
      offset: offset,
      limit: limit,
    };
    const data: ResponseDetailPortfolio = yield call(
      getListPortfolioApi,
      params
    );
    yield put({
      type: getListPortfolioSuccess.type,
      payload: {
        dataDetail: data,
      },
    });
  } catch (error: any) {
    yield put(getListPortfolioFailed(error.response.description));
  }
}

function* watchGetAssetsValueReport() {
  yield takeLatest(getAssetsValueStart.type, getDetailAssetsValueReport);
}

function* watchGetListPortfolioAssetsValueReport() {
  yield takeLatest(
    getListPortfolioStart.type,
    getListPortfolioAssetsValeReport
  );
}
export default function* getChannelsSaga() {
  yield all([
    watchGetAssetsValueReport(),
    watchGetListPortfolioAssetsValueReport(),
  ]);
}
