const errors = {
  defaultErr: "Đã có lỗi xảy ra, vui lòng kiểm tra lại",
  errorServer: "Connect server fail",
  ERROR101: "Buying account invalid. You can not execute",
  ERROR102:
    "Advance account the purchasing power is not advance withdraw without import orders.",
  ERROR103: "Payment account is not exist. You can not execute",
  ERROR104: "Account had been limited Chat24!",
  ERROR105: "Account closed.",
  ERROR106: "Account is invalid.",
  ERROR107:
    "Money transfer can not be done from this account due to the agreement signed with MBS to use Finance Services. Please contact to MBS's Private Client Service department.",
  ERROR108: "Receiving account is not in the list of accounts registered",
  ERROR109: "Receiving account does not exist or was closed.",
  ERROR110:
    "Receiving account does not exist in the account of beneficiary list,",
  ERROR111: "Invalid account receiver.",
  ERROR112: "Cannot link purchasing power to authorized account",
  ERROR113: "Link account  invalid.",
  ERROR114: "Account not approve.",
  ERROR115:
    "Transferring account does not exist in the authorized account list,",
  ERROR116: "Account does not exist or was CLOSED.",
  ERROR117: "Account is not eligible for this feature.",
  ERROR118: "Account transfer and account receiver invalid,",
  ERROR119: "Account out is bcc account or had loan BCC",
  ERROR120: "Invalid account transfer.",
  ERROR121: "Account is not valid. You can not execute",
  ERROR122: "Each stock cannot be set up more than one warning,",
  ERROR123: "Please select the account received",
  ERROR124: "Account is not margin account",
  ERROR125: "Account received and account transfered invalid",
  ERROR126: "Accounts transferred to the accounts received identical",
  ERROR127: "Please select the account transfer",
  ERROR128: "Content invalid",
  ERROR129: "Share invalid",
  ERROR130: "Share not exists",
  ERROR131: "Transferred share must not exceed withdrawable share",
  ERROR132: "New type must not the same current type.",
  ERROR133: "xxx",
  ERROR134: "xxx",
  ERROR135: "Success!",
  ERROR136: "Over count liquid. You can not execute",
  ERROR137:
    "The expiration date of appendix can not exceed the expiration date of the contract ",
  ERROR138: "Appendix does not exist. You can not execute",
  ERROR139: "Appendix status is not valid. You can not execute",
  ERROR140: "Advanced withdraw approval error!,",
  ERROR141: "Error approving share transfer.",
  ERROR142: "This entry can be modified only in status is 'NOT YET PROCESSED',",
  ERROR143: "Account is not fully authorized. You can not execute",
  ERROR144: "Account holders may not have done due authorization.",
  ERROR145: "Balance is not enough to make this transaction.",
  ERROR146: "Contract blocked.",
  ERROR147: "Account of beneficiary already exists.",
  ERROR148: "Not allowed to add new account payee bank,",
  ERROR149: "Not updated beneficiary account when using transfer restrictions",
  ERROR150: "BIDV_Link has disconnected online, please choose other Bank_Link.",
  ERROR151: "Transfer via BIDV link fail,",
  ERROR152:
    "Sub_ contract is suspended for purchasing power. You cannot execute ",
  ERROR153: "Operation error!,            ",
  ERROR154:
    "System is temporarily out of service. Please come back in a few minutes.",
  ERROR155: "Transferring and receiving account cannot be a same account.",
  ERROR156: "The value is not met",
  ERROR157:
    "Today's online resources are temporarily expired, Kindly contact MBS staffs at all transaction offices for the service,",
  ERROR158: "Cash balance not enough,",
  ERROR159: "Advanced withdrawable ammount exceeded.",
  ERROR160:
    "Transfer failed connection system with associated loss of BIDV, customers please choose other affiliate banks,",
  ERROR161:
    "The system is in the process of processing data. Please visit again later.",
  ERROR162: "Content is invalid.",
  ERROR163: "Account does not exist. You can not execute",
  ERROR164: "Contract status is not valid. You can not execute",
  ERROR165: "Buying contract does not exist. You can not execute.",
  ERROR166:
    "System is temporarily out of service. Please come back in a few minutes.",
  ERROR167: "ID card has expired.",
  ERROR168: "Contact Address",
  ERROR169: "Emergency Contact ",
  ERROR170: "Contact Name",
  ERROR171: "Relationship",
  ERROR172: "Telephone",
  ERROR173: "Current Address",
  ERROR174: "Email",
  ERROR175: "Currently Reside in VN",
  ERROR176: "Mobile Phone",
  ERROR177: "Permanent address",
  ERROR178: "Home Phone",
  ERROR179: "You may only exercise one time per day online for a stock symbol.",
  ERROR180: "Extension type is not valid. You can not execute",
  ERROR181: "Disconnected online, please use the 'Transfer Out'.",
  ERROR182: "Account margin has no fees.",
  ERROR183: "Account is using BCC/T+ service. You can not execute",
  ERROR184: "File approved.",
  ERROR185: "Appendix is suspended buying money. You can not execute",
  ERROR186: "Account has valid appendix. You can not execute",
  ERROR187: "Accounts are limited transfer / withdrawal.",
  ERROR188: "Accounts are limited advance withdrawal.",
  ERROR189: "Client has framework contract ",
  ERROR190: "Account is not opened framework contract. You can not execute",
  ERROR191: "Advanced withdraw update error!,            ",
  ERROR192: "Invalid PIN, please input PIN again!",
  ERROR193: "Can not liquidate intraday appendix",
  ERROR194: "Account out is had loan margin/BCC+",
  ERROR195:
    "System is temporarily out of service. Please come back in a few minutes.",
  ERROR196: "Bank account name is not valid.",
  ERROR197: "Can not connect to Bank,",
  ERROR198:
    "BCC Plus will not carry out disbursement after 16h and/or on holiday.",
  ERROR199: "Account was not authorized to transfer money.",
  ERROR200: "Command cannot be executed out of transaction time.",
  ERROR201: "Out of transaction time.",
  ERROR202: "Received Amount",
  ERROR203: "Reject",
  ERROR204: "Pending Amount",
  ERROR205: "Disconnected online, please use the 'Transfer Out'.",
  ERROR206: "Connected online money transfer, please use the 'Transfer to MB'.",
  ERROR207: "Buy",
  ERROR208: "Not defined",
  ERROR209: "Sell",
  ERROR210: "Approval",
  ERROR211: "Pending",
  ERROR212: "Matched",
  ERROR213: "Not defined",
  ERROR214: "Pending",
  ERROR215: "Reject",
  ERROR216: "UnMatched",
  ERROR217: "Waiting",
  ERROR218: "Cancel",
  ERROR219: "Appendix other term is not link buying power!",
  ERROR220: "Out of subscription time.",
  ERROR221: "The bank application deadline has expired. Limit remaining:,",
  ERROR222: "Contract limit has expired. Limit remaining:",
  ERROR223: "Over customer CREDIT!,",
  ERROR224: "Out of MBS's Limit!,",
  ERROR225: "Max out of online limit:,",
  ERROR226: "Exercised rights",
  ERROR227: "Pending rights",
  ERROR228: "Buyable ammount exceeded.",
  ERROR229: "Can not connect to server! ,",
  ERROR230: "Stock code does not exist.",
  ERROR231: "Can not tranfer share odd lot,",
  ERROR232:
    "Mã CK còn số dư lô lẻ trên các trạng thái khác. Quý khách vui lòng chuyển chứng khoán về trạng thái Thường trước khi thực hiện.",
  ERROR233: "Can not sell share with handle status.",
  ERROR234: "Another deal is processing. Pls contact to customer care.  ",
  ERROR235: "Invalid odd volume, pls check share balance in other account.",
  ERROR236: "Share status invalid",
  ERROR237: "Can not handle online banking at day T+2.",
  ERROR238: "Term or capital level is no longer applied",
  ERROR239: "Can not use other term",
  ERROR240: "Account out is had loan margin+",
  ERROR241: "Daily number of transactions exceeded.",
  ERROR242: "Transaction ammount cannot be greater than maximum ammount.",
  ERROR243: "Daily transferable amount exceeded.",
  ERROR244: "Transaction ammount cannot be less than minimum ammount.",
  ERROR245: "TRANSACTION LOG cannot be updated.",
  ERROR246: "Advanced withdraw source update error.",
  ERROR247: "Error updating share transfer.",
  ERROR248: "System is LOCKED. Modifications cannot be updated.",
  ERROR2: "Order needs approval",
  ERROR3: "Order needs approval",
  ERROR_6: "Market is closed",
  ERROR_8: "Order process is not ready",
  ERROR_9: "Order query process is not ready",
  ERROR_10: "Query process is not ready",
  ERROR_11: "System cannot connect to Exchange",
  ERROR_12: "System is suspended",
  ERROR_13: "Oracle query process is not ready",
  ERROR_28: "Exchange is not accept orders",
  ERROR_1001: "Account is not existed",
  ERROR_1002: "Account is duplicated",
  ERROR_1003: "Account is closed",
  ERROR_2001: "Ticker is not existed",
  ERROR_2002: "Ticker is duplicated",
  ERROR_2003: "Stock is halted",
  ERROR_2004: "Stock is suspend",
  ERROR_3001: "Buy/sell is in the same session",
  ERROR_3002: "Order No is not existed",
  ERROR_3003: "Order No is duplicated",
  ERROR_3004: "Aproval order is done",
  ERROR_3005: "Order cann't be cancelled",
  ERROR_3006: "Matched order cann't be changed",
  ERROR_3007: "Number of orders are over foreign investors room",
  ERROR_3008: "Order cann't be cancelled or changed in the period session",
  ERROR_3009: "The state of membership is locked to send buying orders",
  ERROR_3010: "The state of membership is locked to send selling orders",
  ERROR_3011:
    "The state of membership is locked to send buying/selling ordersr",
  ERROR_3012: "Price is out range",
  ERROR_3013: "Price step is invalid",
  ERROR_3014: "Volume is invalid",
  ERROR_3015: "Market is suspended",
  ERROR_3016: "Price is invalid for puthrough order",
  ERROR_3017: "Volume is invalid",
  ERROR_3018: "Format of Put_Through Price is invalid",
  ERROR_3019: "Matched order cann't be cancelled",
  ERROR_3020: "Cancelled order cann't be cancelled",
  ERROR_3022: "Not enough cash to change price",
  ERROR_3023: "Not allow to buy/sell ATC at the moment",
  ERROR_3024: "Not allow to buy/sell at the moment. Pls cancel ATC order",
  ERROR_3025:
    "Not allow to buy/sell same price at the moment. Pls cancel order same price.",
  ERROR_3026:
    "Not allow to buy anh sell a stock at the same time in Call Market",
  ERROR_3030: "Cannot cancel, change the awated verifying orders.",
  ERROR_3091: "Cannot change orders",
  ERROR_4001: "Cash balance is not enough to trade",
  ERROR_4002: "Cash balance is duplicated",
  ERROR_4003: "Not enough Buying Power",
  ERROR_4004:
    "Transaction exceeds Service limit. Please kindly place orders on Sub account 1.",
  ERROR_4005: "Out of MBS's Limit",
  ERROR_4006: "Not allow to trade over daily's credit line",
  ERROR_4007: "Not allow to buy over risk rate",
  ERROR_4008: "Special share code. Over credit limit",
  ERROR_4444: "Account is not allowed",
  ERROR_4445: "Dupplicated order is prevented",
  ERROR_5001: "stock balance is not enough to trade",
  ERROR_5003: "stock balance is not enough to trade",
  ERROR_5005: "Not enough money to buy a stock which is over margin’s room ",
  ERROR_5006: "Stock is not in the Margin Approved List ",
  ERROR_5007:
    "Not enough money to buy a stock which is not in Margin Approved List ",
  ERROR_5009: "Over FAL credit",
  ERROR_5010: "FAL share code invalid",
  ERROR_5011: "Account had share code trading limited",
  ERROR_5012: "Over safe rate after buy",
  ERROR_5013: "Over securities FAL credit limit",
  ERROR_5014: "Over FAL service credit",
  ERROR_5015: "Over FAL MBS credit",
  ERROR_5016: "Over FAL daily approve credit",
  ERROR_5017: "Over FAL approve credit",
  ERROR_6010: "Pin code is invalid",
  ERROR_6776: "MP order is not order corresponding order ",
  ERROR_6777: "Account is not signed in",
  ERROR_6778: "Verified code is invalid",
  ERROR_6779: "User is not allowed",
  ERROR_6780: "Ticker is not existed",
  ERROR_6781: "Ticker is halted",
  ERROR_6782: "Ticker is not allowed to trade(buy or sell)",
  ERROR_6783: "Volume invalid",
  ERROR_6784: "Volume Step Unit is invalid",
  ERROR_6786: "Order cann't be placed in outtime",
  ERROR_6787: "ATC order cann't be placed",
  ERROR_6788: "Cannot trade MP price (ES6788)",
  ERROR_6789: "Price is greater than ceiling",
  ERROR_6790: "Price is less than floor",
  ERROR_6791: "Volume is invalid",
  ERROR_6792: "Error! Data can not be found",
  ERROR_6793: "Market closed, unable to order",
  ERROR_6795: "Ticker is suspended",
  ERROR_6796: "Order submitted unsuccessfully: Over Foreign Ownership Room",
  ERROR_6800: "Symbol has not had matched volume (ES6800)",
  ERROR_6801: "Cannot trade MP price (ES6801)",
  ERROR_6802: "Volume Step Unit is invalid (ES6802)",
  ERROR_6901: "Acount cann't be bought",
  ERROR_6902: "Account cann't place a buy_order",
  ERROR_6998: "Cann't order in outtime",
  ERROR_6999: "Order channel was locked",
  ERROR_9001: "Order hasn't been supported by system yet.",
  ERROR_9007: "This business hasn't been supported ",
  ERROR_9566: "Unable to change the order",
  ERROR_9900: "Order hasn't been verified by stock exchange yet.",
  ERROR_9901: "Order hasn't been supported by system yet.",
  ERROR_999: "Error is undefined",
  ERROR_9991: "Board lot unit invalid",
  ERROR_9999: "Database error",
  ERROR7: "PIN invalid!",
  ERROR6605: "Invalid password",
  ERROR1001: "Account does not exist",
  ERROR249: "You have not entered a PIN code to confirm",
  ERROR250: "The new quota limit is not allowed to exceed the maximum limit",
  ERROR251:
    "The new quota limit is not allowed to be less than the minimum limit",
  ERROR252:
    "The new limit value must be different from the current limit value",
  ERROR253: "",
  ERROR254:
    "The margin contract has not been approved, you can not make the limit change",
  ERROR_8001: "Invalid A/O",
  ERROR_8003: "Branch not exits",
  ERROR_8004: "Sub Branch not exits",
  ERROROLS0000: "SUCCESS",
  ERROROLS0001: "NO DATA",
  ERROROLS0002: "SYSTEM EXCEPTION",
  ERROROLS0003: "Invalid Action ",
  ERROROLS0004: "Invalid core response",
  ERROROLS0005:
    "Service Deny! Please check you account service Status. Contact support for more detail",
  ERROROLS0006: "Invalid parameter request",
  ERROROLS0011: "Wrong Account Number or Password",
  ERROROLS0012: "Login not yet or Session is expired",
  ERROROLS0013: "Old password not match",
  ERROROLS0014: "Wrong Security Code",
  ERROROLS0015: "Authenticate is failed ",
  ERROROLS0016: "Your password was expired, please change your password ",
  ERROROLS0017: "Your password should be changed",
  ERROROLS0018: "User trading online status was not enabled",
  ERROROLS0019: "Invalid account ",
  ERROROLS0020:
    "Invalid Cash amount to Withdraw (Max Amount %s, Withdraw Amount %s)",
  ERROROLS0021: "Invalid Cash amount",
  ERROROLS0022: "Account %s has been prohibited %s",
  ERROROLS0023: "Invalid PIN code",
  ERROROLS0024: "Client did not register traing online ",
  ERROROLS0025: "Invalid email",
  ERROROLS0026: "Invalid phone number",
  ERROROLS0027: "Price is outside ceiling and floor",
  ERROROLS0028: "Invalid transfer time",
  ERROROLS0036: "Trigger price  must be lower than last price ",
  ERRORDHSIEF0106: "Trigger price  must be greater than last price ",
  ERRORHSIEF0009: "Series VNFE VN30F2012 Not Found Error",
  ERRORHSIEF0025: "Invalid State To Cancel Order Error!",
  ERRORHSIEF0033: "Account is awatting for VDS's approval,",
  ERRORHSIEF0035:
    "Internet Account Locked Error. Please contact {phone} for assistance.",
  ERRORHSIEF0039: "Change quantity to zero is not allow!",
  ERRORHSIEF0088:
    "{market} {symbol} volume {quantity} exceed market limit {market_qty}!",
  ERRORHSIEF0093: "Total position quantity 69 exceeds position limit 10",
  ERRORHSIEF0106: "Stop Price is not available",
  ERRORHSIEF0114: "Invalid market status",
  ERRORHSIEF0154: "Price {price} exceeds ceiling price {ceil}!",
  ERRORHSIEF0155: "Price {price} lower than floor price {floor}!",
  ERRORHSIEF0161: "Available Collateral value at VSD {money} is negative!",
  ERRORHSIEF0162:
    "Available Collateral value of an account counted by company {0} is negative!",
  ERRORHSIEF0163: "Invalid Order Price for spread 0.1",
  ERRORHSIEF0173: "Series expired already! Place ordr is not allowed",
  ERRORHSIEF0178: "Price and Qty not changed.",
  ERRORHSIE90196:
    "Invalid Cash amount to Deposit (Max Amount {0}, Current Amount {1}).",
  ERRORHSIE90197:
    "Invalid Cash amount to Withdraw (Max Amount {0}, Withdraw Amount {1})",
  ERROR001: "Authentication code from client not match",
  ERROR002: "SMS OTP Template has not set up yet",
  ERROR003: "Invalid infomation user",
  ERROR004: "Invalid device",
  ERROR005: " Invalid SMS OTP code",
  ERROR006: "Not found infomation device (NEW)",
  ERROR007: "Not found SMS OTP for authentication",
  ERROR008: "SMS OTP is expired",
  ERROR009: "SMS OTP is not correct",
  ERROR010: "Invalid PIN code",
  ERROR011: "Not found infomation device (VERIFY)",
  ERROR012: "Not found infomation device (ACTIVE)",
  ERROR013: "PIN code is not correct",
  error_message_default:
    "There was an error connecting to the server, please try again",
  ERRORMSG2001: "Authen code is not valid",
  ERRORMSG2002: "SMS OTP template is not valid",
  ERRORMSG2003: "UserId is not valid",
  ERRORMSG2004: "Device Id is not valid",
  ERRORMSG2005: "SMS OTP is not valid",
  ERRORMSG2006: "Device not found",
  ERRORMSG2007: "SMS OTP not found",
  ERRORMSG2008: "SMS OTP expired",
  ERRORMSG2009: "SMS OTP incorrect",
  ERRORMSG2010: "PinCode is not valid",
  ERRORMSG2011: "Device not found(VERIFY)",
  ERRORMSG2012: "Device not found(ACTIVE)",
  ERRORMSG2013: "PinCode incorrect",
  ERRORMSG2014: "Smart OTP incorrect",
  ERROR_1011:
    "Exceed 30 days. You choose From date to date corresponding to 30 days in any time.",
  ERROR_1012: "No valid sale date",
  ERROR_8101: "Token has expired",
  ERROR_8102: "Invalid token",
  ERRORHSIEF0066: "Invalid price",
  ERROR999202: "Suspension system service",
  ERROR1: "Can not connect to server!",
  ERROR4: "TRANSACTION LOG cannot be updated.",
  ERROR5: "Account of beneficiary already exists.",
  ERROR6:
    "System is temporarily out of service. Please come back in a few minutes.",
  ERROR8: "Account does not exist or was CLOSED.",
  ERROR9: "Account not approve.",
  ERROR10: "Account closed.",
  ERROR11: "Account is not eligible for this feature.",
  ERROR12: "Transferring account does not exist in the authorized account list",
  ERROR13:
    "Receiving account does not exist in the account of beneficiary list",
  ERROR14: "Command cannot be executed out of transaction time.",
  ERROR15: "Account was not authorized to transfer money.",
  ERROR16: "Account holders may not have done due authorization.",
  ERROR17: "ID card has expired.",
  ERROR18: "Balance is not enough to make this transaction.",
  ERROR19: "Transferring and receiving account cannot be a same account.",
  ERROR20: "This entry can be modified only in status is 'NOT YET PROCESSED'",
  ERROR21: "Accounts are limited transfer / withdrawal.",
  ERROR22: "Accounts are limited advance withdrawal.",
  ERROR23: "Operation error!",
  ERROR24: "Advanced withdraw update error!",
  ERROR25: "Advanced withdraw approval error!",
  ERROR26: "Out of transaction time.",
  ERROR27: "Transaction ammount cannot be less than minimum amount",
  ERROR28: "Transaction ammount cannot be greater than maximum ammount.",
  ERROR29: "Daily number of transactions exceeded.",
  ERROR30: "Daily transferable amount exceeded.",
  ERROR31: "Account margin has no fees.",
  ERROR32:
    "BCC Plus will not carry out disbursement after 16h and/or on holiday.",
  ERROR33: "Disconnected online, please use the 'Transfer Out'.",
  ERROR34:
    "System is temporarily out of service. Please come back in a few minutes.",
  ERROR35:
    "System is temporarily out of service. Please come back in a few minutes.",
  ERROR36: "Disconnected online, please use the 'Transfer Out'",
  ERROR37: "BIDV-Link has disconnected online, please choose other Bank-Link.",
  ERROR38: "Connected online money transfer, please use the 'Transfer to MB'.",
  ERROR39: "Out of MBS's Limit!",
  ERROR40: "Over customer CREDIT!",
  ERROR41: "Contract blocked.",
  ERROR42: "File approved.",
  ERROR43: "Advanced withdrawable ammount exceeded.",
  ERROR44: "Cash balance not enough",
  ERROR45:
    "Today's online resources are temporarily expired, Kindly contact MBS staffs at all transaction offices for the service",
  ERROR46: "Advanced withdraw source update error.",
  ERROR47: "Buyable ammount exceeded.",
  ERROR48: "Out of subscription time.",
  ERROR49: "Each stock cannot be set up more than one warning",
  ERROR50:
    "Money transfer can not be done from this account due to the agreement signed with MBS to use Finance Services. Please contact to MBS's Private Client Service department.",
  ERROR51: "Stock code does not exist.",
  ERROR52: "Account is invalid.",
  ERROR53: "Invalid account transfer.",
  ERROR54: " account receiver.",
  ERROR55: "Account transfer and account receiver invalid",
  ERROR56: "Content is invalid.",
  ERROR57: "Error updating share transfer.",
  ERROR58: "Error approving share transfer.",
  ERROR60: "Account out is had loan margin/BCC+",
  ERROR62: "Account out is had loan margin+",
  ERROR63: "Can not tranfer share odd lot",
  ERROR64: "Receiving account is not in the list of accounts registered",
  ERROR65: "Not updated beneficiary account when using transfer restrictions",
  ERROR66:
    "Advance account the purchasing power is not advance withdraw without import orders.",
  ERROR67: "Can not connect to Bank",
  ERROR68: "Not allowed to add new account payee bank",
  ERROR69: "Transfer via BIDV link fail",
  ERROR70:
    "Transfer failed: connection system with associated loss of BIDV, customers please choose other affiliate banks",
  ERROR71: "Bank account name is not valid.",
  ERROR72: "Max out of online limit",
  ERROR73: "Can not handle online banking at day T+2.",
  ERROR74: "The bank application deadline has expired",
  ERROR75: "Contract limit has expired",
  ERROR76: "",
  ERROR77: "Transfer time is 2 consecutive times too close together",
  ERROR78: "Đã có lỗi xảy ra, vui lòng kiểm tra lại",
  ERROR79: "",
  ERROR80: "",
  ERROR81: "",
  ERROR82: "",
  ERROR83: "",
  ERROR84: "",
  ERROR85: "",
  ERROR86: "",
  ERROR87: "",
  ERROR88:
    "System is temporarily out of service. Please come back in a few minutes.",
  ERROR89: "Receiving account does not exist or was closed.",
  ERROR99: "System is LOCKED. Modifications cannot be updated.",
  ERROR800001: "Invalid information",
  ERROR800002: "Change password failed. Invalid Old password",
  ERROR262: "MB account does not exist - Recipient  account is incorrect",
  ERROR263: "MB account is locked",
  ERROR264: "MB account has been closed",
  ERROR265: "Another reason from MB",
  ERROR266: "There is a problem connecting to the MB",
  ERROR98: "Disconnect to MB",
  ERROR268: "Account no not match with Account holder. Please enter again!",
  ERROR_999899: "System is currently suspending receiving orders",
  ERROR802001:
    "Utilities are implemented during 08:30:00 to 15:45:00 on working days",
  ERROR802002: "Out of trading hours. You cannot make money transfers",
  ERROR907100:
    "Margin contract has not been approved/amended, you cannot make limit changes!",
  ERROR907101: "An error occurred",
  ERROR907102: "An error occurred",
  ERROR907103: "An error occurred",
  ERROR907104: "An error occurred",
  ERROR907105: "An error occurred",
  ERROR907106: "An error occurred",
  ERROR907107:
    "Invalid broker id! Please contact our customer service hotline at 19009088 for assistance.",
  ERROR907108: "",
  ERROR907109: "The time allowed for this function has been exceeded",
  ERROR907110: "",
  ERROR907111: "",
  ERROR907112: "New renewal date cannot be less than the current date!",
  ERROR907113: "The renewal date cannot be on holiday!",
  ERROR907114: "Renewal interest rate is not valid!",
  ERROR907115: "The loan is currently being blocked for renewal!",
  ERROR907116: "Current account is blocked to extend all loans!",
  ERROR907117: "Do not extend the loan on a ladder basis!",
  ERROR907118:
    "The number of renewal days exceeds the maximum number of extension days allowed, if you have a need to renew, please send the renewal request in writing to MBS for evaluation!",
  ERROR907119:
    "The number of renewal days exceeds the maximum number of online extension days allowed, if you have a need to renew, please send the renewal request in writing to MBS for evaluation!",
  ERROR907120: "Do not renew the loan with a debt due!",
  ERROR907121:
    "Loan is in status of NOT CONTROL, please contact care staff or Customer Care Center (1900 9088) for support and answer",
  ERROR907122:
    "Loans are in status of NOT APPROVED, please contact care staff or Customer Care Center (1900 9088) for support and answers.",
  ERROR907124: "NEW_CREDIT_VAL_SMALLER_MIN",
  ERROR907125: "Hạn mức yêu cầu giống hạn mức hiện tại",
  ERROR907126:
    "The margin contract has not been approved, you can not make the limit change",
  ERROR907123: "The new quota limit is not allowed to exceed the maximum limit",
  ERROR802039:
    "You may only exercise one time per day online for a stock symbol.",
  ERROR802038: "Payment account is not exist. You can not execute",
  ERROR802037: "Account was not authorized to transfer money.",
  ERROR802036: "Account not approve.",
  ERROR802035: "ACCOUNT_CLOSE",
  ERROR802034: "Account does not exist or was CLOSED.",
  ERROR802033: "Balance is not enough to make this transaction.",
  ERROR802032: "Out of subscription time.",
  ERROR802031: "Buyable ammount exceeded.",
  ERROR802030: "Money transfer cannot be made from a limited account.",
  ERROR802029:
    "Transferring account does not exist in the authorized account list.",
  ERROR802028: "Account is not eligible for this feature.",
  ERROR802027: "BCC_PLUS_CONTRACT_BLOCK",
  ERROR802026: "OVER_CUSTOMER_CREDIT",
  ERROR802025: "OVER_TOTAL_VVF_CREDIT",
  ERROR802000: "Volume is bigger than available",
  ERROR802003: "CODE_ERROR_GET_SHARE_TRANSFER_STATUS",
  ERROR802004: "CODE_ERROR_GET_SHARE_TRANSFER_STATUS_HISTORY",
  ERROR802005: "CODE_ERROR_GET_RIGHT_REGISTER",
  ERROR802006: "CODE_ERROR_GET_RIGHT_REGISTER_HISTORY",
  ERROR802007: "Đã có lỗi xảy ra. Vui lòng thử lại",
  ERROR802008: "",
  ERROR802009: "",
  ERROR802010: "Share status invalid",
  ERROR802011: "Account out is bcc account or had loan BCC",
  ERROR802012: "Invalid account transfer.",
  ERROR802013: "Invalid account receiver.",
  ERROR802014: "Account transfer and account receiver invalid",
  ERROR802015: "Account out is had loan margin+",
  ERROR802016: "Account out is had loan margin/BCC+",
  ERROR802017: "Stock code does not exist.",
  ERROR802018: "Can not tranfer share odd lot",
  ERROR802020: "You can not update, please logout and relogin.",
  ERROR802021:
    "Money transfer can not be done from this account due to the agreement signed with MBS to use Finance Services. Please contact to MBS's Private Client Service department.",
  ERROR802022: "Deals are being matched. Command cannot be executed.",
  ERROR802023: "Command cannot be executed out of transaction time.",
  ERROR802024: "System is LOCKED. Modifications cannot be updated.",
  ERROR802050:
    "The account has not registered the online order confirmation service.",
  ERROR802040: "An error occurred",
  ERROR802041: "An error occurred",
  ERROR802042: "An error occurred",
  ERROR802043: "ID/Passport is expired (15 years)",
  ERROR802044: "File approved.",
  ERROR802045: "TRANSACTION LOG cannot be updated.",
  ERROR802046:
    "The symbol has odd lot balance on other status. Please transfer it to normal status before executing.",
  ERROR802047: "Can not sell share with handle status.",
  ERROR802048: "Invalid odd volume, pls check share balance in other account.",
  ERROR802049: "Another deal is processing. Pls contact to customer care.",
  ERROR802051:
    "The account has not been approved to register for online order confirmation service",
  error_switch_stock: "The account {} transfer securities state",
  error_switch_tranfer: "The account {} transfer to {}",
  ERROR802052: "Tài khoản phát sinh lệnh FAL. Không cho phép thực hiện",
  ERROR802053: "Tài khoản còn dư nợ FAL. Không cho phép thực hiện",
  ERROR802054: "Khối lượng chuyển vượt qua số tối đa có thể chuyển ",
  ERROR802055: "Chỉ được chuyển khoản giữa các tài khoản của một khách hàng",
  ERROR802056: "Không cho phép với trạng thái nhận này",
  ERROR802057: "Không cho phép chuyển giữa 2 trạng thái này",
  ERROR802058: "Chỉ được chuyển khoản chứng khoán trạng thái 02, 60, 61, 68",
  ERROR802059:
    "Các tiểu khoản dịch vụ chỉ được chuyển các trạng thái 02, 60, 61, 68",
  ERROR802060: "Mã chứng khoán hết room",
  ERROR907139: "Referrer code is not broker",
  ERROR907130: "Account does not exist or was CLOSED.",
  ERROR907127: "You can not update, please logout and relogin.",
  ERROR907128: "System is LOCKED. Modifications cannot be updated.",
  ERROR907129: "Account is not eligible for this feature.",
  ERROR907131:
    "Transferring account does not exist in the authorized account list.",
  ERROR907132: "Account is using BCC/T+ service. You can not execute",
  ERROR907133: "Account is not fully authorized. You can not execute",
  ERROR907134: "Client has framework contract",
  ERROR907135: "This contract has not be modelling",
  ERROR907137: "Not selected auto contract term",
  ERROR907138:
    "The selection term does not exist or is not allowed to be selected",
  ERRORHSIEF0185: "Stop Price is not available",
  ERROR802061: "Số dư chứng khoán không đủ",
  ERROR802062: "MB account does not exist ",
  ERROR802063: "MB account is locked",
  ERROR802064: "MB account has been closed",
  ERROR802065: "Another reason from MB",
  ERROR802066: "There is a problem connecting to the MB",
  ERROR802067: "Disconnect to MB",
  ERROR802068:
    "The Account Number does not match the name of the Account holder. Please re-enter your information!",
  ERROR905100: "An error occurred",
  ERROR905101: "Loại KH không hợp lệ",
  ERROR905102: "Loại KH không hợp lệ",
  ERROR905103: "Khách hàng đã tồn tại",
  ERROR905104: "Tài khoản đã tồn tại",
  ERROR905105: "Tài khoản không hợp lệ",
  ERROR905106: "Dải số tài khoản được cấp đã hết",
  ERROR905107: "Tài khoản không thể cập nhật",
  ERROR905108: "Lỗi nghiệp vụ khi cập nhật dữ liệu",
  ERROR905109: "Số TK đã được sử dụng",
  ERROR905110: "Người dùng không thể thực hiện cập nhật",
  ERROR905111: "Khách hàng không tồn tại",
  ERROR905112: "Tài khoản không tồn tại",
  ERROR905113: "Tài khoản hoặc số điện thoại đã tồn tại liên kết",
  ERROR905114: "Mật khẩu đăng nhập không đúng",
  ERROR905115: "Liên kết tài khoản đã bị hủy hoặc không tồn tại liên kết",
  ERROR905116: "Kích hoạt sự kiện không thành công",
  ERROR905117: "Tài khoản đã liên kết với một số điện thoại khác",
  ERROR905118: "Số điện thoại đã liên kết với một tài khoản khác",
  ERROR905119: "Loại giấy tờ không hợp lệ",
  ERROR905120: "Loại giấy tờ không cho phép ở kênh này",
  ERROR905121: "Kênh nhập không hợp lệ",
  ERROR905122: "Tài khoản tạm khóa",
  ERROR905123: "Tài khoản đã đóng",
  ERROR905124: "Không tìm thấy số tham chiếu này",
  ERROR905125: "Ngoài giờ giao dịch",
  ERROR905126: "Giá trị chuyển khoản sang MBS nhỏ hơn giá trị tối thiểu",
  ERROR905127: "Giá trị chuyển khoản sang MBS lớn hơn giá trị tối đa",
  ERROR905128: "Quý khách chưa đủ 18 tuổi để mở TKCK",
  ERROR905129:
    "ID Nhân viên chăm sóc không hợp lệ. Vui long liên hệ tổng đài 1900 9088  để được hướng dẫn!",
  ERROR905130: "907138",
  ERROR905131:
    "Mã giới thiệu không hợp lệ. Người giới thiệu không đồng thời là Nhân viên quản lý tài khoản hoặc Nhân viên phát triển KH tại MBS!",
  ERROR905132: "Người giới thiệu đã đăng ký!",
  ERROR905133:
    "Mã giới thiệu không hợp lệ. Người giới thiệu không đồng thời là BDS-3!",
  ERROR905134:
    "Mã giới thiệu không hợp lệ. Người giới thiệu phải là khách hàng cá nhân trong nước!",
  ERROR905199: "Lỗi ngoại lệ, lỗi khác",
  ERROR802069: "Mã chứng quyền chưa được duyệt hoặc đã đáo hạn!",
  ERROR900099: "Modul is developing",
  ERROR297: "Không thể hủy HĐ sức mua ứng trước",
  error_mqtt_connect: "Lỗi kết nối dữ liệu Realtime",
  ERROR_11000: "Hệ thống đang bị khóa",
  ERROR_11001: "Không tìm thấy thông tin Trái Phiếu",
  ERROR_11002: "Không tìm thấy Lãi suất theo kỳ hạn",
  ERROR_11003: "Không tính được giá MUA trái phiếu",
  ERROR_11004: "Không tìm thấy thông tin Khách hàng",
  ERROR_11005: "Trái phiếu chưa được DUYỆT hoặc ĐÃ_ĐÓNG",
  ERROR_11006: "Không tìm thấy hợp đồng MUA",
  ERROR_11007:
    "Không được phép xóa hợp đồng MUA. Chỉ được phép xóa khi ĐẶT_CHỖ",
  ERROR_11008: "Không duyệt được hợp đồng MUA",
  ERROR_11009: "Chỉ được duyệt hợp đồng khi ngày giao dịch = ngày duyệt",
  ERROR_11010: "Không tìm thấy thông tin liên kết tài khoản",
  ERROR_11011: "Không tìm thấy hợp đồng BÁN",
  ERROR_11012:
    "Không được phép xóa hợp đồng BÁN. Chỉ được phép xóa khi đang CHỜ",
  ERROR_11013: "Tài khoản không đủ số dư",
  ERROR_11014: "Mã Khách hàng không được để trống",
  ERROR_11015: "Mã trái phiếu không được để trống",
  ERROR_11016: "Mã kỳ hạn không được để trống",
  ERROR_11017: "Lãi suất cho vay phải > 0",
  ERROR_11018: "Trái phiếu không tồn tại hoặc CHƯA DUYỆT",
  ERROR_11019: "Số dư trái phiếu không được <0",
  ERROR_11020: "Không tìm thấy mã TRANSACTION",
  ERROR_11021: "Phải có ngày giao dịch hợp đồng",
  ERROR_11022: "Ngày giao dịch hợp đồng không nhỏ hơn ngày hiện tại",
  ERROR_11023: "Can't buy and sell on same day",
  ERROR_11024: "Ngày BÁN không được nhỏ hơn ngày lập hợp đồng",
  ERROR_11025: "Ngày BÁN không lớn hơn ngày hết hạn trái phiếu",
  ERROR_11026: "Ngày BÁN trái phiếu phải <= ngày nhận coupon kỳ cuối",
  ERROR_11027: "Ngày BÁN trái phiếu phải < ngày nhận coupon kỳ cuối",
  ERROR_11028: "Yêu cầu bắt buộc tick box nếu có ngày hoàn thiện hồ sơ",
  ERROR_11029: "Không tìm thấy thông tin Khách hàng MUA",
  ERROR_11030: "Không tìm thấy thông tin khách hàng BÁN",
  ERROR_11031: "Ngày mở hợp đồng MUA không được để trống",
  ERROR_11032: "Ngày mở hợp đồng BÁN không được để trống",
  ERROR_11033: "Ngày đáo hạn hợp đồng MUA không được để trống",
  ERROR_11034: "Mã hợp đồng BÁN đã tồn tại. Hãy thử lại lần sau.",
  ERROR_11035: "Mã hợp đồng MUA đã tồn tại. Hãy thử lại lần sau.g",
  ERROR_11036: "Số lượng trái phiếu BÁN phải > 0",
  ERROR_11037: "Số lượng trái phiếu MUA phải > 0",
  ERROR_11038: "Số dư BÁN không đủ",
  ERROR_11039: "Không tìm thấy thông tin hợp đồng BÁN",
  ERROR_11040: "Không được phép thay đổi thông tin hợp đồng",
  ERROR_11041: "Chưa khai báo Room kỳ hạn",
  ERROR_11042: "Không ghi nhận số âm cho room trái chủ",
  ERROR_11043: "Kênh giao dịch không được để trống",
  ERROR_11044: "Room kênh trái phiếu chưa được định nghĩa",
  ERROR_11045: "Không ghi nhận số âm cho room đặt chỗ",
  ERROR_11046: "Room kỳ hạn đã hết",
  ERROR_11047: "Không tìm thấy room kỳ hạn",
  ERROR_11048: "Không ghi nhận số âm khi unblock",
  ERROR_11049: "Không ghi nhận số âm cho room duyệt mua",
  ERROR_11050: "Không thể unblock",
  ERROR_11051: "Chưa khai báo Room khách hàng",
  ERROR_11052: "Đã hết room trái chủ được phép MUA",
  ERROR_11053: "Không duyệt được hợp đồng BÁN",
  ERROR_11054: "Không xác định được hợp đồng là BÁN BUÔN hay BÁN LẺ",
  ERROR_11055: "Click đúp",
  ERROR_11056: "Ngày MUA không được nhỏ hơn ngày lập hợp đồng",
  ERROR_11057: "Ngày MUA không được thực hiện vào ngày nghỉ/ngày lễ",
  ERROR_11058:
    "Số lượng trái phiếu BÁN lại không lớn hơn số lượng trái phiếu MUA",
  ERROR_11059: "Lãi suất MUA phải > 0",
  ERROR_11060: "Ngày đáo hạn hợp đồng phải lớn hơn ngày giao dịch",
  ERROR_11061: "Ngày đáo hạn hợp đồng pha",
  ERROR_11062: "Ngày đáo hạn hợp đồng phải <= ngày nhận coupon kỳ cuối",
  ERROR_11063: "Ngày đáo hạn hợp đồng  phải < ngày nhận coupon kỳ cuối",
  ERROR_11064: "Ngày đáo hạn hợp đồng  phải < ngày đáo hạn trái phiếu",
  ERROR_11065: "Ngày đáo hạn hợp đồng không được để trống",
  ERROR_11066: "Ngày giao dịch hợp đồng phải nhỏ hơn ngày đáo hạn trái phiếu",
  ERROR_11067: "Ngày giao dịch hợp đồng phải nhỏ hơn ngày nhận coupon kỳ cuối",
  ERROR_11068: "Thông tin trái phiếu chưa được duyệt",
  ERROR_11069: "Ngày mở hợp đồng MUA không được để trống",
  ERROR_11070: "Ngày đặt chỗ chỉ trước 3 ngày giao dịch",
  ERROR_11071: "Mã hợp đồng không được để trống",
  ERROR_11072: "Không được phép hủy hợp đồng",
  ERROR_11073: "Không tìm thấy hợp đồng kênh phân phối",
  ERROR_11074: "hông tìm thấy thông tin kỳ hạn",
  ERROR_11075: "Không tìm thấy biểu lãi suất phù hợp",
  ERROR_11076: "Xảy ra lỗi khi tính giá BÁN",
  ERROR_11077: "Giá trị MUA phải > 0",
  ERROR_11078: "Số lượng MUA phải lớn hơn số lượng tối thiểu theo quy định",
  ERROR_11079: "Chưa thiết lập room để khách hàng có thể bán trái phiếu",
  ERROR_11080: "Đã hết room Khách hàng có thể bán. Hãy thử 1 ngày Bán khác.",
  ERROR_11081: "Không được nhập số âm (<0)",
  ERROR_11082: "Hiện tại không phải ngày giao dịch trái phiếu.",
  ERROR_11083: "Đang ngoài giờ giao dịch trái phiếu",
  ERROR_11084: "Ngày tạo hợp đồng không được để trống",
  ERROR_11085: "Ngày đặt mua/bán tương lai phải lớn hơn ngày hiện tại",
  ERROR_11086: "Vượt quá giá trị giao dịch tối đa trên 1 lần giao dịch",
  ERROR_11087: "Tài khoản nhận tiền không hợp lệ",
  ERROR_11088:
    "Người mua không phải là NĐT chuyên nghiệp hoặc đã hết hạn là NĐT chuyên nghiệp",
  ERROR_11089: "Không thể thay đổi thông tin tk nhận tiền",
  ERROR_11090: "Hợp đồng đang sửa giao dịch, không hỗ trợ giao dich",
  ERROR_11091: "Không tồn tại nhân viên quản lý ",
  ERROR_11092: "Không tồn tại nhân viên tư vấn",
  ERROR_11093:
    "Đã quá thời gian đăng ký BÁN/SỬA BÁN, bạn không thể thực hiện Giao dịch. Vui lòng liên hệ MBS để được hỗ trợ",
  ERROR_11100: "Xác thực OTP chưa chính xác",
  ERROR_11101: "Chưa thiết lập OTP",
  ERROR_11102: "Lỗi chưa xác định",
  error0_cancel_sell:
    "Hợp đồng đang sửa đổi, Quý Khách chưa thể thực hiện giao dịch",
  error_1_cancel_sell:
    "Hợp đồng đang được cấp Giấy chứng nhận sở hữu, Quý Khách chưa thể thực hiện giao dịch",
  ERROR_11103:
    "Hợp đồng đang được cấp Giấy chứng nhận sở hữu, Quý Khách chưa thể thực hiện giao dịch.",
  ERROR258: "Chưa đến thời gian đăng ký trong ngày",
  ERROR259: "Hết thời gian đƯăng ký trong ngày",
  ERROR260: "Số lượng đăng ký nhỏ hơn số lượng đăng ký mua tối thiểu",
  ERROR261: "Số lượng đăng ký lớn hơn số lượng đăng ký mua tối đa",
  ERROR298: "Bạn đang thao tác quá nhanh. Vui lòng thử lại sau 30s",
  ERROR1080: "Lệnh đặt không hợp lệ",
  ERROR100003: "(100003) Effect date is greater than expire date",
  ERRORFOSEO207: "Vi phạm cảnh báo vị thế",
  ERRORPRO_INVESTOR_ACCOUNT_NOT_FOUND: "Không tìm thấy tài khoản",
  ERRORPRO_INVESTOR_REGISTED: "Đã đăng ký làm NDT chuyên nghiệp",
  ERRORPRO_INVESTOR_INVALID_MBS_SHARE_ROOM: "Hết room TP MBS",
  ERRORPRO_INVESTOR_INVALID_INFO:
    "Giao dịch không thành công, đề nghị thực hiện lại",
  ERRORPRO_INVESTOR_INVALID_VOL:
    "Giao dịch không thành công, khối lượng không hợp lệ",
  ERRORPRO_INVESTOR_CASH_NOT_ENOUGH: "Tiền mặt không đủ mua",
  ERRORPRO_INVESTOR_SET_MBS_SHARE_ROOM_ERROR: "Block room TP MBS lỗi",
  ERRORPRO_IVT_CASH_BLOCK_ERROR: "Block tiền của khách hàng lỗi",
  ERROR_1014:
    "Exceed 180 days. You choose From date to date corresponding to 180 days in any time.",
  ERROR200045:
    "Tài khoản đang có sao chép. Vui lòng dừng sao chép trước khi đóng Copier.",
  ERROR200021: "Tài khoản leader đã đóng. Quý khách vui lòng không nộp tiền.",
  ERROR200001: "Account is registed become to leader",
  ERROR200002: "Input master account is empty",
  ERROR200003: "Paramter CS_LEAD_NAV_MIN_VALUE is null or empty",
  ERROR200004: "Paramter CS_LEAD_TRADE_MONTH_NO is null or empty",
  ERROR200005: "Paramter CS_LEAD_TRADINGDIARY_MONTH_NO is null or empty",
  ERROR200006: "Paramter CS_LEAD_TRADINGDIARY_RATE is null or empty",
  ERROR200007: "Paramter CS_LEAD_PORTFOLIO_POINT is null or empty",
  ERROR200008: "Paramter CS_LEAD_RISK is null or empty",
  ERROR200009: "Input NavValue must greater than 0",
  ERROR200010: "Input tradeMonthNo must greater than 0",
  ERROR200011: "Input portfolioPoint is empty",
  ERROR200012: "Input accountName is empty",
  ERROR200013: "Account is became to copier",
  ERROR200014: "Input leaderMasterAccount is empty",
  ERROR200015: "Input leaderAccount is empty",
  ERROR200016: "Input leaderAccountName is empty",
  ERROR200017: "Input masterAccount is empty",
  ERROR200018: "Input account is empty",
  ERROR200019: "Input accountName is empty",
  ERROR200020: "Master Account is following leader master account",
  ERROR200022: "Master Account is not following leader master account",
  ERROR200023: "Copier Account is not active",
  ERROR200024: "Copier Account is copying leader account",
  ERROR200025: "Stop loss percent need less than 10000",
  ERROR200026: "Stop profit percent need less than 10000",
  ERROR200027: "Tài khoản đã dừng sao chép hoặc đang xử lý dừng sao chép",
  ERROR200028: "Copier Account - Leader account more than 1 records active",
  ERROR200029: "Not found records in CashBalance.",
  ERROR200030: "Not found records in SecBalance.",
  ERROR200031: "Amount need to integer number.",
  ERROR200032: "Param NAV_CHART_DURATION is null or not number",
  ERROR200033: "Master account not satisfy portfolio",
  ERROR200034: "Master account  not satisfy NAV min",
  ERROR200035: "Master account not satisfy TradeMonthNo",
  ERROR200036: "Master account not satisfy TradingDiaryRate",
  ERROR200037: "Error call store synchronous asset CORTASSET_COPIER_CAL. ",
  ERROR200038: "Not found fee info",
  ERROR200039: "Call api cash payment to MBS failed.",
  ERROR200040: "Call api cash withdraw to MBS failed.",
  ERROR200041: "Master Account not a leader",
  ERROR200042: "Master Account is requesting close",
  ERROR200043: "Copier Fee Status is updated, not execute.",
  ERROR200044: "Call api cancel register copier failed. ",
  ERROR200046: "Config CLOSED_DISTANCE_DAYS not number",
  ERROR200047: "TradingDiaryRate is greater than 10000",
  ERROR200048: "CS_LEAD_TRADINGDIARY_RATE is greater than 10000",
  ERROR200049: "Price Max Percent need less than 10000",
  ERROR200050: "Sec Per nav rate need less than 10000",
  ERROR200051: "amount need greater then 0",
  ERROR200052: "Error for send sms/email to copier for close leader",
  ERROR200053: "Call api create copy to MBS failed. copierAccount",
  ERROR200054: "Eror call API to MBS for stop copy. ",
  ERROR200055: "Not found record in CORTCASH_BALANCE. ",
  ERROR200056: "Not compute BOUGHT_AMT in ORDTORDER. ",
  ERROR200057: "Not found AMOUNT in COPTCOPIER_FEE. ",
  ERROR200058: "Not found record in COPTCOPY or many records founded.",
  ERROR200059: "Error call API to MBS for perform fee stop copy",
  ERROR200060: "Error call API to MBS for perform fee",
  ERROR200061: "Error call API to MBS for cash fee copy. ",
  ERROR200062: "Error call API to MBS for nav information. ",
  ERROR200063: "Cash not available for withdraw. ",
  ERROR200064: "Quantity not not available for withdraw. ",
  ERROR200065: "Fee is greater than cash avilable.",
  ERROR200066: "Request for get leader month check to MBS error. ",
  ERROR200067: "Request for get leader rank to MBS error.",
  ERROR200068: "Không đủ số tiển rút. ",
  ERROR200069: "Dang chờ đóng copy.",
  ERROR200070: "Request for close leader to MBS error. ",
  ERROR200071: "Request for register leader to error. ",
  ERROR200072: "Ngày mới phải lớn các ngày cũ!",
  ERROR200073:
    "Call api to MBS cancel register copier failed. Account still have stock.",
  ERROR200074:
    "Call api to MBS cancel register copier failed. Account still have cash.",
  ERROR200091:
    "Call api to MBS cancel register copier failed. Account not found.",
  ERROR200097:
    "Call api to MBS cancel register copier failed. Account is waiting stock return.",
  ERROR200075:
    "Call api to MBS register copier failed. MasterAccount is not find in core.",
  ERROR200076:
    "Call api to MBS register copier failed. MasterAccount is existed in core.",
  ERROR200077: "Call api to MBS register copier failed. Service limited.",
  ERROR200078:
    "Call api to MBS register copier failed. Initialize account is error.",
  ERROR200079: "Call api to MBS register copier failed.",
  ERROR200080:
    "Call api to MBS register leader failed. MasterAccount is not find in core.",
  ERROR200081:
    "Call api to MBS register leader failed. MasterAccount is existed in core.",
  ERROR200082:
    "Call api to MBS register leader failed. Service limited. MasterAccount:",
  ERROR200083:
    "Call api to MBS register leader failed. Initialize account is error.",
  ERROR200084: "Call api to MBS register leader failed.",
  ERROR200085: "Call api to MBS for cash payment. Account not found.",
  ERROR200086:
    "Call api to MBS for cash payment. Only transfer from account 1 to account 9.",
  ERROR200087:
    "Call api to MBS for cash payment. Cash transfer greater than current cash.",
  ERROR200088: "Call api to MBS for cash withdraw. Account not found.",
  ERROR200089:
    "Call api to MBS for cash withdraw. Only transfer from account 9 to account 1.",
  ERROR200090:
    "Call api to MBS for cash withdraw. Cash withdraw greater than current cash.",
  ERROR200092: "Tồn tại tài khoản tiền không cân.",
  ERROR200093: "Tồn tại tài khoản chứng khoán không cân.",
  ERROR200094: "Tồn tại lệnh khớp chưa được đối chiếu.",
  ERROR200095: "Tồn tại lệnh khớp chưa hạch toán T0.",
  ERROR200096: "Tồn tại lệnh khớp chưa được hạch toán T2.",
  ERRORMSG1047: "Account existed",
  ERROR908101:
    "Chức năng đang trong quá trình chạy thử nghiệm nội bộ. Tài khoản quý khách không được phép thực hiện chức năng này.",
  ERROR_1010: "Exceed the search limit 180 days",
  ERROR299: "Giá trị phụ lục > Room tổng còn lại!",
  ERROR300: "Số ngày vay không hợp lệ!",
  ERROR301: "Không tìm thấy thông tin nhân viên BDS3 nội bộ",
  ERROR302: "Số tiền nhỏ hơn giá trị tối thiểu của kỳ hạn!",
  ERROR303: "Số tiền lớn hơn giá trị vay tối đa của kỳ hạn!",
  ERROR304: "Không cho phép PL vay tính sức mua!",
  ERROR_10001:
    "You have entered the wrong OTP verification code too many times. Please try again after 5 minutes.",
  ERROR_6043: "Không đặt lệnh mua bán cùng phiên",
  ERROR_6785: "Giá đặt không hợp lệ",
  ERROR_20606: "Chứng khoán hết hạn mức vay",
  ERROR_5008: "Mã chứng khoán hết room",
};
export default errors;
