import numeral from "numeral";
import { toast } from "react-toastify";
import { ChannelItem } from "src/domain/models/channel";
import { all, call, put, takeLatest, select, delay } from "redux-saga/effects";
import { createSelector } from "@reduxjs/toolkit";
import {
  getChannelsApi,
  createChannelApi,
  updateChannelApi,
  delChannelApi,
} from "src/core/http/service/channel";

import {
  getChannelsStart,
  callApiFailed,
  getChannelsSuccess,
  createChannelStart,
  updateChannelStart,
  deleteChannelStart,
  resetUpdateItem,
  resetFilter,
} from "../redux/channel";

import { channelState } from "../redux/selector";
import { FilterState } from "../redux/types";
import { listAccountsStaticStart } from "src/redux-store/commons/staticInfo/redux/listAccountsStatic";
import { channelListStaticStart } from "src/redux-store/commons/staticInfo/redux/channelListStatic";

const channelSelector = createSelector(channelState, (state) => ({
  filter: state.data.filter,
  updateItem: state.data.updateItem,
  createItem: state.data.createItem,
}));

function* getChannels(action: ReturnType<typeof getChannelsStart>) {
  try {
    const { filter } = yield select(channelSelector);
    const params: FilterState = {
      ...filter,
    };
    yield delay(200);
    const data: ChannelItem[] = yield call(getChannelsApi, params);
    data.map((item) => {
      let channelDetailList = "";
      item.channelDetail.forEach((channel) => {
        channelDetailList = channelDetailList + "," + channel.channelDetailCode;
        if (channel.isDefault === 1) {
          item.channelDetailDefault = channel.channelDetailCode;
        }
      });
      item.channelDetailList = channelDetailList.substring(1);
      item.rate = numeral(item.rate).format("0,0[.]000");
      return item;
    });
    yield put({
      type: getChannelsSuccess.type,
      payload: {
        items: data,
      },
    });
  } catch (error: any) {
    yield put(callApiFailed(error));
  }
}
function* createChannel(action: ReturnType<typeof createChannelStart>) {
  try {
    const { createItem } = yield select(channelSelector);
    const params: ChannelItem = {
      ...createItem,
      rate: Number(createItem.rate),
    };
    yield call(createChannelApi, params);
    toast.success("Thêm thành công");
    yield put(getChannelsStart());
    // === update dữ liệu dùng chung
    yield put(listAccountsStaticStart());
    yield put(channelListStaticStart());
    // ===
  } catch (error: any) {
    yield put(callApiFailed(error));
    yield put(resetFilter());
    yield put(getChannelsStart());
  }
}
function* updateChannel(action: ReturnType<typeof updateChannelStart>) {
  try {
    const { updateItem } = yield select(channelSelector);
    const params: ChannelItem = {
      ...updateItem,
      rate: Number(updateItem.rate),
    };
    toast.success("Cập nhật thành công");
    yield call(updateChannelApi, params);
    yield put(resetUpdateItem());
    yield put(getChannelsStart());
    // === update dữ liệu dùng chung
    yield put(listAccountsStaticStart());
    yield put(channelListStaticStart());
    // ===
  } catch (error: any) {
    yield put(callApiFailed(error));
    yield put(resetFilter());
  }
}
function* deleteChannel(action: ReturnType<typeof deleteChannelStart>) {
  try {
    const { updateItem } = yield select(channelSelector);
    const params: ChannelItem = {
      channelCode: updateItem.channelCode,
    };
    yield call(delChannelApi, params);
    toast.success("Xóa thành công");
    yield put(getChannelsStart());
  } catch (error: any) {
    yield put(callApiFailed(error));
    yield put(resetFilter());
    yield put(getChannelsStart());
  }
}

function* watchGetChannels() {
  yield takeLatest(getChannelsStart.type, getChannels);
}
function* watchCreateChannel() {
  yield takeLatest(createChannelStart.type, createChannel);
}
function* watchUpdateChannel() {
  yield takeLatest(updateChannelStart.type, updateChannel);
}
function* watchDeleteChannel() {
  yield takeLatest(deleteChannelStart.type, deleteChannel);
}
export default function* getChannelsSaga() {
  yield all([
    watchGetChannels(),
    watchCreateChannel(),
    watchUpdateChannel(),
    watchDeleteChannel(),
  ]);
}
