import { combineReducers } from "redux";
import { AppReducerType } from "./types";
import authReducer from "src/modules/Auth/redux";
import languageReducer from "src/languages/redux";
import themesReducer from "src/themes/redux";
import headerReducer from "src/layouts/SidebarLayout/Header/redux";
import sideBarReducer from "src/layouts/SidebarLayout/Sidebar/redux";
import channelReducer from "src/modules/Channel/redux";
import managementAccountReducer from "src/modules/ManagementAccount/redux";
import financeServiceReducer from "src/modules/FinanceService/redux";
import transferMoneyReducer from "src/modules/TransferMoney/redux";
import managementConnectReducer from "src/modules/ManagementConnect/redux";
import stockTradingReducer from "src/modules/StockTrading/redux";
import stockTradingHandReducer from "src/modules/StockTradingHand/redux";
import staticInfoReducer from "src/redux-store/commons/staticInfo/redux";
import assetsValueReportSlice from "src/modules/AssetValueReport/redux";
import orderHistoryReducer from "src/modules/OrderHistory/redux";
import userAssetsReducer from "src/modules/UserAssets/redux";
import reportSyntheticReducer from "src/modules/ReportSynthetic/redux";
import reportCategoryCustomerReducer from "src/modules/ReportCategoryCustomer/redux";
import recommendationReducer from "src/modules/Recommendations/redux";
import profitLossReducer from "src/modules/ReportPortfolioRealize/redux";
import forControlReducer from "src/modules/ForControlReport/redux";
import dividendReducer from "src/modules/Dividend/redux";
import channelPortfolioReducer from "src/modules/ReportPortfolioChannels/redux";
import reportPortfolioReducer from "src/modules/ReportPortfolio/redux";
import reportOrderCommissionReducer from "src/modules/ReportOrderCommission/redux";
import referralAccountReducer from "src/modules/ReferralAccount/redux";
import reportFeeSyntheticReducer from "src/modules/ReportFeeSynthetic/redux";
import settingsAuth2FAReducer from "src/modules/SettingsAuth2FA/redux";

export default combineReducers({
  [AppReducerType.AUTH]: authReducer,
  [AppReducerType.LANGUAGE]: languageReducer,
  [AppReducerType.THEMES]: themesReducer,
  [AppReducerType.HEADER]: headerReducer,
  [AppReducerType.SIDEBAR]: sideBarReducer,
  [AppReducerType.MANAGEMENTACCOUNT]: managementAccountReducer,
  [AppReducerType.CHANNEL]: channelReducer,
  [AppReducerType.FINANCESERVICE]: financeServiceReducer,
  [AppReducerType.TRANSFERMONEY]: transferMoneyReducer,
  [AppReducerType.MANAGEMENTCONNECT]: managementConnectReducer,
  [AppReducerType.STOCKTRADING]: stockTradingReducer,
  [AppReducerType.STOCKTRADINGHAND]: stockTradingHandReducer,
  [AppReducerType.STATICINFO]: staticInfoReducer,
  [AppReducerType.ASSETSVALUEREPORT]: assetsValueReportSlice,
  [AppReducerType.ORDERHISTORY]: orderHistoryReducer,
  [AppReducerType.USERASSETS]: userAssetsReducer,
  [AppReducerType.REPORTSYNTHETIC]: reportSyntheticReducer,
  [AppReducerType.REPORTCATEGORYCUSTOMER]: reportCategoryCustomerReducer,
  [AppReducerType.RECOMMENDATIONS]: recommendationReducer,
  [AppReducerType.PROFITLOSS]: profitLossReducer,
  [AppReducerType.FOR_CONTROL]: forControlReducer,
  [AppReducerType.MANAGEMENTDIVIDEND]: dividendReducer,
  [AppReducerType.CHANNELPORTFOLIO]: channelPortfolioReducer,
  [AppReducerType.REPORTPORTFOLIO]: reportPortfolioReducer,
  [AppReducerType.REPORTORDERCOMMISSION]: reportOrderCommissionReducer,
  [AppReducerType.REFERRALACCOUNT]: referralAccountReducer,
  [AppReducerType.REPORTFEESYNTHETIC]: reportFeeSyntheticReducer,
  [AppReducerType.SETTINGS_AUTH_2_FA]: settingsAuth2FAReducer,
});
