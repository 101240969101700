import { toast } from "react-toastify";
import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";
import { orderTradingApi } from "src/core/http/service/stockTradingHand";
import { orderTradingParams } from "src/core/http/service/stockTradingHand/types";
import { errorCodeNew } from "src/languages/errorCodeNew";
import { RootState } from "src/redux-store/store";
import { resetInputFormOrderHand } from "../redux/formOrderHand";
import { listOrdersHandStart } from "../redux/listOrdersHand";
import {
  orderTradingHandFailed,
  orderTradingHandStart,
  orderTradingHandSuccess,
} from "../redux/orderTradingHand";
import { reportBuyingPowerHandStart } from "../redux/reportBuyingPowerHand";
import moment from "moment";

function* orderTradingHandStartWork() {
  yield delay(400);
  const { accountCode, channelCode, shareCode, price, volume, orderDate } =
    yield select(
      (state: RootState) => state.stockTradingHand.formOrderHand.inputForm
    );

  const { activeTab } = yield select(
    (state: RootState) => state.stockTradingHand.tabsOrderHand.tabs
  );
  const params: orderTradingParams = {
    account: channelCode?.label,
    channelId: channelCode?.key,
    traderCode: accountCode.value,
    accountCode: accountCode.value,
    shareCode: shareCode.value,
    timestamp:
      moment(orderDate).format("YYYY-MM-DD") +
      " " +
      moment().format("HH:mm:ss"),
    price:
      price !== "ATC" || price !== "ATO"
        ? Math.round(Number(price) * 1000)
        : "",
    quantity: Number(volume),
    orderType: price === "ATC" || price === "ATO" ? price : "",
    side: activeTab === "B" ? "B" : "S",
    orderKind: activeTab === "ST0" ? "T0" : "2/8",
  };

  try {
    yield call(orderTradingApi, params);
    toast.success("Đặt lệnh thành công", {
      autoClose: 4000,
    });
    yield put({
      type: orderTradingHandSuccess,
    });
    yield put(resetInputFormOrderHand());
    yield put(listOrdersHandStart());
    yield put(reportBuyingPowerHandStart());
  } catch (error: any) {
    yield put({
      type: orderTradingHandFailed,
    });
    const errCode = error?.response?.data?.code;
    const errMessage = error?.response?.data?.message;
    toast.error(errorCodeNew[errCode] ?? errMessage);
  }
}

function* watchOrderTradingHand() {
  yield takeLatest(orderTradingHandStart.type, orderTradingHandStartWork);
}

export default function* orderTradingHandSaga() {
  yield all([watchOrderTradingHand()]);
}
