import { lazy } from "react";
import { roles } from "src/helper/const";
import paths from "src/routes/pathRoutes";
const financeServiceLazy = lazy(() => import("./pages"));
export const financeServiceRoutes = [
  {
    page: financeServiceLazy,
    path: paths.financeService,
    roleSide: roles.ROLE_USER,
  },
];
