import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { reportBuyingPowerApi } from "src/core/http/service/stockTrading";
import { ReportBuyingPowerParams } from "src/core/http/service/stockTrading/types";
import { ReportBuyingPowerRes } from "src/domain/models/stocktrading";
import { RootState } from "src/redux-store/store";
import {
  reportBuyingPowerFailed,
  reportBuyingPowerStart,
  reportBuyingPowerSuccess,
} from "../redux/reportBuyingPower";

function* reportBuyingPowerStartWork() {
  const { accountCode, shareCode } = yield select(
    (state: RootState) => state.stockTrading.formOrder.inputForm
  );

  const params: ReportBuyingPowerParams = {
    accountCode: accountCode?.value,
    shareCode: shareCode?.value ? shareCode?.value : "",
  };

  try {
    const res: ReportBuyingPowerRes = yield call(reportBuyingPowerApi, params);
    yield put({
      type: reportBuyingPowerSuccess.type,
      payload: res,
    });
  } catch (error: any) {
    yield put({
      type: reportBuyingPowerFailed.type,
    });
  }
}

function* watchReportBuyingPower() {
  yield takeLatest(reportBuyingPowerStart.type, reportBuyingPowerStartWork);
}

export default function* reportBuyingPowerSaga() {
  yield all([watchReportBuyingPower()]);
}
