import { createSlice } from "@reduxjs/toolkit";
interface InitialState {
  inputForm: {
    accountCode: {
      value: string;
      label: string;
      accountName: string;
      channelName: string;
      channelId: string | number;
      channelCode: string;
      channelDetailDefault: string;
      channelDetailList: string;
    };
    shareCode: string;
    volume: string | number;
    price: string | number;
    checkValidate: false;
  };
}
const initialState: InitialState = {
  inputForm: {
    accountCode: {
      value: "",
      label: "",
      accountName: "",
      channelName: "",
      channelId: "",
      channelCode: "",
      channelDetailDefault: "",
      channelDetailList: "",
    },
    shareCode: "",
    volume: "",
    price: "",
    checkValidate: false,
  },
  // tableOrder: {
  // },
};

const formOrder = createSlice({
  name: "formOrderSlice",
  initialState,
  reducers: {
    updateInputForm: (state, action) => {
      Object.assign(state.inputForm, action.payload);
    },
    resetInputFormOrder: (state) => {
      state.inputForm.shareCode = "";
      state.inputForm.volume = "";
      state.inputForm.price = "";
      state.inputForm.checkValidate = false;
    },
    // updateTableOrder: (state, action) => {
    //   Object.assign(state.tableOrder, action.payload);
    // },
    // resetTableOrder: (state) => {
    //   state.tableOrder = initialState.tableOrder;
    // },
    resetFormOrder: () => initialState,
  },
});

export const {
  updateInputForm,
  resetInputFormOrder,
  // updateTableOrder,
  // resetTableOrder,
  resetFormOrder,
} = formOrder.actions;

export default formOrder.reducer;
