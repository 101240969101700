import financeHttp from "src/core/http/singleton/financeService";
import {
  GET_LIST_PORTFOLIO_ASSET_VALUE_REPORT,
  GET_DETAIL_ASSET_VALUE_REPORT,
} from "./urls";
import { AssetsValueReportParams } from "./types";

//lay chi tiet danh muc dau tu
export const getListPortfolioApi = async (
  params: AssetsValueReportParams
): Promise<any> => {
  const res = await financeHttp.get<any>(
    GET_LIST_PORTFOLIO_ASSET_VALUE_REPORT,
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

//lay chi tiet tong tai san
export const getDetailAssetsApi = async (
  params: AssetsValueReportParams
): Promise<any> => {
  const res = await financeHttp.get<any>(GET_DETAIL_ASSET_VALUE_REPORT, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

// export const getChannelsApi = async (
//   params: FilterState
// ): Promise<ChannelItem[]> => {
//   const { ...restParams } = params;
//   const res = await financeHttp.post<ChannelItem[]>(POST_CHANNELS, restParams);
//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };
