import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
interface InitialState {
  status: ReduxStateType;
}
const initialState: InitialState = {
  status: ReduxStateType.INIT,
};

const logOutConnect = createSlice({
  name: "logOutConnectSlice",
  initialState,
  reducers: {
    logOutConnectStart: (state, payload) => {
      state.status = ReduxStateType.LOADING;
    },
    logOutConnectSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
    },
    logOutConnectFailed: (state) => {
      state.status = ReduxStateType.ERROR;
    },
  },
});

export const { logOutConnectStart, logOutConnectSuccess, logOutConnectFailed } =
  logOutConnect.actions;

export default logOutConnect.reducer;
