export enum AppReducerType {
  LANGUAGE = "language",
  THEMES = "themes",
  CHANNEL = "channel",
  AUTH = "authenication",
  HEADER = "header",
  SIDEBAR = "sideBar",
  MANAGEMENTACCOUNT = "managementAccount",
  FINANCESERVICE = "financeService",
  TRANSFERMONEY = "transferMoney",
  MANAGEMENTCONNECT = "managementConnect",
  STOCKTRADING = "stockTrading",
  STOCKTRADINGHAND = "stockTradingHand",
  STATICINFO = "staticInfo",
  ASSETSVALUEREPORT = "assetsValueReport",
  ORDERHISTORY = "orderHistory",
  USERASSETS = "userAssets",
  REPORTSYNTHETIC = "reportSynthetic",
  RECOMMENDATIONS = "recommendations",
  PROFITLOSS = "profitLoss",
  FOR_CONTROL = "forControl",
  MANAGEMENTDIVIDEND = "managementDividend",
  CHANNELPORTFOLIO = "channelPortfolio",
  REPORTCATEGORYCUSTOMER = "reportCategoryCustomer",
  REPORTPORTFOLIO = "reportPortfolio",
  REPORTORDERCOMMISSION = "reportOrderCommissions",
  REFERRALACCOUNT = "referralAccount",
  REPORTFEESYNTHETIC = "reportFeeSynthetic",
  SETTINGS_AUTH_2_FA = "settingsAuth2FA",
}

export enum ReduxStateType {
  INIT = "init",
  LOADING = "loading",
  LOADED = "loaded",
  ERROR = "error",
  CANCELLED = "cancelled",
  SUCCESS = "success",
}

export interface ReduxData<T> {
  data: T;
  status: ReduxStateType;
  error?: Error;
}
