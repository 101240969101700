import { lazy } from "react";
import { roles } from "src/helper/const";
import paths from "src/routes/pathRoutes";
const StockTradingLazy = lazy(() => import("./pages"));
export const stockTradingRoutes = [
  {
    page: StockTradingLazy,
    path: paths.stockTrading,
    roleSide: roles.ROLE_USER,
  },
];
