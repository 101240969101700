import { toast } from "react-toastify";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { addAccountApi } from "src/core/http/service/managementAccount";
import { listAccountsStaticStart } from "src/redux-store/commons/staticInfo/redux/listAccountsStatic";
import { RootState } from "src/redux-store/store";
import {
  addAccountFailed,
  addAccountStart,
  addAccountSuccess,
} from "../redux/addAccount";
import {
  filterAccountStart,
  resetDataFilterAccount,
} from "../redux/filterAccount";
import { controlModal, resetLimitFilter } from "../redux/filterTable";
import { resetFormAccount } from "../redux/formAccount";
import { listAuthorizeStart } from "../redux/listAuthorize";

function* addAccountStartWork() {
  const { inputForm } = yield select(
    (state: RootState) => state.managementAccount.formAccount
  );
  const reChannelDto = inputForm.channelDto.map((item) => item.id);
  const reInputForm = { ...inputForm };
  delete reInputForm.channelDto;
  const params = {
    ...reInputForm,
    roles: [reInputForm.roles.value],
    accountStatus: reInputForm.accountStatus ? "ACTIVE" : "INACTIVE",
    listAuthorize: (reInputForm.listAuthorize || []).map((item) => item?.value),
    channelIds: reChannelDto,
    referrerCode1: reInputForm.referrerCode1 ? reInputForm.referrerCode1 : null,
    referrerCode2: reInputForm.referrerCode2 ? reInputForm.referrerCode2 : null,
    referrerCommission1: reInputForm.referrerCommission1
      ? reInputForm.referrerCommission1
      : 0,
    referrerCommission2: reInputForm.referrerCommission1
      ? reInputForm.referrerCommission2
      : 0,
    trader: true,
  };

  try {
    yield call(addAccountApi, params);
    toast.success("Thêm mới thành công", { autoClose: 4000 });
    yield put({
      type: addAccountSuccess,
    });
    yield put(resetDataFilterAccount());
    yield put(resetLimitFilter());
    yield put(filterAccountStart());
    yield put(listAuthorizeStart());
    yield put(listAccountsStaticStart());
    yield put(controlModal(false));
    yield put(resetFormAccount());
  } catch (error: any) {
    yield put({
      type: addAccountFailed,
    });
    const errMess = error?.response?.data?.message;
    toast.error(errMess, { autoClose: 4000 });
  }
}

function* watchAddAccount() {
  yield takeLatest(addAccountStart.type, addAccountStartWork);
}

export default function* addAccountSaga() {
  yield all([watchAddAccount()]);
}
