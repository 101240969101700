import { addDays, format } from "date-fns";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  listOrdersApi,
  listOrdersUserApi,
} from "src/core/http/service/stockTrading";
import { ListOrdersParams } from "src/core/http/service/stockTrading/types";
import { ListOrdersAccountRes } from "src/domain/models/stocktrading";
import { roles as RolesLogin } from "src/helper/const";
import { RootState } from "src/redux-store/store";
import {
  listOrdersFailed,
  listOrdersStart,
  listOrdersSuccess,
} from "../redux/listOrders";
import { filterOrderSelector } from "../redux/selector";

function* listOrdersStartWork() {
  const { accountCode } = yield select(
    (state: RootState) => state.stockTrading.formOrder.inputForm
  );
  const { roles } = yield select(
    (state: RootState) => state.authenication.auth.dataAuth
  );
  const { inputFilterOrder } = yield select(filterOrderSelector);
  const { shareCode, side, statusOrder } = inputFilterOrder;
  const params = {
    from: format(new Date(), "yyyy-MM-dd"),
    to: format(addDays(new Date(), 1), "yyyy-MM-dd"),
    limit: 1000,
    offset: 0,
    sort: "desc",
    shareCode: shareCode?.value ?? "",
    side: side.value,
    status: statusOrder.value,
    accountCode: accountCode.value,
  };

  try {
    if (roles[0] !== RolesLogin.ROLE_USER) {
      const paramsEditor: ListOrdersParams = {
        ...params,
      };
      const res: ListOrdersAccountRes = yield call(listOrdersApi, paramsEditor);
      yield put({
        type: listOrdersSuccess,
        payload: res,
      });
    } else if (roles[0] === RolesLogin.ROLE_USER) {
      const res: ListOrdersAccountRes = yield call(listOrdersUserApi, params);
      yield put({
        type: listOrdersSuccess,
        payload: res,
      });
    }
  } catch (error: any) {
    yield put({
      type: listOrdersFailed,
    });
  }
}

function* watchListOrders() {
  yield takeLatest(listOrdersStart.type, listOrdersStartWork);
}

export default function* listOrdersSaga() {
  yield all([watchListOrders()]);
}
