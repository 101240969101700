import { combineReducers } from "redux";
import listOrdersHandSlice from "./listOrdersHand";
import orderTradingHandSlice from "./orderTradingHand";
import importExcelOrderSlice from "./importExcelOrder";
import formOrderHandSlice from "./formOrderHand";
import filterOrderHandSlice from "./filterOrderHand";
import shareWithdrawHandSlice from "./shareWithdrawHand";
import getBuyInfoHandSlice from "./getBuyInfoHand";
import reportBuyingPowerHandSlice from "./reportBuyingPowerHand";
import orderCancelHandSlice from "./orderCancelHand";
import deleteOrderHandSlice from "./deleteOrderHand";
import portfolioAccountsHandSlice from "./portfolioAccountsHand";
import tabsOrderHandSlice from "./tabsOrderHand";
import channelDetailHandSlice from "./channelDetailHand";

export default combineReducers({
  listOrdersHand: listOrdersHandSlice,
  orderTradingHand: orderTradingHandSlice,
  formOrderHand: formOrderHandSlice,
  filterOrderHand: filterOrderHandSlice,
  importExcelOrder: importExcelOrderSlice,
  shareWithdrawHand: shareWithdrawHandSlice,
  getBuyInfoHand: getBuyInfoHandSlice,
  reportBuyingPowerHand: reportBuyingPowerHandSlice,
  orderCancelHand: orderCancelHandSlice,
  deleteOrderHand: deleteOrderHandSlice,
  portfolioAccountsHand: portfolioAccountsHandSlice,
  tabsOrderHand: tabsOrderHandSlice,
  channelDetailHand: channelDetailHandSlice,
});
