import {
  RecommendationImportResponse,
  RecommendationItem,
} from "src/domain/models/recommendations";
import {
  getRecommendationApi,
  importRecommendationApi,
} from "src/core/http/service/recommendations";
import {
  getRecommendationStart,
  getRecommendationSuccess,
  importRecommendationStart,
  callApiFailed,
  importRecommendationSuccess,
} from "../redux/recommendations";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* getRecommendations(
  action: ReturnType<typeof getRecommendationStart>
) {
  try {
    const data: RecommendationItem[] = yield call(getRecommendationApi);
    yield put({
      type: getRecommendationSuccess.type,
      payload: data,
    });
  } catch (error: any) {
    yield put(callApiFailed(error));
  }
}

function* importRecommendations(
  action: ReturnType<typeof importRecommendationStart>
) {
  try {
    const data: RecommendationImportResponse = yield call(
      importRecommendationApi,
      action.payload
    );
    yield put({
      type: importRecommendationSuccess.type,
      payload: data,
    });

    yield put(getRecommendationStart());
  } catch (error: any) {
    yield put(callApiFailed(error));
  }
}

function* watchGetRecommendations() {
  yield takeLatest(getRecommendationStart.type, getRecommendations);
}
function* watchImportRecommendations() {
  yield takeLatest(importRecommendationStart.type, importRecommendations);
}
export default function* getRecommendationSaga() {
  yield all([watchGetRecommendations(), watchImportRecommendations()]);
}
