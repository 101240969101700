import { localStorageKey } from "./const";

export const setLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, value);
};
export const getLocalStorage = (key: string) => {
  return localStorage.getItem(key);
};
export const removeLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};
export const clearLocalStorage = () => {
  localStorage.clear();
};

export const setAccountCode = (accountCode: string) => {
  setLocalStorage(localStorageKey.ACCOUNT_CODE_KEY, accountCode);
};

export const getAccountCode = () => {
  return getLocalStorage(localStorageKey.ACCOUNT_CODE_KEY);
};

export const setJwtToken = (token: string) => {
  setLocalStorage(localStorageKey.ACCOUNT_CODE_KEY, token);
};

export const getJwtToken = () => {
  return getLocalStorage(localStorageKey.TOKEN_KEY);
};
