import financeHttp from "src/core/http/singleton/financeService";
import {
  GET_LIST_ORDER_HISTORY,
  GET_LIST_ORDER_HISTORY_SUM,
  GET_LIST_ORDER_HISTORY_USER,
} from "./urls";
import {
  OrderHistoryByAccountFilterParams,
  OrderHistoryByAccountSumParams,
} from "./types";
import { OrderHistoryItems } from "src/domain/models/orderHistory";
import { roles } from "src/helper/const";

//api lay danh sach lịch sử đặt lệnh KH ROLE ADMIN
export const getListOrderHistoryApi = async (
  params: OrderHistoryByAccountFilterParams,
  role: string
): Promise<OrderHistoryItems[]> => {
  if (role === roles.ROLE_USER) {
    const res = await financeHttp.get<OrderHistoryItems[]>(
      GET_LIST_ORDER_HISTORY_USER,
      params
    );
    if (!res || !res.data) throw new Error("Opps");
    return res.data;
  } else {
    const res = await financeHttp.get<OrderHistoryItems[]>(
      GET_LIST_ORDER_HISTORY,
      params
    );
    if (!res || !res.data) throw new Error("Opps");
    return res.data;
  }
};
export const orderHistorySumApi = async (
  params: OrderHistoryByAccountSumParams,
  role: string
): Promise<any[]> => {
  if (role !== roles.ROLE_USER) {
    const res = await financeHttp.get<any[]>(
      GET_LIST_ORDER_HISTORY_SUM,
      params
    );
    if (!res || !res.data) throw new Error("Opps");
    return res.data;
  }
};
