import {
  CashHistoryItem,
  CreateTransferBody,
  GetCashWithdrawable,
  GetHistoryTransferParams,
  ResponseApproveTransfer,
  ResponseCancelTransfer,
  ResponseCreateTransfer,
  ResponseGetCashWithdrawable,
  UpdateTransferBody,
} from "src/domain/models/transferMoney";
import transferMoneyHttp from "src/core/http/singleton/transferMoney";
import {
  GET_CASH_WITHDRAWABLE,
  GET_HISORY_TRANSFER,
  POST_APPROVE_TRANSFER,
  POST_CANCEL_TRANSFER,
  POST_CREATE_TRANSFER,
} from "./urls";

export const getHistoryApi = async (
  params: GetHistoryTransferParams
): Promise<CashHistoryItem[]> => {
  const { ...restParams } = params;
  const res = await transferMoneyHttp.get<any>(GET_HISORY_TRANSFER, restParams);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const createTransferMoneyApi = async (
  params: CreateTransferBody
): Promise<ResponseCreateTransfer> => {
  const { ...restParams } = params;
  const res = await transferMoneyHttp.post<ResponseCreateTransfer>(
    POST_CREATE_TRANSFER,
    restParams
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const approveTransferMoneyApi = async (
  params: UpdateTransferBody
): Promise<ResponseApproveTransfer> => {
  const { ...restParams } = params;
  const res = await transferMoneyHttp.post<ResponseApproveTransfer>(
    POST_APPROVE_TRANSFER,
    restParams
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const cancelTransferMoneyApi = async (
  params: UpdateTransferBody
): Promise<ResponseCancelTransfer> => {
  const { ...restParams } = params;
  const res = await transferMoneyHttp.post<ResponseCancelTransfer>(
    POST_CANCEL_TRANSFER,
    restParams
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getCashWithDrawableApi = async (
  params: GetCashWithdrawable
): Promise<ResponseGetCashWithdrawable> => {
  const { ...restParams } = params;
  const res = await transferMoneyHttp.get<ResponseGetCashWithdrawable>(
    GET_CASH_WITHDRAWABLE,
    restParams
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
