// import { toast } from "react-toastify";
import { all, call, put, takeLatest, select, delay } from "redux-saga/effects";
import { createSelector } from "@reduxjs/toolkit";

import { getOrderHistoryState } from "../redux/selector";
import { OrderHistoryByAccountFilterParams } from "src/core/http/service/orderHistory/types";
import {
  getOrderHistoriesFailed,
  getOrderHistoriesStart,
  getOrderHistoriesSuccess,
} from "../redux/orderHistory";
import { getListOrderHistoryApi } from "src/core/http/service/orderHistory";
import { auth } from "src/modules/Auth/redux/selector";
import moment from "moment";
import { DATE_DIFF, valueAccount } from "../const";
import { roles as rolesAccount } from "src/helper/const";

const orderHistorySelector = createSelector(getOrderHistoryState, (state) => ({
  filter: state.data.filter,
}));

const authSelector = createSelector(auth, (state) => ({
  roles: state.dataAuth.roles,
}));
function* getOrderHistories(action: ReturnType<typeof getOrderHistoriesStart>) {
  try {
    const { filter } = yield select(orderHistorySelector);
    const { roles } = yield select(authSelector);
    const fromDate = moment(filter.from, "YYYY-MM-DD").toDate();
    const toDate = moment(filter.to, "YYYY-MM-DD").toDate();
    if (
      filter.accountCode === valueAccount.ALL_ITEMS &&
      roles[0] !== rolesAccount.ROLE_ADMIN
    ) {
      if (moment(toDate).diff(fromDate, "days") > DATE_DIFF.SHOW_ALL) {
        yield put(
          getOrderHistoriesFailed("Từ ngày , đến ngày không vượt quá 365 ngày")
        );
        return;
      }
    } else if (
      filter.accountCode !== valueAccount.ALL_ITEMS &&
      roles[0] !== rolesAccount.ROLE_ADMIN
    ) {
      if (moment(toDate).diff(fromDate, "days") > DATE_DIFF.SHOW_ACCOUNT) {
        yield put(
          getOrderHistoriesFailed("Từ ngày , đến ngày không vượt quá 90 ngày")
        );
        return;
      }
    }
    const params: OrderHistoryByAccountFilterParams = {
      ...filter,
      sort: "desc",
    };
    yield delay(100);
    const res = yield call(getListOrderHistoryApi, params, roles[0]);
    yield put({
      type: getOrderHistoriesSuccess.type,
      payload: {
        items: res,
      },
    });
  } catch (error: any) {
    yield put(getOrderHistoriesFailed(error));
  }
}

function* watchGetOrderHistories() {
  yield takeLatest(getOrderHistoriesStart.type, getOrderHistories);
}
export default function* getChannelsSaga() {
  yield all([watchGetOrderHistories()]);
}
