import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { createSelector } from "@reduxjs/toolkit";
import { postListGDKHQApi } from "src/core/http/service/financeService";
import {
  importListGDKHQStart,
  importListGDKHQSuccess,
  importListGDKHQFailed,
} from "../redux/importListGDKHQ";

import { getFinanceServicesStart } from "../redux/financeServiceList";

import { ImportListGDKHQState } from "../redux/selector";
import { toast } from "react-toastify";

const importListGDKHQSelector = createSelector(
  ImportListGDKHQState,
  (state) => ({
    data: state.data.importGDKHQ,
  })
);

function* importListGDKHQ(action: ReturnType<typeof importListGDKHQStart>) {
  try {
    const { data } = yield select(importListGDKHQSelector);
    yield call(postListGDKHQApi, data);
    yield put({ type: importListGDKHQSuccess.type });
    yield put({ type: getFinanceServicesStart.type });
    toast.success("Import Danh sách GDKHQ thành công.");
  } catch (error: any) {
    yield put(importListGDKHQFailed(error));
  }
}

function* watchImportListGDKHQ() {
  yield takeLatest(importListGDKHQStart.type, importListGDKHQ);
}
export default function* importListGDKHQSaga() {
  yield all([watchImportListGDKHQ()]);
}
