import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  inputConnect: {
    accountCode: string;
    password: string;
    channelId: string | number;
    validateConnect: boolean;
  };
  otp: {
    inputOtp: string;
    countDownCompleted: boolean;
  };
  formConnectVPS: {
    masterAccount: string;
    password: string;
    channelId: string | number;
    otp: string;
    isValFormConnectVPS: boolean;
  };
  showPopUp: boolean;
  showOtp: boolean;
  showModalVPSOtp: boolean;
}
const initialState: InitialState = {
  inputConnect: {
    accountCode: "",
    password: "",
    channelId: "",
    validateConnect: false,
  },
  otp: {
    inputOtp: "",
    countDownCompleted: false,
  },
  formConnectVPS: {
    masterAccount: "",
    password: "",
    channelId: "",
    otp: "",
    isValFormConnectVPS: false,
  },
  showPopUp: false,
  showOtp: false,
  showModalVPSOtp: false,
};

const formModalSlice = createSlice({
  name: "formModal",
  initialState,
  reducers: {
    updateInputConnect: (state, action) => {
      Object.assign(state.inputConnect, action.payload);
    },
    updateFormModal: (state, action) => {
      Object.assign(state, action.payload);
    },
    updateOtp: (state, action) => {
      Object.assign(state.otp, action.payload);
    },
    updateFormConnectVPS: (state, action) => {
      Object.assign(state.formConnectVPS, action.payload);
    },
    resetFormModal: () => initialState,
  },
});

export const {
  updateInputConnect,
  updateFormModal,
  updateOtp,
  resetFormModal,
  updateFormConnectVPS,
} = formModalSlice.actions;

export default formModalSlice.reducer;
