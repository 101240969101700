import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux-store/store";

const information = (state: RootState) => state.header.information;

export const informationSelector = createSelector(information, (state) => ({
  checkValidateInfo: state.inputInformation.checkValidateInfo,
  accountCode: state.inputInformation.accountCode,
  accountEmail: state.inputInformation.accountEmail,
  accountMobile: state.inputInformation.accountMobile,
  accountName: state.inputInformation.accountName,
  bankCode: state.inputInformation.bankCode,
  bankNumber: state.inputInformation.bankNumber,
  showInfo: state.showModal.showInfo,
  showChangePass: state.showModal.showChangePass,
}));

const changePass = (state: RootState) => state.header.changePass;

export const changePassSelector = createSelector(changePass, (state) => ({
  checkValidatePass: state.inputPass.checkValidatePass,
  oldPass: state.inputPass.oldPass,
  newPass: state.inputPass.newPass,
  repeatPass: state.inputPass.repeatPass,
}));
