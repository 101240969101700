import { createSlice } from "@reduxjs/toolkit";
import { addDays, subDays } from "date-fns";

interface InitialState {
  inputFilter: {
    fromDate: Date;
    toDate: Date;
  };
}
const initialState: InitialState = {
  inputFilter: {
    fromDate: subDays(new Date(), 7),
    toDate: addDays(new Date(), 1),
  },
};

const filterOrderHand = createSlice({
  name: "filterOrderHandSlice",
  initialState,
  reducers: {
    updateFilterOrderHand: (state, action) => {
      Object.assign(state.inputFilter, action.payload);
    },
    resetFilterOrderHand: () => initialState,
  },
});

export const { updateFilterOrderHand, resetFilterOrderHand } =
  filterOrderHand.actions;

export default filterOrderHand.reducer;
