import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";
import { loginApi } from "src/core/http/service/auth";
import {
  setAccountCode,
  setJwtToken,
  setLocalStorage,
} from "src/helper/localStorage";
import { RootState } from "src/redux-store/store";
import { authFailed, authStart, authSuccess } from "../redux/auth";
import { resetFormAuth } from "../redux/formAuth";
import { auth2faSelector } from "../redux/selector";
import { resetAuth2fa } from "../redux/auth2fa";

function* authStartWork() {
  yield delay(300);
  const { accountCode, password } = yield select(
    (state: RootState) => state.authenication.formAuth.inputAuth
  );
  const { token, otp } = yield select(auth2faSelector);
  const params = {
    accountCode,
    password,
    token,
    otp: otp,
  };
  try {
    const res = yield call(loginApi, params);
    const token = String(res?.access_token) || "";
    const decoded = Object(jwtDecode(token)); // Returns with the JwtPayload type
    setJwtToken(token);
    setAccountCode(decoded?.accountCode);
    const dataAccount = {
      accountCode: decoded?.accountCode,
      app: decoded?.azp,
      timeExp: decoded?.exp,
      sub: decoded?.sub,
      token: token,
      roles: decoded?.roles,
    };
    setLocalStorage("token", token);
    yield put({
      type: authSuccess.type,
      payload: dataAccount,
    });
    yield put(resetFormAuth());
    yield put(resetAuth2fa());
    toast.success("Đăng nhập thành công", { autoClose: 4000 });
  } catch (error: any) {
    const errMess = error?.response?.data?.message;
    toast.error(errMess, { autoClose: 4000 });
    yield put({
      type: authFailed.type,
    });
  }
}

function* watchAuth() {
  yield takeLatest(authStart.type, authStartWork);
}

export default function* authSaga() {
  yield all([watchAuth()]);
}
