import AppLanguage from "./types";
import errorsVi from "./errorVi";

const vi: AppLanguage = {
  common: {
    titleAlert: "THÔNG BÁO",
    buttonClose: "ĐÓNG",
    login: "Đăng nhập",
    errorlogin: "Thông tin đăng nhập không hợp lệ. Vui lòng thử lại.",
    username: "Tên đăng nhập",
    password: "Mật khẩu",
    enterPassword: "Nhập mật khẩu",
    signin: "",
    darkMode: "Theme bóng tối",
    lightMode: "Theme ánh sáng",
    EN: "TA",
    VI: "TV",
    search: "Tìm kiếm",
    buy: "Mua",
    sell: "Bán",
    buyPlus: "MUA +",
    sellPlus: "BÁN +",
    buyUp: "MUA",
    sellUp: "BÁN",
    tickerTracking: "Nhập mã chứng khoán muốn theo dõi",
    searchHeader: "Nhập mã chứng khoán, tên công ty",
    noValue: "Không có mã tương ứng",
    cancel: "Hủy bỏ",
    accept: "Đồng ý",
    short: "SHORT",
    long: "LONG",
    shortUp: "SHORT",
    longUp: "LONG",
    closeUp: "ĐÓNG",
    shortPlus: "SHORT +",
    longPlus: "LONG +",
    editUp: "SỬA",
    save: "Lưu",
    ok: "OK",
    OUTTIME: "Ngoài giờ",
    BOD: "Trước giờ",
    AVAILABLE: "Hiệu lực",
    OPEN: "Liên tục",
    RUNOFF: "Thỏa thuận",
    PAUSE: "Nghỉ trưa",
    CLOSED: "Đóng cửa",
    choose: "Tùy chọn",
    option: "Tùy chọn",
    expiredToken: "Phiên làm việc đã hết, nhấn Đồng ý ra màn đăng nhập",
    errorDelete: "Không có lệnh nào hợp lệ để hủy",
    errorCancelCopier: "Không được hủy lệnh tài khoản copier",
    hiddenTab: "Tab ẩn",
    close: "Đóng",
    maximizeTab: "Phóng to tab",
    restoreTab: "Thu nhỏ tab",
    CP: "CP",
    unit: "Tỷ",
    million: "tr",
    loading: "Đang tải...",
    inputSymbol: "Nhập mã",
    warningRangeDay:
      "Vượt quá {day} ngày. Quý KH chọn Từ ngày Đến ngày tương ứng {day} ngày trong bất kỳ thời gian nào.",
    warningInvalidDay: "Từ ngày không được vượt quá Đến ngày",
    nullInputFromDate: "Quý khách chưa nhập Từ ngày",
    nullInputToDate: "Quý khách chưa nhập Đến ngày",
    existTabError: "Chức năng đã mở",
    warningLiquidAppendix: "Vui lòng chọn phụ lục",
    warningCreateAppendix: "Tài khoản không có quyền thực hiện chức năng này",
    extendTypeError: "Vui lòng chọn phương thức gia hạn",
  },
  system: {
    modalSupport: {
      title: "HỖ TRỢ VÀ GÓP Ý",
      content:
        "MBS trân trọng cảm ơn Quý khách hàng đã sử dụng dịch vụ. Quý khách cần trợ giúp vui lòng liên hệ với chúng tôi tại đây",
      lableComment: "Góp ý của bạn",
      errorComment: "Góp ý của bạn không được bỏ trống",
      errorEmail: "Email/Số điện thoại  không được bỏ trống.",
      enterComment: "Nhập góp ý",
      email: "Email/Số điện thoại",
      enterEmail: "Nhập email hoặc số điện thoại",
      send: "Gửi góp ý",
      upPhoto: "Tải ảnh lên",
      success: "Gửi thành công",
      contentSuccess:
        "Thông tin đã được gửi tới MBS. Cảm ơn bạn đã liên hệ với chúng tôi.",
    },
  },
  auth: {
    errors: {
      emptyPassword: "Mật khẩu không được bỏ trống",
      emptyUsername: "Tài khoản không được bỏ trống",
    },
    info: {
      title: "Thông tin cá nhân",
      customerNo: "STK",
      generalInformation: "Thông tin chung",
      identityCardId: "CMND/ĐKKD",
      issueDate: "Ngày cấp",
      issuePlace: "Nơi cấp",
      birthDate: "Ngày sinh",
      sex: "Giới tính",
      communications: "Thông tin liên lạc",
      currentAddress: "Địa chỉ",
      phone: "Điện thoại",
      email: "Email",
      contactName: "Người liên hệ",
      contactTel: "Điện thoại",
      caringStaff: "Nhân viên chăm sóc",
      brokerName: "Tên nhân viên",
      brokerBrand: "Chi nhánh",
      brokerPhone: "Điện thoại",
      brokerEmail: "Email",
      curentPass: "Mật khẩu hiện tại",
      newPass: "Mật khẩu mới",
      confirmNewPass: "Xác nhận mật khẩu mới",
      editInfo: {
        titleButton: "Sửa thông tin",
        title: "Sửa thông tin liên lạc",
        cancel: "Hủy thay đổi",
        update: "Xác nhận",
        inputAddress: "Nhập địa chỉ liên lạc",
        inputPhone: "Nhập số điện thoại liên lạc",
        inputEmail: "Nhập Email liên lạc",
        inputContactName: "Nhập tên người liên hệ",
        inputContactTel: "Nhập số điện thoại",
        inputCurentPass: "Nhập mật khẩu hiện tại",
        inputNewPass: "Nhập mật khẩu hiện mới",
        inputConfirmNewPass: "Nhập lại mật khẩu mới",
        curentPassRequired: "Mật khẩu hiện tại không được bỏ trống",
        newPassRequired: "Mật khấu mới không được bỏ trống",
        confirmNewPassRequired: "Xác nhận mật khẩu mới không được bỏ trống",
        confirmNewPass: "Mật khẩu không khớp, vui lòng thử lại",
        errorPhoneNumber: "Số điện thoại không hợp lệ. Vui lòng thử lại!",
        errorEmail: "Email không hợp lệ. Vui lòng thử lại!",
        errorAddress: "Địa chỉ nhỏ hơn 15 ký tự. Vui lòng nhập lại địa chỉ!",
        errorChangeAll:
          "Email và số điện thoại không chỉnh sửa đồng thời. Vui lòng thử lại!",
        changeAccountInfoSuccess: "Cập nhật thành công",
        noChangeInfo: "Thông tin liên lạc không thay đổi!",
      },
      smartOTP: {
        cancel: "Hủy",
        smartOTP: "NHẬP MÃ SMART OTP",
        qrcode: "QR CODE",
        enterOTP: "Nhập mã OTP",
        content: "Vui lòng mở ứng dụng MBS Mobile và quét mã",
        expired: "Mã QR hết hạn",
        buttonGetQR: "Lấy mã mới",
        errorInputOTP: "Chưa nhập đủ mã, vui lòng nhập đủ 6 số!",
        errorQR: "Quét mã không thành công, vui lòng thử lại",
      },
      sendOTP: {
        label: "Nhập mã OTP",
        title: "Xác thực OTP",
        cancel: "Hủy",
        confirm: "Xác nhận",
        sendOTP: "Gửi mã OTP",
        sendOTPAgain: "Gửi lại OTP ",
        textConfirm: "Tôi xác nhận đăng ký thay đổi thông tin liên hệ",
        textTitle: "Gửi mã OTP qua:",
        sendOTPAgainToastSuccess: "Gửi lại mã xác thực thành công",
      },
    },
    login: {
      callMBS: "Gọi MBS",
      numberMBS: "Hotline MBS: 1900 9088",
      username: "Số tài khoản",
      enterUsername: "Nhập số tài khoản",
      rememberMe: "Lưu tài khoản đăng nhập",
      forgetPassword: "Quên mật khẩu?",
      forgetPasswordSuccess: "Quên mật khẩu thành công",
      userIncorect: "Sai tài khoản",
      passIncorect: "Sai mật khẩu",
      signIn: "Mở tài khoản",
      risk: "Công bố rủi ro",
      Hotline: "Hotline",
      Contact: "Liên hệ",
      Tutorial: "Hướng dẫn",
    },
    forgetPassword: {
      max6: "Nhập tối đa 6 ký tự",
      errorCMND: "CMND CMND/CCCD/GPKD/... không được bỏ trống",
      CMND: "CMND/CCCD/GPKD/...",
      errorUser: "Số tài khoản không được bỏ trống",
      forgetPassword: "QUÊN MẬT KHẨU",
      enter: "Nhập thông tin",
      cancel: "Hủy",
      submit: "Đồng ý",
      forgetPasswordSuccess:
        "Mật khẩu đã được gửi về SMS/Email đăng ký của Khách hàng",
    },
    changePassword: "Đổi mật khẩu",
    changePasswordSuccess: "Đổi mật khẩu thành công",
    setting: "Cài đặt",
    logout: "Đăng xuất",
    notification: {
      comeinand: "Lệnh",
      money: "Tiền",
      advisory: "Tư vấn",
      cskh: "CSKH",
      maskAsRead: "Đánh dấu đã đọc",
      warning: "Cảnh báo",
    },
    tradingAccount: {
      titleTypeOtp: "Xác thực giao dịch",
      typeOtp: "Loại xác thực",
      title: "Tài khoản giao dịch",
      defaultAccount: "Tài khoản mặc định",
      accountName: "Tài khoản",
      accountStock: "Tài khoản chứng khoán",
      accountCash: "Tài khoản Cash",
      accountMCredit: "Tài khoản M-Credit",
      accountMargin: "Tài khoản Margin",
      accountDerivative: "Tài khoản phái sinh",
      accountBeneficiary: "Tài khoản thụ hưởng",
      addAccountBeneficiary: "Thêm tài khoản thụ hưởng",
      success: "Thêm tài khoản thụ hưởng thành công",
      masterAccountName: "Chủ Tài khoản",
      bankAccountCode: "Số tài khoản",
      bankAccountCodeInput: "Nhập số tài khoản",
      errorBankAccount: "Số tài khoản không được bỏ trống",
      errorBankBranch: "Chi nhánh không được bỏ trống",
      bankName: "Ngân hàng",
      bankNameInput: "Chọn ngân hàng",
      bankProvince: "Tỉnh/Thành phố",
      bankProvinceInput: "Chọn Tỉnh/Thành phố",
      bankBranch: "Chi nhánh",
      bankBranchInput: "Nhập chi nhánh",
      cancel: "Hủy",
      addAccount: "Thêm tài khoản",
      typeAccount: "Loại TK",
      deleteAccount: "Xóa tài khoản",
      confirmDelete: "Xác nhận xóa tài khoản thụ hưởng",
      accept: "Đồng ý",
      deleteSuccess: "Xóa tài khoản thành công",
      table: {
        tooltipTable: "{bankName}",
      },
    },
    smsEmail: {
      title: "Dịch vụ Email/SMS",
      sms: "Dịch vụ SMS",
      email: "Dịch vụ Email",
      free: "GÓI MIỄN PHÍ",
      basic: "GÓI CƠ BẢN (8.800 VND)",
      pro: "GÓI NÂNG CAO (33.000 VND)",
      settingEmail: "THIẾT LẬP DỊCH VỤ EMAIL",
      basicRegister: "Bạn muốn đăng ký gói Cơ bản?",
      advancedToBasic: "Bạn muốn chuyển từ gói Nâng cao sang gói Cơ bản?",
      advancedRegister: "Bạn muốn đăng ký gói Nâng cao?",
      basicToAdvanced: "Bạn muốn chuyển từ gói Cơ bản sang gói Nâng cao?",
      canelBasic: "Bạn muốn hủy đăng ký gói Cơ bản?",
      canelAdvanced: "Bạn muốn hủy đăng ký gói Nâng cao?",
      modalTitle: "Cài đặt dịch vụ SMS/Email",
    },
    onlineService: {
      modalTitle: "Đăng ký dịch vụ online",
      titleCancelMargin: "Hủy đăng ký tài khoản dịch vụ Margin",
      marginContr: "Hạn mức đăng ký",
      cancelMarginConfirm:
        "Tôi đồng ý với các điều kiện điều khoản sử dụng dịch vụ của MBS trong từng thời kỳ",
      buttonCancel: "Hủy đăng ký",
      titleCancelSMUT: "Hủy đăng ký dịch vụ Sức mua ứng trước",
      SMUTCancelConfirm:
        "Tôi đã đọc và đồng ý các với điều khoản của MBS khi Hủy đăng ký sức mua ứng trước",
      accountNumber: "Số tài khoản",
      fullName: "Họ tên",
      titleBroker: "Đăng ký dịch vụ có Broker",
      lableBroker: "Đề xuất chuyên viên chăm sóc và quản lý tài khoản",
      brokerConfirm:
        "Tôi đã đọc và đồng ý với các quyền lợi khi chuyển đổi dịch vụ quản lý tài khoản",
      buttonYes: "Đăng ký",
      brokerRequest:
        "Đề nghị chuyển đổi dịch vụ quản lý tài khoản: từ Dịch vụ MBS Online sang Dịch vụ có Broker",
      enterCode: "Nhập mã nhân viên",
      titleMargin: "Đăng ký tài khoản dịch vụ Margin",
      maxMarginValue: "Giá trị tối đa được đăng ký là ",
      minMarginValue: "Giá trị tối thiểu là ",
      stepMarginValue: "Giá trị  hạn mức phải là bội số của ",
      marginValue: "Hạn mức đăng ký",
      marginConfirm:
        "Tôi đồng ý với các điều kiện điều khoản sử dụng dịch vụ của MBS trong từng thời kỳ",
      titleMbsOnline: "Đăng ký dịch vụ MBS Online (Không có Broker)",
      mbsOnline: "MBS Online",
      authenticationMethod: "Phương thức xác thực",
      matrix: "Ma trận",
      smartOTP: "SmartOTP",
      titleSmartOTP: "Thay đổi phương thức xác thực Smart OTP",
      smartOTPRequest:
        "ĐỀ NGHỊ CHUYỂN ĐỔI PHƯƠNG THỨC XÁC THỰC TỪ MA TRẬN SANG SMART OTP",
      smartOTPConfirm:
        "Tôi đã đọc và đồng ý với điều khoản của MBS khi thay đổi phương thức xác thực",
      mbsOnlineRequest:
        "Đề nghị chuyển đổi dịch vụ QL tài khoản: từ Dịch vụ có Broker sang Dịch vụ MBS Online",
      mbsOnlineConfirm:
        "Tôi đã đọc và đồng ý với các quyền lợi khi chuyển đổi dịch vụ quản lý tài khoản",
      titleSMUT: "Đăng ký dịch vụ Sức mua ứng trước",
      SMUTConfirm:
        "Tôi đã đọc và đồng ý các với điều khoản của MBS khi Đăng ký sức mua ứng trước",
      accountService: "Tài khoản dịch vụ",
      marginService: "Dịch vụ Margin",
      registered: "Đã đăng ký",
      unregistered: "Chưa đăng ký",
      MCreditService: "Dịch Vụ M-Credit",
      CKService: "Dịch vụ CK phái sinh",
      registerETrading: "Đăng ký Giao dịch điện tử",
      callTrading: "Giao dịch tổng đài",
      onlineTrading: "Giao dịch trực tuyến",
      accountManagementService: "Dịch vụ quản lý tài khoản ",
      brokerService: "Dịch vụ có Broker",
      SMUTService: "Sức mua ứng trước",
      DateRequest: "NGÀY YÊU CẦU",
      content: "NỘI DUNG",
      status: "TRẠNG THÁI",
      fromDate: "Từ ngày",
      toDate: "Đến ngày",
      all: "Tất cả",
      CHUA_DUYET: "Chưa duyệt",
      DA_DUYET: "Đã duyệt",
      statusFilter: "Trạng thái",
      errorDayRange: "Ngày bắt đầu không thể lớn hơn ngày kết thúc",
      MBSOnlineService: "Dịch vụ MBS Online",
      registerMBSOnline: "Đăng ký dịch vụ Online",
      history: "Lịch sử",
      sendOTP: "Gửi mã OTP qua:",
      numberPhone: "Số điện thoại",
      enterOTP: "Nhập mã OTP",
      enterSmsOTP: "Nhập mã SMS OTP",
      send: "Gửi lại",
    },
    chooseTheme: {
      editTheme: "Cài đặt theme",
      chooseTheme: "Chọn giao diện",
      blackTheme: "Giao diện tối",
      darkTheme: "Giao diện tối xanh",
      lightTheme: "Giao diện trắng",
      lightBlueTheme: "Giao diện trắng xanh",
      default: "(mặc định)",
    },
    keyboardShortcuts: {
      title: "Cài đặt phím tắt",
      noKey: "Không",
      duplicateKey: "Lỗi trùng phím tắt, vui lòng chọn lại!",
      keyShortcutOpenForm: {
        title: "Phím tắt mở form",
        category: "Danh mục",
        market: "Thị trường",
        stockOrder: "Lệnh cơ sở",
        stockOrderDer: "Lệnh phái sinh",
      },
      transactionSettings: {
        title: "Cài đặt giao dịch",
        stockOrderBuy: "Đặt lệnh bán",
        stockOrderSell: "Đặt lệnh mua",
        popupConfirmOrder: "Popup Xác nhận lệnh",
      },
    },
  },
  category: {
    table: {
      noContent: "",
      profitPerLoss: "+/-",
      changePercentage: "%",
      ticker: "Mã CK",
      ceiling: "Trần",
      floor: "Sàn",
      referenceShort: "TC",
      reference: "Tham chiếu",
      overBought: "Dư mua",
      overSold: "Dư bán",
      filled: "Khớp lệnh",
      price: "Giá",
      amount: "KL",
      high: "Cao",
      low: "Thấp",
      average: "TB",
      foreign: "NN",
      overBoughtAmount3: "(Dư mua) KL 3",
      amount3: "KL 3",
      overBoughtPrice3: "(Dư mua) Giá 3",
      price3: "GIÁ 3",
      overBoughtAmount2: "(Dư mua) KL 2",
      amount2: "KL 2",
      overBoughtPrice2: "(Dư mua) Giá 2",
      price2: "GIÁ 2",
      overBoughtAmount1: "(Dư mua) KL 1",
      amount1: "KL 1",
      overBoughtPrice1: "(Dư mua) Giá 1",
      price1: "GIÁ 1",
      filledPrice: "(KHỚP LỆNH) GIÁ",
      lastSale: "LAST_SALE",
      filledDiff: "(KHỚP LỆNH) +/-",
      filledAmount: "(KHỚP LỆNH) KL",
      overSoldPrice1: "(Dư bán) Giá 1",
      overSoldAmount1: "(Dư bán) KL 1",
      overSoldPrice2: "(Dư bán) Giá 2",
      overSoldAmount2: "(Dư bán) KL 2",
      overSoldPrice3: "(Dư bán) Giá 3",
      overSoldAmount3: "(Dư bán) KL 3",
      sumAmount: "TỔNG KL",
      foreignBought: "(ĐTNN) Mua",
      buy: "MUA",
      foreignSold: "(ĐTNN) Bán",
      sold: "BÁN",
      date_dh: "Ngày ĐH",
      diff: "Độ lệch",
      oi: "OI",
      tcph: "TCPH",
      gdcc: "GDCC",
      ckcs: "CKCS",
      price_ckcs: "Giá CKCS",
      th: "TH",
      ratio: "Tỷ lệ",
      diff_cd: "Độ lệch CĐ",
      break_even_point: "Điểm hòa vốn",
      volume: "Khối lượng",
      stock: "Chứng khoán",
      time: "Thời gian",
      transactionValue: "Giá trị giao dịch",
      accumulatedValue: "Giá trị tích lũy",
      accumulatedVolume: "KL tích lũy",
      bid: "Chào mua",
      ask: "Chào bán",
    },
    modal: {
      title: "Chỉnh sửa cột",
      editColumn: "Sửa cột",
      selectColumn: "Chọn cột hiển thị",
      reset: "Reset",
      cancel: "Hủy",
      apply: "Áp dụng",
    },
    custom: {
      title: "Danh mục của bạn",
      confirm: "Xác nhận",
      nameCategory: "Nhập tên danh mục",
      addCategory: "Thêm danh mục",
      cancel: "Hủy",
      favorite: "Danh mục yêu thích",
      addToFavoriteListSuccess: "Thêm vào danh mục yêu thích thành công",
      addToFavoriteListFailure: "Mã này đã tồn tại trong danh mục",
    },
    tabs: {
      owner: "Danh mục sở hữu",
      listed: "Niêm yết",
      putThrough: "Thỏa thuận",
      derivative: "Phái sinh",
      coverWarrant: "Chứng quyền",
      industry: "Ngành",
    },
    derivative: {
      vn30: "HĐTL chỉ số VN30",
      tpcp: "HĐTL TPCP",
    },
    coverWarrant: {
      mbs: "Chứng quyền tại MBS",
      full: "Chứng quyền toàn thị trường",
    },
    putThrough: {
      hsx: "HSX",
      hnx: "HNX",
      upcom: "UPCOM",
      totalSharesTradedPT: "TỔNG KHỐI LƯỢNG GIAO DỊCH THỎA THUẬN",
      totalValuesTradedPT: "TỔNG GIÁ TRỊ GIAO DỊCH THỎA THUẬN",
      billionVnd: "Tỷ đồng",
      dataEmpty: "Chưa có dữ liệu",
    },
    errors: {
      duplicatedName: "Tên danh mục đã tồn tại",
      required: "Nhập tên danh mục để lưu lại",
      sameOwnerName: "Tên danh mục đã trùng với tên danh mục sở hữu",
    },
  },
  market: {
    dragTooltip: "Thêm vùng hiển thị<br>(Di chuột đến khu vực cần thêm)",
    viewDoesntExist: "Màn hình không tồn tại",
    newScreen: "Phân tích thị trường",
  },
  widgets: {
    orderBookCondition: {
      name: "Sổ lệnh điều kiện",
    },
    orderBasket: {
      name: "Giỏ lệnh cơ sở",
    },
    orderBasketDer: {
      name: "Giỏ lệnh phái sinh",
    },
    moneyblock: {
      name: "Thông tin tài sản",
    },
    coverWarrantInfo: {
      name: "Thông tin CW",
      cwIssuerName: "Tổ chức phát hành",
      cwType: "Loại CW",
      cwExercisePrice: "Giá thực hiện",
      cwExerciseRatio: "Tỉ lệ chuyển đổi",
      cwRemainLastTradingDate: "Thời gian còn lại (ngày)",
      CKCSCode: "Mã CKCS",
      CKCSPrice: "Giá CKCS",
      status: "Trạng thái",
      cwLastTradingDate: "Ngày giao dịch cuối cùng",
      cwMaturityDate: "Ngày đáo hạn",
    },
    tickerGraph: {
      name: "Phân tích kỹ thuật",
    },
    watchList: {
      name: "Danh sách theo dõi",
    },
    accountInfo: {
      name: "Thông tin tài khoản",
    },
    financialService: {
      name: "Dịch vụ tài chính",
    },
    stockOrder: {
      name: "Đặt lệnh",
    },
    priceList: {
      name: "Bảng giá",
    },
    TickerInfo: {
      name: "Thông tin ticker",
      errors: {
        noWatchlistToAdd: "Bạn cần có ít nhất một danh sách yêu thích",
      },
    },
    orderBookOfTheDay: {
      name: "Sổ lệnh trong ngày",
    },
    finance: {
      year: "Năm",
      quarter: "Quý",
      viewType: "Xem theo",
      mainIndicator: {
        label: "Chỉ số chính",
        type: {
          roe: "ROE",
          roa: "ROA",
          eps: "EPS",
          yoy: "YOY",
        },
      },
      reportRevenue: {
        label: "Báo cáo doanh thu",
        type: {
          netincome: "Lợi nhuận",
          netrevenue: "Tổng doanh thu",
          operatingincome: "Lợi nhuận KD",
          yoy: "YOY",
        },
      },
      cashFlow: {
        label: "Dòng tiền",
        type: {
          cashflowoperating: "Kinh doanh",
          cashflowinvesting: "Đầu tư",
          cashflowfinance: "Tài chính",
          yoy: "YOY",
        },
      },
      balanceSheet: {
        label: "Bảng cân đối kế toán",
        totalAssets: "Tổng tài sản",
        totalLiabilities: "Nợ phải trả",
        solvencyDebtAssets: "Nợ trên tổng tài sản",
      },
    },
    Card: {
      agree: "Thỏa thuận",
    },
    foreignWatch: {
      name: "Khối ngoại",
      description:
        "Màu xanh trên biểu đồ thể hiện khối ngoại mua ròng. Màu đỏ thể hiện khối ngoại bán ròng. Dữ liệu bao gồm Cổ phiếu và ETF. Dữ liệu bao gồm cả thỏa thuận. Đơn vị giá trị là triệu VND.",
      floor: "Sàn",
      marketTypes: {
        HSX: "HSX",
        HNX: "HNX",
        UPCOM: "UPCOM",
        VN30: "VN30",
        HNX30: "HNX30",
      },
      buyValue: "GT Mua",
      sellValue: "GT Bán",
      netValue: "GT Ròng",
      totalBuyValue: "Tổng mua",
      totalSellValue: "Tổng bán",
      totalNetValue: "GT ròng",
    },
    marketWatch: {
      name: "Chuyển động thị trường",
      topIndex: "TOP đóng góp INDEX",
      marketRange: "Độ rộng thị trường",
      liquidity: "Thanh khoản",
      transactionValueVolume: "Giá trị và khối lượng GD",
      capitalization: "Vốn hóa",
      floor: "Sàn",
      valueType: "Loại GD",
      value: "Giá trị",

      marketTypes: {
        HSX: "HSX",
        HNX: "HNX",
        UPCOM: "UPCOM",
        VN30: "VN30",
        HNX30: "HNX30",
      },
      transactionTypes: {
        value: "Giao dịch khớp lệnh",
        valueAll: "Giao dịch khớp lệnh và thỏa thuận",
      },
      valueTypes: {
        price: "Giá trị giao dịch",
        volume: "Khối lượng giao dịch",
      },
    },
    marketStats: {
      yAxesLabel: "Đóng góp vào INDEX (%)",
      xAxesLabel: "KLGD / TB KLGD 5 phiên",
      changedPercentVolumeWithAvg5d: "KLGD / TB KLGD 5 phiên:",
      contributionPercentage: "Đóng góp vào INDEX (%):",
      contributionValue: "Đóng góp vào INDEX (điểm):",
      marketPercent: "% Vốn hóa:",
    },
    liquidity: {
      name: "Thanh khoản",
      description:
        "Biểu đồ so sánh dòng tiền tại cùng thời điểm với phiên trước, trung bình 1 tuần, 2 tuần và trung bình 1 tháng.",
      present: "Hiện tại",
      lastSession: "Phiên trước",
      oneWeek: "1 tuần",
      twoWeek: "2 tuần",
      oneMonth: "1 tháng",
    },
    topIndex: {
      percent: "Đóng góp vào INDEX (%)",
      value: "Đóng góp vào INDEX (điểm)",
      increaseContribute: "Mức đóng góp tăng",
      decreaseContribute: "Mức đóng góp giảm",
      point: "điểm",
      description:
        "Biểu đồ thể hiện thông tin điểm ảnh hưởng và % ảnh hưởng của 30 cổ phiếu tác động nhất tới tăng giảm điểm của Bộ chỉ số, trong đó 15 mã ảnh hưởng chiều tăng và 15 mã ảnh hưởng chiều giảm.",
    },
    capitalization: {
      description:
        "Biểu đồ cho thấy phân bổ vốn hóa của tất cả các mã trên sàn. Đơn vị là triệu VNĐ.",
    },
    transactionValueVolume: {
      description:
        "Biểu đồ thể hiện giá trị/khối lượng giao dịch của sàn đang tập trung vào những mã nào. Đơn vị giá trị giao dịch là triệu VND.",
    },
    industryDescription: {
      topIndex: {
        description:
          "Biểu đồ thể hiện đóng góp của ngành vào INDEX. Màu xanh trên biểu đồ thể hiện ngành đóng góp dương (kéo INDEX lên), màu đỏ thể hiện đóng góp âm (kéo INDEX xuống).",
      },
      transactionIndustry: {
        description:
          "Biểu đồ thể hiện giá trị giao dịch ngành (đơn vị Triệu VND). Màu xanh trên biểu đồ thể hiện ngành đóng góp dương (kéo INDEX lên), màu đỏ thể hiện đóng góp âm (kéo INDEX xuống).",
      },
    },
    marketRange: {
      description:
        "Trục ngang thể hiện tỷ lệ KLGD với trung bình KLGD 5 phiên. Trục đứng cho thấy đóng góp của từng mã vào return của index tại phiên hiện tại.",
    },
    topPrice: {
      left: "Top tăng",
      right: "Top giảm",
      headers: {
        today: "Hôm nay",
        oneWeek: "1 tuần",
        oneMonth: "1 tháng",
        threeMonths: "3 tháng",
        sixMonths: "6 tháng",
        oneYear: "1 năm",
      },
      table: {
        stockId: "MÃ CK/TÊN CÔNG TY",
        price: "giá khớp",
        changedPrice: "Thay đổi",
        changedPricePercentage: "%",
      },
    },
    coverWarrant: {
      title: "Chứng quyền",
      table: {
        stockId: "Mã CK",
        price: "Giá Khớp",
        changedPrice: "+/-",
        changedPricePercentage: "%",
        volume: "Tổng KL",
      },
      headers: {
        allIssuer: "Tất cả TCPH",
        allUnderlying: "Tất cả CKCS",
      },
    },
    industry: {
      title: "Ngành",
      select: "Chọn ngành",
      table: {
        ticker: "MÃ CK",
        price: "GIÁ KHỚP",
        totalAmount: "TỔNG K/L",
      },
    },
    topStatistical: {
      name: "Cổ phiếu GD hàng đầu",
      headers: {
        topVolume: "Khối lượng",
        topValue: "Giá trị",
        topChange: "% Biến động",
      },
      table: {
        stockId: "Mã CK",
        price: "Giá cuối",
        changedPrice: "Thay đổi",
        changedPricePercentage: "%",
        topValue: "Giá trị(Tỷ)",
        topValueMass: "KL(Triệu)",
      },
    },
    topForeign: {
      name: "Top nước ngoài",
      headers: {
        foreignNetValuesBuy: "Mua ròng",
        foreignNetValuesSell: "Bán ròng",
        foreignBuy: "KL Mua",
        foreignSell: "KL Bán",
        foreignBuyValue: "GT Mua",
        foreignSellValue: "GT Bán",
      },
      table: {
        stockId: "MÃ CK/TÊN CÔNG TY",
        price: "giá khớp",
        changedPrice: "Thay đổi",
        changedPricePercentage: "%",
        topValue: "Giá trị(Triệu)",
        topValueMass: "KL(Nghìn)",
      },
    },
    exchangeTradedFund: {
      name: "ETF",
      priceMatch: "Giá khớp",
      codeTicker: "Mã CK",
      volume: "Tổng KL",
    },
    recommendation: {
      name: "Cổ phiếu khuyến nghị",
      code: "Mã",
      side: "KN",
      recommendedPrice: "Giá KN",
      lastSale: "Giá TT",
      expectedPrice: "Mục tiêu",
      positive: "Tích cực",
      watching: "Theo dõi",
      buy: "Mua",
      sell: "Bán",
    },
    adIndex: {
      name: "Độ sâu thị trường",
      title: "Chỉ số A-D",
      sum: "Tổng: {number}",
      advance: "Tăng (Advancers): {number}",
      decline: "Giảm (Decliners): {number}",
    },
    vn30Index: {
      title: "Chỉ số VN30",
    },
    organization: {
      title: "Hồ sơ doanh nghiệp",
      domesticAndForeign: "Cơ cấu trong và ngoài nước",
      domesticStackHolder: "Nhà đầu tư trong nước",
      foreignStackHolder: "Nhà đầu tư ngoài nước",
    },
    netflow: {
      name: "Netflow",
      positive: "Net Flow > 0: Tín hiệu tích cực",
      negative: "Net Flow < 0: Tín hiệu tiêu cực",
      headers: {
        present: "T",
        oneDayAgo: "T - 1",
        twoDayAgo: "T - 2",
        threeDayAgo: "T - 3",
        fourDayAgo: "T - 4",
      },
      noteText:
        "Là chỉ báo đo bằng giá trị lệnh mua chủ động - giá trị lệnh bán chủ động của tất cả các mã cổ phiếu trong VN30, để từ đó xác định được dòng tiền đang mua chủ động hay bán chủ động.",
    },
    orderFlowDistribution: {
      title: "Phân bổ lệnh khớp (Đơn vị: Tỷ đồng)",
      small: "Nhỏ",
      medium: "TB",
      large: "Lớn",
      totalBuy: "Giá trị Mua",
      totalSell: "Giá trị Bán",
      noteTitle:
        "GIÁ TRỊ MUA/BÁN ĐƯỢC THỐNG KÊ THEO CHI TIẾT LỆNH KHỚP LÀ MUA CHỦ ĐỘNG/BÁN CHỦ ĐỘNG VỚI CÁC MỨC GIÁ KHỚP LỆNH (GTKL) TƯƠNG ỨNG:",
      noteText1: "- Lớn: 10% GTKL lớn nhất",
      noteText2: "- Nhỏ: 10% GTKL bé nhất",
      noteText3: "- Trung bình: 80% GTKL còn lại",
    },
    infoQuote: {
      title: "Bước giá",
      volume: "Khối lượng",
    },
    volumeAndTime: {
      title: "Khối lượng và thời gian",
      matchHistory: "Lịch sử khớp",
      volumeAnalysis: "Phân tích KL",
      esideShorthand: {
        buy: "M",
        sell: "B",
      },
    },
    tickerInfo: {
      fillCode: "Nhập mã CK tên công ty...",
      ceilingPrice: "Trần",
      floorPrice: "Sàn",
      referencePrice: "TC",
      openAveragePrice: "Mở cửa/TB",
      lowesthighestPrice: "Thấp - Cao",
      totalSellQty: "KLNN Bán",
      totalBuyQty: "KLNN Mua",
      totalSharesTradeMt: "Tổng khối lượng",
      marketCap: "Vốn hóa",
      sharesOutstanding: "KL Lưu hành",
      priceToEarningRatio: "P/E (TTM)",
      tangibleBookValuePerShare: "Giá trị/ Giá trị sổ sách",
      returnOnEquity: "ROE",
      highestPrice52Week: "52 Tuần",
      turnoverRate: "% Giao dịch ",
      priceToBook: "P/B",
      dividend: "Cổ tức",
      range: "% Chênh lệch",
      earningPerShare: "EPS (TTM)",
      priceToSalesRatio: "P/S",
      dividendYield: "TL Cổ tức",
      currentRoom: "Room NN",
      DILastTradingDate: "Ngày ĐH",
      bias: "Độ Lệch",
      OI: "OI",
    },
    placeOrder: {
      conditionalOrder: {
        title: "Lệnh điều kiện",
        normal: "---",
        timeConditionOrder: "Trước ngày",
        trailingStopOrder: "Xu hướng",
        priorityOrder: "Tranh Mua/Bán",
        takeProfit: "Chốt lãi",
        cutLoss: "Cắt lỗ",
        generatedOneTime: "Phát sinh 1 lần",
        matchingEnoughVolume: "Khớp đủ khối lượng",
        byPercent: "Theo %",
        byValue: "Theo giá trị",
        conditionsList: {
          noCondition: "Không điều kiện",
          referencePrices: "Giá tham chiếu",
          matchedPrice: "Giá khớp",
        },
        priceTC: "Giá TC",
        errorPlaceSellOrderSPCL:
          "Lệnh chốt lãi/ Lệnh cắt lỗ không áp dụng lệnh mua",
        errorPriceTCOLO: "Lệnh trước ngày chỉ hỗ trợ giá LO",
      },
      errorCheckFAL: "Vui lòng nhấn xác nhận trước khi đặt lệnh.",
      inputTicker: "Nhập mã CK",
      errorTickerEmpty: "Vui lòng nhập mã chứng khoán",
      errorTickerIllegal: "Mã CK không hợp lệ",
      account: "Tài khoản",
      tradetype: "Loại lệnh",
      method: "Phương thức",
      condition: "Điều kiện",
      toDate: "Đến ngày",
      buyTitle: "XÁC NHẬN LỆNH MUA",
      sellTitle: "XÁC NHẬN LỆNH BÁN",
      accountNumber: "Số tài khoản",
      commandType: "Loại lệnh",
      stockCode: "Mã chứng khoán",
      sellVolumn: "Khối lượng bán",
      buyVolumn: "Khối lượng mua",
      sellPrice: "Giá bán",
      buyPrice: "Giá mua",
      totalLabel: "Giá trị lệnh đặt (VND)",
      noVATLabel: "(chưa bao gồm thuế và phí)",
      confirmFAL:
        "Quý khách hàng đang đặt lệnh duyệt FAL, vui lòng xác nhận lại!",
      visibleModalConfirm:
        "Không hiển thị lại vào lần sau. Bạn có thể bật lại chức năng này ở Cài đặt giao dịch",
      comfirmBuy: "Xác nhận mua",
      comfirmSell: "Xác nhận bán",
      slidingMargin: "Biên trượt",
      value: "Giá trị",
      percent: "%",
      stopInterval: "Khoảng dừng",
      minBuyPrice: "Giá mua thấp nhất",
      maxSellPrice: "Giá bán cao nhất",
      avgPrice: "Giá BQ",
      arbitrage: "Chênh lệch giá",
      cancel: "Hủy",
      buyFal: "Mua Fal",
      dailyCredit: "Hạn mức mua trong ngày",
      maxBuy: "Mua tối đa",
      maxSell: "Bán tối đa",
      maxVolume: {
        tooltip: "Nhấn để đặt lệnh tối đa",
      },
      marginRate: "Tỷ lệ ký quỹ",
      purchasingAbility: "Sức mua",
      buy_command: "ĐẶT LỆNH MUA",
      sell_command: "ĐẶT LỆNH BÁN",
      volumn: "KHỐI LƯỢNG",
      ceilingPrice: "Trần",
      floorPrice: "Sàn",
      referencePrice: "TC",
      orderSuccess: "Đặt lệnh thành công",
      orderError: "Có lỗi xảy ra. Vui lòng kiểm tra lại",
      errorAccountDer: "Dữ liệu không hợp lệ, vui lòng thử lại",
      volume: "Khối lượng",
      price: "Giá",
      normal: "Thường",
      tabNameTooltip: "Kích để đặt lệnh",
    },
    orderBookDetail: {
      title: "Chi tiết đặt lệnh",
      status: "Trạng thái",
      tradeType: "Loại giao dịch",
      symbol: "Mã CK",
      tradingDate: "Thời gian đặt",
      orderQty: "Khối lượng đặt",
      price: "Giá đặt",
      cumQty: "Khối lượng khớp",
      matchAvgPrice: "Giá trị khớp",
      channel: "Kênh đặt lệnh",
      rejectReason: "Lý do từ chối",
      table: {
        no: "STT",
        cumQty: "KL KHỚP",
        matchAvgPrice: "GIÁ KHỚP",
        time: "THỜI GIAN",
      },
      noData: "Không có dữ liệu",
    },
    orderBookEdit: {
      setPrice: "Giá đặt",
      setVolumn: "KL đặt",
      cancel: "Hủy",
      confirm: "Xác nhận",
      BUY: "Sửa lệnh mua",
      BUY_OUTR: "Sửa lệnh mua OutR",
      SELL: "Sửa lệnh bán",
      SELL_OutR: "Sửa lệnh bán OutR",
      SELL_TT68: "Sửa lệnh bán TT68",
      orderSuccess: "Sửa Lệnh Thành Công",
      orderError: "Có lỗi xảy ra. Vui lòng kiểm tra lại",
      errorVolumnUp: "Không được sửa khối lượng lên.",
      errorVolumnDown: "Không được sửa khối lượng xuống.",
      errorChangeBoth: "Không sửa đồng thời giá và khối lượng.",
    },
    placeOrderDer: {
      name: "Đặt lệnh",
      conditionalOrder: "Lệnh điều kiện",
      EE: "EE",
      net: "Net",
      maxLong: "Max Long",
      maxShort: "Max Short",
      comfirmShortTitle: "Xác nhận lệnh SHORT",
      comfirmLongTitle: "Xác nhận lệnh LONG",
      tickerCode: "Mã CK",
      volume: "Khối lượng",
      price: "Giá",
      acceptLong: "LONG",
      acceptShort: "SHORT",
      inputTicker: "Nhập mã CK",
      A: "---",
      UP: "UP",
      DOWN: "DOWN",
      TUP: "TUP",
      TDOWN: "TDOWN",
      BULLBEAR: "BULL & BEAR",
      OCO: "OCO",
      activePrice: "Giá kích hoạt",
      stoplot: "Giá cắt lỗ",
      takeprofit: "Giá chốt lãi",
      margin: "Biên trượt",
      normal: "Normal",
      orderSuccess: "Đặt lệnh thành công",
      orderError: "Đặt lệnh thất bại",
      errorAccountEquity: "Dữ liệu không hợp lệ, vui lòng thử lại",
    },
    portfolio: {
      name: "Danh mục đầu tư",
      userName: "TÀI KHOẢN",
      code: "MÃ CK",
      type: "LOẠI",
      weight: "KHẢ DỤNG",
      wait: "CHỜ NHẬN",
      rightShares: "QUYỀN CHỜ VỀ",
      avgPrice: "GIÁ TB (VNĐ)",
      marketPrice: "GIÁ HIỆN TẠI",
      grossValue: "GIÁ TRỊ MUA",
      marketValue: "GIÁ TRỊ HIỆN TẠI",
      marketValueRatio: "TỈ TRỌNG (%)",
      profit: "LÃI/LỖ",
      profitRatio: "% LÃI/LỖ",
      sum: "TỔNG CỘNG",
      sharesBalance: "TỔNG",
      sellAll: {
        title: "Bán hết MP",
        titleConfirm: "Xác nhận đặt lệnh",
        percent: "Chọn tỉ lệ danh mục muốn bán",
        table: {
          userName: "Tài khoản",
          code: "Mã CK",
          type: "Loại",
          sharesBalance: "KL khả dụng",
          weight: "KL bán",
          marketPrice: "Giá hiện tại",
          exchanges: "Sàn",
          sellQty: "KL đặt",
          price: "Giá đặt",
        },
        cancel: "Hủy",
        confirm: "Xác nhận",
        placeOrder: "Đặt lệnh",
        emptySelect: "Không có lệnh nào phù hợp được chọn",
        orderSuccess: "Đặt lệnh thành công {count}/ {total} lệnh",
      },
    },
    orderBookDerEdit: {
      titleLong: "Sửa lệnh long",
      titleShort: "Sửa lệnh short",
      setPrice: "Giá đặt",
      setVolume: "KL đặt",
      profit: "Giá chốt lãi",
      conditionPrice: "Giá điều kiện",
      stopLoss: "Giá cắt lỗ",
      margin: "Biên trượt",
      cancel: "Hủy",
      orderSuccess: "Gửi lệnh sửa thành công",
      orderError: "Có lỗi xảy ra. Vui lòng kiểm tra lại",
      confirmLong: "Xác nhận lệnh Long",
      confirmShort: "Xác nhận lệnh Short",
    },
    openPosition: {
      name: "Vị thế mở",
      seriesId: "Mã HĐ",
      net: "KL vị thế mở",
      askAvgPrice: "Giá vốn",
      marketPrice: "Giá TT",
      floatingPL: "Lãi/Lỗ",
      summary: "TỔNG LÃI/LỖ",
      valuePositionSummary: "TỔNG GT",
    },
    closePosition: {
      name: "Vị thế đóng",
      seriesId: "Mã HĐ",
      long: "Vị thế đóng",
      net: "Vị thế ròng",
      askAvgPrice: "Bán trung bình",
      bidAvgPrice: "Mua trung bình",
      tradingPL: "Lãi/Lỗ",
      summary: "TỔNG CỘNG",
    },
    watchlist: {
      selectCategory: "Chọn danh mục",
      ticker: "MÃ CK",
      sumVolume: "TỔNG KL",
      percent: "%",
    },
    maximunTabError: "Vượt quá số lượng bảng tối đa",
  },
  tickerDetail: {
    title: "Chi tiết cổ phiếu",
    tabs: {
      finance: "Tài chính",
      orgInfo: "Thông tin doanh nghiệp",
      news: "Tin tức",
      listPrice: "Bước giá",
      volumeAndTime: "KL & thời gian",
      distribution: "Phân bổ lệnh khớp",
    },
  },
  extends: {
    statement: {
      orderError: "Có lỗi xảy ra. Vui lòng kiểm tra lại",
      error90Day: "Vượt quá giới hạn tìm kiếm 90 ngày",
      error30Day: "Vượt quá giới hạn tìm kiếm 30 ngày",
      error7Day: "Vượt quá giới hạn tìm kiếm 7 ngày",
      errorDay: "Từ ngày không được lớn hơn Đến ngày",
      type: "Nghiệp vụ",
      all: "Tất cả",
      cashStatement: "Sao kê Tiền",
      stockStatement: "Sao kê chứng khoán",
      derStatement: "Sao kê phái sinh",
      stt: "STT",
      userName: "TÀI KHOẢN",
      tradeDate: "NGÀY THỰC HIỆN",
      cashUp: "PHÁT SINH TĂNG",
      cashDown: "PHÁT SINH GIẢM",
      balance: "SỐ DƯ",
      content: "NỘI DUNG",
      beginBalance: "SỐ DƯ ĐẦU KỲ",
      sum: "TỔNG CỘNG",
      closeBalance: "SỐ DƯ CUỐI KỲ",
      ticker: "Mã chứng khoán",
      exchange: "Sàn giao dịch",
      account: "Tài khoản",
      shareBegin: "Số dư đầu kỳ",
      shareChange: "Tổng cộng",
      shareEnd: "Số dư cuối kỳ",
      shareCode: "MÃ CHỨNG KHOÁN",
      shareStatus: "LOẠI",

      derStatementTable: {
        rowNo: "STT",
        date: "NGÀY THỰC HIỆN",
        transactionNo: "SỐ CHỨNG TỪ",
        balanceMBS: "SỐ DƯ TẠI MBS",
        clientDebit: "PHÁT SINH GIẢM",
        clientCredit: "PHÁT SINH TĂNG",
        balanceVSD: "SỐ DƯ TẠI VSD",
        brokerDebit: "PHÁT SINH GIẢM",
        brokerCredit: "PHÁT SINH TĂNG",
        totalBalance: "SỐ DƯ",
        description: "NỘI DUNG",

        summary: "TỔNG PHÁI SINH",
        beginBalance: "SỐ DƯ ĐẦU KỲ",
        endBalance: "SỐ DƯ CUỐI KỲ",
      },
    },
    profitLossEquity: {
      title: "Lãi lỗ cơ sở",
      header: {
        account: "Tài khoản",
        fromDate: "Từ ngày",
        toDate: "Đến ngày",
        symbol: "Mã CK",
        search: "Tìm kiếm",
        inputSearch: "Nhập mã CK",
        allAccount: "Tất cả",
        errorDayRange: "Vượt quá giới hạn tìm kiếm 30 ngày",
        errorDayRange2: "Từ ngày không được vượt quá Đến ngày",
      },
      tableContent: {
        tradingDate: "Ngày giao dịch",
        accountNo: "Tài khoản",
        symbol: "Mã CK",
        status: {
          title: "Trạng thái",
          normal: "Thường",
          outR: "OutR",
          tt68: "TT68",
        },
        content: "Loại lệnh",
        volume: "Khối lượng",
        matchedPrice: "Giá khớp",
        fee: "Phí bán",
        taxToPay: "Thuế/Phí khác",
        avgPrice: "Giá TB khi bán",
        gainloss: "Lãi/lỗ",
        percent: "%Lãi/lỗ",
        totalProfit: "Tổng cộng",
        loadmore: "Tải thêm...",
        loadend: "Bạn đã tải hết!!!",
        nothing: "Không có bản ghi nào",
        buy: "MUA",
        sell: "BÁN",
      },
    },
    profitLossDer: {
      title: "Lãi lỗ phái sinh",
      header: {
        account: "Tài khoản",
        fromDate: "Từ ngày",
        toDate: "Đến ngày",
        symbol: "Mã HĐ",
        search: "Tìm kiếm",
        inputSearch: "Nhập mã HĐ",
        allAccount: "Tất cả",
        errorDayRange: "Vượt quá giới hạn tìm kiếm 30 ngày",
      },
      tableContent: {
        transDate: "Ngày giao dịch",
        symbol: "Mã HĐ",
        netOff: "Đóng giao dịch",
        expired: "Đáo hạn",
        open: "Vị thế mở còn lại",
        totalNetOff: "Tổng vị thế",
        avgBuyPrice: "Giá mua TB",
        avgSellPrice: "Giá bán TB",
        closePrice: "Giá đóng cửa",
        profit: "Tổng lãi/lỗ",
        totalProfit: "Tổng cộng",
        buy: "Mua",
        sell: "Bán",
        long: "Long",
        short: "Short",
      },
    },
    confirmationOrderOnline: {
      success: "Đăng ký thành công",
      title: "Xác nhận lệnh online",
      modalMessage: {
        title: "Thông báo",
        titleConfirm:
          "Tôi đồng ý và xác nhận thông tin tại đây là chính xác và đúng theo thỏa thuận/yêu cầu của tôi với MBS.",
        textBody:
          " \t Khách hàng đồng ý với các thông tin ở trên và xác nhận rằng các lệnh giao dịch trên được thực hiện bởi nhân viên của MBS là đúng theo yêu cầu của Khách hàng gửi tới MBS trước thời điểm các lệnh giao dịch này được thực hiện. \n \t Khách hàng xác nhận rằng, số tiền giải ngân theo từng ngày được MBS thông báo tới Khách hàng theo các phương thức do MBS triển khai từng thời kì.\n \t Đồng thời, Khách hàng nhận nợ và cam kết thanh toán cho MBS và/hoặc Bên thứ 3 có liên quan toàn bộ các nghĩa vụ tài chính phát sinh từ kết quả khớp lệnh đã được Khách hàng xác nhận ở trên.",
        content:
          "Quý khách vui lòng đăng ký tiện ích Xác nhận lệnh qua hệ thống GDĐT tại MBS.",
      },
      modalConfirmExit: {
        title: "Xác nhận thoát",
        content: "Bạn có chắc chắn muốn hủy hoạt động này?",
      },
      modalConfirmCondition: {
        title: "Đăng ký dịch vụ Xác nhận lệnh Online",
        account: "Tài khoản",
        textCondition1: "Tôi đồng ý với",
        textCondition2: "Điều kiện và Điều khoản",
        textCondition3: "xác nhận lệnh qua hệ thống giao dịch điện tử tại MBS.",
        messageConfirmOrderSuccess: "Xác nhận lệnh thành công",
        messageVerifyOTPSuccess: "Xác nhận mã OTP thành công",
        messageVerifyOTPFailure:
          "Xác nhận mã OTP thất bại, vui lòng xác nhận lại.",
        sendAgain: "Gửi lại",
      },
      header: {
        account: "Tài khoản",
        fromDate: "Từ ngày",
        toDate: "Đến ngày",
        search: "Tìm kiếm",
        allAccount: "Tất cả",
        confirmType: "Trạng thái XNL",
        errorDayRange: "Vượt quá giới hạn tìm kiếm 30 ngày",
        errorDay: "Ngày bắt đầu không thể lớn hơn ngày kết thúc",
      },
      tableContent: {
        orderDate: "Ngày đặt lệnh",
        accountCode: "Tài khoản",
        side: "Loại giao dịch",
        shareCode: "Mã CK",
        orderVolume: "Khối lượng",
        showPrice: "Giá đặt",
        orderStatus: "Trạng thái",
        orderNo: "Số hiệu lệnh",
        channels: "Kênh",
        confirmType: "Trạng thái XNL",
        confirmDate: "Thời gian xác nhận",
        confirmOrder: "Xác nhận lệnh",
        buy: "Mua",
        sell: "Bán",
        unconfimred: "Chưa xác nhận",
        signed: "Đã ký",
        confirmed: "Đã xác nhận",
        selectAll: "Tất cả",
        cancelWaiting: "Chờ hủy",
        fixWaitingFixed: "Chờ sửa/Đã sửa",
        matchWaiting: "Chờ khớp",
        cancelled: "Đã hủy",
        matched: "Đã khớp",
        partialMatch: "Khớp 1 phần",
        refuse: "Từ chối",
      },
    },
    categoryFinancialService: {
      title: "Danh mục Chứng khoán",
      header: {
        serviceType: "Loại dịch vụ",
        serviceTypeAll: "Tất cả",
        exchanges: "Sàn giao dịch",
        exchangesSelectAll: "Tất cả",
        shareCode: "Mã chứng khoán",
        shareCodeSelectAll: "Tất cả",
        search: "Tìm kiếm",
      },
      tableContent: {
        numericalOrder: "STT",
        exchanges: "Sàn giao dịch",
        shareCode: "Mã chứng khoán",
        shareName: "Tên chứng khoán",
        marginMin: "TL ký quỹ tối thiểu (%)",
        marginMax: "TL MBS cho vay/Hợp tác tối đa (%)",
        marginReal: "TL MBS cho vay/Hợp tác thực tế (%)",
        textFooter:
          "Tỷ lệ MBS cho vay/hợp tác thực tế đang được tính trên giá tham chiếu, tỷ lệ giải ngân chính xác sẽ phụ thuộc vào giá đặt mua của Qúy khách hàng.",
      },
    },
    oddLotShare: {
      tabHeader: {
        available: "Bán chứng khoán lô lẻ",
        history: "Lịch sử bán chứng khoán lô lẻ",
      },
      tabPanel: {
        sellOddLotShare: {
          filterActions: {
            search: "Tìm kiếm",
            accountNumberLabel: "Số tài khoản",
            shareCodeLabel: "Mã chứng khoán",
            selectAll: "Tất cả",
          },
          table: {
            header: {
              account: "TÀI KHOẢN",
              shareCode: "MÃ CK",
              oddShare: "CP LẺ",
              sellingPrice: "GIÁ BÁN",
              sellValue: "GIÁ TRỊ BÁN",
              exchange: "SÀN GIAO DỊCH",
              stateShare: "TRẠNG THÁI CK",
            },
            footer:
              "Giao dịch lô lẻ với giá sàn, phí dịch vụ 10% tổng giá trị giao dịch từ ngày 23/07/2012",
            sellButton: "Bán",
            transactionSuccessfulMsg: "Bán thành công",
          },
        },
        sellOddHistory: {
          filterActions: {
            search: "Tìm kiếm",
            fromDateLabel: "Từ ngày",
            toDateLabel: "Đến ngày",
            accountNumberLabel: "Số tài khoản",
            shareCodeLabel: "Mã CK",
            selectAll: "Tất cả",
          },
          table: {
            header: {
              order: "STT",
              account: "TÀI KHOẢN",
              shareCode: "MÃ CK",
              oddShare: "SỐ LƯỢNG",
              buyPrice: "GIÁ BÁN",
              buyValue: "GIÁ TRỊ BÁN",
              buyDate: "NGÀY BÁN",
              statusProcess: "TRẠNG THÁI CK",
            },
          },
          error: {
            dateRange: "Vượt quá giới hạn tìm kiếm 30 ngày",
            dateError: "Từ ngày phải nhỏ hơn Đến ngày",
          },
        },
      },
    },
  },
  categories: {
    placeOrderTooltip: "Click đúp để đặt lệnh",
    ticketTooltip: "Kích để xem chi tiết mã",
    ticketDetail: "Xem chi tiết mã",
  },
  moneyTransfer: {
    all: "Tất cả",
    equity: "Cơ sở",
    derivative: "Phái sinh",
    bankTransfer: "Chuyển tiền ngân hàng",
    internalTransfer: "Chuyển tiền nội bộ",
    derivativeTransfer: "Chuyển tiền phái sinh",
    depositToVSD: "Nộp ký quỹ lên VSD",
    withdrawFromVSD: "Rút ký quỹ từ VSD",
    D_CP: "Nộp ký quỹ",
    W_CP: "Rút ký quỹ",
    BIDVTransfer: "Chuyển tiền BIDV",
    ExternalTransfer: "Chuyển tiền ra ngoài",
    CS_TO_PS: "Chuyển tiền ra phái sinh",
    PS_TO_CS: "Chuyển tiền ra cơ sở",
    transferHistory: "Lịch sử chuyển tiền",
    createTransferRequest: "Tạo yêu cầu chuyển tiền",
    fromAccount: "Từ tài khoản",
    toAccount: "Đến tài khoản",
    accountHolder: "Chủ tài khoản",
    bank: "Ngân hàng",
    branch: "Chi nhánh",
    address: "Địa chỉ",
    transferMethod: "Phương thức chuyển",
    amountsTransferable: "Số tiền có thể chuyển",
    cashBalance: "Tiền mặt hiền tại",
    transferAmount: "Số tiền chuyển",
    transferFee: "Phí tạm tính",
    remark: "Nội dung",
    enterRemark: "Nhập nội dung sử dụng Tiếng Việt không dấu",
    enterAmount: "Nhập số tiền",
    fastTransfer: "Chuyển nhanh",
    normalTransfer: "Chuyển thường",
    payMarginFee: "Chuyển tiền thanh toán lãi Margin/BCC+",
    transferType: "Loại chuyển khoản",
    checkDetail: "Vui lòng kiểm tra lại chi tiết giao dịch trước khi thực hiện",
    petitioner: "Người yêu cầu",
    idOrPassportNumber: "Số CMND/ Hộ chiếu",
    MBSAccountNumber: "Số tài khoản tại MBS",
    fromDate: "Từ ngày",
    toDate: "Đến ngày",
    accountType: "Loại tài khoản",
    transferStatus: "Trạng thái",
    transferOverLimit: "Chuyển tiền vượt hạn mức",
    transferOverLimitDesc:
      "Số tiền chuyển vượt quá hạn mức giao dịch, cần MBS kiểm tra và phê duyệt. Bạn có chắc chắn muốn thực hiện?",
    moneyTransferSuccess: "Gửi yêu cầu thành công",
    transferSuccess:
      "Yêu cầu chuyển tiền của quý khách đã được gửi tới hệ thống, vui lòng kiểm tra lại trạng thái chuyển tiền",
    noRecord: "Không có bản ghi nào",
    choseBranch: "Chọn chi nhánh",
    escrow_d_cp: "Nop ky quy",
    escrow_w_cp: "Rut ky quy",
    first_escrow_desc:
      "Lưu ý: Thời gian thực hiện từ 8h30 đến 15h45 các ngày làm việc. Quý khách hàng lưu ý khi thực hiện nộp/rút ký quỹ để hạn chế phát sinh phí Quản lý tài sản ký quỹ. Chi tiết về biểu phí",
    is_here: "Tại đây",
    from_vsd: "Phí rút tiền từ",
    to_vsd: "Phí chuyển tiền lên",
    last_escrow_desc:
      "VSD (5.500) thu theo biểu phí của ngân hàng sẽ được trừ trên tài khoản của Quý khách tại MBS.",
    ac: "TK",
    accountNotSupport:
      "Tài khoản Ngân hàng nhận không thuộc danh sách liên kết,  giao dịch thực hiện theo hình thức chuyển thường.",
    transferNormalMethod:
      "Giao dịch được thực hiện theo hình thức chuyển thường",
    branchNote:
      "Quý khách chọn Chi nhánh trong danh sách liên kết để chuyển tự động, trường hợp Chi nhánh NH của Quý khách không có trong danh sách vui lòng chọn “Khác”, giao dịch sẽ được xử lý thủ công.",
    calculatingTransFee: "Hệ thống đang tính phí chuyển tiền...",
    error: {
      limitAmount: "Không được chuyển quá số dư",
      notEmptyContent: "Nội dung không được bỏ trống",
      notEmptyAmount: "Số tiền chuyển không được bỏ trống",
      notEmptyBranch: "Chi nhánh không được để trống",
      invalidAmount: "Số tiền chuyển không hợp lệ",
      invalidFeePaymentAccount:
        "Tài khoản nhận thanh toán phí Margin/BCC+ không hợp lệ",
      doNotTransferMoreThanCashMaxInOneDay:
        "Trong một ngày không được chuyển số tiền lớn hơn",
      doNotTransferLargerAmounts: "Không được chuyển số tiền lớn hơn",
      lessThanMin: "Không được chuyển số tiền nhỏ hơn",
      remarksIsAlphanumeric:
        "Nội dung không được chứa ký tự đặc biệt, tiếng Việt có dấu",
      errorDay: "Ngày bắt đầu không được lớn hơn ngày kết thúc",
    },
    table: {
      transferDate: "Ngày GD",
      transferType: "Loại giao dịch",
      accountTransfer: "TK chuyển",
      accountReceive: "TK nhận",
      inputClientName: "Tên người nhận",
      transferAmount: "Số tiền",
      transferFee: "Phí GD",
      remark: "Nội dung",
      rejectReason: "Lý do từ chối",
      status: {
        status: "Trạng thái",
        notAccept: "Chưa duyệt",
        success: "Đã duyệt",
        reject: "Từ chối",
        pending: "Đang xử lý",
        DA_DUYET: "Đã duyệt",
        DANG_XU_LY: "Đang xử lý",
      },
    },
    button: {
      confirm: "Xác nhận",
      cancel: "Hủy",
      search: "Tìm kiếm",
    },
  },
  orderBook: {
    confirm: "Xác nhận",
    contentConfirm: "Bạn có chắc chắn muốn hủy lệnh đã chọn",
    title: "Sổ lệnh trong ngày",
    search: "Tìm kiếm",
    select: "Chọn",
    account: "Tài khoản",
    cancel: "Hủy lệnh đã chọn",
    all: "Tất cả",
    buy: "Mua",
    sell: "Bán",
    condition: "Loại lệnh",
    conditionType: {
      title: "Lệnh điều kiện",
      all: "Tất cả",
      timeConditionOrder: "Trước ngày",
      priorityOrder: "Tranh mua/bán",
      trailingStopOrder: "Xu hướng",
      takeProfit: "Chốt lãi",
      cutLoss: "Cắt lỗ",
    },
    normal: "Loại thường",
    errorCheckCancel: "Không có lệnh nào được chọn",
    symbol: "Mã CK",
    table: {
      ticker: "Mã CK",
      account: "Tài khoản",
      type: "Loại GD",
      status: "Trạng thái",
      volume: "Khối lượng",
      price: "Giá",
      matchVolume: "KL Khớp",
      matchPrice: "Giá Khớp",
      commandNumber: "Số hiệu lệnh",
      timeBook: "TG Đặt",
      timeMatch: "TG Khớp",
      timeCancel: "TG Hủy",
      channel: "Kênh",
      tooltipTable: "Click đúp để xem chi tiết",
    },
    tableCondition: {
      tradeDate: "Trade Date",
      reqTime: "Thời gian đặt",
      channel: "Kênh ĐL",
      side: "Loại lệnh",
      traderId: "Trader Id",
      masterAcc: "Master Account",
      accoNo: "Tài khoản",
      brokerId: "Broker Id",
      secCd: "Mã CK",
      secType: "Sec Type",
      secSubType: "Sec Sub Type",
      tradingSessionId: "Trading Session Id",
      ordQty: "KL đặt",
      ordPrice: "Giá Đặt",
      matQty: "KL khớp",
      cancelQty: "KL hủy",
      condType: "Lệnh ĐK",
      effectDate: "Từ ngày",
      expiredDate: "Đến ngày",
      matMethod: "Phương thức",
      optType: "Điều kiện",
      optVal: "Giá TC",
      optValType: "Loại TC",
      condition: "Loại điều kiện",
      value: "Giá trị",
      valueActive: "Giá kích hoạt",
      tooltipValueActive:
        "Giá điều kiện khi đặt lệnh căn cứ theo giá thị trường và khoảng dừng tại thời điểm đặt lệnh",
      limitOffset: "Biên trượt",
      notes: "Notes",
      clientInfo: "Client Info",
      status: "Trạng thái",
      updDateType: "Thời gian hủy",
      takeEffect: "Hiệu lực",
      complete: "Hoàn thành",
      expired: "Hết hạn",
      cancel: "Đã hủy",
      generatedOneTime: "Phát sinh 1 lần",
      matchingEnoughVolume: "Khớp đủ khối lượng",
      noValue: "---",
      timeConditionOrder: "Trước ngày",
      trailingStopOrder: "Xu hướng",
      priorityOrder: "Tranh Mua/Bán",
      takeProfit: "Chốt lãi",
      cutLoss: "Cắt lỗ",
      noData: "Không có dữ liệu",
      arbitrage: "Chênh lệch",
      optValByValue: "Giá trị",
      optValByPercent: "%",
    },
    // Utils status - start
    NONE: "None",
    PENDING: "Đang chờ",
    WAIT_MATCH: "Chờ khớp",
    MATCHING_PARTIAL: "Khớp 1 phần",
    MATCH_PART: "Khớp 1 phần",
    MATCHING: "Đã khớp",
    MATCHED: "Đã khớp",
    WAIT_EDIT: "Chờ sửa",
    WAIT_CANCEL: "Chờ hủy",
    WAIT_ACTIVE: "Chờ kích hoạt",
    REJECTED_EDIT: "Từ chối sửa",
    REJECTED_CANCEL: "Từ chối hủy",
    EDITED: "Đã sửa",
    CANCELLED: "Đã Hủy",
    REJECTED: "Từ chối",
    WAIT_APPROVE: "Chờ duyệt",
    // Utils status - end
    BUY: "Mua",
    BUY_OUTR: "Mua OutR",
    SELL: "Bán",
    SELL_OutR: "Bán OutR",
    SELL_TT68: "Bán TT68",
    SELL_FORCE: "Bán Force",
    SELL_FORCE_OutR: "Bán Force OutR",
    SELL_FORCE_TT68: "Bán Force TT68",
    orderSuccess: "Đặt lệnh thành công",
    orderError: "Có lỗi xảy ra. Vui lòng kiểm tra lại",
    editCommand: "Sửa Lệnh",
    undefined: "",
  },
  orderBasket: {
    filter: {
      account: "Tài khoản",
      tradeType: {
        title: "Loại GD",
        all: "Tất cả",
        buy: "Mua",
        sell: "Bán",
      },
      symbol: "Mã chứng khoán",
    },
    table: {
      account: "Tài khoản",
      tradeType: {
        title: "Loại GD",
        buy: "Mua",
        sell: "Bán",
      },
      symbol: "Mã CK",
      commandType: {
        title: "Loại CK",
        normal: "Thường",
        outR: "OUTR",
        tt68: "TT68",
      },
      volume: "Khối lượng",
      price: "Giá",
      createdTime: "Thời gian tạo",
    },
    titleButtonBuy: "GIỎ LỆNH (MUA)",
    titleButtonSell: "GIỎ LỆNH (BÁN)",
    cancel: "Hủy",
    placeOrder: "Đặt lệnh",
    activeOrder: "Kích hoạt lệnh",
    orderCreateSuccess: "Thêm lệnh vào giỏ lệnh thành công",
    orderDeleteError: "Xóa lệnh khỏi giỏ lệnh thất bại",
    confirmDelete: "Xác nhận hủy lệnh",
    contentConfirmDelete: "Bạn chắc chắn muốn hủy lệnh",
    confirmActive: "Xác nhận đặt lệnh",
    contentConfirmActive: "Bạn chắc chắn muốn đặt lệnh",
    cancelSuccess: "Hủy lệnh trong giỏ thành công",
    cancelFail: "Hủy lệnh trong giỏ thất bại",
    activeSuccess: "Đặt lệnh trong giỏ thành công",
    activeFailed: "Đặt lệnh trong giỏ thất bại",
    createSuccess: "Tạo lệnh vào giỏ thành công",
    createFailed: "Tạo lệnh vào giỏ thất bại",
    errorCheckCancel: "Không có dòng nào được chọn để hủy",
    errorCheckActive: "Không có dòng nào được chọn để đặt lệnh",
    orderError: "Thêm vào giỏ lệnh thất bại",
    tabNameTooltip: "Kích để add lệnh vào giỏ",
  },
  orderBasketDer: {
    filter: {
      account: "Tài khoản",
      tradeType: {
        title: "Loại GD",
        all: "Tất cả",
        buy: "Long",
        sell: "Short",
      },
      symbol: "Mã HĐ",
    },
    table: {
      account: "Tài khoản",
      tradeType: {
        title: "Loại GD",
        buy: "Mua",
        sell: "Bán",
      },
      symbol: "Mã HĐ",
      commandType: {
        title: "Loại lệnh",
        normal: "Thường",
        outR: "OUTR",
        tt68: "TT68",
      },
      volume: "Khối lượng",
      price: "Giá",
      createdTime: "Thời gian tạo",
    },
    titleButtonLong: "GIỎ LỆNH (LONG)",
    titleButtonShort: "GIỎ LỆNH (SHORT)",
    cancel: "Hủy",
    placeOrder: "Đặt lệnh",
    activeOrder: "Kích hoạt lệnh",
    orderError: "Thêm vào giỏ lệnh thất bại",
  },
  error: errorsVi,
  validation: {
    emptyAccount: "Vui lòng chọn tài khoản",
    emptyTicker: "Vui lòng nhập Mã CK",
    emptyPrice: "Vui lòng nhập giá",
    emptyStopPrice: "Vui lòng nhập giá kích hoạt",
    emptyStopLoss: "Vui lòng nhập giá cắt lỗ",
    emptyProfit: "Vui lòng nhập giá chốt lãi",
    restrictedTrading: "Mã bị tạm ngưng/hạn chế giao dịch",
    rangePrice: "Giá phải nằm trong khoảng từ Sàn đến Trần",
    patternPrice: "Giá đặt không hợp lệ (bước giá 0.1)",
    patternStopPrice: "Giá kích hoạt không hợp lệ",
    patternStopLoss: "Giá cắt lỗ không hợp lệ",
    patternProfit: "Giá chốt lãi không hợp lệ",
    patternMargin: "Giá biên trượt không hợp lệ",
    emptyVolume: "Vui lòng nhập Khối lượng",
    emptyOtpVal: "Vui lòng nhập giá trị",
    emptyLimitOffset: "Vui lòng nhập biên trượt",
    patternVolume: "Khối lượng không tròn lô (lô 100)",
    maxVolumeSell: "Khối lượng bán vượt khối lượng tối đa có thể bán",
    maxVolumeBuy: "Khối lượng mua vượt khối lượng tối đa có thể mua",
    maxVolumeFAL: "Khối lượng mua vượt quá FAL tối đa",
    overRoom: "Số lượng mua vượt quá Room còn lại",
    patternMaxLongVolume: "KL phải nhỏ hơn KL Max Long",
    patternMaxShortVolume: "KL phải nhỏ hơn KL Max Short",
    activePriceUp: "Giá kích hoạt phải > giá thị trường",
    activePriceDown: "Giá kích hoạt phải < giá thị trường",
    emptyMargin: "Vui lòng nhập biên trượt",
    takeProfilePrice_Error_001: "Giá chốt lãi phải lớn hơn giá đặt",
    takeProfilePrice_Error_002: "Giá chốt lãi không được lớn hơn giá Trần",
    takeProfilePrice_Error_003: "Giá chốt lãi phải nhỏ hơn giá đặt",
    takeProfilePrice_Error_004: "Giá chốt lãi phải >= giá sàn",
    stopLotPrice_Error_001: "Giá cắt lỗ phải nhỏ hơn giá đặt",
    stopLotPrice_Error_002: "Giá cắt lỗ không được nhỏ hơn giá Sàn",
    stopLotPrice_Error_003:
      "Giá cắt lỗ Trừ biên trượt không được nhỏ hơn giá Sàn",
    stopLotPrice_Error_004: "Giá cắt lỗ phải lớn hơn giá đặt",
    stopLotPrice_Error_005: "Giá cắt lỗ không được lớn hơn giá Trần",
    stopLotPrice_Error_006:
      "Giá cắt lỗ + biên trượt không được lớn hơn giá Trần",
    stopLotPrice_Error_007: "Giá cắt lỗ phải khác giá đặt",
    stopLotPrice_Error_008: "Giá cắt lỗ phải LỚN hơn giá thị trường",
    stopLotPrice_Error_009:
      "Giá cắt lỗ CỘNG biên trượt phải nhỏ hơn hoặc bằng giá TRẦN",
    stopLotPrice_Error_010: "Giá cắt lỗ phải NHỎ hơn giá thị trường",
    stopLotPrice_Error_011:
      "Giá cắt lỗ TRỪ biên trượt phải nhỏ hơn hoặc bằng giá SÀN",
  },
  formPlaceholder: {
    inputPrice: "Nhập giá",
    inputVolumne: "Nhập KL",
    inputValue: "Nhập giá trị",
    inputAvgPrice: "Nhập giá BQ",
    inputOptValByValue: "Nhập giá trị",
    inputOptValByPercent: "Nhập %",
    inputLimitOffset: "Nhập biên trượt",
  },
  sidebar: {
    getOTP: "Lấy OTP",
    categories: "Danh mục",
    market: "Thị trường",
    stockOrder: "Lệnh cơ sở",
    placeOrderDer: "Lệnh phái sinh",
    orderBook: "Sổ lệnh",
    orderHistory: "Lịch sử lệnh",
    moneyTransfer: "Chuyển tiền",
    advance: "Ứng tiền",
    coveredWarrant: "Chứng quyền",
    proInvestor: "Nhà đầu tư chuyên nghiệp",
    marginLimit: "Thay đổi hạn mức Online",
    loanExtension: "Gia hạn khoản vay Online",
    screeners: "Bộ lọc",
    DVTC_NAV: "Báo cáo tài sản",
    infoWarrant: "Đăng ký quyền mua & Tra cứu quyền",
    disableMenu: "Ẩn/ Hiện menu",
    support: "Hỗ trợ/ Góp ý",
    businessCooperation: "Giao dịch eM24",
    extends: {
      title: "Mở rộng",
      profitLoss: "Lãi lỗ đã thực hiện",
      confirmOrderOnline: "Xác nhận lệnh Online",
      categoryFinancialService: "Danh mục DVTC",
      statement: "Sao kê tài khoản",
      oddLotShare: "Bán chứng khoán lô lẻ",
    },
    transferShare: "Chuyển khoản chứng khoán",
    packServices: "Đăng ký chính sách DVTC",
    extendsOne: "Extends One",
    extendsTwo: "Extends Two",
    extendsThree: "Extends Three",
    investmentEfficiency: "Hiệu quả đầu tư",
    techAnalysis: "Phân tích kỹ thuật",
    expandMenu: "Mở rộng",
    collapseMenu: "Thu gọn",
  },
  stockOrderBoard: {
    chooseABoard: "Chọn một bảng",
    all: "Tất cả",
    default: "Mặc định",
    customized: "Tùy chỉnh",
    newBoard: "Bảng mới",
    descNewBoard: "Thêm các khối vào bảng trống.",
    standard: "Tiêu chuẩn",
    descStandard: "Dành cho nhà giao dịch cơ bản và mới.",
    standard2: "Tiêu chuẩn 2",
    widgetsSettings: "Cài đặt các khối",
    suggestionsWidgetsSettings: "Chọn và kéo thả vào bảng",
    addDisplayArea: "Thêm vùng hiển thị",
    hoverOverTheAreaToAdd: "Di chuột đến khu vực cần thêm",
    viewDoesNotExist: "Chế độ xem không tồn tại",
    tab: {
      title: "Màn",
      open: "Mở",
      rename: "Đổi tên",
      saveTheTemplate: "Lưu lại mẫu",
    },
    modalSaveBoard: {
      titleModal: "Lưu lại bảng",
      nameBoard: "Tên bảng",
      description: "Mô tả",
      cancel: "Hủy",
      save: "Lưu",
    },
    modalRenameBoard: {
      titleModal: "Đổi tên bảng",
      ok: "OK",
      cancel: "Hủy",
    },
  },
  stockOrderDerBoard: {
    all: "Tất cả",
    default: "Mặc định",
    customized: "Tùy chỉnh",
    addingWidggets: "Thêm tiện ích vào bảng trống",
    blank: "Bảng trống",
    DefaultStock: "Thêm tiện ích vào bảng trống",
    normal: "Bình thường",
    multiTrades: "Nhiều giao dịch",
    MultiChart: "Nhiều biểu đồ hoặc bảng giao dịch",
    addDisplaytitle: "Thêm vùng hiển thị<br>(Di chuột đến khu vực cần thêm)",
    saveBoard: "Lưu lại 1 bảng",
    boardName: "Tên bảng",
    description: "Mô tả",
    changeBoardName: "Đổi tên bảng",
    chooseBoard: "Chọn một bảng",
    open: "Mở",
    changeName: "Đổi tên",
    save: "Lưu lại mẫu",
    noView: "Chế độ xem không tồn tại",
  },
  advance: {
    advanceHistory: "Lịch sử ứng tiền",
    advanceMoney: "Ứng trước tiền bán",
    fromDate: "Từ ngày",
    toDate: "Đến ngày",
    account: "Tài khoản",
    overDate: "Ngày bắt đầu không được lớn hơn ngày kết thúc",
    lengthDate: "Vượt quá giới hạn tìm kiếm 30 ngày",
    success: "Ứng trước tiền bán thành công",
    button: {
      confirm: "Xác nhận",
      cancel: "Hủy",
      search: "Tìm kiếm",
      add: "Thêm tài khoản",
    },
    table: {
      index: "STT",
      contractID: "SỐ HĐ",
      account: "TÀI KHOẢN",
      accountHolder: "CHỦ TÀI KHOẢN",
      soldDate: "NGÀY BÁN",
      advanceDate: "NGÀY ỨNG",
      amount: "SỐ TIỀN",
      advanceFree: "PHÍ ỨNG",
      advanceSource: "NGUỒN ỨNG",
      receiveAccount: "TK NHẬN TIỀN",
      status: {
        status: "TRẠNG THÁI",
        success: "ĐÃ DUYỆT",
        reject: "CHƯA DUYỆT",
        pending: "ĐANG XỬ LÝ",
      },
    },
    advanceAccount: "Tài khoản",
    totalSaleAmount: "Tổng tiền bán",
    advancedMoney: "Tiền đã ứng + phí",
    advanceableMoney: "Tiền bán có thể ứng",
    withdrawable: "Tiền ứng (có thể rút)",
    interestRate: "Lãi suất",
    provisionalFee: "Phí tạm tính",
    enterAmount: "Nhập số tiền",
    addAccount: "+ Thêm tài khoản thụ hưởng",
    error: {
      noContent: "Vui lòng nhập số tiền ứng",
      overPriced: "Vượt quá số tiền có thể ứng",
    },
    accountReceiver: {
      header: "THÊM TÀI KHOẢN",
      id: "Số tài khoản",
      owner: "Chủ tài khoản",
      bank: "Ngân hàng",
      city: "Tỉnh/Thành phố",
      branch: "Chi nhánh",
      register: "Đăng ký ngay tại đây.",
      description: "Mô tả",
      input: {
        id: "Nhập số tài khoản",
        owner: "Nhập tên chủ tài khoản",
        bank: "Chọn ngân hàng",
        city: "Chọn tỉnh/thành phố",
        branch: "Chọn chi nhánh",
      },
    },
  },
  tableInfinityScroll: {
    tableContent: {
      loadMore: "Tải thêm",
      loadEnd: "Không có dữ liệu mới",
      nothing: "Không có bản ghi nào",
    },
  },
  orderBookDer: {
    symbol: "Mã HĐ",
    side: "Loại GD",
    stopType: "Loại lệnh",
    Status: "Trạng thái",
    orderQty: "Khối lượng",
    price: "Giá",
    cumQty: "KL khớp",
    matchAvgPrice: "Giá khớp BQ",
    stopPrice: "Giá ĐK/ Cắt lỗ",
    stopPrice2: "Giá chốt lãi",
    orderId: "Số hiệu lệnh",
    createdTime: "TG đặt",
    matchTime: "TG khớp",
    cancelTime: "TG hủy",
    channel: "Kênh",
    Normal: "Thường",
    UP: "UP",
    DOWN: "Down",
    TUP: "T Up",
    TDOWN: "T Down",
    BULLBEAR: "Bull&Bear",
    OCO: "OCO",
    inputHD: "Nhập mã HD",
    search: "Tìm kiếm",
    comfirmTitle: "Xác Nhận Lệnh Hủy",
    comfirmMessage: "Bạn có chắc chắn muốn hủy lệnh này",
    accept: "Đồng ý",
    cancel: "Hủy bỏ",
    Updated: "Đã Sửa",
    PedingUpdate: "Chờ sửa",
    Waiting: "Đang chờ",
    WaitingCancel: "Chờ hủy",
    RejectUpdate: "Từ chối sửa",
    RejectCancel: "Từ chối hủy",
    noValue: "---",
    cancelSucces: "Gửi lệnh hủy thành công",
    cancelFail: "Không có lệnh hợp lệ để hủy",
  },
  OrderBookDerDetail: {
    title: "Chi tiết lệnh",
    tradeNumber: "Số hiệu lệnh",
    account: "Tài khoản",
    status: "Trạng thái",
    tradeType: "Loại lệnh",
    orderTime: "Thời gian đặt",
    activeTime: "Thời gian kích hoạt",
    orderVolumn: "Khối lượng đặt",
    orderPrice: "Giá đặt",
    stopLoss: "Giá điều kiện",
    stopPrice: "Giá cắt lỗ",
    margin: "Biên trượt",
    adjustableStopLossPrice: "Giá cắt lỗ điều chỉnh",
    matchingVolume: "Khối lượng khớp",
    stopPrice2: "Giá chốt lãi",
    matchingValue: "Giá trị khớp",
    cancelTime: "Thời gian hủy",
    cancelVolume: "Khối lượng hủy",
    chanel: "Kênh đặt lệnh",
    cancelSuccessMessage: "Hủy lệnh thành công",
  },
  orderHistory: {
    orderHistoryCS: "Lịch sử lệnh cơ sở",
    orderHistoryFS: "Lịch sử lệnh phái sinh",
    orderDetail: "Chi tiết lệnh",
    fromDate: "Từ ngày",
    toDate: "Đến ngày",
    account: "Tài khoản",
    side: "Loại giao dịch",
    orderType: "Loại lệnh",
    conditionOrder: "Lệnh điều kiện",
    status: "Trạng thái",
    priceConditionNote:
      "Giá điều kiện khi đặt lệnh căn cứ theo giá thị trường và khoảng dừng tại thời điểm đặt lệnh",
    table: {
      stt: "STT",
      orderDate: "Ngày đặt lệnh",
      orderTime: "Thời gian đặt",
      orderActive: "Thời gian kích hoạt",
      account: "Tài khoản",
      orderShare: "Mã CK",
      orderContract: "Mã HĐ",
      orderSide: "Loại giao dịch",
      volume: "Khối lượng",
      orderVolume: "Khối lượng đặt",
      price: "Giá",
      stopPrice: "Giá cắt lỗ",
      orderPrice: "Giá đặt",
      orderStatus: "Trạng thái",
      orderNo: "Số hiệu lệnh",
      orderChannel: "Kênh đặt lệnh",
      orderVolumeFilled: "Khối lượng khớp",
      orderPriceFilled: "Giá khớp",
      orderType: "Loại lệnh",
      order: "Lệnh",
      slipMargin: "Biên trượt",
      adjustableStopPrice: "Giá cắt lỗ điều chỉnh",
      cancelTime: "Thời gian hủy",
      cancelVolume: "Khối lượng hủy",
      channel: "Kênh",
      time: "Thời gian",
      action: "Thao tác",
      reasonReject: "Nguyên nhân từ chối",
      conditionPrice: "Giá đk/Cắt lỗ",
      takeProfitPrice: "Giá chốt lãi",
      orderVolumeShort: "KL đặt",
      match: "Khớp",
      conditionOrder: "Lệnh điều kiện",
      method: "Phương thức",
      fromDate: "Từ ngày",
      toDate: "Đến ngày",
      referencePrice: "Giá TC",
      channelOrder: "Kênh ĐL",
      difference: "Chênh lệch",
      byPercent: "Theo %",
      byValue: "Theo giá trị",
      value: "Giá trị",
      pause: "Khoảng dừng",
      activePrice: "Giá kích hoạt",
      status: {
        all: "Tất cả",
        filled: "Đã khớp",
        pending: "Chờ khớp",
        cancel: "Đã hủy",
      },
      fsStatus: {
        all: "Tất cả",
        reject: "Từ chối",
        cancel: "Đã hủy",
        filled: "Đã khớp",
        pending: "Chờ khớp",
      },
      fsStatusHistory: {
        inActive: "Chưa kích hoạt",
        stopReady: "Lệnh dừng",
        readyToSend: "Sẵn sàng gửi",
        queue: "Chờ khớp",
        sending: "Đang gửi",
        sent: "Đã gửi",
        reject: "Từ chối",
        cancel: "Đã hủy",
        partialMatch: "Khớp 1 phần",
        partialFilledCancel: "Đã hủy",
        fullMatch: "Đã khớp",
        matchAndCancel: "Đã hủy",
        waitApprove: "Đang chờ",
        waitEdit: "Chờ sửa",
        waitCancel: "Chờ hủy",
        priceWarning: "Cảnh báo giá",
        accomplished: "Đã hoàn thành",
        expried: "Đã hết hạn",
        partialExpried: "Hết hạn 1 phần",
        conditionalOrder: "Lệnh điều kiện",
        coi: "COI",
        oco: "OCO",
        oci: "OCI",
        undefined: "Không xác định",
        beAllocated: "Be Allocated (Fully Filled)",
      },
      side: {
        all: "Tất cả",
        buy: "Mua",
        sell: "Bán",
      },
      tradeDate: "Ngày đặt lệnh",
      seriesId: "Mã hợp đồng",
      fsSide: {
        all: "Tất cả",
        short: "Short",
        long: "Long",
        longUp: "LONG",
        shortUp: "SHORT",
      },
      fsAction: {
        IC: "Đặt lệnh",
        AS: "Đặt thành công",
        PE: "Khớp lệnh",
        MC: "Sửa lệnh",
        MS: "Sửa thành công",
        FL: "Khớp toàn bộ",
        MO: "Sửa lệnh",
        AF: "Từ chối lệnh",
        CO: "Hủy lệnh",
        DC: "Hủy thành công",
        AZ: "Hủy phần còn lại",
        IS: "Đặt lệnh",
        CI: "Chuyển về chưa kích hoạt",
        CA: "Kích hoạt lệnh",
        KL: "Bị từ chối",
        II: "Đặt lệnh",
        SC: "Kích hoạt lệnh dừng",
        EX: "Từ chối phần còn lại",
        CS: "Hủy lệnh thành công",
        IJ: "Đặt lệnh",
        IA: "Đặt lệnh",
        AD: "Đã duyệt",
        AM: "Đã duyệt",
        AP: "Đã duyệt",
        AX: "Không duyệt",
        BC: "Hủy lệnh nội bộ",
        BM: "Sửa lệnh nội bộ",
        BR: "Giảm khối lượng lệnh nội bộ",
        CF: "Thông báo khớp hết",
        CM: "Từ chối sửa",
        CW: "Hủy cảnh báo giá",
        DA: "Hủy kích hoạt",
        DE: "Yêu cầu không phân bổ lệnh",
        DI: "Gỡ treo ký quỹ",
        FA: "Khớp lệnh định kỳ",
        FC: "Hủy toàn bộ",
        FF: "Khớp toàn bộ",
        HF: "Treo ký quỹ",
        ID: "Input C.D. Order",
        IG: "Không hiệu lực hôm nay",
        IK: "Đặt lệnh",
        IM: "Đặt lệnh",
        IN: "Đặt lệnh",
        IP: "Sửa lệnh",
        LM: "Sửa lệnh",
        MJ: "Từ chối sửa",
        ML: "Sửa lệnh",
        NA: "Thông báo khách hàng",
        OI: "Nhập lệnh vào hệ thống",
        PC: "Hủy phần còn lại",
        PF: "Khớp một phần",
        PM: "Chờ sửa",
        PR: "Hủy khớp 1 phần",
        SS: "Gửi lệnh",
        RA: "Yêu cầu phân bổ lệnh",
        RB: "Gửi lại lệnh",
        RD: "Giảm khối lượng lệnh",
        RJ: "Bị từ chối",
        RM: "Từ chối sửa",
        RR: "Từ chối (Thị trường đóng cửa)",
        SD: "Chia lệnh",
        PW: "Cảnh báo giá",
        ST: "Kích hoạt",
        SX: "Trả lại trạng thái lệnh",
        TB: "User Manually Unfill Executed Trade",
        TM: "Sửa lệnh",
        TR: "Từ chối",
        TS: "Kích hoạt lệnh",
        UC: "Hủy chưa xử lý",
      },
      fsOrderType: {
        L: "Lệnh LO",
        M: "Lệnh MO",
        A: "Lệnh ATO",
        C: "Lệnh ATC",
        K: "Lệnh MTL",
      },
      channelName: {
        I: "Internet",
        M: "Mobile",
        D: "Broker",
        B: "Bloomberg",
        H: "Home",
        O: "Omnibus",
        G: "Plus24",
        N: "New Mobile",
        W: "New Web",
      },
      channelFSName: {
        WT: "D24",
        FOT: "FOT",
        BR: "Broker",
        G: "Plus24",
        N: "New Mobile",
        W: "New Web",
      },
      fsStopType: {
        N: "Thường",
        D: "Down",
        U: "Up",
        V: "T Up",
        E: "T Down",
        O: "OCO",
        B: "Bull & Bear",
      },
      fsDealStatus: {
        waitApprove: "Chờ khớp",
        waitActive: "Chờ kích hoạt",
        partialMatch: "Khớp 1 phần",
        fullMatch: "Đã khớp",
        cancel: "Đã hủy",
        reject: "Từ chối",
        edited: "Đã sửa",
        waiting: "Đang chờ",
        waitCancel: "Chờ hủy",
        rejectEdit: "Từ chối sửa",
        waitEdit: "Chờ sửa",
        rejectCancel: "Từ chối hủy",
      },
      filterOrderType: {
        normal: "Lệnh thường",
        condition: "Lệnh điều kiện",
      },
      filterCondition: {
        all: "Tất cả",
        beforeDay: "Trước ngày",
        trending: "Xu hướng",
        competitive: "Tranh mua/bán",
        takeProfit: "Chốt lãi",
        stopLoss: "Cắt lỗ",
      },
      statusCondition: {
        all: "Tất cả",
        complete: "Hoàn thành",
        expired: "Hết hạn",
        cancel: "Đã hủy",
      },
      sideCondition: {
        all: "Tất cả",
        buy: "Mua",
        sell: "Bán",
      },
      methodCondition: {
        once: "Phát sinh 1 lần",
        full: "Khớp đủ KL đặt",
      },
      channelCondition: {
        N: "Mobile App",
        W: "New Web",
      },
    },
    null: "--",
    modal: {
      link: "Xem chi tiết lệnh",
      title: "Chi tiết lệnh phái sinh",
    },
  },
  infoWarrant: {
    registerBuy: {
      errorShareRegister0: "Số CP đăng ký không được bỏ trống",
      errorShareRegister_shareCanBuy:
        "Số CP đăng ký không được lớn hơn Số lượng được mua",
      errorBuyPrice_cashBalance: "Số dư hiện tại không đủ",
      accountCode: "Tài khoản đăng ký",
      shareCode: "Mã CK",
      registerFromDate: "Thời gian đăng ký từ ngày",
      registerToDate: "Đến ngày",
      shareCanBuy: "SL CP còn được mua",
      buyPrice: "Giá mua",
      shareRegister: "Số CP đăng ký",
      placeholder_shareRegister: "Nhập số CP",
      sum: "Tổng tiền mua",
      accountPay: "TK chuyển tiền",
      cashBalance: "Số dư tiền",
      success: "Đăng kí thành công",
    },
    filter: {
      all: "Tất cả",
      CHUA_THUC_HIEN: "Chưa thực hiện",
      DA_THUC_HIEN: "Đã thực hiện",
      account: "Số tài khoản",
      sticker: "Mã chứng khoán",
      status: "Trạng thái",
      search: "Tìm kiếm",
      RIGHT_BUY: "Quyền mua",
      RIGHT_DIVIDEND: "Quyền cổ tức bằng tiền",
      RIGHT_STOCK: "Quyền cổ tức bằng cổ phiếu",
      RIGHT_VOTE: "Quyền họp ĐHCĐ",
      CONVERSION_STOCK: "Quyền chuyển đổi cổ phiếu",
      RIGHT_CASH_ORIGIN_BOND: "Quyền nhận gốc Trái phiếu",
      CHUA_CHOT_DS_CO_DONG: "Chưa chốt DS cổ đông",
      RIGHT_COVERED_WARRANT: "Quyền đáo hạn CW",
      DA_DUYET: "Đã duyệt",
      RIGHT_BUY_CHUA_THUC_HIEN: "Quyền mua chưa về TK",
      RIGHT_BUY_DA_THUC_HIEN: "Quyền mua đã về TK",
      CHUA_CHOT_CO_DONG: "Chưa chốt danh sách sở hữu",
      DA_CHUYEN_T_THAI: "Đã chuyển trạng thái",
      DA_DUYET_T_THAI: "Đã duyệt chuyển trạng thái",
      RIGHT_STOCK_CHUA_CHOT_DS_CO_DONG: "Chưa chốt DS sở hữu",
      CHUA_CHOT_DANG_KY: "Chưa chốt danh sách đăng ký mua",
      RIGHT_STOCK_CHUA_THUC_HIEN: "Chưa thực hiện quyền",
      RIGHT_STOCK_DA_THUC_HIEN: "Đã thực hiện quyền",
      type: "Loại quyền",
      closeFromDate: "Ngày chốt từ",
      execFromDate: "Ngày thực hiện từ",
    },
    table: {
      index: "STT",
      DA_THUC_HIEN: "Quyền đã về",
      CHUA_THUC_HIEN: "Quyền chưa về",
      accountCode: "SỐ TÀI KHOẢN",
      shareCode: "MÃ CK",
      shareRegister: "SL CỔ PHIẾU ĐĂNG KÝ MUA",
      buyPrice: "GIÁ MUA",
      cashMoney: "SỐ TIỀN",
      registerDate: "NGÀY ĐĂNG KÝ",
      excuteDate: "NGÀY THỰC HIỆN",
      statusCode: "TRẠNG THÁI",
      registerFromDate: "NGÀY BẮT ĐẦU ĐĂNG KÝ",
      registerToDate: "NGÀY KÊT THÚC ĐĂNG KÝ",
      rightTotal: "SL QUYỀN",
      shareCanBuy: "SL CP ĐƯỢC MUA",
      valueShareRegister: "SL ĐÃ ĐĂNG KÝ",
      type: "LOẠI QUYỀN",
      closeDate: "NGÀY ĐK CUỐI CÙNG",
      volumeCanBuy: "SLCP CÒN ĐƯỢC MUA",
      registedVolume: "SLCP ĐÃ ĐĂNG KÝ",
      shareDividend: "CỔ TỨC/CP THƯỞNG ĐƯỢC NHẬN",
      cashDividend: "CỔ TỨC BẰNG TIỀN ĐƯỢC NHẬN",
      rightCashRate: "TỈ LỆ CỔ TỨC TIỀN (%)",
      rightShareRate: "TỈ LỆ CỔ TỨC/ THƯỞNG CP",
      registerTime: "TG ĐĂNG KÝ MUA",
      transferTime: "TG CHUYỂN NHƯỢNG",
      rightBuyRate: "TỈ LỆ MUA",
      price: "GIÁ MUA",
      confirmDate: "NGÀY TH",
    },
    tab: {
      RESIGNTER: "Đăng ký Quyền mua",
      HISTORY: "Lịch sử đăng ký Quyền mua",
      INFORMATION: "Tra cứu thông tin Quyền",
      register: "Đăng ký mua",
    },
  },
  transferShare: {
    title: "Chuyển khoản chứng khoán",
    filter: {
      fromDate: "Từ ngày",
      toDate: "Đến ngày",
      account: "TK chuyển",
      shareCode: "Mã CK",
      search: "Tìm kiếm",
    },
    table: {
      index: "STT",
      transactionDate: "Ngày giao dịch",
      accountOut: "TK chuyển",
      accountIn: "TK nhận",
      shareCode: "Mã CK",
      shareStatusOut: {
        title: "Loại CK",
        normal: "Thường",
        outR: "OutR",
        tt68: "TT68",
      },
      shareVolume: "Số lượng",
      statusName: "Loại CK",
      content: "Nội dung",
    },
    tab: {
      transferShare: "Chuyển khoản chứng khoán",
      transferShareHistory: "Lịch sử chuyển khoản CK",
      transferShareForm: "Chuyển khoản CK",
    },
    transferForm: {
      accountOut: "Tài khoản chuyển",
      accountIn: "Tài khoản nhận",
      content: "Nội dung",
      content_value: "TK {accountOut} chuyển khoản sang {accountIn}",
      content_value1: "TK",
      content_value2: "chuyển khoản sang",
      placeholder_content: "Nhập nội dung",
      table: {
        shareCode: "Mã CK",
        statusName: "Loại CK",
        shareBalance: "Số dư",
        maxWithDraw: "SL chuyển",
      },
      button: {
        cancel: "Hủy",
        confirm: "Đồng ý",
      },
      errorCheckNone: "Quý khách vui lòng chọn Mã CK chuyển trạng thái",
      errorMaxWidthdraw: "Vui lòng nhập SL chuyển lớn hơn 0",
      transferShareSuccess: "Chuyển khoản thành công",
    },
  },
  packServices: {
    title: "Đăng ký chính sách DVTC",
    filter: {
      fromDate: "Từ ngày",
      toDate: "Đến ngày",
      status: {
        title: "Trạng thái",
        all: "Tất cả",
        notApprovedYet: "Chưa duyệt",
        approved: "Đã duyệt",
        rejectApproved: "Từ chối duyệt",
        canceled: "Đã hủy",
        cancelApprovedWait: "Chờ duyệt hủy",
      },
      search: "Tìm kiếm",
    },
    table: {
      accountCode: "Tài khoản",
      createdDate: "Ngày ĐK",
      packServicesName: "Tên chính sách",
      packServicesCode: "Mã",
      expiredDate: "Ngày kết thúc",
      status: "Trạng thái",
      cancel: "Hủy",
      cancelSuccess: "Hủy đăng ký thành công",
    },
    tab: {
      packServices: "Đăng ký chính sách DVTC",
      packServicesHistory: "Lịch sử đăng ký chính sách DVTC",
      packServicesForm: "Đăng ký chính sách DVTC",
    },
    packServicesForm: {
      accountCode: "Tài khoản",
      fullName: "Họ và tên",
      packServicesName: "Tên chính sách",
      packServicesCode: "Mã chính sách",
      content: "Thông tin chính sách",
      createdDate: "Ngày đăng ký",
      expiredDate: "Ngày kết thúc",
      contentCondition:
        "Tôi đã đọc, hiểu và xác nhận đồng ý với các chính sách, dịch vụ liên quan đã đăng ký",
      placeholder_content: "Nhập nội dung",
      button: {
        cancel: "Hủy",
        confirm: "Đồng ý",
      },
      errorCheckNone: "Quý khách vui lòng chọn Mã CK chuyển trạng thái",
      packServicesSuccess: "Đăng ký chính sách thành công",
    },
  },
  coveredWarrant: {
    register: "Đăng ký mua",
    purchaseHistory: "Lịch sử đăng ký mua",
    information: "Thông tin chứng quyền",
    all: "Tất cả",
    buy: "Mua",
    sell: "Bán",
    history: {
      filter: {
        fromDate: "Từ ngày",
        toDate: "Đến ngày",
        cutAccount: "TK cắt tiền",
        coveredWarrantID: "Mã CQ",
      },
      table: {
        index: "STT",
        coveredWarrantID: "Mã CQ",
        registerAccount: "TK đăng ký",
        cutAccount: "TK cắt tiền",
        ckcs: "Mã CKCS",
        publishDate: "Đợt phát hành",
        registerDate: "Ngày đăng ký",
        quantity: "Số lượng",
        purchasePrice: "Giá đặt mua",
      },
      register: {
        coveredWarrantID: "Mã chứng quyền",
        cwIDPlaceHolder: "Chọn mã chứng quyền",
        coveredWarrantPlaceholder: "Nhập mã CQ",
        quantityPlaceholder: "Nhập KL đăng ký mua",
        codeList: "Chứng Quyền FPT/BSC/C/6M/EU/",
        ckcs: "Mã CKCS",
        publishDate: "Đợt phát hành",
        publishDatePlaceHolder: "Chọn đợt phát hành",
        purchaseAccount: "TK đăng ký mua",
        cutAccount: "TK cắt tiền",
        availableAmount: "Tiền có thể mua",
        quantity: "KL đăng ký mua",
        purchasePrice: "Giá đặt mua",
        total: "Tổng giá trị đặt mua",
        agree: "Tôi đã đọc và đồng ý với các",
        terms: "điều khoản và điều kiện",
        registerToBuy: "đăng ký mua Chứng quyền tại MBS",
        success: "Đăng ký thành công",
        modalTerms: {
          title: "Điều khoản và điều kiện",
          close: "Đóng",
        },
        error: {
          noCWCode: "Mã chứng quyền không được bỏ trống",
          noVolume: "Khối lượng đăng ký mua không được bỏ trống",
          overPriced: "Giá trị đặt mua phải nhỏ hơn tiền có thể mua",
          overDate: "Ngày bắt đầu không được lớn hơn ngày kết thúc",
          lengthDate: "Vượt quá giới hạn tìm kiếm 180 ngày",
          divisible: "Khối lượng phải chia hết cho 100",
          notYet:
            "Chưa đến thời gian đăng ký trong ngày. Quý khách vui lòng thử lại sau",
          overVolume:
            "Số lượng đăng ký mua vượt số lượng chứng quyền phát hành còn được đăng ký",
          account: "Tài khoản CHƯA DUYỆT hoặc ĐANG SỬA ĐỔI",
        },
        button: {
          cancel: "Hủy bỏ",
          confirm: "Xác nhận",
        },
      },
    },
    info: {
      filter: {
        coveredWarrantID: "Mã chứng quyền",
        type: "Loại chứng quyền",
        all: "Tất cả",
        underlyingSecuritiesID: "Mã CKCS",
        underlyingSecuritiesIDPlaceHolder: "Nhập mã CKCS",
      },
      table: {
        index: "STT",
        coveredWarrantID: "Mã CQ",
        name: "Tên CQ",
        ckcs: "Mã CKCS",
        tcph: "TCPH",
        amount: "KL niêm yết",
        exerciseRatio: "TL chuyển đổi",
        period: "Thời hạn (tháng)",
        gdccDate: "Ngày GDCC",
        dueDate: "Ngày đáo hạn",
        estimatedPaymentDate: "Ngày TT dự kiến",
        exercisePrice: "Giá thực hiện",
        estimatedPaymentPrice: "Giá TT tạm tính",
      },
      description:
        "Khuyến cáo: Giá thanh toán tạm tính mang tính chất tham khảo. MBS không chịu trách nhiệm về sự sai lệch (nếu có).",
    },
  },
  screeners: {
    tabs: {
      screener: {
        title: "Bộ lọc",
        market: {
          legend: "Niêm yết",
          exchange: "Sàn",
          industry: "Ngành",
          select: "-- Chọn bộ lọc ---",
        },
        quote: {
          legend: "Tiêu chí cơ bản",
          marketCap: "Vốn hóa TT",
          price: "Giá",
          changeRate: "% Thay đổi",
        },
        financial: {
          legend: "Chỉ số cơ bản",
          pe: "P/E",
          eps: "EPS",
        },
        technical: {
          legend: "Tín hiệu kỹ thuật",
        },
        other: {
          legend: "Other",
          analystRating: "Analyst Rating",
          marginAvailable: "Margin Available",
        },
        actions: {
          reset: "Bỏ qua",
          save: "Lưu lại",
        },
      },
      myScreener: {
        title: "Bộ lọc của tôi",
      },
    },
    card: {
      cardTitle: "{total} Kết quả",
      tickerTooltip: "Nhấn để xem chi tiết mã",
      table: {
        order: "STT",
        shareCode: "MÃ CK",
        companyName: "TÊN CÔNG TY",
        price: "PRICE",
        change: "CHANGE",
        changeRate: "% CHANGE",
        marketCap: "MARKET CAP",
        pe: "P/E",
        pb: "P/B",
        netMargin: "NET SALES (B)",
        plusButton: "Thêm vào danh mục ({number})",
        roa: "ROA (%)",
        roe: "ROE (%)",
        eps: "EPS",
      },
      modal: {
        title: "THÊM BỘ LỌC",
        screenerName: "Tên bộ lọc",
        placeholder: "Nhập tên bộ lọc",
        cancel: "Hủy",
        save: "Lưu",
        errorMessage: "Bộ lọc {name} đã tồn tại, vui lòng chọn tên khác",
      },
    },
  },
  loanExtension: {
    tabHeader: {
      loanTracking: {
        title: "Theo dõi dư nợ",
      },
      extendHistory: {
        title: "Lịch sử gia hạn online",
      },
    },
    tabPanel: {
      loanTracking: {
        filterActions: {
          account: "Tài khoản",
          status: "Trạng thái",
          search: "Tìm kiếm",
          all: "Tất cả",
          canExtend: "Có thể gia hạn",
        },
        table: {
          header: {
            deliverDate: "NGÀY GIẢI NGÂN",
            oldExpireDate: "NGÀY ĐẾN HẠN HIỆN TẠI",
            numDayDiff: "SỐ NGÀY ĐÃ VAY",
            feeRate: "LÃI SUẤT HIỆN TẠI (%/NGÀY)",
            intermLoan: "NỢ GỐC TRONG HẠN",
            intermFee: "NỢ LÃI TRONG HẠN",
            exterLoan: "NỢ GỐC NGOÀI HẠN",
            exterFee: "NỢ LÃI NGOÀI HẠN",
            totalLoanBLN: "TỔNG NỢ",
            extendFeeRate: "LÃI SUẤT SAU GIA HẠN (%/NGÀY)",
            newExpireDate: "NGÀY ĐẾN HẠN SAU GIA HẠN",
          },
          footer: {
            extend: "Gia hạn",
            total: "TỔNG",
            notCheckedError: "Chưa món vay nào được chọn",
          },
        },
      },
      extendHistory: {
        filterActions: {
          fromDate: "Từ ngày",
          toDate: "Đến ngày",
          status: "Trạng thái",
          search: "Tìm kiếm",
          all: "Tất cả",
          extendStatus: {
            approved: "Đã duyệt",
            notControl: "Chưa kiểm soát",
            notApprove: "Chưa duyệt",
          },
          error: {
            dateRange: "Số ngày tìm kiếm không được vượt quá 30 ngày",
            invalidDate: "Từ ngày phải nhỏ hơn Đến ngày",
          },
        },
        table: {
          header: {
            order: "STT",
            accountNum: "SỐ TK",
            deliverDate: "NGÀY GIẢI NGÂN",
            oldExpireDate: "NGÀY ĐẾN HẠN CŨ",
            numDayDiff: "SỐ NGÀY ĐÃ VAY",
            loanBalance: "NỢ HIỆN TẠI",
            feeBalance: "NỢ LÃI",
            totalLoanBLN: "TỔNG NỢ",
            newExpireDate: "NGÀY ĐẾN HẠN MỚI",
            extendFeeRate: "LÃI SAU KHI GIA HẠN (%/NĂM)",
            approveDate: "NGÀY GIA HẠN",
            channel: "KÊNH",
            statusName: "TRẠNG THÁI",
          },
        },
      },
      extendExpireSuccessMsg: "Gia hạn thành công",
    },
    card: {
      header: "Gia hạn online",
      accountNum: "Số tài khoản",
      deliverDate: "Ngày giải ngân",
      oldExpireDate: "Ngày đến hạn hiện tại",
      numDayDiff: "Số ngày đã vay",
      loanBalance: "Nợ gốc hiện tại",
      feeBalance: "Nợ lãi",
      totalFeeRate: "Tổng nợ",
      newExpireDate: "Ngày đến hạn sau khi gia hạn",
      extendFeeRate: "Lãi sau khi gia hạn (%/năm)",
      acceptTerms:
        "Tôi đã đọc và đồng ý với các điều khoản thỏa thuận trong hợp đồng ký quỹ, phụ lục hợp đồng và các chính sách dịch vụ tài chính đang áp dụng tại MBS",
      cancelButton: "Hủy bỏ",
      confirmButton: "Xác nhận",
      closeButton: "Đóng",
      note: {
        r1: "Món vay không bị chặn gia hạn",
        r2: "Tài khoản hiện không tồn tại món nợ nào bị quá hạn",
        r3: "Món vay vẫn còn có thể gia hạn: Tổng số thời hạn của khoản vay tính từ lúc giải ngân đến ngày đáo hạn mới phải nhỏ hơn hoặc bằng 275 ngày (tổng số ngày có thể thay đổi theo chính sách MBS từng thời kỳ)",
        r4: "Số ngày còn lại của món vay đến ngày hết hạn hiện tại phải nhỏ hơn hoặc bằng 20 ngày (số ngày vay có thể thay đổi theo chính sách của MBS từng thời kỳ)",
        r5: "Món vay chưa từng được gia hạn tại quầy",
      },
    },
  },
  rangeDateSearch: {
    success: "",
    errorRange: "Ngày bắt đầu không thể lớn hơn ngày kết thúc",
    exceededLimit: "Vượt quá giới hạn tìm kiếm {days} ngày",
  },
  marginLimit: {
    tabs: {
      marginLimitHistory: {
        header: {
          title: "Lịch sử thay đổi hạn mức",
        },
        filterActions: {
          fromDate: "Từ ngày",
          toDate: "Đến ngày",
          account: "Tài khoản",
          status: "Trạng thái",
          search: "Tìm kiếm",
        },
        statusList: {
          all: "Tất cả",
          approved: "Đã duyệt",
          modified: "Đã sửa đổi",
          modifying: "Đang sửa đổi",
          cancel: "Hủy thay đổi",
          reject: "Từ chối duyệt",
        },
        dateError: {
          overRange: "Số ngày tìm kiếm phải không vượt quá 30 ngày",
          underRange: "Từ ngày phải nhỏ hơn đến ngày",
        },
        table: {
          header: {
            order: "STT",
            limitLoanOld: "Giá trị cũ",
            limitLoan: "Giá trị yêu cầu mới",
            modifyDate: "Ngày yêu cầu",
            approveDate: "Ngày duyệt",
            status: "Trạng thái",
          },
        },
      },
    },
    cards: {
      marginLimitChange: {
        header: {
          title: "Thay đổi hạn mức",
        },
        body: {
          placeholderMarginRequire: "Nhập hạn mức yêu cầu",
          underLimitError:
            "Hạn mức yêu cầu mới không được phép nhỏ hơn hạn mức tối thiểu",
          overLimitError:
            "Hạn mức yêu cầu mới không được phép lớn hơn hạn mức tối đa",
          stepError:
            "Hạn mức yêu cầu mới phải tuân theo bước giá trị (nghĩa là mức gia hạn mới phải là số chia hết cho bước giá trị)",
          emptyError:
            "Hạn mức yêu cầu mới không được bỏ trống, vui lòng nhập lại",
          accountNum: "Số tài khoản",
          currentLimit: "Hạn mức hiện tại",
          requestLimit: "Hạn mức yêu cầu",
          requestLimitPlaceHolder: "Nhập hạn mức",
          acceptTerms:
            "Tôi đã đọc và đồng ý với các điều khoản và điều kiện về giao dịch ký quỹ và chính sách dịch vụ tại MBS",
          note: "Lưu ý: Thời gian thực hiện thay đổi hạn mức trước 16h các ngày làm việc",
        },
        actions: {
          cancel: "Hủy bỏ",
          agree: "Xác nhận",
        },
        limitRules: {
          title: "Quy định về hạn mức",
          minLimitLabel: "Hạn mức tối thiểu:",
          minLimitUnit: " triệu",
          maxLimitLabel: "Hạn mức tối đa:",
          maxLimitUnit: " tỷ",
          stepValueLabel: "Bước giá trị:",
          stepValueUnit: " triệu",
          confirmPrefix: "Tăng hạn mức yêu cầu trên ",
          confirmSuffix: " tỷ đồng cần MBS thẩm định",
        },
      },
    },
    message: {
      modifySuccess: "Thay đổi hạn mức thành công",
    },
  },
  tradingHelper: {
    checkPlaceOrder: "Không xác định được thông tin lệnh",
    symbolDataNotFound: "Không tìm thấy thông tin mã CK {symbol}",
    symbolStatusHalt:
      "Mã chứng khoán  {symbol} đã tạm ngưng hoặc hạn chế giao dịch",
    invalidPriceStep: "Giá đặt không hợp lệ (Bước giá {priceStep}đ)",
    invalidSecurityType1: "Chưa hỗ trợ đặt lệnh Trái phiếu doanh nghiệp",
    invalidSecurityType2: "Loại chứng khoán không hợp lệ",
    invalidMarketCode: "Sàn không hợp lệ {marketCode}",
    checkVolumeLot1: "Khối lượng không hợp lệ",
    checkVolumeLot2: "Khối lượng không tròn lô (Lô {number})",
    invalidVolumeLot: "Khối lượng không tròn lô (Lô {number})",
    invalidVolumeLotEdit:
      "Sàn HNX, Upcom không được sửa KL lô chẵn thành lẻ, lẻ thành chẵn",
    invalidMarketCode2: "Sàn không hợp lệ",
    invalidTradingSession: "Không xác định được phiên {tradingSessionId}",
    invalidTradingSession2: "Thị trường đóng cửa",
    invalidMarketState: "Thị trường đóng cửa",
    invalidMarketState2:
      "Giá không hợp lệ. Phiên Ngoài giờ chỉ có thể đặt lệnh LO, ATO, ATC",
    invalidMarketState3:
      "Giá không hợp lệ. Phiên ATO chỉ có thể đặt lệnh LO, ATO, ATC",
    invalidMarketState4:
      "Giá không hợp lệ. Phiên KLLT chỉ có thể đặt lệnh LO, MP, ATC",
    invalidMarketState5:
      "Loại lệnh không hợp lệ. Phiên Nghỉ trưa chỉ có thể đặt lệnh LO, MP, ATC",
    invalidMarketState6:
      "Loại lệnh không hợp lệ. Phiên ATC chỉ có thể đặt lệnh LO, ATC",
    invalidMarketState7:
      "Giá không hợp lệ. Phiên Ngoài giờ chỉ có thể đặt lệnh LO, ATC",
    invalidMarketState8:
      "Giá không hợp lệ. Phiên KLLT chỉ có thể đặt lệnh LO, MOK, MAK, MTL, PLO, ATC",
    invalidMarketState9:
      "Giá không hợp lệ. Phiên Nghỉ trưa chỉ có thể đặt lệnh LO, MOK, MAK, MTL, PLO, ATC",
    invalidMarketState10:
      "Giá không hợp lệ. Phiên ATC chỉ có thể đặt lệnh LO, ATC, PLO",
    invalidMarketState11:
      "Giá không hợp lệ. Phiên RUNOFF chỉ có thể đặt lệnh PLO",
    invalidMarketState12: "Lệnh lô lẻ chỉ được phép đặt giá LO",
    invalidMarketState13:
      "Lệnh lô lẻ chỉ được phép đặt trong phiên KLLT, Nghỉ trưa, Ngoài giờ và Trước giờ",
    invalidMarketState14: "Giá không hợp lệ. Sàn UPCOM chỉ có thể đặt lệnh LO",
    invalidMarketState15:
      "Giá không hợp lệ. Phiên KLLT chỉ có thể đặt lệnh LO, MOK, MAK, MTL, ATC",
    invalidMarketState16:
      "Giá không hợp lệ. Phiên KLLT chỉ có thể đặt lệnh LO, MOK, MAK, MTL, ATC",
    invalidMarketState17:
      "Giá không hợp lệ. Phiên ATC chỉ có thể đặt lệnh LO, ATC",
    invalidMarketState18: "Sàn không hợp lệ",
    volumeShouldNotBeExceeded: "Khối lượng không được vượt quá {limit}",
    invalidPrice0: "Chỉ được đăt giá LO với lệnh điều kiện {stopType}",
    invalidPrice: "Giá cắt lỗ phải lớn hơn giá hiện tại",
    invalidPrice2: "Giá cắt lỗ + biên trượt không được lớn hơn giá trần",
    invalidPrice3: "Giá cắt lỗ phải khác giá đặt",
    invalidPrice4: "Giá cắt lỗ phải nhỏ hơn giá hiện tại",
    invalidPrice5: "Giá cắt lỗ - biên trượt phải lớn hơn giá sàn",
    invalidPrice6: "Giá chốt lãi phải lớn hơn giá đặt",
    invalidPrice7: "Giá chốt lãi phải nhỏ hơn hoặc bằng giá trần",
    invalidPrice8: "Giá cắt lỗ phải nhỏ hơn giá đặt",
    invalidPrice9: "Giá cắt lỗ phải lớn hơn hoặc bằng giá sàn",
    invalidPrice10: "Giá cắt lỗ hoặc chốt lãi phải khác giá đặt",
    invalidPrice11: "Giá chốt lãi phải nhỏ hơn giá đặt",
    invalidPrice12: "Giá chốt lãi phải lớn hơn hoặc bằng giá sàn",
    invalidPrice13: "Giá cắt lỗ phải lớn hơn giá đặt",
    invalidPrice14: "Giá cắt lỗ phải phải nhỏ hơn hoặc bằng giá trần",
    invalidPrice15: "Giá kích hoạt không được lớn hơn giá trần",
    invalidPrice16: "Giá kích hoạt không được nhỏ hơn giá sàn",
    invalidPrice17:
      "Giá kích hoạt phải lớn hơn giá thị trường {priceToCheckCond}",
    invalidPrice18:
      "Giá kích hoạt phải nhỏ hơn giá thị trường {priceToCheckCond}",
    invalidPrice19: "Giá sửa không được lớn hơn giá trần",
    invalidPrice20: "Giá sửa không được nhỏ hơn giá sàn",
    invalidPrice21: "Giá chốt lãi phải nhỏ hơn giá trần",
    invalidPrice22: "Giá cắt lỗ phải lớn hơn giá sàn",
    invalidPrice23: "Không được sửa lệnh mua FAL",
    invalidOrderData: "Không xác định được thông tin lệnh cần sửa",
    marketSessionCloseErr: "Thị trường đóng cửa",
    couldNotGetStockCodeInformationErr:
      "Không tìm thấy thông tin mã CK {symbol}",
    dataDoesNotChange: "Dữ liệu không thay đổi",
    invalidVolumne: "Khối lượng sửa phải lớn hơn 0",
    invalidVolumne2: "Khối lượng mới phải lớn hơn phần đã khớp",
    invalidVolumne3: "Giá cắt lỗ phải lớn hơn giá hiện tại",
    invalidVolumne4: "Giá cắt lỗ + biên trượt không được lớn hơn giá trần",
    invalidVolumne5: "Giá cắt lỗ phải khác giá đặt",
    invalidVolumne6: "Giá cắt lỗ phải nhỏ hơn giá hiện tại",
    invalidVolumne7: "Giá cắt lỗ - biên trượt phải lớn hơn giá sàn",
    invalidVolumne8: "Không được sửa tăng khối lượng",
    doNotModifyOrCancelThisOrderErr: "Không được sửa/hủy lệnh này",
    invalidPriceAndQty: "Không được sửa đồng thời cả Giá và Khối lượng",
    invalidMarketCd: "Không được sửa khối lượng với lệnh sàn HSX",
    volumeAllowedAndNotChangeprice:
      "Lệnh thị trường đang chờ vào sàn chỉ được phép sửa khối lượng, không được sửa giá",
    checkEditOrderStatus: "Không được sửa/hủy lệnh trong phiên KLĐK",
    checkEditOrderStatus2: "Không được sửa/hủy lệnh này",
    checkEditOrderStatus3: "Không được sửa lệnh này",
    checkEditOrderStatusDer: "Không được sửa/hủy lệnh trong phiên KLĐK",
    checkEditOrderStatusDerCond: "Không được sửa/hủy lệnh trong phiên KLĐK",
    checkEditOrderStatusDerCond2: "Không được sửa/hủy lệnh này",
    checkCancelOrder: "Không xác định được lệnh cần hủy",
  },
  netAssetsValue: {
    nav: {
      tabHeaderTitle: "Tổng tài sản & Hiệu quả đầu tư",
      card: {
        navSub: "Tài sản ròng (NAV):",
        marketValueSub: "Giá trị danh mục:",
        profitSub: "Lãi/lỗ tạm tính:",
        totalAsset: "Tổng tài sản:",
      },
      assetInfo: {
        nav: "Tài sản ròng",
        totalAsset: "Tổng tài sản",
        totalCashBalance: "1. Tiền",
        cashBalance: "1.1 Số dư tiền",
        matchedBuyT0: "1.2 Mua khớp chờ thanh toán",
        unMatchBuy: "1.3 Tiền mua chờ khớp",
        cashAdvanceAble: "1.4 Số tiền có thể ứng",
        cashWithdrawable: "1.5 Số tiền có thể rút",
        totalShareValue: "2. Cổ phiếu",
        shareValue: "2.1 Giá trị CP đang có",
        shareValueBuy: "2.2 Giá trị CP mua chờ về",
        shareValueSell: "2.3 Giá trị CP bán chờ thanh toán",
        sumRight: "2.4 Giá trị quyền chờ về",
        rightCash: "Cổ tức bằng tiền mặt",
        rightShare: "Cổ tức bằng CP",
        rightBuyStock: "Quyền mua",
        totalCWValue: "3. Chứng quyền",
        cwValue: "3.1 Giá trị CW đang có",
        cwValueBuy: "3.2 Giá trị CW mua chờ về",
        cwValueSell: "3.3 Giá trị CW bán chờ thanh toán",
        cwValueBuyMaturity: "3.4 Giá trị CW mua chờ thanh toán đáo hạn",
        bondValue: "4. Trái phiếu",
        capValue: "5. Hợp tác kinh doanh",
        fsTotalAssets: "6. Phái sinh",
        fsMBS: "6.1 Tiền phái sinh tại MBS",
        fsVSD: "6.2 Tiền phái sinh ký quỹ VSD",
        fsCashWithdrawable: "6.3 Tiền phái sinh có thể rút",
        totalDebit: "7. Nợ",
        rootDebit: "7.1 Dư nợ gốc",
        fee: "7.2 Lãi phí dịch vụ tài chính",
      },
    },
    financialServices: {
      tabHeaderTitle: "TS DVTC",
      account: "Tài khoản",
      normal: "Thường",
      table: {
        tableName: "Danh mục chứng khoán",
        order: "STT",
        accountNoDetail: "TẢI KHOẢN",
        symbol: "MÃ CK",
        tradeType: "TRẠNG THÁI",
        marginRatio: "TỶ LỆ KÝ QUỸ (%)",
        availableBalance: "Khả dụng",
        sharesBalance: "TỔNG KL",
        unmatchSell: "CHỜ KHỚP BÁN",
        qtyT0: "T + 0",
        qtyT1: "T + 1",
        qtyT2: "T + 2",
        bought: "MUA",
        sold: "BÁN",
        marketValue: "GIÁ TT",
        grossValue: "TỔNG GIÁ TRỊ",
        total: "TỔNG LÃI/LỖ",
        total2: "TỔNG CỘNG",
        rightShares: "QUYỀN CHỜ VỀ",
      },
      cards: {
        totalShareValue: "I. TỔNG GIÁ TRỊ CHỨNG KHOÁN",
        totalCashBalance: {
          title: "II. TIỀN (1-2-3+4+6)",
          cashBalance: "1. Số dư",
          buyingCashBlock: "2. Mua chờ khớp",
          matchedBuyT0: "3. Mua đã khớp chờ thanh toán",
          receivingSellCash: "4. Tiền bán chờ về (T0, T1, T2)",
          withDrawCash: "5. Tiền có thể rút",
          cashDividend: "6. Quyền chờ về tạm tính",
        },
        totalDebit: {
          title: "III. TỔNG NỢ DVTC (7+8+9)",
          principalBalance: "7. Dư nợ gốc",
          paidInterest: "8. Lãi/Phí dịch vụ tài chính",
          cashAdvanced: "9. Tiền đã ứng",
        },
        totalAsset: "IV. TÀI SẢN RÒNG THỰC",
        financialServicesAccInfo: {
          title: "THÔNG TIN TÀI KHOẢN DỊCH VỤ TÀI CHÍNH",
          callRatio: "Tỷ lệ Call của MBS",
          forceSellRatio: "Tỷ lệ Force Sell của MBS",
          afterBuyRatio: "Tỷ lệ sau mua",
          interestRatio:
            "Tỷ lệ lãi vay dịch vụ chưa gồm hệ số gia hạn (nếu có)",
          creditLimit: "Hạn mức tài khoản",
          falLimit: "Hạn mức Fal",
          falRemainCredit: "Hạn mức Fal còn lại",
          falUsed: "Nợ Fal đã dùng",
          buyCredit: "Sức mua",
          portfolioValue: "GTDM tính QTRR",
          netWorth: "TSR tính QTRR",
          currentMarginRatio: "Tỷ lệ ký quỹ hiện tại (TSR/GTDM)",
          capValueLink: "HTKD link sức mua",
          bPLinkMB: "Sức mua liên kết TK MB",
          bPLinkMBUsed: "Đã dùng SM liên kết MB",
        },
      },
    },
    derivative: {
      tabHeaderTitle: "TS Phái sinh",
      noAccount: "Tài khoản chưa có tiểu khoản phái sinh",
      filter: {
        account: "Tài khoản",
      },
      card: {
        overCash: {
          cardTitle: "TỔNG SỐ DƯ TIỀN",
          commissionPerFee: "Phí treo",
          interest: "Lãi treo",
          deliveryAmount: "Giá trị chuyển giao",
          floatingPLPerTradingPL: "Lãi lỗ vị thế (mở/đóng)",
          totalPL: "Tổng lãi/lỗ",
          minreservPerMarginable: "Số dư tối thiếu/Tiền có thể KQ VSD",
          cashDrawablePerNonCashDrawable: "Tổng tiền/Giá trị CK có thể rút",
        },

        assetPortfolio: {
          cardTitle: "DANH MỤC TÀI SẢN",
          MBS: "TẠI MBS",
          VSD: "TẠI VSD",
          cash: "Tiền mặt",
          validNonCash: "Giá trị CK ký quỹ",
          totalValue: "Tổng giá trị ký quỹ hợp lệ",
          maxValidNonCash: "GT CK ký quỹ tối đa tính hợp lệ",
          cashWithdrawable: "Tiền có thể rút",
          ee: "Sức mở vị thế",
        },

        signFund: {
          cardTitle: "SỬ DỤNG KÝ QUỸ",
          MBS: "TẠI MBS",
          VSD: "TẠI VSD",
          signInitValue: "Giá trị ký quỹ ban đầu",
          signTransValue: "Giá trị ký quỹ chuyển giao",
          marginRequirement: "Tổng giá trị ký quỹ yêu cầu",
          accountRatio: "Tỷ lệ sử dụng tài sản ký quỹ (%)",
          usedLimitWarning: "Ngưỡng cảnh báo 1/2/3 (%)",
          marginCall: "Giá trị tiền cần bổ sung",
        },
      },
      table: {
        positionOpen: {
          title: "Vị thế mở",
          rowNum: "STT",
          seriesID: "MÃ HĐ",
          dueDate: "NGÀY ĐÁO HẠN",
          long: "VỊ THẾ MUA",
          short: "VỊ THẾ BÁN",
          net: "VỊ THẾ RÒNG",
          bidAvgPrice: "GIÁ MUA TB",
          askAvgPrice: "GIÁ BÁN TB",
          marketPrice: "GIÁ TT",
          tradingPLPerFloatingPL: "LÃI/LỖ TRONG NGÀY",
        },
        positionClose: {
          title: "Vị thế đóng",
          rowNum: "STT",
          seriesID: "MÃ HĐ",
          long: "VỊ THẾ ĐÓNG",
          net: "VỊ THẾ RÒNG",
          bidAvgPrice: "MUA TRUNG BÌNH",
          askAvgPrice: "BÁN TRUNG BÌNH",
          tradingPLPerFloatingPL: "LÃI/LỖ",
        },
      },
    },
    charts: {
      beta: "(BETA)",
      betaText1: "Dữ liệu HQDT được thống kê từ 23/11/2021",
      betaText2:
        "TSSL tính theo công thức Time-Weighted Rate(TWR) = {End NAV - (Begin NAV + Cash Flow)}/(Begin NAV + Cash Flow)",
      betaText3:
        "Công thức chỉ mang tính chất tham khảo, Chúng tôi không chịu trách nhiệm về các quyết định của Khách hàng khi dựa vào số liệu này",
      navLineChart: {
        title: "TÀI SẢN RÒNG",
        filterDate: {
          oneMonth: "1m",
          twoMonth: "2m",
          threeMonth: "3m",
          sixMonth: "6m",
          oneYear: "1y",
        },
        filterAccount: "Tài khoản",
        growth: "Tăng trưởng:",
        nav: "NAV:",
      },
      investLineChart: {
        title: "HIỆU QUẢ ĐẦU TƯ",
        filterDate: {
          oneMonth: "1m",
          twoMonth: "2m",
          threeMonth: "3m",
          sixMonth: "6m",
          oneYear: "1y",
        },
        filterAccount: "Tài khoản",
        indexLabel: "Index",
        profitLabel: "Tỷ suất sinh lời (TSSL)",
        profitLabelShort: "TSSL",
        idxVal: "VN-Index",
        percentDelta: "NAV",
      },
      assetDistributionChart: {
        title: "PHÂN BỔ TỔNG TÀI SẢN",
        totalCashBalance: "Tiền",
        totalShareValue: "Cổ phiếu",
        totalCWValue: "Chứng quyền",
        bondValue: "Trái phiếu",
        capValue: "Hợp tác kinh doanh",
        fsTotalAssets: "Phái sinh",
      },
      debtAndNavChart: {
        title: "NỢ VÀ TÀI SẢN RÒNG",
        debt: "Nợ",
        nav: "Tài sản ròng",
      },
      tickerRateChart: {
        title: "TỶ TRỌNG MÃ CỔ PHIẾU",
      },
      indexRateChart: {
        title: "TỶ TRỌNG NHÓM CHỈ SỐ",
        account: "Tài khoản",
        ticker: "VN-Index",
      },
      industryRateChart: {
        title: "TỶ TRỌNG NGÀNH",
        account: "Tài khoản",
        ticker: "VN30",
      },
      unit: "Đơn vị: Triệu VNĐ",
    },
  },
  onlineService: {
    careService: "Dịch vụ chăm sóc",
    authenticationMethod: "PT xác thực",
  },
  alertSettings: {
    modalTitle: "Cài đặt cảnh báo",
    symbolWarning: "Vui lòng chọn mã để cài đặt cảnh báo",
    setAlert: {
      tabTitle: "Đặt cảnh báo",
      volume: "KHỐI LƯỢNG",
      ceiling: "TRẦN",
      floor: "SÀN",
      referenceShort: "TC",
      totalValue: "GIÁ TRỊ",
      priceMatchGTE: "Giá khớp >=",
      priceMatchLTE: "Giá khớp <=",
      overBoughtGTE: "Dư mua >=",
      overSoldtLTE: "Dư bán <=",
      priceIncreasePercent: "% tăng giá",
      priceDecreasePercent: "% giảm giá",
      timesPerDay: "Lặp lại",
      fromDate: "Từ ngày",
      toDate: "Đến ngày",
      createSuccessMsg: "Tạo cảnh báo {symbol} thành công",
      editSuccessMsg: "Sửa cảnh báo {symbol} thành công",
      deleteSuccessMsg: "Xóa thành công",
      notExistMsg: "Mã không tồn tại",
      dateError1: "Từ ngày không được vượt quá Đến ngày",
      dateError2: "Từ ngày và đến ngày không phải là ngày trong quá khứ",
      indexValUp: "Chỉ số >=",
      indexValDown: "Chỉ số <=",
      indexChangePerUp: "% Tăng chỉ số",
      indexChangePerDown: "% Giảm chỉ số",
      totalQtyUp: "KL >= (Triệu CP)",
      totalAmtUp: "Giá trị >= (Tỷ)",
      repeatType1: "1 ngày 1 lần",
      repeatType2: "1 phút 1 lần",
      repeatType3: "30 phút 1 lần",
    },
    manageAlert: {
      tabTitle: "Quản lý cảnh báo",
    },
    action: {
      cancel: "Hủy",
      save: "Lưu",
    },
    emptySymbolError: "Không được để trống mã",
  },
  businessCooperation: {
    businessCooperationTab: {
      tableTitle: "Giao dịch eM24",
      filterActions: {
        fromDate: "Từ ngày",
        toDate: "Đến ngày",
        account: "Tài khoản",
        status: "Trạng thái",
        search: "Tìm kiếm",
        disapprove: "Chưa duyệt",
        approved: "Đã duyệt",
        liquidated: "Đã thanh lý",
        all: "Tất cả",
      },
      table: {
        appendixNumber: "SỐ PHỤ LỤC",
        accountNo: "TK LẬP",
        value: "GIÁ TRỊ",
        period: "KỲ HẠN",
        openDate: "NGÀY MỞ",
        expireDate: "NGÀY HẾT HẠN",
        commission: "LS (%/NĂM)",
        estimatedIncome: "THU NHẬP DỰ TÍNH",
        buyCredit: "TÍNH SỨC MUA",
        accountNoLinkBuyCredit: "TK TÍNH SM",
        extendMethod: "PT GIA HẠN",
        introduceStaffName: "TÊN NVGT",
        introduceStaffCode: "MÃ NVGT",
        channel: "KÊNH",
        status: "TRẠNG THÁI",
        total: "TỔNG",
        liquidation: "Thanh lý",
        periodMsg1: "Từ 1 ngày đến 7 ngày",
        periodMsg2: "Từ 8 ngày đến 14 ngày",
        periodMsg3: "Từ 15 ngày đến 21 ngày",
        periodMsg4: "Từ 22 ngày đến 29 ngày",
        periodMsg5: "1 tháng",
        periodMsg6: "2 tháng",
        periodMsg7: "3 tháng",
        periodMsg8: "4 tháng",
        periodMsg9: "5 tháng",
        periodMsg10: "6 tháng",
        periodMsg11: "9 tháng",
        periodMsg12: "1 năm",
      },
      doubleClickTooltip: "Nhấn đúp để sửa đổi",
    },
    createAppendixCard: {
      cardTitle: "Tạo phụ lục",
      cardInfo: {
        accountNum: "Số tài khoản",
        withDrawCash: "Số dư",
        period: "Kỳ hạn",
        days: "Ngày",
        openDate: "Ngày mở",
        expireDate: "Ngày hết hạn",
        cooperationDays: "Số ngày thực tế",
        capitalValue: "Giá trị",
        enterValue: "Nhập giá trị",
        interestRate: "LS (%/năm)",
        profitEstimate: "Tổng thu nhập dự tính",
        calcPurchaseAbility: "Tính sức mua",
        linkType0: "Không tính sức mua",
        linkType1: "Tính sức mua",
        accountDesignation: "Tài khoản chỉ định",
        accountNoLinkBuyCredit: "Tài khoản tính sức mua",
        extensionMethod: "Phương thức gia hạn",
        extensionType0: "Tự động gia hạn gốc",
        extensionType1: "Tự động gia hạn gốc + lãi",
        extensionType2: "Không gia hạn",
        introduceStaffName: "Tên NV giới thiệu",
        enterStaffCode: "Nhập mã nhân viên giới thiệu",
        staffCodeError: "Mã nhân viên không tồn tại",
        introduceStaffCode: "Mã NV giới thiệu",
        confirmationText:
          "Tôi đã đọc, hiểu và đồng ý với các điều khoản và điều kiện đã nêu trong hợp đồng giao dịch eM24",
        createSuccessMsg: "Tạo phụ lục thành công",
        valueMoreWithdrawCashError: "Số dư tiền gửi không đủ để thực hiện",
        valueEmpty: "Vui lòng nhập giá trị góp vốn",
        noteTitle: "QUÝ KHÁCH CHÚ Ý",
        noteText1: `"Số ngày hợp tác (thực tế)" > "Số ngày cụ thể" khách hàng đăng ký hợp tác.`,
        noteText2: `Quý khách nên chọn lại Tên Kỳ hạn và "Số ngày cụ thể" KH đăng ký hợp tác để được hưởng Lợi suất tương ứng.`,
        choose: "-----Chọn-----",
      },
      cardActions: {
        cancel: "Hủy bỏ",
        agree: "Đồng ý",
      },
    },
    liquidationModal: {
      modalTitle: "Thanh lý",
      accountNum: "Số tài khoản",
      appendixNum: "Số phụ lục",
      openDate: "Ngày mở",
      expireDate: "Ngày hết hạn",
      capitalValue: "Giá trị",
      liquidationDate: "Ngày thanh lý",
      numberDayBorrow: "Số ngày thực tế",
      liquidFeeRate: "LS thanh lý (%/năm)",
      liquidValue: "Giá trị thanh lý",
      liquidFee: "Tổng thu nhập nhận được",
      confirmationText:
        "Tôi đã đọc, hiểu và đồng ý với các điều khoản và điều kiện đã nêu trong hợp đồng giao dịch eM24",
      cancel: "Bỏ qua",
      agreeLiquid: "Thanh lý",
      liquidSuccessMsg: "Thanh lý thành công",
    },
    interestPercentTable: {
      tableTitle: "Biểu lợi suất",
      feeType: "Biểu lợi suất",
      feeTypeInterest: "HTKD",
      feeTypeAuto: "HTKD Tự động",
      feeTypeInterestLiquid: "Thanh lý trước hạn",
      customerGroup: "Nhóm KH",
      personnal: "Cá nhân",
      organizational: "Tổ chức",
      search: "Tìm kiếm",
      rowNum: "STT",
      period: "KỲ HẠN",
      fromDate: "NGÀY BAN HÀNH",
      fromValue: "GIÁ TRỊ TỪ (VNĐ)",
      toValue: "GIÁ TRỊ ĐẾN (VNĐ)",
      interestPercent: "LÃI SUẤT (%/NĂM)",
    },
    editModal: {
      modalTitle: "Sửa đổi",
      accountNum: "Số tài khoản",
      appendixNum: "Số phụ lục",
      openDate: "Ngày mở",
      capitalValue: "Giá trị",
      calcBuyCredit: "Tính sức mua",
      calcBuyCreditAccount: "TK tính sức mua",
      designationAccount: "TK chỉ định",
      extensionMethod: "Phương thức gia hạn",
      cancel: "Bỏ qua",
      agreeLiquid: "Cập nhật",
      editSuccessMsg: "Chỉnh sửa phụ lục thành công",
    },
    createContractFrameModal: {
      modalTitle: "Đăng ký dịch vụ",
      accountNum: "Số tài khoản",
      openDate: "Ngày ĐK",
      expireDate: "Ngày kết thúc",
      confirmationText:
        "Tôi đã đọc, hiểu và đồng ý với Bộ điều khoản và điều kiện sử dụng {linkHTKD} và quy định tại MBS.",
      HTKD: "giao dịch eM24",
      DVCalcBuyCredit: " Dịch vụ Tính sức mua",
      cancel: "Hủy bỏ",
      agree: "Đồng ý",
      registerSuccessMsg: "Đăng ký thành công",
    },
  },
  statement: {
    errorDay: "Ngày chốt không được lớn hơn Đến ngày",
  },
  bonds: {
    cancelBuy: {
      infoBond: "THÔNG TIN TRÁI PHIẾU SỞ HỮU",
      bondCode: "Mã trái phiếu",
      contractCode: "Số HĐ",
      buyDate: "Ngày GD mua",
      maturityDate: "Ngày kết thúc đầu tư dự kiến",
      termRate: "Lợi suất (%/năm)",
      sellVol: "KL bán",
      price: "Đơn giá",
      contractValue: "Tổng GTGD",
      cancel: "ĐĂNG KÝ HỦY",
      cancelLeft: "Yêu cầu hủy",
      cancelRight: "Hủy bán để nắm giữ đến khi đáo hạn",
      cancelButton: "Hủy bán",
    },
    manageBond: {
      createDateFrom: "Ngày GD mua từ",
      createDateTo: "Đến ngày",
      buy: "Mua",
      sell: "Bán",
      DA_GIAO_DICH_MUA: "Đã giao dịch mua",
      DA_GIAO_DICH_BAN: "Đã giao dịch bán",
      CHO_BAN: "Chờ bán",
      contractCode: "SỐ HỢP ĐỒNG",
      transType: "LOẠI GIAO DỊCH",
      vol: "KHỐI LƯỢNG (TP)",
      price: "ĐƠN GIÁ",
      contractValue: "GTGD",
      termRate: "LỢI SUẤT (%/NĂM)",
      buyDate: "NGÀY GIAO DỊCH MUA",
      finishDate: "NGÀY KT DỰ KIẾN",
      sellDate: "THỜI GIAN GIAO DỊCH",
      buyChannel: "KÊNH MUA",
      sellChannel: "KÊNH BÁN",
      accountReceiveBank: "MỞ TẠI",
      statusCode: "TRẠNG THÁI",
      accountReceiveCode: "TK nhận tiền",
    },
    buyBond: {
      rowNum: "STT",
      bondCode: "MÃ TRÁI PHIẾU",
      roomBalance: "KHỐI LƯỢNG TP CÒN LẠI",
      termRate: "LỢI SUẤT ĐÁO HẠN NET (%/NĂM)",
      maturityDate: "NGÀY ĐÁO HẠN",
      timeRemaining: "THỜI GIAN CÒN LẠI (THÁNG)",
      minInvestmentValue: "SỐ TIỀN TỐI THIỂU",
    },
    owningBond: {
      contractValue: "GIÁ TRỊ ĐẦU TƯ",
      cashNonInvestSum: "TỔNG GT ĐẦU TƯ DỰ KIẾN",
      accountReceiveCode: "TK NHẬN TIỀN",
      accountReceiveAddr: "MỞ TẠI",
      channel: "KÊNH ĐẶT LỆNH",
      cancelSell: "HỦY BÁN",
      sell: "BÁN",
    },
    sellBond: {
      HUY_DAT: "Hủy đặt",
      HUY_GIAO_DICH: "Hủy giao dịch",
      KY_THUAT: "Kỹ thuật",
      SUA_GIAO_DICH: "Sửa giao dịch",
      DA_GIAO_DICH: "Đã giao dịch",
      DA_SUA_GIAO_DICH: "Đã sửa giao dịch",
      buyPrice: "GIÁ MUA",
      buyDate: "NGÀY GIAO DỊCH",
      footerTable:
        'Quý khách sử dụng chức năng Bán trái phiếu để thay đổi thời gian đầu tư của HĐ, từ "Tới khi đáo hạn Trái phiếu" sang kỳ hạn ngắn hơn.',
    },
    infoBond: {
      issuerBond: "Tổ chức phát hành",
      bondCode: "Mã trái phiếu",
      parPrice: "Mệnh giá trái phiếu",
      bondNum: "Khối lượng phát hành",
      bondVal: "Tổng giá trị mệnh giá phát hành",
      issuePurpose: "Mục đích phát hành",
      moneyAndPay: "Đồng tiền phát hàng và thanh toán",
      VND: "Đồng Việt Nam",
      termBond: "Kỳ hạn trái phiếu",
      releaseDate: "Ngày phát hành",
      maturityDate: "Ngày đáo hạn",
      interestbonds: "Lãi suất danh nghĩa Trái Phiếu",
      applyInterest: "Lãi suất áp dụng cho ",
      firstInterest: " kỳ tính lãi đầu tiên của Trái Phiếu là ",
      year: " %/năm.",
      applyYear:
        " %/năm.Lãi suất áp dụng cho các kỳ tính lãi tiếp theo của Trái Phiếu sẽ được xác định bằng lãi suất tham chiếu cộng (+) biên độ ",
      datePayment: "Ngày thanh toán lãi",
      couponPayment1: "Tiền lãi Trái phiếu được trả sau, định kỳ mỗi ",
      couponPayment2: " tháng một lần vào ngày cuối cùng của mỗi Kỳ Tính Lãi",
      paymentMethod: "Phương thức thanh toán gốc, lãi Trái phiếu",
      typeOfBond: "Loại hình Trái phiếu",
      releaseForm: "Hình thức Trái phiếu",
      depositAgents: "Đại lý đăng ký lưu ký",
      paymentAgents: "Đại lý thanh toán",
      collateral: "Tài sản đảm bảo",
      collateralAgents: "Đại lý quản lý TSĐB",
      repreOwner: "Đại diện NSH Trái phiếu",
      issuerBond1:
        "Các điều khoản, điều kiện khác của Trái Phiếu theo thông tin nêu tại Bản Công bố thông tin sơ bộ do ",
      ocDate1: " công bố ngày ",
      ocDate2:
        " và các tài liệu khác có liên quan (nếu có) (gọi chung là 'Các Văn Kiện Trái Phiếu')",
    },
    registerBuy: {
      errorVolNull: "Vui lòng nhập khối lượng trái phiếu",
      errorVol0: "Vui lòng nhập khối lượng trái phiếu",
      EXTERNAL_ACCOUNT: "TK Ngân hàng",
      INTERNAL_ACCOUNT: "TK GDCK",
      infoBond: "THÔNG TIN TRÁI PHIẾU SỞ HỮU",
      termRate: "Lợi suất đáo hạn NET",
      numInvestDate: "Số ngày đầu tư",
      maturityDate: "Ngày đáo hạn TP",
      roomBalance: "Khối lượng TP còn lại",
      infoTrading: "THÔNG TIN GIAO DỊCH",
      accountPay: "TK GDCK",
      cashBalance: "Số dư tiền (VND)",
      buyVolMax: "KL mua tối đa",
      buyVolMin: "KL mua tối thiểu",
      date: "Ngày đăng ký mua",
      buyDate: "Ngày giao dịch mua",
      buyVol: "Khối lượng (TP)",
      enterVol: "Nhập khối lượng",
      buyPrice: "Đơn giá giao dịch",
      buyPrice2: "Giá trị giao dịch",
      buyFee: "Phí chuyển nhượng",
      buyFee2: "Tổng giá trị giao dịch",
      termCodeFuture: "Thời gian đầu tư dự kiến",
      termDate: "Ngày kết thúc đầu tư dự kiến",
      termRate2: "Lợi suất đầu tư NET ",
      detail: "Xem chi tiết dòng tiền đầu tư dự kiến",
      infoAccount: "THÔNG TIN TÀI KHOẢN NHẬN TIỀN",
      accountReceiveType: "Loại TK",
      accountReceiveCode: "Số TK",
      accountName: "Tên chủ TK",
      bankName: "Mở tại",
      bankBranch: "Chi nhánh",
      infoFrontUser: "THÔNG TIN NHÂN VIÊN TƯ VẤN ",
      optional: "(KHÔNG BẮT BUỘC)",
      contractMgtCode: "Mã NV quản lý",
      enterContractMgtCode: "Nhập mã NV quản lý",
      frontUserName: "Tên NV quản lý",
      distrCustomerCode: "Mã NV tư vấn",
      enterDistrCustomerCode: "Nhập mã NV tư vấn",
      distrCustomerName: "Tên NV tư vấn",
      contractDistrCode: "Sổ HĐ NV tư vấn",
      one: "1. Các điều khoản và Điều kiện mua Trái phiếu",
      two: "2. Đề nghị chuyển nhượng mua Trái phiếu",
      confirm:
        'Tôi xác nhận và đồng ý với "Các Điều khoản và Điều kiện Mua Trái Phiếu", " Đề nghị chuyển nhượng Mua Trái phiếu" đã nêu trên và các Văn kiện Trái Phiếu liên quan',
      registerBuy: "Đăng ký mua",
    },
    investmentDetail: {
      content: "NỘI DUNG",
      payCouponDate: "NGÀY TT",
      cashNonInvest: "SỐ TIỀN DỰ KIẾN NHẬN",
      cashCouponReInvest: "SỐ TIỀN COUPON TÁI ĐẦU TƯ",
      cashReInvest: "LÃI/LỢI SUẤT",
      payCouponDate2: "NGÀY ĐẦU TƯ",
      lastPayCouponDate: "NGÀY KẾT THÚC",
      detail: "Chi tiết dòng tiền đầu tư dự kiến nhận",
      sumCashFlowNonInvest: "Tổng dòng tiền từ Trái phiếu",
      termFlowNonInvest: "Lợi suất chưa bao gồm tái đầu tư Coupon",
      flowCashReInvests: "GIẢ ĐỊNH KH TÁI ĐẦU TƯ COUPON NHẬN ĐƯỢC",
      sumCashFlowReInvest: "Tổng dòng tiền từ Trái phiếu",
      termFlowReInvest: "Lợi suất đã bao gồm tái đầu tư Coupon ",
      year: " %/năm",
      tooltip1:
        "Tái đầu tư coupon: Là việc Khách Hàng tiếp tục đầu tư khoản coupon nhận được vào các hạng mục đầu tư khác trong thời gian nắm giữ Trái Phiếu (ví dụ gửi tiết kiệm….)",
      tooltip2:
        "Lợi suất đã tái đầu tư: Là lợi suất đầu tư Trái Phiếu Khách Hàng nhận được đã bao gồm tái đầu tư coupon (với giả định Khách Hàng tiếp tục đầu tư khoản coupon với lãi suất 7.2%/năm)",
      tooltip3:
        "Là lợi suất khách hàng thực nhận được trong thời gian đầu tư (MBS đã hỗ trợ các khoản thuế/phí liên quan)",
      dateCoupon: "Ngày thanh toán lãi Coupon",
      tooltipCoupon:
        "COUPON: Là lãi Trái Phiếu (đã trừ thuế thu nhập cá nhân (nếu có)) do Tổ Chức Phát Hành thanh toán. Lãi suất coupon và cách tính lãi coupon căn cứ theo quy định của Tổ Chức Phát Hành đối với Trái Phiếu.",
    },
    referenceRate: {
      termName: "THỜI GIAN ĐẦU TƯ",
      termRate: "LỢI SUẤT (%/NĂM)",
      content: "Bảng lợi suất tham khảo",
      bondCode: "Mã trái phiếu",
      buyValue: "Giá trị đầu tư",
    },
    transferBond: {
      dear: "Kính gửi:",
      mb: " Công ty cổ phần Chứng khoán MB",
      issuerBond:
        "Sau khi thỏa thuận, Bên chuyển nhượng và Bên nhận chuyển nhượng thống nhất thực hiện việc chuyển nhượng Trái phiếu ",
      bondCode1: " - Mã số Trái Phiếu: ",
      bondCode2:
        " từ bên chuyển nhượng sang bên nhận chuyển nhượng theo thông tin được nêu dưới đây:",
      taxValue: "Thuế TNCN (do MBS chi trả)",
      taxValue2: "(Thuế TNCN tạm khấu trừ = Tổng giá trị x 0.1%)",
      content: "ĐỀ NGHỊ CHUYỂN NHƯỢNG MUA TRÁI PHIẾU",
      contentSell: "ĐỀ NGHỊ CHUYỂN NHƯỢNG BÁN TRÁI PHIẾU",
      bondCode: "Mã trái phiếu",
      assignor: "BÊN CHUYỂN NHƯỢNG",
      organizationName1: "Tên tổ chức",
      organizationName2: "CTCP Chứng khoán MB",
      registrationNumber1: "Số ĐKKD",
      registrationNumber2: "116/GP-UBCK do UBCKNN cấp ngày 09/12/2013",
      address1: "Địa chỉ",
      address2: "Tầng M-3-7, Tòa nhà MB, Số 3 Liễu Giai - Ba Đình - Hà Nội",
      representative1: "Người đại diện",
      representative2: "Ông Trần Hải Hà",
      position1: "Chức vụ",
      position2: "Tổng giám đốc",
      transferParty: "BÊN NHẬN CHUYỂN NHƯỢNG",
      fullName: "Tên cá nhân",
      identityCardId: "Số CMND/CCCD",
      issueDate1: "Ngày cấp",
      issuePlace1: "Nơi cấp",
      accountReceiveCode: "Số tài khoản",
      bankName: "Mở tại",
      transferContent: "NỘI DUNG CHUYỂN NHƯỢNG",
      buyVol: "Khối lượng",
      unit: " Trái phiếu",
      parPrice: "Mệnh giá",
      parPrice2: " VND/Trái phiếu",
      buyPrice: "Giá chuyển nhượng",
      valueBuyPrice: "Tổng giá trị chuyển nhượng",
      buyFee: "Phí chuyển nhượng",
      VND: " VND",
      textArea1: "Tổ chức (Ông/ Bà): ",
      textArea2:
        " được ghi tên trên Sổ Đăng Ký và được sở hữu, hưởng mọi quyền lợi và chịu trách nhiệm về số trái phiếu chuyển nhượng nói trên kể từ ngày có xác nhận đăng ký chuyển nhượng của Công ty Cổ phần Chứng khoán MB. Bên nhận chuyển nhượng không chịu ảnh hưởng cũng như không phải chịu trách nhiệm về bất kỳ thỏa thuận liên quan đến Trái Phiếu giữa Tổ Chức Phát Hành và/hoặc Đại Lý Đăng Ký Lưu Ký và/hoặc Bên chuyển nhượng và/hoặc bên thứ ba nào khác mà Bên nhận chuyển nhượng không được thông báo bằng văn bản, ngoại trừ Bản Công Bố Thông Tin, Hợp Đồng Đại lý Đăng Ký Lưu Ký và Hợp đồng mua bán giữa Bên chuyển nhượng và Bên nhận chuyển nhượng. ",
      textArea3:
        " ủy quyền cho Công ty Cổ phần Chứng khoán MB xác nhận đăng ký chuyển nhượng theo yêu cầu của các Nhà Đầu Tư (Bên chuyển nhượng, Bên nhận chuyển nhượng). Công ty Cổ phần Chứng khoán MB chỉ xác nhận tính hợp lệ về thủ tục và việc đăng ký quyền sở hữu trái phiếu, không xác nhận việc thanh toán giữa hai bên. Các Bên thừa nhận, trong mọi trường hợp, ",
      textArea4:
        " là đơn vị chịu trách nhiệm thanh toán gốc và/hoặc lãi Trái Phiếu cho Chủ Sở Hữu Trái Phiếu và cam đoan chịu trách nhiệm về tính đầy đủ, hợp pháp của nguồn tiền thanh toán gốc và/hoặc lãi cho Chủ Sở Hữu Trái Phiếu.",
    },
    registerSell: {
      canSell0:
        "Hợp đồng đang sửa đổi, Quý Khách chưa thể thực hiện giao dịch.",
      canSell_1:
        "Hợp đồng đang được cấp Giấy chứng nhận sở hữu, Quý Khách chưa thể thực hiện giao dịch.",
      buyValue: "Giá trị đầu tư (VND)",
      maturityDate: "Ngày kết thúc đầu tư",
      termNonInvest: "Lợi suất NET (%/năm)",
      infoSell: "THÔNG TIN GIAO DỊCH BÁN",
      date: "Ngày đề nghị bán",
      sellDate: "Ngày giao dịch bán",
      termRate: "Lợi suất (%/năm)",
      sellValue: "Tổng giá trị giao dịch (VND)",
      taxPit: "Tỷ lệ thuế TNCN",
      taxValue: "Thuế TNCN (Do MBS chi trả)",
      detail: "Xem chi tiết dòng tiền dự kiến nhận được khi bán TP",
      infoAccount: "THÔNG TIN TÀI KHOẢN NHẬN TIỀN",
      change: "Thay đổi",
      two: "1. Các điều khoản và Điều kiện bán Trái phiếu",
      three: "2. Đề nghị chuyển nhượng bán Trái phiếu",
      confirm:
        "Tôi xác nhận và đồng ý với Các Điều khoản và Điều kiện Bán Trái Phiếu, Đề nghị chuyển nhượng Bán Trái phiếu đã nêu trên và các Văn kiện Trái Phiếu liên quan",
      registerSell: "Đăng ký bán",
      contractCode: "Mã hợp đồng",
    },
    changeAccountBank: {
      success: "Thay đổi tài khoản nhận tiền thành công",
      changeAccountBank: "Thay đổi tài khoản nhận tiền",
    },
    tab: {
      BUYBONDS: "Mua trái phiếu",
      SELLBONDS: "Bán trái phiếu",
      OWNINGBONDS: "Sở hữu trái phiếu",
      MANAGEBONDS: "Quản lý GD trái phiếu",
      dayCareParams1: "Thời gian được thực hiện Đăng ký bán trong vòng tối đa ",
      dayCareParams2: " ngày làm việc kể từ Ngày GD mua Trái Phiếu.",
      infoTrading: "Thông tin trái phiếu giao dịch",
      registerBuy: "Đăng ký mua trái phiếu",
      registerSell: "Đăng ký bán trái phiếu ",
      cancelSell: "Hủy bán trái phiếu",
    },
    toast: {
      buySuccess: "Đăng ký mua thành công",
      cancelSellSuccess: "Hủy bán thành công",
      sellSuccess: "Đăng ký bán thành công",
    },
  },
  techAnalysis: {
    tab: {
      title: "Màn",
      open: "Mở",
      rename: "Đổi tên",
      saveTheTemplate: "Lưu lại mẫu",
    },
    createBoard: {
      chooseABoard: "Chọn một bảng",
      all: "Tất cả",
      default: "Mặc định",
      customized: "Tùy chỉnh",
      newBoard: "Bảng mới",
      descNewBoard: "Thêm các khối vào bảng trống.",
      standard: "Tiêu chuẩn",
      descStandard: "Dành cho nhà giao dịch cơ bản và mới.",
      standard2: "Tiêu chuẩn 2",
      widgetsSettings: "Cài đặt các khối",
      suggestionsWidgetsSettings: "Chọn và kéo thả vào bảng",
      addDisplayArea: "Thêm vùng hiển thị",
      hoverOverTheAreaToAdd: "Di chuột đến khu vực cần thêm",
      viewDoesNotExist: "Chế độ xem không tồn tại",
    },
    modalSaveBoard: {
      titleModal: "Lưu lại bảng",
      nameBoard: "Tên bảng",
      description: "Mô tả",
      cancel: "Hủy",
      save: "Lưu",
    },
    modalRenameBoard: {
      titleModal: "Đổi tên bảng",
      ok: "OK",
      cancel: "Hủy",
    },
    widgets: {
      technicalAnalysis: "Phân tích kỹ thuật",
      priceList: "Bảng giá",
      volumeAndTime: "Khối lượng và thời gian",
      news: "Tin tức",
      orderFlowDistribution: "Phân bổ lệnh khớp (Đơn vị: Tỷ đồng)",
      finance: "Tài chính",
      organizationalInfo: "Thông tin doanh nghiệp",
      tickerInfo: "Thông tin mã chứng khoán",
    },
  },
  changeStockStatus: {
    changeStatus: " chuyển trạng thái CK",
    shareStatus: "LOẠI CK",
    shareStatusIn: "TT NHẬN",
    shareVol: "SL CHUYỂN",
    enter: "Chuyển khoản",
    transactionDate: "NGÀY GIAO DỊCH",
    accountOut: "TK CHUYỂN",
    shareVol2: "SỐ LƯỢNG",
    shareStatusOut: "TRẠNG THÁI CHUYỂN",
    shareStatusIn2: "TRẠNG THÁI NHẬN",
    history: "Lịch sử chuyển trạng thái CK",
    changeStatusHeader: "Chuyển trạng thái",
    changeStatusSuccess: "Chuyển trạng thái chứng khoán thành công",
    bonds: "Trái phiếu",
    changeStockStatus: "Chuyển trạng thái chứng khoán",
    fromDateNull: "Quý khách chưa nhập Từ ngày",
    toDateNull: "Quý khách chưa nhập Đến ngày",
  },
  proInvestor: {
    header: {
      registrationHistory: "Lịch sử đăng ký",
      registerPro: "Đăng ký NĐT chuyên nghiệp",
    },
    noti: {
      success: "Đăng ký NĐT chuyên nghiệp thành công",
    },
    button: {
      close: "Đóng",
      search: "Tìm Kiếm",
    },
    popUp: {
      title: "Thông tin nhà đầu tư chuyên nghiệp",
      condition:
        "Theo quy định của Luật chứng khoán số 54/QH2020, từ ngày 1/1/2021, đối tượng mua Trái Phiếu phát hành riêng lẻ phải là nhà đầu tư chứng khoán chuyên nghiệp “NĐTCN”.",
      conditionPeople:
        "Với cá nhân, để là NĐTCN, KH cần đáp ứng 1 trong các tiêu chí sau:",
      coditionCategory:
        "- Sở hữu danh mục chứng khoán niêm yết, đăng ký giao dịch tại các Sở Giao dịch (Theo quy định của Luật Chứng khoán có hiệu lực ngày 1/1/2021) có giá trị tối thiểu là 02 tỷ đồng.",
      conditionFee:
        "- Có thu nhập chịu thuế năm gần nhất tối thiểu là 01 tỷ đồng theo hồ sơ khai thuế đã nộp cho cơ quan thuế hoặc chứng từ khấu trừ thuế của tổ chức, cá nhân chi trả.",
      conditonJob: "- Có chứng chỉ hành nghề chứng khoán.",
    },
    history: {
      number: "STT",
      registDate: "Ngày đăng ký",
      bondCode: "Mã trái phiếu",
      volume: "Khối lượng",
      unitPrice: "Đơn giá",
      transaction: "GT giao dịch",
      purchaseFee: "Phí mua",
      totalValue: "Tổng giá trị",
      status: "Trạng thái",
    },
    form: {
      account: "Tài khoản",
      name: "Họ tên",
      status: "Trạng thái",
      acceptCondition: "Quý KH đã đủ điều kiện trở thành NĐT CN",
      registed: "Quý KH đã đăng ký dịch vụ NĐT CN",
      noInfo: "Quý KH chưa có thông tin NĐT CN tại MBS",
      button: "Trải nghiệm NĐT CN",
    },
    modal: {
      title:
        "ĐK mua Trái phiếu niêm yết từ MBS để có đủ điều kiện xác nhận là NĐT CN:",
      idAccount: "Số tài khoản",
      regisDate: "Ngày ĐK",
      valueMine: "GT DMCK sở hữu",
      valueBone: "GT cần mua trái phiếu",
      moneyBalance: "Số dư tiền",
      codeBone: "Mã trái phiếu",
      release: "TCPH",
      info: "GT cần mua TP = 2 tỷ đồng - GT DMCK sở hữu * Tỷ lệ chiết khấu theo chính sách MBS từng thời kỳ.",
      transactionInfo: {
        title: "Thông tin giao dịch",
        dayTrading: "Ngày GD",
        price: "Đơn giá",
        volume: "Số lượng",
        valueTransaction: "Gía trị GD",
        feeTransaction: "Phí GD",
        tax: "Thuế bán + phí CK",
        sumTransaction: "Tổng tiền GD",
        account: "TK cắt tiền",
        moneyBalance: "Số dư tiền",
        buyTransaction: "GD MUA",
        sellTransaction: "GD BÁN",
      },
      footer: {
        note: "Ghi chú:",
        conditionMoney:
          "- Để trở thành NĐT CN, Quý khách cần nắm giữ danh mục CK NY từ 02 tỷ đồng.",
        conditionDate:
          "- Sau 1 ngày làm việc kể từ ngày GD Mua Trái phiếu thành công, Quý khách sẽ được MBS xác nhận là NĐT CN.",
        conditionAccept:
          "- Sau 02 ngày làm việc kể từ ngày thực hiện GD Mua thành công, hệ thống sẽ tự động Bán lại toàn bộ Trái phiếu mà Quý khách đã   Mua trong giao dịch này!",
        conditionInTime:
          "- Trong thời gian đăng ký trải nghiệm NĐTCN tại MBS, kính đề nghị Quý KH không bán chứng khoán niêm yết trên tài khoản để đảm bảo duy trì số dư khi MBS xác nhận KH đủ điều kiện trở thành NĐTCN.",
        register: "Đăng ký",
      },
      error: {
        noVolume: "Vui lòng nhập số lượng",
        min: "Vui lòng nhập số lượng",
        room: "Hiện tại đã hết Hạn mức giao dịch Nhà đầu tư chuyên nghiệp. Quý Khách vui lòng đăng ký lại sau.",
      },
      placeHolder: {
        input: "Nhập số lượng",
      },
    },
  },
  textConfirmLoanExtension:
    "Tôi đã đọc và đồng ý với các điều khoản thỏa thuận trong hợp đồng ký quỹ, phụ lục đồng và các chính sách dịch vụ tài chính đang áp dụng tại MBS",
  toast: {
    success: "Thông báo",
    warning: "Cảnh báo",
    error: "Lỗi",
    default: "Thông báo",
  },
  smartOtp: {
    alert: "Vui lòng mở ứng dụng MBS Mobile và lấy mã SmartOTP",
  },
  statusWarrant: {
    QUYEN_CHUA_THUC_HIEN: "Quyền chưa thực hiện",
    QUYEN_CHO_VE: "Quyền chờ về",
  },
  registerCKService: {
    title: "Đăng ký tài khoản dịch vụ Phái sinh",
    confirm:
      "Tôi đã đọc, hiểu và đồng ý với các điều khoản và điều kiện về Giao dịch chứng khoán phái sinh tại MBS.",
  },
  cleanCache: {
    title: "Xóa cache",
    contentConfirm: "Bạn chắc chắn muốn xóa bộ nhớ tạm thời",
  },
  tooltipAccountReceiveCode: "Kích để thay đổi TK nhận tiền",
  modalTransferMoney: {
    sidebar: "HD chuyển tiền vào TKCK",
    headerTitle: "HƯỚNG DẪN CHUYỂN TIỀN VÀO TKCK",
    TAB_MB: "Từ NH MB",
    TAB_FAST: "Siêu nhanh - 9612",
    TAB_FAST_MBS: "Siêu nhanh-MBS",
    TAB_NORMAL: "Chuyển thường",
    toastSuccessNumber: "Đã sao chép số tài khoản chuyển tiền",
    toastSuccessContent: "Đã sao chép nội dung chuyển tiền",
    TabFastTextRed:
      "Quý khách có TK tại các NH khác MB/BIDV, sử dụng DV Ngân hàng điện tử trên web/app để chuyển tiền vào TKCK tại MBS 24/7, tự động hạch toán như dưới đây:",
    step1: "Bước 1:",
    TabFastStep1: "Đăng nhập app/web Ngân hàng chọn Chuyển tiền",
    TabFastStep2_1: "Thông tin Người nhận chọn:",
    TabFastStep2_2: "Ngân hàng: Đầu tư phát triển - BIDV",
    TabFastStep2_3: "Số Tài khoản: 9612+ Số TKCK 7 số (tiểu khoản tại MBS)",
    TabFastStep2_4: "Họ tên: Tự động hiển thị tên theo số TKCK nhập ở trên",
    TabFastStep2_5: "Số tiền: Nhập số tiền muốn chuyển vào TKCK",
    TabFastStep2_6: "Ví dụ: ",
    TabFastStep2_7: "Nhập Số tài khoản nhận ",
    TabFastStep2_8: "khi nộp tiền vào TKCK",
    TabFastStep2_9: "- Nộp tiền vào tài khoản thường (tiểu khoản 1): ",
    TabFastStep2_10: "- Nộp tiền vào tài khoản Margin (tiểu khoản 8): ",
    TabFastStep2_11: "- Nộp tiền vào tài khoản M-Credit (tiểu khoản 7): ",
    TabFastStep2_12: "(abcdef là số TKCK 6 số của Quý khách tại MBS)",
    step2: "Bước 2:",
    step3: "Bước 3:",
    TabFastStep3_1: "Xác nhận OTP và ấn Tiếp tục",
    step4: "Bước 4:",
    TabFastStep4_1: "Giao dịch thành công, TKCK nhận được tiền ngay",
    note: "Nếu Quý Khách có tài khoản tại BIDV, Quý khách chọn mục “Thanh toán”, chọn chức năng “Nộp tiền chứng khoán” và nhập thông tin thụ hưởng: Công ty CP Chứng khoán MB và số tài khoản 9612+TKCK 7 số của Quý khách như trên.",
    step5: "Bước 5:",
    TabFastMBSTextRed:
      "Sử dụng mã định danh “MBS” qua kết nối MBBank để chuyển tiền vào TKCK tại MBS từ các Ngân hàng có liên kết Napas, Quý khách sẽ nhận được tiền ngay lập tức 24/7 với thao tác đơn giản như sau:",
    TabFastMBSStep1:
      "Đăng nhập app/web Ngân hàng (khác MBBank) chọn Chuyển tiền",
    TabFasMBStStep2_1: "Thông tin Người nhận chọn:",
    TabFastMBSStep2_2: "-	Ngân hàng nhận: Ngân hàng TMCP Quân đội (MB/ MBBank)",
    TabFastMBSStep2_3:
      "-	Số Tài khoản: MBS + Số TKCK 7 số (bao gồm cả tiểu khoản tại MBS)",
    TabFastMBSStep2_4: "-	Họ tên: Tự động hiển thị tên theo số TKCK nhập ở trên",
    TabFastMBSStep2_5: "-	Số tiền: Nhập số tiền =< 500 triệu đồng/ giao dịch",
    TabMBTextRed:
      "Quý khách vui lòng đăng nhập vào App MBBank và làm theo hướng dẫn:",
    TabMBStep1_1: "Chọn mục Sản phẩm",
    TabMBStep2_1: "Chọn Bảo hiểm, Chứng khoán và Vay tiêu dùng",
    TabMBStep3_1: "Chọn Chứng khoán MB",
    TabMBStep4_1: "Chuyển tiền đến tài khoản MBS",
    TabMBStep5_1: "Thêm (+) Người thụ hưởng mới và nhập thông tin",
    detail: "Xem chi tiết hướng dẫn ",
    here: " tại đây",
    TabNormalTextRed:
      "Quý khách lưu ý chuyển khoản đúng nội dung hướng dẫn để tiền được hạch toán tự động vào TKCK tại MBS",
    left: "Người thụ hưởng",
    right: "Công ty CP Chứng khoán MB",
    title: "Chuyển khoản tới một trong các tài khoản sau",
    bottom1: "MBBank - Sở Giao dịch3",
    bottom2: "MBBank - CN Hàm Nghi",
    bottom3: "Vietcombank - CN Hà Nội",
    bottom4: "BIDV - CN Hà Thành",
    bottom5: "Techcombank - CN Hội Sở",
    content: "NỘI DUNG CHUYỂN KHOẢN",
    top1: "Nộp tiền vào TK thường (đuôi 1)",
    top8: "Nộp tiền vào TK Margin (đuôi 8)",
    top7: "Nộp tiền vào TK Margin (đuôi 7)",
    bottom6: "Nop tien TKCK ",
    bottom7: "1 cua ",
    bottom8: "8 cua ",
    bottom9: "7 cua ",
  },
  saveQRSuccess: "Quét QR code thành công",
  saveOtpSuccess: "Đã lưu mã xác thực",
  smartOTP: {
    success: "Gửi mã OTP thành công",
    title1: "Quý khách vui lòng ",
    title2: "Lấy mã OTP",
    title3: " qua sms/email và lưu tại ô dưới đây",
    title4: "Quý khách đã lấy OTP thành công, vui lòng kiểm tra sms/email.",
  },
  industry: {
    name: "Chuyển động ngành",
    topIndex: "TOP đóng góp INDEX",
    transactionIndustry: "GD theo ngành",
  },
  foreignWatch: {
    topStocks: "TOP cổ phiếu mua bán ròng",
    topIndustry: "TOP ngành mua bán ròng",
  },
  modalSell: {
    shareStatus: "Trạng thái CK",
    shareOddLot: "CP lẻ",
    sellValue: "Giá trị bán",
    confirm1: "Tôi đã đọc và đồng ý với ",
    confirm2: "các điều khoản và điều kiện",
    confirm3:
      " về Giao dịch lô lẻ trực tuyến của MBS và xác nhận đăng ký dịch vụ.",
    note: "Ghi chú: giao dịch lô lẻ với giá sàn, phí dịch vụ 10% tổng giá trị giao dịch từ 23/07/2021",
  },
  modalSVD: {
    title1: "Trân trọng chào mừng Quý Khách hàng đến với MBS!",
    title2:
      "Kính chúc Quý khách có trải nghiệm giao dịch vượt trội và nhận được những ưu đãi hấp dẫn.",
    acceptText1: "Tôi đã đọc, hiểu và đồng ý với",
    acceptText2: " Bộ các điều khoản và điều kiện ",
    acceptText3: "Mở tài khoản và sửa dụng dịch vụ tại MBS",
    onSubmit: "TIẾP TỤC",
  },
  copi24: {
    tab: {
      TOPLEADER: "Top Leader",
      FOLLOW: "Theo dõi",
      MANAGECOPY: "Quản lý sao chép",
      HISTORY: "Lịch sử đầu tư",
    },
    modalCreateCashPayment: {
      valueAmount0: "Số tiền nộp phải > 0",
      cashBalance: "Số tiền nộp không được vượt quá Số dư tài khoản Cash",
      cashPayment: "Nộp tiền",
      account: "Tài khoản Copi24",
      cash: "Số dư tài khoản Cash",
      amount: "Số tiền nộp",
      placeholder: "Nhập số tiền",
      noteRed:
        "Ghi chú: Sau khi nộp thêm tiền, đầu ngày giao dịch tiếp theo hệ thống sẽ tự động renew lại danh mục theo tỷ trọng Leader.",
      cancel: "Hủy",
    },
    modalCreateCashWithdraw: {
      valueAmount0: "Số tiền rút phải > 0",
      maximumCashWithdraw0: "Lớn hơn số tiền rút tối đa",
      cashWithdraw: "Rút tiền",
      account: "Tài khoản Copi24",
      totalAsset: "NAV theo giá hiện tại",
      navBasic: "NAV theo giá tham chiếu",
      maximumCashWithdraw: "Số tiền rút tối đa",
      amount: "Số tiền rút",
      placeholder: "Nhập số tiền",
      cancel: "Hủy",
    },
    modalCreateCopy: {
      valueAmount0: "Giá trị sao chép phải > 0",
      cashBalance: "Giá trị sao chép không được vượt quá Số dư tài khoản Cash",
      COPY_MIN_AMOUNT0:
        "Giá trị sao chép không được nhỏ hơn giá trị sao chép tối thiểu",
      secPerNavRate0: "Bạn chưa nhập Tỷ trọng tối đa 1 mã",
      priceMaxPercent0: "Bạn chưa nhập Giá đặt chênh lệch tối đa",
      stopLossPercent0: "Bạn chưa nhập Danh mục lỗ",
      stopProfitPercent0: "Bạn chưa nhập Danh mục lãi",
      optionPriceType1: "Lệnh thị trường",
      optionPriceType2: "Lệnh LO",
      rule: "Quy tắc sao chép",
      account: "Tài khoản Copi24",
      cash: "Số dư tài khoản Cash",
      COPY_MIN_AMOUNT: "Giá trị sao chép tối thiểu",
      amount: "Giá trị sao chép (VND)",
      ALLOW_CHOOSE_PRICE_TYPE: "Nguyên tắc giá sinh lệnh",
      priceBuyPercent: "Chênh lệch giá MUA (%)",
      priceSellPercent: "Chênh lệch giá BÁN (%)",
      secPerNavRate: "Tỷ trọng tối đa 1 mã (%)",
      textLine1: "Lệnh sao chép phát sinh khi",
      textLine2: "Lệnh Leader khớp",
      isCopyCurrentPortfolio: "Sao chép DM hiện tại",
      stop: "Điều kiện lệnh dừng sao chép",
      stopLossPercent: "% Biến động tài sản giảm",
      stopProfitPercent: "% Biến động tài sản tăng",
      NoteRed:
        "Ghi chú: Khi dừng sao chép, toàn bộ tiền và chứng khoán tự động được chuyển sang tài khoản 1",
      cancel: "Hủy",
      copy: "Sao chép",
    },
    modalStopCopy: {
      title: "Dừng sao chép",
      account: "Tài khoản Copi24",
      amount: "Giá trị sao chép",
      profitAmt: "Lãi/lỗ",
      feeCopy: "Phí sao chép",
      total: "Tổng giá trị",
      NoteRed:
        "Ghi chú: Khi dừng sao chép, toàn bộ tiền và chứng khoán tự động được chuyển sang tài khoản 1",
      confirm: "Xác nhận chuyển toàn bộ danh mục sang tài khoản 1",
      cancel: "Hủy",
      stop: "Dừng sao chép",
    },
    modalUpdateAvatar: {
      title: "Cập nhật ảnh đại diện",
      error: "Ảnh đại diện của bạn không được vượt quá xxx Kbs",
      cancel: "Đóng",
      confirm: "Xác nhận",
    },
    modalCancelCopier: {
      title: "Đóng COPIER",
      confirm: "Bạn có chắn chắn muốn đóng Copier?",
      cancel: "Hủy",
      yes: "Đồng ý",
    },
    modalCancelLeader: {
      toastSuccess:
        "Yêu cầu đóng leader đã được gửi tới hệ thống. Yêu cầu của quý khách sẽ được xử lý trong {day} ngày tới",
      title: "Đóng LEADER",
      confirm: "Bạn có chắn chắn muốn đóng Leader?",
      cancel: "Hủy",
      yes: "Đồng ý",
    },
    cardUser: {
      month: " /tháng",
      month6: " trong 6 tháng",
      portfolioPoint: "Điểm danh mục: ",
      totalFollow: "Tổng theo dõi:",
      totalCopy: "Tổng sao chép:",
      copy: "Sao chép",
      stop: " Dừng sao chép",
      delay: "Dừng chờ xử lý",
    },
    topLeader: {
      topLeader: "TOP LEADER",
      registerCopier: "Đăng ký COPIER",
      cancelCopier: "Đóng COPIER",
      delay: "Chờ xử lý đóng",
      registerLeader: "Đăng ký LEADER",
      cancelLeader: "Đóng LEADER",
      stringReturn1: "Hiệu quả 1 tháng",
      stringReturn3: "Hiệu quả 3 tháng",
      stringReturn6: "Hiệu quả 6 tháng",
      filterLeaderAccountName: "Tên Leader",
      placeholder: "Nhập tên Leader",
      fetchDataReset: "Reset",
      registerCopierSuccess: "Đăng kí Copier thành công",
      cancelCopierSuccess: "Gửi yêu cầu Đóng Copier thành công",
      registerLeaderSuccess: "Gửi yêu cầu Đăng ký leader thành công",
      followSuccess: "Follow thành công",
      cancelFollowSuccess: "Hủy follow thành công",
      INDIVIDUALERR: "Sản phẩm dành riêng khách hàng Cá nhân",
    },
    registerCopier: {
      registerCopier: "Đăng ký COPIER",
      customerId: "Tài khoản",
      confirm:
        "Tôi đã đọc, hiểu và đồng ý với {rules} của sản phẩm. Sản phẩm có tiềm ẩn rủi ro đối với các khách hàng thuộc diện phải công bố thông tin theo quy định của Pháp luật, khách hàng lưu ý cân nhắc trước khi đăng ký sử dụng.",
      cancel: "Hủy",
      rules: "điều khoản & điều kiện",
    },
    registerLeader: {
      disableButtonRegister: " của tài khoản không đáp ứng giá trị tối thiểu",
      registerLeader: "Đăng ký LEADER",
      accountRegister: "Tài khoản đăng ký ban đầu:",
      confirm:
        "Tôi đã đọc, hiểu và đồng ý với {rules} của sản phẩm. Sản phẩm có tiềm ẩn rủi ro đối với các khách hàng thuộc diện phải công bố thông tin theo quy định của Pháp luật, khách hàng lưu ý cân nhắc trước khi đăng ký sử dụng.",
      accountName: "Tên hiển thị",
    },
    tableRegisterLeader: {
      accountRegisterErr: "Quý khách vui lòng nhập Tài khoản đăng ký",
      accountNameErr: "Quý khách vui lòng nhập Tên hiển thị",
      navValue: "Tài sản ròng",
      tradeMonthNo: "Số tháng đã giao dịch",
      tradingDiaryRate1: "Hiệu quả đầu tư (",
      tradingDiaryRate2: " tháng)",
      portfolioPoint: "Điểm số danh mục",
      title: "TIÊU CHÍ",
      valueMin: "GIÁ TRỊ TỐI THIỂU",
      valueAccount: "GIÁ TRỊ CỦA TÀI KHOẢN",
    },
    topFollow: {
      listTopFollow: "DANH SÁCH THEO DÕI",
    },
    manager: {
      popoverProfitAmt:
        "Biến động tài sản = Tiền mặt còn lại - Tiền mua chưa thanh toán + Tiền bán chờ thanh toán + Giá trị chứng khoán - Giá trị đầu tư",
      total_amount: "Tổng giá trị đầu tư: ",
      total_profit_amt: "Lãi/lỗ:",
      total_value: "Tổng giá trị:",
      leaderAccoStat: "Tài khoản leader đã đóng hoặc đang xử lý đóng",
      amount: "Giá trị đầu tư",
      beginTotalAsset: "NAV đầu ngày",
      nav: "NAV theo giá thị trường",
      profitAmt: "Biến động tài sản",
      profitPercent: " % Biến động tài sản",
      totalFeeDebt: "Phí sao chép",
      createCashPayment: " Nộp tiền",
      withdrawMoney: "Rút tiền",
      stopCopy: "Dừng sao chép",
      delay: "Dừng chờ xử lý",
    },
    history: {
      tradeDate: "NGÀY SAO CHÉP",
      leader: "LEADER",
      amount: "GIÁ TRỊ ĐẦU TƯ",
      profitAmt: "LÃI LỖ",
      profitPercent: "% LÃI LỖ",
      stopDate: "NGÀY DỪNG SAO CHÉP",
    },
    portTransaction: {
      popover:
        "Tiếp tục tính toán Lãi lỗ danh mục đến khi tài khoản dừng sao chép thành công",
      tradeDate: "NGÀY GIAO DỊCH",
      secCd: "MÃ CK",
      tradeType: "LOẠI GD",
      profitAmt: "LÃI/LỖ DANH MỤC",
      profitAmtPercent: "% LÃI/LỖ DANH MỤC",
      profitPercent: "% LÃI LỖ",
      qty: "KHỐI LƯỢNG",
      price: "GIÁ",
      avgPrice: "GIÁ VỐN",
      feeAmt: "PHÍ",
      taxAmt: "PHÍ THUẾ KHÁC",
      history: "Lịch sử giao dịch",
      remarks: "GHI CHÚ",
      ratio: "TỈ TRỌNG",
      totalQty: "TỔNG",
      availableQty: "KHẢ DỤNG",
      waitingReceive: "CHỜ NHẬN",
      avgPrice2: "GIÁ TB",
      currentPrice: "GIÁ HIỆN TẠI",
      buyAmt: "GIÁ TRỊ MUA",
      currentAmt: "GIÁ TRỊ HIỆN TẠI",
      tabRule1: "DANH MỤC SAO CHÉP",
      tabRule2: "QUY TẮC SAO CHÉP",
      priceMaxPercent: "Giá Mua chênh tối đa với DM hiện tại (%)",
      tradeDate2: "Ngày sao chép",
    },
    modalDetailLeader: {
      title: "Chi tiết Leader",
      titleUppercase: "CHI TIẾT LEADER",
      percentDeltaFormatter: "Hiệu quả đầu tư",
      standardDeviationChart: "LÃI LỖ DANH MỤC",
      tickerRateChart: "PHÂN BỔ THEO DANH MỤC",
    },
    modalDetailCopier: {
      title: "Danh mục Copier",
      titleUppercase: "DANH MỤC COPIER",
      compareWithLeaderChart: "HIỆU QUẢ ĐẦU TƯ LEADER SO VỚI COPIER",
      tableTicker: "DANH MỤC LEADER",
    },
  },
  openPosition: {
    orderType: "Loại hợp đồng",
    coefficient: "Hệ số",
    valuePosition: "Giá trị vị thế mở",
  },
  policy: {
    tabHistory: "Lịch sử đăng ký",
    tabRegister: "Đăng ký chính sách ưu đãi",
    all: "Tất cả",
    INIT: "Khởi tạo",
    PROCESS: "Đang xử lý",
    APPROVE: "Đã duyệt",
    REJECT: "Từ chối",
    ERROR: "Không thành công",
    policyCode: "Chính sách",
    status: "Trạng thái",
    proposalDate: "NGÀY ĐĂNG KÝ",
    policyName: "CHÍNH SÁCH",
    preferentialFeeName: "TÊN BIỂU ƯU ĐÃI",
    preferentialFeeValue: "GIÁ TRỊ",
    expiredDate: "THỜI GIAN HIỆU LỰC",
    status2: "TRẠNG THÁI",
    approvalDate: "NGÀY DUYỆT",
    duration: "TG HIỆU LỰC",
    day: "(ngày)",
    register: "Đăng ký",
    registerSuccess: "Đăng ký thành công, yêu cầu của Quý khách đang chờ xử lý",
  },
};

export default vi;
