import { lazy } from "react";
import { roles } from "src/helper/const";
import paths from "src/routes/pathRoutes";
const StockTradingHandLazy = lazy(() => import("./pages"));
export const StockTradingHandRoutes = [
  {
    page: StockTradingHandLazy,
    path: paths.stockTradingHand,
    roleSide: roles.ROLE_EDITOR,
  },
];
