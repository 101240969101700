import basePathHttp from "src/core/http/singleton/channel";
import { PortfolioItem } from "src/domain/models/channelPortfolio";
import { GET_CHANNEL_PORTFOLIOS } from "./urls";
import { FilterState } from "src/modules/ReportPortfolioChannels/redux/filter";

export const getChannelPortfoliosApi = async (
  params: FilterState
): Promise<PortfolioItem[]> => {
  const { ...restParams } = params;
  const res = await basePathHttp.get<PortfolioItem[]>(
    GET_CHANNEL_PORTFOLIOS,
    restParams
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
