import { Grid } from "@mui/material";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import BoxField from "src/components/BoxField";
import TextField from "src/components/Forms/TextField";
import {
  NumberFormatBankCode,
  NumberFormatMobile,
} from "src/components/NumberFormat";
import { enterCallBack, enterFocus, handleValidate } from "src/helper/validate";
import { updateInputInformation } from "../../redux/information";
import { informationSelector } from "../../redux/selector";

function Information({ handleSubmitInformation }) {
  // Khai bao
  const dispatch = useDispatch();
  const {
    checkValidateInfo,
    accountCode,
    accountMobile,
    accountEmail,
    accountName,
    bankCode,
    bankNumber,
  } = useSelector(informationSelector);
  // Tao hotkey
  const inputAccountCodeRef = useRef(null);
  const inputAccountMobileRef = useRef(null);
  const inputAccountEmailRef = useRef(null);
  const inputAccountNameRef = useRef(null);
  const inputBankNumberRef = useRef(null);
  const inputBankCodeRef = useRef(null);

  // Hàm tính toán
  const handleInput = (e: any, name: string) => {
    dispatch(
      updateInputInformation({
        [name]: e.target.value,
      })
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <BoxField required label="Mã người dùng">
          <TextField
            disabled
            inputRef={inputAccountCodeRef}
            value={accountCode}
            placeholder="Mã người dùng"
          />
        </BoxField>
      </Grid>
      <Grid item xs={6}>
        <BoxField labelWidth="170px" required label="Họ và tên">
          <TextField
            autoFocus
            value={accountName}
            inputRef={inputAccountNameRef}
            onKeyDown={(e) => enterFocus(e, inputAccountMobileRef)}
            onChange={(e) => handleInput(e, "accountName")}
            placeholder="Nhập tên người dùng"
            error={
              handleValidate(checkValidateInfo, accountName) ? true : false
            }
            helperText={handleValidate(checkValidateInfo, accountName)}
          />
        </BoxField>
      </Grid>
      <Grid item xs={6}>
        <BoxField label="Điện thoại">
          <TextField
            value={accountMobile}
            inputRef={inputAccountMobileRef}
            onChange={(e) => handleInput(e, "accountMobile")}
            placeholder="Nhập tên số điện thoại"
            onKeyDown={(e) => enterFocus(e, inputAccountEmailRef)}
            InputProps={{
              inputComponent: NumberFormatMobile as any,
            }}
          />
        </BoxField>
      </Grid>
      <Grid item xs={6}>
        <BoxField labelWidth="170px" required label="Email">
          <TextField
            value={accountEmail}
            placeholder="Nhập email"
            inputRef={inputAccountEmailRef}
            onKeyDown={(e) => enterFocus(e, inputBankNumberRef)}
            onChange={(e) => handleInput(e, "accountEmail")}
            error={
              handleValidate(checkValidateInfo, accountEmail) ? true : false
            }
            helperText={handleValidate(checkValidateInfo, accountEmail)}
          />
        </BoxField>
      </Grid>
      <Grid item xs={6}>
        <BoxField label="STK ngân hàng">
          <TextField
            value={bankNumber}
            inputRef={inputBankNumberRef}
            onChange={(e) => handleInput(e, "bankNumber")}
            placeholder="Nhập STK ngân hàng"
            InputProps={{
              inputComponent: NumberFormatBankCode as any,
            }}
            onKeyDown={(e) => enterFocus(e, inputBankCodeRef)}
          />
        </BoxField>
      </Grid>
      <Grid item xs={6}>
        <BoxField labelWidth="170px" label="Mã ngân hàng">
          <TextField
            value={bankCode}
            inputRef={inputBankCodeRef}
            onChange={(e) => handleInput(e, "bankCode")}
            placeholder="Nhập mã ngân hàng"
            onKeyDown={(e) => enterCallBack(e, handleSubmitInformation)}
          />
        </BoxField>
      </Grid>
    </Grid>
  );
}

export default Information;
