import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
interface InitialState {
  data: any[];
  status: ReduxStateType;
}

const initialState: InitialState = {
  data: [],
  status: ReduxStateType.INIT,
};

const orderTradingHand = createSlice({
  name: "orderTradingHandSlice",
  initialState,
  reducers: {
    orderTradingHandStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    orderTradingHandSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
      state.data = action.payload;
    },
    orderTradingHandFailed: (state) => {
      state.status = ReduxStateType.ERROR;
      state.data = [];
    },
  },
});

export const {
  orderTradingHandStart,
  orderTradingHandSuccess,
  orderTradingHandFailed,
} = orderTradingHand.actions;

export default orderTradingHand.reducer;
