import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { ReportBuyingPowerParams } from "src/core/http/service/stockTrading/types";
import { reportBuyingPowerApi } from "src/core/http/service/stockTradingHand";
import { ReportBuyingPowerRes } from "src/domain/models/stockTradingHand";
import { RootState } from "src/redux-store/store";
import {
  reportBuyingPowerHandFailed,
  reportBuyingPowerHandStart,
  reportBuyingPowerHandSuccess,
} from "../redux/reportBuyingPowerHand";

function* reportBuyingPowerHandStartWork() {
  const { accountCode, shareCode } = yield select(
    (state: RootState) => state.stockTradingHand.formOrderHand.inputForm
  );

  const params: ReportBuyingPowerParams = {
    accountCode: accountCode?.value,
    shareCode: shareCode?.value ? shareCode?.value : "",
  };

  try {
    const res: ReportBuyingPowerRes = yield call(reportBuyingPowerApi, params);
    yield put({
      type: reportBuyingPowerHandSuccess.type,
      payload: res,
    });
  } catch (error: any) {
    yield put({
      type: reportBuyingPowerHandFailed.type,
    });
  }
}

function* watchReportBuyingPowerHand() {
  yield takeLatest(
    reportBuyingPowerHandStart.type,
    reportBuyingPowerHandStartWork
  );
}

export default function* reportBuyingPowerHandSaga() {
  yield all([watchReportBuyingPowerHand()]);
}
