import {
  RecommendationItem,
  RecommendationImportResponse,
  RecommendationImport,
} from "src/domain/models/recommendations";
import recommendationHttp from "src/core/http/singleton/recommendations";
import { GET_RECOMMENDATIONS, IMPORT_RECOMMENDATIONS } from "./urls";

export const getRecommendationApi = async (): Promise<RecommendationItem> => {
  const res = await recommendationHttp.get<any>(GET_RECOMMENDATIONS);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const importRecommendationApi = async (
  params: RecommendationImport[]
): Promise<RecommendationImportResponse> => {
  const res = await recommendationHttp.put<any>(IMPORT_RECOMMENDATIONS, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
