import { lazy } from "react";
import { roles } from "src/helper/const";
import paths from "src/routes/pathRoutes";
const ChannelLazy = lazy(() => import("./pages"));

export const channelRoutes = [
  {
    page: ChannelLazy,
    path: paths.channel,
    roleSide: roles.ROLE_EDITOR,
  },
];
