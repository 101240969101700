import basePathHttp from "src/core/http/singleton/basePathHttp";
import {
  ResFilterAccount,
  ResListAuthorize,
  ResponseManagementAccount,
} from "src/domain/models/managementAccount";
import {
  addOrUpdateAccountParams,
  FilterAccountParams,
  ListAuthorizeParams,
} from "./types";
import { addAccount, filterAccount, updateAccount } from "./urls";

// export function UseAuthSwr() {
//   const params = {
//     account: "110221",
//     date: "100697",
//   };
//   const commentsFetcher = (objItem: any): Promise<resPonse> =>
//     authHttp.get<resPonse>(objItem.url, objItem.params).then((res) => res.data);
//   return useSWR({ url: "users", params: params }, commentsFetcher);
// }

export const listAuthorizeApi = async (
  params: ListAuthorizeParams
): Promise<ResListAuthorize> => {
  const res = await basePathHttp.post<ResListAuthorize>(filterAccount, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const filterAccountApi = async (
  params: FilterAccountParams
): Promise<ResFilterAccount[]> => {
  const res = await basePathHttp.post<ResFilterAccount[]>(
    filterAccount,
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const addAccountApi = async (
  params: addOrUpdateAccountParams
): Promise<ResponseManagementAccount> => {
  const res = await basePathHttp.post<ResponseManagementAccount>(
    addAccount,
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const updateAccountApi = async (
  params: addOrUpdateAccountParams
): Promise<ResponseManagementAccount> => {
  const res = await basePathHttp.put<ResponseManagementAccount>(
    updateAccount,
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
