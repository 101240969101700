import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";

export type FilterState = {
  accountCode?: string;
  shareCode?: string;
};

interface InitialState {
  inputReportCC: FilterState;
  status: ReduxStateType;
}

const initialState: InitialState = {
  inputReportCC: {
    accountCode: "",
    shareCode: "",
  },
  status: ReduxStateType.INIT,
};

const filterReportCC = createSlice({
  name: "filterReportCCSlice",
  initialState,
  reducers: {
    updateFilterReportCC: (state, action) => {
      Object.assign(state.inputReportCC, action.payload);
    },
    resetFilterReportCC: () => initialState,
  },
});

export const { updateFilterReportCC, resetFilterReportCC } =
  filterReportCC.actions;

export default filterReportCC.reducer;
