import {
  ResCheckOtpStatus,
  ResLogin2FA,
  resPonse,
} from "src/domain/models/auth";
import basePathHttp from "../../singleton/basePathHttp";
import {
  CheckOtpStatusParams,
  LoginParams,
  RegisOtp2FAParams,
  RegisterParams,
  ResetPasswordParams,
} from "./types";
import {
  login,
  resetPassword,
  resgister,
  login2fa,
  checkOtpStatus,
  regisOtp2FA,
} from "./urls";

export const loginApi = async (params: LoginParams): Promise<resPonse> => {
  const res = await basePathHttp.post<resPonse>(login, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const login2faApi = async (
  params: LoginParams
): Promise<ResLogin2FA> => {
  const res = await basePathHttp.get<ResLogin2FA>(login2fa, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const registerApi = async (
  params: RegisterParams
): Promise<resPonse> => {
  const res = await basePathHttp.post<resPonse>(resgister, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const resetPasswordApi = async (
  params: ResetPasswordParams
): Promise<resPonse> => {
  const res = await basePathHttp.post<resPonse>(resetPassword, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const checkOtpStatusApi = async (
  params: CheckOtpStatusParams
): Promise<ResCheckOtpStatus> => {
  const res = await basePathHttp.get<ResCheckOtpStatus>(checkOtpStatus, params);

  return res.data;
};
export const regisOtp2FAApi = async (
  params: RegisOtp2FAParams
): Promise<resPonse> => {
  const res = await basePathHttp.post<resPonse>(regisOtp2FA, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
