import { toast } from "react-toastify";
import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";
import { changePasswordApi } from "src/core/http/service/layout/header";
import { RootState } from "src/redux-store/store";
import { resetInputPass } from "../redux/changePass";
import { updateShowModal } from "../redux/information";
import {
  postChangePassFailed,
  postChangePassStart,
  postChangePassSuccess,
} from "../redux/postChangePass";

function* postChangePassStartWork() {
  yield delay(200);
  const { oldPass, newPass, repeatPass } = yield select(
    (state: RootState) => state.header.changePass.inputPass
  );
  const params = {
    presentPassword: oldPass,
    newPassword: newPass,
    repeatPassword: repeatPass,
  };
  try {
    yield call(changePasswordApi, params);
    yield put({
      type: postChangePassSuccess.type,
    });
    yield put({
      type: resetInputPass.type,
    });
    yield put({
      type: updateShowModal.type,
      payload: {
        showChangePass: false,
      },
    });
    toast.success("Cập nhật mật khẩu thành công", { autoClose: 4000 });
  } catch (error: any) {
    const errMess = error?.response?.data?.message;
    toast.error(errMess, { autoClose: 4000 });
    yield put({
      type: postChangePassFailed.type,
    });
  }
}

function* watchPostChangePass() {
  yield takeLatest(postChangePassStart.type, postChangePassStartWork);
}

export default function* postChangePassSaga() {
  yield all([watchPostChangePass()]);
}
