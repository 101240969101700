import { combineReducers } from "redux";
import filterFinanceServiceSlice from "./financeServiceList";
import insertFinanceServiceSlice from "./insertFinanceService";
import deleteFinancialsSuspendSlice from "./deleteFinancialsSuspend";
import postFinancialsSuspendSlice from "./postFinancialsSuspend";
import controlFinancialModalSlice from "./controlFinancialModal";
import importListGDKHQSlice from "./importListGDKHQ";

export default combineReducers({
  filterFinanceServiceSlice,
  insertFinanceServiceSlice,
  deleteFinancialsSuspendSlice,
  postFinancialsSuspendSlice,
  controlFinancialModalSlice,
  importListGDKHQSlice,
});
