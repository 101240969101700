import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { channelDetailApi } from "src/core/http/service/stockTradingHand";
import { ChannelDetailParams } from "src/core/http/service/stockTradingHand/types";
import { ChannelDetailsRes } from "src/domain/models/stockTradingHand";
import { RootState } from "src/redux-store/store";
import {
  channelDetailHandFailed,
  channelDetailHandStart,
  channelDetailHandSuccess,
} from "../redux/channelDetailHand";
import { updateInputFormHand } from "../redux/formOrderHand";

function* channelDetailHandStartWork() {
  const { accountCode } = yield select(
    (state: RootState) => state.stockTradingHand.formOrderHand.inputForm
  );

  const params: ChannelDetailParams = {
    accountCode: accountCode?.value,
  };
  try {
    const res: ChannelDetailsRes = yield call(channelDetailApi, params);
    const optionsChannelList = (res || []).map((item) => ({
      key: item?.channelId,
      value: item.id,
      label: item.channelDetailCode,
    }));
    yield put({
      type: channelDetailHandSuccess.type,
      payload: optionsChannelList,
    });
    yield put({
      type: updateInputFormHand.type,
      payload: {
        channelCode: optionsChannelList[0] || {},
      },
    });
  } catch (error: any) {
    yield put({
      type: channelDetailHandFailed,
    });
  }
}

function* watchChannelDetailHand() {
  yield takeLatest(channelDetailHandStart.type, channelDetailHandStartWork);
}

export default function* channelDetailHandSaga() {
  yield all([watchChannelDetailHand()]);
}
