// import { toast } from "react-toastify";
import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { createSelector } from "@reduxjs/toolkit";

import { putDividendUpdateApi } from "src/core/http/service/dividend";
import { dividenUpdateState } from "../redux/selector";
import { DividendUpdateParams } from "src/core/http/service/dividend/types";
import { getDividendFilterStart } from "../redux/getDividendFilter";
import {
  dividendUpdateFailed,
  dividendUpdateStart,
  dividendUpdateSuccess,
} from "../redux/dividendUpdate";

const dividendUpdateSelector = createSelector(dividenUpdateState, (state) => ({
  dividends: state.data.dividends,
}));

function* dividendUpdate(action: ReturnType<typeof dividendUpdateStart>) {
  try {
    const { dividends } = yield select(dividendUpdateSelector);
    const params: DividendUpdateParams = {
      ...dividends,
    };
    const res = yield call(putDividendUpdateApi, params);
    yield put({
      type: dividendUpdateSuccess.type,
      payload: {
        items: res,
      },
    });
    yield put({
      type: getDividendFilterStart.type,
    });
  } catch (error: any) {
    yield put(dividendUpdateFailed(error));
  }
}

function* watchPostDividendUpdate() {
  yield takeLatest(dividendUpdateStart.type, dividendUpdate);
}
export default function* dividendUpdateSaga() {
  yield all([watchPostDividendUpdate()]);
}
