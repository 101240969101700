import { AppReducerType } from "./types";

import { createTransform } from "redux-persist";
import createWebStorage from "redux-persist/lib/storage/createWebStorage";

const createNoopStorage = () => {
  return {
    getItem(_key: any) {
      return Promise.resolve(null);
    },
    setItem(_key: any, value: any) {
      return Promise.resolve(value);
    },
    removeItem(_key: any) {
      return Promise.resolve();
    },
  };
};

const storage =
  typeof window !== "undefined"
    ? createWebStorage("local")
    : createNoopStorage();

const Transform = createTransform(
  (inboundState: any, key) => {
    return inboundState;
  },
  (outBoundState: any, key) => {
    return outBoundState;
  }
);

export const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    AppReducerType.AUTH,
    // AppReducerType.REPORT_COMMAND
  ],
  transforms: [Transform],
};
