import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
interface InitialState {
  status: ReduxStateType;
}
const initialState: InitialState = {
  status: ReduxStateType.INIT,
};

const postChangePass = createSlice({
  name: "postChangePassSlice",
  initialState,
  reducers: {
    postChangePassStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    postChangePassSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
    },
    postChangePassFailed: (state) => {
      state.status = ReduxStateType.ERROR;
    },
  },
});

export const {
  postChangePassStart,
  postChangePassSuccess,
  postChangePassFailed,
} = postChangePass.actions;

export default postChangePass.reducer;
