import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux-store/store";

export const transferMoneyState = (state: RootState) =>
  state.transferMoney.transferMoneySlice;

export const transferMoneySelector = createSelector(
  transferMoneyState,
  (state) => ({
    filter: state.data.filter,
  })
);
