// import { toast } from "react-toastify";
import { all, call, put, takeLatest, select, delay } from "redux-saga/effects";

import {
  getListRAFailed,
  getListRAStart,
  getListRASuccess,
} from "../redux/referralAccount";
import { getListReferralAccountApi } from "src/core/http/service/referralAccount";
import { ReferralAccountParams } from "src/core/http/service/referralAccount/types";
import { getReferralAccountSelector } from "../redux/selector";

function* getReferralAccount() {
  try {
    const { data } = yield select(getReferralAccountSelector);
    const { filter } = data;
    const params: ReferralAccountParams = {
      ...filter,
    };
    delay(200);
    const responseListReportForControl = yield call(
      getListReferralAccountApi,
      params
    );
    yield put({
      type: getListRASuccess.type,
      payload: responseListReportForControl,
    });
  } catch (error: any) {
    yield put(getListRAFailed());
  }
}

function* watchGetReferralAccount() {
  yield takeLatest(getListRAStart.type, getReferralAccount);
}
export default function* getReferralAccountSaga() {
  yield all([watchGetReferralAccount()]);
}
