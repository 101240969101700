export const listCompany = [
  {
    value: "",
    label: "Tất cả",
  },
  {
    value: "MBS",
    label: "MBS",
  },
  {
    value: "TCBS",
    label: "TCBS",
  },
  {
    value: "VPBS",
    label: "VPBS",
  },
  {
    value: "VPS",
    label: "VPS",
  },
  {
    value: "KIS",
    label: "KIS",
  },
  {
    value: "SHS",
    label: "SHS",
  },
  {
    value: "HSC",
    label: "HSC",
  },
  {
    value: "BSC",
    label: "BSC",
  },
  {
    value: "KB",
    label: "KB",
  },
  {
    value: "Mirae",
    label: "Mirae",
  },
  {
    value: "Tân Việt",
    label: "Tân Việt",
  },
  {
    value: "SSI",
    label: "SSI",
  },
  {
    value: "YUANTA",
    label: "Yuanta",
  },
  {
    value: "DNSE",
    label: "DNSE",
  },
  {
    value: "PHS",
    label: "PHS",
  },
];
export const listStatus = [
  {
    value: "",
    label: "Tất cả",
  },
  {
    value: "ACTIVE",
    label: "Hoạt động",
  },
  {
    value: "INACTIVE",
    label: "Ngừng hoạt động",
  },
];

export const STATUS_INACTIVE = "INACTIVE";
export const STATUS_ACTIVE = "ACTIVE";
export const ACTION_UPDATE = "UPDATE";
export const ACTION_CREATE = "CREATE";
export const ERROR_INPUT = "Vui lòng nhập thông tin";
export const ERROR_INPUT_RATE = "Lãi suất lớn hơn 0";
