import { all, call, put, takeLatest } from "redux-saga/effects";
import { channelListConnectApi } from "src/core/http/service/managementConnect";
import { GetChannelListConnectParams } from "src/core/http/service/managementConnect/types";
import {
  channelListConnectFailed,
  channelListConnectStart,
  channelListConnectSuccess,
} from "../redux/channelListConnect";

function* channelListConnectStartWork() {
  const params: GetChannelListConnectParams = {
    channelCode: "",
    channelName: "",
    limit: 10000,
    offset: 0,
    status: "",
    companyCode: "",
  };

  try {
    const res = yield call(channelListConnectApi, params);
    const filterActive = res.filter(
      (item) =>
        item?.status === "ACTIVE" &&
        (item?.companyCode === "MBS" || item?.companyCode === "VPS")
    );
    yield put({
      type: channelListConnectSuccess,
      payload: filterActive,
    });
  } catch (error: any) {
    yield put({
      type: channelListConnectFailed,
    });
  }
}

function* watchChannelListConnect() {
  yield takeLatest(channelListConnectStart.type, channelListConnectStartWork);
}

export default function* channelListConnectSaga() {
  yield all([watchChannelListConnect()]);
}
