import basePathHttp from "src/core/http/singleton/basePathHttp";
import {
  ResListAuthorize,
  ResManagementAccount,
} from "src/domain/models/managementAccount";
import { getAccountCode } from "src/helper/localStorage";
import {
  listAccounts,
  accountInfo,
  listShares,
  channelList,
  accountInfoStatic,
} from "./urls";

export const listAccountstApi = async (
  params: any
): Promise<ResListAuthorize> => {
  const res = await basePathHttp.post<ResListAuthorize>(listAccounts, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const getAccountInfoStaticApi =
  async (): Promise<ResManagementAccount> => {
    const res = await basePathHttp.get<ResManagementAccount>(accountInfoStatic);
    if (!res || !res.data) throw new Error("Opps");
    return res.data;
  };
export const getAccountInfoApi = async (): Promise<ResManagementAccount> => {
  const accountCode: string = getAccountCode();
  const LIMIT: number = 1;
  const res = await basePathHttp.post<ResManagementAccount>(accountInfo, {
    accountCode: accountCode,
    limit: LIMIT,
  });
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const listSharesApi = async (
  params: any
): Promise<ResManagementAccount> => {
  const res = await basePathHttp.get<ResManagementAccount>(listShares, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const channelListApi = async (
  params: any
): Promise<ResManagementAccount> => {
  const res = await basePathHttp.post<ResManagementAccount>(
    channelList,
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
