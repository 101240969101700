import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";
import { portfolioApi } from "src/core/http/service/stockTradingHand";
import { PortfolioParams } from "src/core/http/service/stockTradingHand/types";
import { PortfolioRes } from "src/domain/models/stockTradingHand";
import { RootState } from "src/redux-store/store";
import {
  portfolioAccountsHandFailed,
  portfolioAccountsHandStart,
  portfolioAccountsHandSuccess,
} from "../redux/portfolioAccountsHand";

function* portfolioAccountsHandStartWork() {
  yield delay(300);
  const { accountCode } = yield select(
    (state: RootState) => state.stockTradingHand.formOrderHand.inputForm
  );
  const params: PortfolioParams = {
    accountCode: accountCode?.value,
  };

  try {
    const res: PortfolioRes = yield call(portfolioApi, params);
    yield put({
      type: portfolioAccountsHandSuccess,
      payload: res,
    });
  } catch (error: any) {
    yield put({
      type: portfolioAccountsHandFailed,
    });
  }
}

function* watchPortfolioAccountsHand() {
  yield takeLatest(
    portfolioAccountsHandStart.type,
    portfolioAccountsHandStartWork
  );
}

export default function* portfolioAccountsHandSaga() {
  yield all([watchPortfolioAccountsHand()]);
}
