import { toast } from "react-toastify";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxData, ReduxStateType } from "src/redux-store/types";
import { ResponseForControl } from "src/domain/models/reportForControl";
import { ReferralAccountParams } from "src/core/http/service/referralAccount/types";

export type ReportForControlState = {
  dataRA: ResponseForControl[];
  filter: ReferralAccountParams;
  hasMore: boolean;
};

const initialState: ReduxData<ReportForControlState> = {
  data: {
    dataRA: [],
    filter: {
      referrerCode: "",
      accountCode: "",
    },
    hasMore: true,
  },
  status: ReduxStateType.INIT,
};

const referralAccountSlice = createSlice({
  name: "referralAccount",
  initialState,
  reducers: {
    updateFilter: (state, action: PayloadAction<ReferralAccountParams>) => {
      Object.assign(state.data.filter, action.payload);
    },
    resetFilter: (state) => {
      state.data.filter = initialState.data.filter;
    },
    resetListRARealize: (state) => {
      state.data.dataRA = initialState.data.dataRA;
    },
    getListRAStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    getListRASuccess: (state, action: PayloadAction<ResponseForControl[]>) => {
      state.status = ReduxStateType.SUCCESS;
      state.data.dataRA = action.payload;
    },
    getListRAFailed: (state) => {
      state.status = ReduxStateType.ERROR;
      toast.error("Fetching Data Failed");
    },
  },
});

export const {
  updateFilter,
  resetFilter,
  resetListRARealize,
  getListRAStart,
  getListRASuccess,
  getListRAFailed,
} = referralAccountSlice.actions;

export default referralAccountSlice.reducer;
