import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxData, ReduxStateType } from "src/redux-store/types";
// import { BondsItem } from "domain/models/bond";
// import { FilterBondsItem } from "core/http/service/abond/types";
// import { toast } from "react-toastify";
import { ResponseDetailAssetValueReport } from "src/domain/models/assetsValueReport";

export type AssetsValueState = {
  detailAssetValue: ResponseDetailAssetValueReport;
  masterAccount: string;
  account: string;
  id: number | null;
};

const initialState: ReduxData<AssetsValueState> = {
  data: {
    detailAssetValue: {},
    masterAccount: "",
    account: "",
    id: null,
  },
  status: ReduxStateType.INIT,
};

const GetDetailAssetsValueSlice = createSlice({
  name: "getDetailAssetsValueReport",
  initialState,
  reducers: {
    updateMasterAccount: (state, action) => {
      state.data.masterAccount = action.payload;
    },
    updateAccount: (state, action) => {
      state.data.account = action.payload;
    },
    updateAssetsValueReportId: (state, action) => {
      state.data.id = action.payload;
    },
    resetDetailAssetsValue: (state) => {
      state.data.detailAssetValue = initialState.data.detailAssetValue;
      state.data.masterAccount = initialState.data.masterAccount;
      state.data.account = initialState.data.account;
    },
    getAssetsValueStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    getAssetsValueFailed: (state, action: PayloadAction<Error>) => {
      state.status = ReduxStateType.ERROR;
      state.data.detailAssetValue = initialState.data.detailAssetValue;
      state.data.masterAccount = initialState.data.masterAccount;
      state.data.account = initialState.data.account;
    },
    getAssetsValueSuccess: (
      state,
      action: PayloadAction<{
        detailAssetValue: ResponseDetailAssetValueReport;
      }>
    ) => {
      state.status = ReduxStateType.SUCCESS;
      state.data.detailAssetValue = action.payload.detailAssetValue;
    },
  },
});

export const {
  updateMasterAccount,
  updateAccount,
  resetDetailAssetsValue,
  updateAssetsValueReportId,
  getAssetsValueStart,
  getAssetsValueFailed,
  getAssetsValueSuccess,
} = GetDetailAssetsValueSlice.actions;

export default GetDetailAssetsValueSlice.reducer;
