import { createSlice, current } from "@reduxjs/toolkit";
import { compareArray } from "src/helper/const";
import { ReduxStateType } from "src/redux-store/types";
interface InitialState {
  data: any[];
  status: ReduxStateType;
}
const initialState: InitialState = {
  data: [],
  status: ReduxStateType.INIT,
};

const listOrders = createSlice({
  name: "listOrdersSlice",
  initialState,
  reducers: {
    listOrdersStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    listOrdersSuccess: (state, action) => {
      if (!compareArray(current(state.data), action.payload)) {
        state.data = action.payload;
      }
      //  trường hợp lấy mới dữ liệu mà không thay đổi thì sẽ ko cho updateState để rerender lại
      state.status = ReduxStateType.SUCCESS;
    },
    listOrdersFailed: (state) => {
      state.status = ReduxStateType.ERROR;
      state.data = [];
    },
    resetListOrders: () => initialState,
  },
});

export const {
  listOrdersStart,
  listOrdersSuccess,
  listOrdersFailed,
  resetListOrders,
} = listOrders.actions;

export default listOrders.reducer;
