import { createSlice } from "@reduxjs/toolkit";
// import { addOrUpdateAccountParams } from "src/core/http/service/managementAccount/types";
interface InitialState {
  inputInformation: ItemsInput;
  showModal: ItemsModal;
}
type ItemsInput = {
  accountAddress: string;
  accountCode: string;
  accountName: string;
  accountMobile: string;
  accountEmail: string;
  bankNumber: string;
  bankCode: string;
  checkValidateInfo: boolean;
};
type ItemsModal = {
  showInfo: boolean;
  showChangePass: boolean;
};
const initialState: InitialState = {
  inputInformation: {
    accountAddress: "",
    accountCode: "",
    accountName: "",
    accountMobile: "",
    accountEmail: "",
    bankNumber: "",
    bankCode: "",
    checkValidateInfo: false,
  },
  showModal: {
    showInfo: false,
    showChangePass: false,
  },
};

const informationSlice = createSlice({
  name: "informationSlice",
  initialState,
  reducers: {
    updateInputInformation: (state, action) => {
      Object.assign(state.inputInformation, action.payload);
    },
    updateShowModal: (state, action) => {
      Object.assign(state.showModal, action.payload);
    },
    resetInputInformation: () => initialState,
  },
});

export const {
  updateInputInformation,
  updateShowModal,
  resetInputInformation,
} = informationSlice.actions;

export default informationSlice.reducer;
