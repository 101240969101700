import { lazy } from "react";
import { roles } from "src/helper/const";
import paths from "src/routes/pathRoutes";
const TransferMoneyLazy = lazy(() => import("./pages"));

export const transferMoneyRoutes = [
  {
    page: TransferMoneyLazy,
    path: paths.tranferMoney,
    roleSide: roles.ROLE_USER,
  },
];
