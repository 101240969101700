import { lazy } from "react";
import { roles } from "src/helper/const";
import paths from "src/routes/pathRoutes";
const reportFeeSyntheticLazy = lazy(() => import("./pages"));
export const reportFeeSyntheticRoutes = [
  {
    page: reportFeeSyntheticLazy,
    path: paths.reportFeeSynthetic,
    roleSide: roles.ROLE_EDITOR,
  },
];
