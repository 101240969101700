import { createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "src/redux-store/types";
interface Formauth2faState {
  status: ReduxStateType;
  token: string;
  is2faStatus: boolean;
  is2faTele: boolean;
  isTeleRegister: boolean;
  is2faMail: boolean;
  otp: string;
}

const initialState: Formauth2faState = {
  status: ReduxStateType.ERROR,
  is2faStatus: false,
  is2faTele: false,
  isTeleRegister: false,
  is2faMail: false,
  token: "",
  otp: "",
};

const auth2fa = createSlice({
  name: "auth2faSlice",
  initialState,
  reducers: {
    auth2faStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    auth2faSuccess: (state, action) => {
      const { is2faStatus, is2faTele, isTeleRegister, is2faMail, token } =
        action.payload;
      state.token = token;
      state.is2faStatus = is2faStatus;
      state.is2faTele = is2faTele;
      state.isTeleRegister = isTeleRegister;
      state.is2faMail = is2faMail;
      state.status = ReduxStateType.SUCCESS;
    },
    updateOTP2fa: (state, action) => {
      state.otp = action.payload;
    },
    auth2faFailed: (state) => {
      state.status = ReduxStateType.ERROR;
    },
    resetAuth2fa: () => initialState,
  },
});

export const {
  auth2faStart,
  auth2faSuccess,
  updateOTP2fa,
  auth2faFailed,
  resetAuth2fa,
} = auth2fa.actions;

export default auth2fa.reducer;
