import HttpClient from "src/core/http";
export default class channelHttp extends HttpClient {
  private static classInstance?: channelHttp;
  private constructor() {
    super(process.env.REACT_APP_RESTFUL_BASE_URL || "");
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new channelHttp();
    }
    return this.classInstance;
  }
}
