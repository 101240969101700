import { useMemo } from "react";
import { useSelector } from "react-redux";
import { roles as rolesConst } from "src/helper/const";
import { authSelector } from "src/modules/Auth/redux/selector";
import { sideBarKey } from "../const";
import { sideBarSelector } from "./redux/selector";
import SidebarItem from "./SidebarItem";
import { ItemNew, SidebarWrapper, Text, TopSection } from "./style";

function Sidebar() {
  const { roles } = useSelector(authSelector);

  const { activeTab } = useSelector(sideBarSelector);

  const sideBarRoles = useMemo(() => {
    if (roles[0] === rolesConst.ROLE_USER) {
      return sideBarKey.filter(
        (item) =>
          item.activeTab !== "ManagementAccount" &&
          item.activeTab !== "ReportAdmin"
      );
    } else {
      return sideBarKey;
    }
  }, [roles]);

  return (
    <SidebarWrapper>
      <TopSection />
      {sideBarRoles.map((item) => {
        return (
          <SidebarItem
            active={item.activeTab === activeTab ? true : false}
            sideBarItem={item}
            key={item.name}
          >
            {item.icon}
            <Text> {item.name}</Text>
            {item.isNew && <ItemNew>New</ItemNew>}
          </SidebarItem>
        );
      })}
    </SidebarWrapper>
  );
}

export default Sidebar;
