import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import LogoEasyInvest from "src/assets/images/logo_easy_invest.png";
const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        justify-content:center;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
        width: 100%;
`
);
const LogoImg = styled("img")(
  () => `
    width: 70px;
  `
);
function Logo() {
  return (
    <LogoWrapper to="command/stockTrading">
      <Box display="flex" alignItems="center" justifyContent="center">
        <LogoImg src={LogoEasyInvest} alt="" />
      </Box>
    </LogoWrapper>
  );
}

export default Logo;
