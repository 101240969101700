import { combineReducers } from "redux";
import listAccountsStaticSlice from "./listAccountsStatic";
import accountInfoSlice from "./accountInfo";
import getAccountInfoStaticSlice from "./getAccountInfoStatic";
import listSharesStaticSlice from "./listSharesStatic";
import channelListStaticSlice from "./channelListStatic";

export default combineReducers({
  listAccountsStatic: listAccountsStaticSlice,
  accountInfo: accountInfoSlice,
  getAccountInfoStatic: getAccountInfoStaticSlice,
  listSharesStatic: listSharesStaticSlice,
  channelListStatic: channelListStaticSlice,
});
