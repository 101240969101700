import { styled } from "@mui/material/styles";
import myImage from "src/assets/images/loginbg.jpg";
export const WrapLogin = styled("div")(
  ({ theme }) => `
margin-top: 30vh;
background-color: ${theme.mixColor.bgElevated1}; 
width: 340px;
height: 330px;
border-radius: 10px;
box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
&.form-login {
    padding: 10px 50px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: black !important;
}
`
);

export const Wrap = styled("div")(
  ({ theme }) => `
position: absolute;
background-image: url(${myImage});
background-repeat: no-repeat;
background-size: cover;
display: flex;
justify-content: center;  
width: 100%;
height: 100%;
`
);

export const WrapIcon = styled("div")(
  ({ theme }) => `
margin-top: 10px;
display: flex;
justify-content: center;
`
);

export const LogoText = styled("div")(
  ({ theme }) => `
        svg{
          path{
            fill: ${theme.palette.logo.main};
          }
        }
    `
);

export const WrapMain = styled("div")`
  height: 130px;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
`;

export const TextError = styled("p")(
  ({ theme }) => `
         color: ${theme.colors.error.main};
         position: absolute;
         top: 37px;
         font-size: 12px;
         font-weight: bold;
      `
);

export const TitleAction = styled("p")(
  ({ theme }) => `
         color: ${theme.colors.primary.dark};
         font-size: 12px;
         font-weight: bold;
         cursor: pointer;
         &:hover {
           color: blue
         }
      `
);

export const TitleBy = styled("p")(
  ({ theme }) => `
         font-size: 12px;
         font-weight: bold;
         cursor: pointer;
      `
);
