import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  RecommendationImport,
  RecommendationItem,
} from "src/domain/models/recommendations";
import { ReduxData, ReduxStateType } from "src/redux-store/types";

export type RecommendationState = {
  listRecommendation: RecommendationItem[];
};

const initialState: ReduxData<RecommendationState> = {
  data: {
    listRecommendation: [
      {
        buyDate: "19/05/2022",
        shareCode: "MBS",
        buyPrice: 24100,
        target: 65000,
        stopLoss: 15000,
        lastPrice: 23100,
        sellDate: "15/07/2022",
        sellPrice: 65000,
        status: "DONE",
        unrealized: 181.38,
      },
    ],
  },
  status: ReduxStateType.INIT,
};

const recommendationSlice = createSlice({
  name: "recommendations",
  initialState,
  reducers: {
    getRecommendationStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    getRecommendationSuccess: (
      state,
      action: PayloadAction<RecommendationItem[]>
    ) => {
      state.data.listRecommendation = action.payload;
      state.status = ReduxStateType.SUCCESS;
    },
    callApiFailed: (state, action: PayloadAction<Error>) => {
      state.status = ReduxStateType.ERROR;
      toast.error("Đã có lỗi xảy ra");
    },
    importRecommendationStart: (
      state,
      action: PayloadAction<RecommendationImport[]>
    ) => {
      state.status = ReduxStateType.LOADING;
    },
    importRecommendationSuccess: (state) => {
      state.status = ReduxStateType.SUCCESS;
    },
  },
});

export const {
  importRecommendationStart,
  importRecommendationSuccess,
  getRecommendationStart,
  getRecommendationSuccess,
  callApiFailed,
} = recommendationSlice.actions;

export default recommendationSlice.reducer;
