import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { FinanceServiceAddItem } from "src/core/http/service/financeService/types";
import { ReduxData, ReduxStateType } from "src/redux-store/types";

export type InsertFinanceServiceState = {
  insertData: FinanceServiceAddItem[];
};

const initialState: ReduxData<InsertFinanceServiceState> = {
  data: {
    insertData: [],
  },
  status: ReduxStateType.INIT,
};

const insertFinanceServiceSlice = createSlice({
  name: "insertFinanceService",
  initialState,
  reducers: {
    insertFinanceServicesStart: (
      state,
      action: PayloadAction<FinanceServiceAddItem[]>
    ) => {
      state.data.insertData = action.payload;
      state.status = ReduxStateType.LOADING;
    },
    insertFinanceServicesSuccess: (state) => {
      state.status = ReduxStateType.SUCCESS;
    },
    insertFinanceServicesFailed: (state, action: PayloadAction<Error>) => {
      state.status = ReduxStateType.ERROR;
      toast.error("Đã có lỗi xảy ra.");
    },
  },
});

export const {
  insertFinanceServicesStart,
  insertFinanceServicesSuccess,
  insertFinanceServicesFailed,
} = insertFinanceServiceSlice.actions;

export default insertFinanceServiceSlice.reducer;
