import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  getReportAccountShareFailed,
  getReportAccountShareStart,
  getReportAccountShareSuccess,
} from "../redux/getReportAccountShare";
import { getReportAccountShareApi } from "src/core/http/service/reportCategoryCustomer";
import { ReportAccountShareParams } from "src/core/http/service/reportCategoryCustomer/type";
import { filterReportCCSelector } from "../redux/selector";
import { toast } from "react-toastify";
import { ResReportAccountShare } from "src/domain/models/reportCategoryCustomer";

function* getReportAccountShare() {
  try {
    const { shareCode, accountCode } = yield select(filterReportCCSelector);
    const params: ReportAccountShareParams = {
      accountCode: accountCode,
      shareCode: shareCode,
    };
    const data: ResReportAccountShare[] = yield call(
      getReportAccountShareApi,
      params
    );
    yield put({
      type: getReportAccountShareSuccess.type,
      payload: data,
    });
  } catch (error: any) {
    yield put({
      type: getReportAccountShareFailed,
    });
    const errMess = error?.response?.data?.message;
    toast.error(errMess, { autoClose: 4000 });
  }
}

function* watchGetReportAccountShare() {
  yield takeLatest(getReportAccountShareStart.type, getReportAccountShare);
}
export default function* getReportAccountShareSaga() {
  yield all([watchGetReportAccountShare()]);
}
