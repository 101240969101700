import { ReduxStateType } from "src/redux-store/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FilterState = {
  channelCode?: string;
  shareCode?: string;
};

interface InitialState {
  filter: FilterState;
  status: ReduxStateType;
}

const initialState: InitialState = {
  filter: {
    channelCode: "",
    shareCode: "",
  },
  status: ReduxStateType.INIT,
};

const filterChannelPortfolio = createSlice({
  name: "filterChannelPortfolioSlice",
  initialState,
  reducers: {
    updateFilter: (state, action: PayloadAction<FilterState>) => {
      Object.assign(state.filter, action.payload);
    },
    resetFilter: (state) => {
      Object.assign(state.filter, initialState.filter);
    },
  },
});

export const { updateFilter, resetFilter } = filterChannelPortfolio.actions;

export default filterChannelPortfolio.reducer;
