import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux-store/store";

export const FilterReportSyntheticState = (state: RootState) =>
  state.reportSynthetic.reportSyntheticListSlice;

const filterReportSynthetic = (state: RootState) =>
  state.reportSynthetic.reportSyntheticListSlice;

export const filterReportSyntheticSelector = createSelector(
  filterReportSynthetic,
  (state) => ({
    dataReport: state.data.data,
    accountCode: state.data.filter.accountCode,
  })
);
