import { createSlice } from "@reduxjs/toolkit";
interface FormAuthState {
  inputAuth: {
    accountCode: string;
    password: string;
    checkValidateAuth: boolean;
    showRegister: boolean;
    showForgetPass: boolean;
  };
}

const initialState: FormAuthState = {
  inputAuth: {
    accountCode: "",
    password: "",
    checkValidateAuth: false,
    showRegister: false,
    showForgetPass: false,
  },
};

const formAuth = createSlice({
  name: "formAuthSlice",
  initialState,
  reducers: {
    updateInputAuth: (state, action) => {
      Object.assign(state.inputAuth, action.payload);
    },
    resetFormAuth: () => initialState,
  },
});

export const { updateInputAuth, resetFormAuth } = formAuth.actions;

export default formAuth.reducer;
