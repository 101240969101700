import { all } from "redux-saga/effects";
import addAccountSaga from "src/modules/ManagementAccount/saga/addAccount";
import updateAccountSaga from "src/modules/ManagementAccount/saga/updateAccount";
import filterAccountSaga from "src/modules/ManagementAccount/saga/filterAccount";
import listAuthorizeSaga from "src/modules/ManagementAccount/saga/listAuthorize";

export default function* managementAccountSaga() {
  yield all([
    addAccountSaga(),
    updateAccountSaga(),
    filterAccountSaga(),
    listAuthorizeSaga(),
  ]);
}
