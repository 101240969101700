import { toast } from "react-toastify";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxData, ReduxStateType } from "src/redux-store/types";

export type DividendDeleteState = {
  id: string;
};

const initialState: ReduxData<DividendDeleteState> = {
  data: {
    id: "",
  },
  status: ReduxStateType.INIT,
};

const deleteDividendSlice = createSlice({
  name: "dividendDelete",
  initialState,
  reducers: {
    updateIdDelete: (state, action) => {
      state.data.id = action.payload;
    },
    resetIdDelete: (state) => {
      state.data.id = initialState.data.id;
    },
    deleteDividendStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    deleteDividendFailed: (state, action: PayloadAction<string>) => {
      state.status = ReduxStateType.ERROR;
      toast.error(action.payload || "Đã có lỗi xảy ra");
    },
    deleteDividendSuccess: (state, action) => {
      state.status = ReduxStateType.SUCCESS;
      toast.success("Xóa thành công.");
    },
  },
});

export const {
  updateIdDelete,
  resetIdDelete,
  deleteDividendStart,
  deleteDividendFailed,
  deleteDividendSuccess,
} = deleteDividendSlice.actions;

export default deleteDividendSlice.reducer;
