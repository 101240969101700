// import { toast } from "react-toastify";
import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { createSelector } from "@reduxjs/toolkit";

import { postDividendCreateApi } from "src/core/http/service/dividend";
import { dividendCreateState } from "../redux/selector";
import { DividendCreateParams } from "src/core/http/service/dividend/types";
import {
  postDividendCreateFailed,
  postDividendCreateStart,
  postDividendCreateSuccess,
} from "../redux/postDividendCreate";
import { getDividendFilterStart } from "../redux/getDividendFilter";

const dividendCreateSelector = createSelector(dividendCreateState, (state) => ({
  dividends: state.data.dividends,
}));

function* postDividendCreate(
  action: ReturnType<typeof postDividendCreateStart>
) {
  try {
    const { dividends } = yield select(dividendCreateSelector);
    const params: DividendCreateParams = {
      ...dividends,
    };
    const res = yield call(postDividendCreateApi, params);
    yield put({
      type: postDividendCreateSuccess.type,
      payload: {
        items: res,
      },
    });
    yield put({
      type: getDividendFilterStart.type,
    });
  } catch (error: any) {
    yield put(postDividendCreateFailed(error));
  }
}

function* watchPostDividendCreate() {
  yield takeLatest(postDividendCreateStart.type, postDividendCreate);
}
export default function* getDividendCreateSaga() {
  yield all([watchPostDividendCreate()]);
}
