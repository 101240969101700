import { combineReducers } from "redux";
import informationSlice from "./information";
import changePassSlice from "./changePass";
import postChangeInfoSlice from "./postChangeInfo";
import postChangePassSlice from "./postChangePass";

export default combineReducers({
  information: informationSlice,
  changePass: changePassSlice,
  postChangeInfo: postChangeInfoSlice,
  postChangePass: postChangePassSlice,
});
