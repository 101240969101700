import { all, call, put, takeLatest, select } from "redux-saga/effects";
import {
  getReportFeeSyntheticStart,
  getReportFeeSyntheticFailed,
  getReportFeeSyntheticSuccess,
} from "../redux/reportFeeSyntheticList";

import { filterReportFeeSyntheticSelector } from "../redux/selector";
import { ReportFeeSyntheticParams } from "src/core/http/service/reportFeeSynthetic/types";
import { getListReportFeePortfolioApi } from "src/core/http/service/reportFeeSynthetic";
import { ResReportFeeSynthetic } from "src/domain/models/reportFeeSynthetic";

function* getReportSynthetic(
  action: ReturnType<typeof getReportFeeSyntheticStart>
) {
  try {
    const { accountCode, fromDate, toDate } = yield select(
      filterReportFeeSyntheticSelector
    );
    const params: ReportFeeSyntheticParams = {
      referrerCode: accountCode,
      fromDate: fromDate,
      toDate: toDate,
    };
    const data: ResReportFeeSynthetic = yield call(
      getListReportFeePortfolioApi,
      params
    );

    yield put({
      type: getReportFeeSyntheticSuccess.type,
      payload: {
        data: data,
      },
    });
  } catch (error: any) {
    yield put(getReportFeeSyntheticFailed(error));
  }
}

function* watchGetReportSynthetic() {
  yield takeLatest(getReportFeeSyntheticStart.type, getReportSynthetic);
}

export default function* listReportSyntheticSaga() {
  yield all([watchGetReportSynthetic()]);
}
