import React, { memo, ReactNode, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { roles as rolesConst } from "src/helper/const";
import { setLocalStorage } from "src/helper/localStorage";
import { authSelector } from "src/modules/Auth/redux/selector";
import { updateSideBar } from "./redux";
import { ListSubItem, WrapSelectItem, WrapSideBarItem } from "./style";

interface SidebarItem {
  active?: boolean;
  children?: ReactNode;
  sideBarItem?: any;
}

function SidebarItem({ active, children, sideBarItem }: SidebarItem) {
  const { roles } = useSelector(authSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showList, setShowList] = useState(false);

  const handleMouseEnter = () => {
    setShowList(true);
  };

  const handleMouseLeave = () => {
    setShowList(false);
  };

  const handlePath = (path: string) => {
    dispatch(updateSideBar(sideBarItem.activeTab));
    setLocalStorage("activeTab", sideBarItem.activeTab);
    navigate(path);
  };

  const renderSideBarRoles = (item: any) => {
    switch (roles[0]) {
      case rolesConst.ROLE_ADMIN:
        return (
          <WrapSelectItem key={item.path} onClick={() => handlePath(item.path)}>
            {item.subIcon} {item.subName}
          </WrapSelectItem>
        );
      case rolesConst.ROLE_EDITOR:
        return item.roleSide === rolesConst.ROLE_EDITOR ||
          item.roleSide === rolesConst.ROLE_USER ? (
          <WrapSelectItem key={item.path} onClick={() => handlePath(item.path)}>
            {item.subIcon} {item.subName}
          </WrapSelectItem>
        ) : null;

      case rolesConst.ROLE_USER:
        return item.roleSide === rolesConst.ROLE_USER ? (
          <WrapSelectItem key={item.path} onClick={() => handlePath(item.path)}>
            {item.subIcon} {item.subName}
          </WrapSelectItem>
        ) : null;
      default:
        return null;
    }
  };

  return (
    <>
      <WrapSideBarItem
        active={active}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        // path của item nào bằng activeTab sẽ hightlight
      >
        {children}
        {showList ? (
          <ListSubItem>
            {sideBarItem.subSideBar.map((item) => {
              return renderSideBarRoles(item);
            })}
          </ListSubItem>
        ) : null}
      </WrapSideBarItem>
    </>
  );
}

export default memo(SidebarItem);
