export const errorDescription = {
  SESSION_LOGOUT: "Tài khoản chưa đăng nhập",
  60003: "Tài khoản chưa đăng nhập",
};

export const errorCode = {
  defaultErr: "Đã có lỗi xảy ra, vui lòng kiểm tra lại",
  errorServer: "Lỗi kết nối đến server, vui lòng thử lại sau",
  ERROR101: "TK sức mua không hợp lệ",
  ERROR102: "Tài khoản sức mua ứng trước không được ứng khi chưa import lệnh.",
  ERROR103: "Tài khoản chuyển tiền không tồn tại.",
  ERROR104: "TK bị hạn chế sử dụng MBS Chat 24!",
  ERROR105: "Tài khoản ĐÃ ĐÓNG.",
  ERROR106: "Tài khoản không hợp lệ.",
  ERROR107:
    "Xin vui lòng liên hệ với các Phòng MG (PCS) để làm thủ tục chuyển tiền do bạn đang sử dụng dịch vụ tài chính của MBS.",
  ERROR108: "Tài khoản nhận không nằm trong danh sách tài khoản đã đăng ký",
  ERROR109: "Tài khoản nhận không tồn tại hoặc ĐÃ ĐÓNG.",
  ERROR110: "Tài khoản nhận không thuộc danh sách TK thụ hưởng.",
  ERROR111: "Tài khoản nhận CK không hợp lệ.",
  ERROR112: "Không được thực hiện link sức mua cho TK UQ",
  ERROR113:
    "Tài khoản link sức mua không hợp lệ (Không được link sức mua vào tiểu khoản đuôi 7)",
  ERROR114: "Tài khoản CHƯA DUYỆT hoặc ĐANG SỬA ĐỔI.",
  ERROR115:
    "Tài khoản chuyển tiền không thuộc danh sách TK ủy quyền.//Hệ thống đã bị KHÓA. Bạn không thể thực hiện.",
  ERROR116: "Tài khoản không tồn tại hoặc ĐÃ ĐÓNG.",
  ERROR117: "Tài khoản không có quyền thực hiện chức năng này.",
  ERROR118: "Tài khoản chuyển và tài khoản nhận không hợp lệ.",
  ERROR119: "Tài khoản chuyển là TK BCC hoặc TK còn dư nợ BCC",
  ERROR120: "Tài khoản chuyển CK không hợp lệ.",
  ERROR121: "TK chỉ định không hợp lệ",
  ERROR122: "Mỗi CK chỉ được thiết lập một cảnh báo.",
  ERROR123: "Hãy chọn tài khoản nhận",
  ERROR124: "Tài khoản nhận không phải tài khoản Margin",
  ERROR125: "Tài khoản chuyển và tài khoản nhận không hợp lệ",
  ERROR126: "Tài khoản chuyển và tài khoản nhận trùng nhau",
  ERROR127: "Hãy chọn tài khoản chuyển",
  ERROR128: "Nội dung không được để trống",
  ERROR129: "Mã chứng khoán không hợp lệ",
  ERROR130: "Mã chứng khoán không tồn tại",
  ERROR131: "Không được chuyển quá số dư",
  ERROR132: "Trạng thái chuyển không được trùng loại CK hiện tại",
  ERROR133: "Số dư chứng khoán không hợp lệ hoặc chưa chọn mã CK cần chuyển",
  ERROR134: "Số dư chứng khoán không đủ để thực hiện.",
  ERROR135: "Chuyển khoản thành công",
  ERROR136: "Đã quá số lần sửa đổi trong ngày. Bạn không thể thực hiện!",
  ERROR137: "Ngày hết hạn phụ lục không được quá này hết hạn HĐ",
  ERROR138: "Phụ lục không tồn tại",
  ERROR139: "Trạng thái phụ lục không hợp lệ",
  ERROR140: "Lỗi khi duyệt ứng.",
  ERROR141: "Duyệt chuyển khoản CK bị lỗi.",
  ERROR142: "Bút toán không ở trạng thái CHƯA XỬ LÝ, nên bạn không thể sửa.",
  ERROR143: "Tài khoản không được ủy quyền toàn phần, bạn không thể thực hiện",
  ERROR144: "Chủ tài khoản không có quyền thực hiện do đã Ủy quyền.",
  ERROR145: "Số dư tiền gửi không đủ.",
  ERROR146: "Hợp đồng BCC+ đã bị khóa.",
  ERROR147: "Tài khoản thụ hưởng đã tồn tại.",
  ERROR148: "Không cho phép thêm mới tài khoản NH thụ hưởng",
  ERROR149: "Không được cập nhật TK thụ hưởng khi sử dụng chuyển khoản hạn chế",
  ERROR150:
    "Đã ngắt kết nối online với ngân hàng BIDV, mời bạn lựa chọn ngân hàng liên kết khác.",
  ERROR151: "Chuyển tiền qua liên kết BIDV không thành công",
  ERROR152:
    "Phụ lục đã được đảm bảo cho các khoản mua của tài khoản, bạn không thể thực hiện.",
  ERROR153: "Lỗi nghiệp vụ.            ",
  ERROR154:
    "Hệ thống đang tạm dừng cung cấp dịch vụ. Quí khách vui lòng quay lại sau ít phút nữa.",
  ERROR155: "Tài khoản nhận và tài khoản chuyển không được giống nhau.",
  ERROR156: "Giá trị không đáp ứng",
  ERROR157:
    "Nguồn ứng online ngày hôm nay tạm thời hết hạn mức, Quý khách vui lòng thực hiện ứng trực tiếp tại các quầy giao dịch của MBS,",
  ERROR158: "Số dư tài khoản không đủ để thực hiện,",
  ERROR159: "Không được ứng quá số tiền có thể ứng.",
  ERROR160:
    "Chuyển khoản không thành công hệ thống mất kết nối với liên kết BIDV, khách hàng vui lòng chọn ngân hàng liên kết khác",
  ERROR161:
    "Hệ thống đang trong quá trình xử lý dữ liệu. Quý khách vui lòng truy cập lại sau.",
  ERROR162: "Nội dung không được viết tiếng Việt có dấu,",
  ERROR163: "HĐ không tồn tại",
  ERROR164: "Trạng thái HĐ không hợp lệ",
  ERROR165: "KH chưa có HĐ link sức mua. Bạn không thể thực hiện.",
  ERROR166:
    "Hệ thống tạm dừng phục vụ, Quý khách vui lòng quay lại sau ít phút nữa.",
  ERROR167: "CMT/Hộ chiếu/Passport/ĐKKD đã hết hạn sử dụng.",
  ERROR168: "Địa chỉ liên lạc",
  ERROR169: "Khi cần liên hệ",
  ERROR170: "Tên người liên hệ",
  ERROR171: "Quan hệ",
  ERROR172: "Điện thoại",
  ERROR173: "Địa chỉ hiện tại",
  ERROR174: "Email",
  ERROR175: "Cư trú tại VN",
  ERROR176: "Điện thoại DĐ",
  ERROR177: "Địa chỉ thường trú",
  ERROR178: "Điện thoại CĐ",
  ERROR179: "Quý khách chỉ được thực hiện quyền online 1 lần/ngày cho 1 mã CK.",
  ERROR180: "Phương thức gia hạn không hợp lệ",
  ERROR181:
    "Đã ngắt kết nối online, mời bạn sử dụng chức năng 'Chuyển tiền ra ngoài'.",
  ERROR182: "Tài khoản margin không nợ lãi margin,",
  ERROR183:
    "Tài khoản đang còn sử dụng dịch vụ BCC/T+. Bạn không thể thực hiện.",
  ERROR184: "Hồ sơ đã được duyệt.",
  ERROR185:
    "Phụ lục đã được đảm bảo cho các khoản mua hoặc tỷ lệ an toàn của tài khoản, bạn không thể thực hiện.",
  ERROR186: "Tài khoản có phụ lục còn hiệu lực. Bạn không thể thực hiện.",
  ERROR187: "Tài khoản đang bị hạn chế chuyển/rút tiền.",
  ERROR188: "Tài khoản đang bị hạn chế ứng trước tiền bán.",
  ERROR189: "Tài khoản đã có hợp đồng khung. Bạn không thể thực hiện.",
  ERROR190: "Tài khoản chưa mở hợp đồng khung. Bạn không thể thực hiện.",
  ERROR191: "Lỗi khi cập nhật ứng.",
  ERROR192: "Quý khách nhập sai PIN, Xin Quý khách vui lòng nhập lại",
  ERROR193: "Không thể thanh lý phụ lục được lập trong ngày",
  ERROR194: "Tài khoản chuyển còn dư nợ margin/BCC+,",
  ERROR195:
    "Hệ thống đang tạm dừng cung cấp dịch vụ. Quí khách vui lòng quay lại sau ít phút nữa.",
  ERROR196: "Tên tài khoản nhận không hợp lệ. Vui lòng kiểm tra lại.",
  ERROR197: "Không thể kết nối tới Ngân hàng. Đề nghị kiểm tra lại bút toán",
  ERROR198: "Dịch vụ BCC+ không thực hiện giải ngân sau 16h và ngày nghỉ.",
  ERROR199: "Tài khoản không được ủy quyền chuyển tiền.",
  ERROR200: "Đã ngoài giờ giao dịch. Bạn không thể thực hiện.",
  ERROR201: "Đã ngoài thời gian giao dịch.",
  ERROR202: "Tiền đã về",
  ERROR203: "Từ chối",
  ERROR204: "Tiền chưa về",
  ERROR205:
    "Đã ngắt kết nối online, mời bạn sử dụng chức năng 'Chuyển tiền ra ngoài'.",
  ERROR206:
    "Đã kết nối chuyển tiền online, mời bạn sử dụng chức năng 'Chuyển khoản ra MB'.",
  ERROR207: "Mua",
  ERROR208: "Chưa xác định",
  ERROR209: "Bán",
  ERROR210: "Chờ duyệt",
  ERROR211: "Chờ khớp",
  ERROR212: "Khớp",
  ERROR213: "Chưa xác định",
  ERROR214: "Chờ khớp",
  ERROR215: "Từ chối",
  ERROR216: "Chưa khớp",
  ERROR217: "Chờ Hủy",
  ERROR218: "Đã Hủy",
  ERROR219: "Phụ lục kỳ hạn khác không được link sức mua!",
  ERROR220: "Đã ngoài thời gian đăng ký mua.",
  ERROR221: "Hạn mức ứng ngân hàng đã hết. Hạn mức còn lại:,",
  ERROR222: "Hạn mức hợp đồng đã hết. Hạn mức còn lại:",
  ERROR223: "Tài khoản đã vượt quá hạn mức cho vay tối đa.",
  ERROR224: "Hạn mức giải ngân của MBS đã hết.",
  ERROR225: "Vượt hạn mức tối đa một lần ứng online:,",
  ERROR226: "Quyền đã về",
  ERROR227: "Quyền chưa về",
  ERROR228: "Không được đăng ký quá số lượng được mua.",
  ERROR229: "Không thể kết nối được với Server",
  ERROR230: "Mã chứng khoán không tồn tại.",
  ERROR231: "Không được chuyển khoản phần cổ phiếu lẻ",
  ERROR232:
    "Mã CK còn số dư lô lẻ trên các trạng thái khác. Quý khách vui lòng chuyển chứng khoán về trạng thái Thường trước khi thực hiện.",
  ERROR233:
    "Không thể bán cổ phiếu đã bị hủy niêm yết hoặc thuộc diện kiểm soát.",
  ERROR234:
    "Quý khách có lệnh bán CP lẻ khác đang được xử lý. Quý khách vui lòng liên hệ nhân viên chăm sóc hoặc tổng đài để được hướng dẫn.  ",
  ERROR235:
    "Mã CK còn số dư lô lẻ trên các tiểu khoản khác. Quý khách vui lòng chuyển về tiểu khoản thường (tiểu khoản đuôi 1) trước khi thực hiện.",
  ERROR236: "Trạng thái chứng khoán không hợp lệ",
  ERROR237: "Không thể ứng online nguồn ngân hàng tại ngày T+2.",
  ERROR238: "Kỳ hạn không tồn tại",
  ERROR239: "Không được sử dụng kỳ hạn khác",
  ERROR240: "Tài khoản chuyển còn dư nợ margin+",
  ERROR241: "Đã quá số lần chuyển trong 1 ngày.",
  ERROR242: "Không được quá số tiền tối đa.",
  ERROR243: "Đã quá số tiền chuyển tối đa trong 1 ngày.",
  ERROR244: "Không được nhỏ hơn số tiền tối thiểu.",
  ERROR245: "Không thể cập nhật TRANSACTION LOG.",
  ERROR246: "Cập nhật nguồn ứng bị lỗi.",
  ERROR247: "Cập nhật chuyển khoản CK bị lỗi.",
  ERROR248: "Bạn không thể thực hiện. Hãy THOÁT và đăng nhập lại.",
  ERROR2: "Lệnh đang chờ duyệt",
  ERROR3: "Lệnh đang chờ duyệt",
  ERROR_6: "Thị trường đã đóng cửa (ES6)",
  ERROR_8: "Thành phần xửa lý lệnh chưa sẵn sàng (ES8)",
  ERROR_9: "Thành phân truy vấn dữ liệu 1 chưa sẵn sàng (ES9)",
  ERROR_10: "Thành phần truy vấn dữ liệu chưa sẵn sàng (ES10)",
  ERROR_11: "Hệ thống không kết nối với sở giao dịch (ES11)",
  ERROR_12: "Hệ thống đang tạm dừng hoạt động (ES12)",
  ERROR_13: "Thành phần truy vấn dữ liệu 2 chưa sẵn sàng (ES13)",
  ERROR_28: "Sở giao dịch đang tạm ngừng nhận lệnh (ES28)",
  ERROR_1001: "Tài khoản không tồn tại (ES1001)",
  ERROR_1002: "Trùng số tài khoản (ES1002)",
  ERROR_1003: "Tài khoản đã bị đóng (ES1003)",
  ERROR_2001: "Mã chứng khoán không tồn tại (ES2001)",
  ERROR_2002: "Mã chứng khoán bị trùng (ES2002)",
  ERROR_2003: "Chứng khoán bị tạm ngừng giao dịch (ES2003)",
  ERROR_2004: "Chứng khoán bị treo, không được giao dịch  (ES2004)",
  ERROR_3001: "Mua bán trong cùng 1 phiên (ES3001)",
  ERROR_3002: "Số hiệu lệnh không tồn tại (ES3002)",
  ERROR_3003: "Trùng số hiệu lệnh (ES3003)",
  ERROR_3004: "Lệnh cần duyệt đã được duyệt (ES3004)",
  ERROR_3005: "Không được phép hủy lệnh (ES3005)",
  ERROR_3006: "Không được sửa thông tin đối với lệnh đã khớp (ES3006)",
  ERROR_3007: "Số lượng lệnh đặt vượt quá Room nhà đầu tư nước ngoài (ES3007)",
  ERROR_3008: "Không được hủy/sửa lệnh trong phiên khớp lệnh định kỳ (ES3008)",
  ERROR_3009: "Trạng thái thành viên bị khóa gửi lệnh mua (ES3009)",
  ERROR_3010: "Trạng thái thành viên bị khóa gửi lệnh bán (ES3010)",
  ERROR_3011: "Trạng thái thành viên bị khóa gửi lệnh mua và bán (ES3011)",
  ERROR_3012: "Giá nằm ngoài giới hạn cho phép (ES3012)",
  ERROR_3013: "Bước giá không hợp lệ (ES3013)",
  ERROR_3014: "Khối lượng lệnh đặt không hợp lệ (ES3014)",
  ERROR_3015: "Thị trường đang tạm ngừng giao dịch (ES3015)",
  ERROR_3016: "Sai định dạng của giá trong lệnh thoả thuận (ES3016)",
  ERROR_3017:
    "Khối lượng trong lệnh đặt thường vượt quá quy định giao dịch (ES3017)",
  ERROR_3018: "Khối lượng trong lệnh thỏa thuận không hợp lệ (ES3018)",
  ERROR_3019: "Không được hủy lệnh đã khớp (ES3019)",
  ERROR_3020: "Không được hủy lệnh đã hủy (ES3020)",
  ERROR_3022: "Không đủ số dư để sửa giá (ES3022)",
  ERROR_3023: "Đang có lệnh ATC trái chiều còn hiệu lực trên hệ thống (ES3023)",
  ERROR_3024:
    "Tài khoản đang có lệnh ATC chờ vào hệ thống, Khách hàng vui lòng hủy lệnh ATC đang chờ để nhập lệnh ngược chiều trong phiên liên tục. (ES3024)",
  ERROR_3025:
    "Không được vừa mua vừa bán cùng một mức giá khi lệnh ngược chiều chưa khớp toàn bộ. (ES3025)",
  ERROR_3026:
    "Không được phép vừa mua vừa bán cùng 1 mã chứng khoán trong phiên định kỳ (ES3026)",
  ERROR_3030: "Lệnh đang chờ xác nhận không được Hủy, Sửa (ES3030)",
  ERROR_3091: "Không được phép sửa/hủy lệnh này (ES3091)",
  ERROR_4001: "Số dư tài khoản tiền không đủ để giao dịch (ES4001)",
  ERROR_4002: "Lặp số dư tiền (ES4002)",
  ERROR_4003: "Không đủ số dư giao dịch (ES4003)",
  ERROR_4004:
    "Giao dịch chạm Hạn mức Dịch vụ (ES4004). Quý Khách vui lòng chuyển tiền sang tài khoản đuôi 1 để giao dịch.",
  ERROR_4005: "Không được phép giao dịch vượt hạn mức hợp đồng (ES4005)",
  ERROR_4006:
    "Không được phép giao dịch vượt hạn mức tín dụng hàng ngày (ES4006)",
  ERROR_4007: "Không được phép mua vượt tỷ lệ ký quỹ duy trì (ES4007)",
  ERROR_4008: "Mã CK đặc biệt. Vượt hạn mức dịch vụ (ES4008)",
  ERROR_4444: "Thao tác không được phép thực hiện (ES4444)",
  ERROR_4445:
    "Có hiện tượng lệnh bị lặp, hệ thống đã ngăn chặn, hãy kiểm tra lại (ES4445)",
  ERROR_5001: "Không được phép giao dịch vượt quá số dư chứng khoán (ES5001)",
  ERROR_5003: "Không được phép giao dịch vượt quá số dư chứng khoán (ES5003)",
  ERROR_5005: "Không đủ tiền mặt để mua mã Chứng khoán Margin (ES5005)",
  ERROR_5006: "Mã chứng khoán không thuộc danh mục Margin (ES5006)",
  ERROR_5007:
    "Không đủ tiền mặt để mua mã chứng khoán ngoài danh mục Margin (ES5007)",
  ERROR_5009: "Không được phép giao dịch vượt quá hạn mức FAL (ES5009)",
  ERROR_5010: "Chứng khoán không thuộc danh mục chứng khoán duyệt FAL (ES5010)",
  ERROR_5011: "TK bị hạn chế giao dịch đối với mã CK (ES5011)",
  ERROR_5012: "Không được phép giao dịch vượt tỷ lệ sau mua FAL (ES5012)",
  ERROR_5013: "Vượt hạn mức duyệt theo mã chứng khoán (ES5013)",
  ERROR_5014: "Vượt hạn mức FAL dịch vụ (ES5014)",
  ERROR_5015: "Vượt hạn mức FAL của MBS (ES5015)",
  ERROR_5016: "Vượt hạn mức duyệt FAL hằng ngày. Lệnh đang chờ duyệt (ES5016)",
  ERROR_5017: "Vượt hạn mức duyệt FAL. Lệnh đang chờ duyệt (ES5017)",
  ERROR_6010: "Mã xác thực không hợp lệ",
  ERROR_6776: "Lệnh MP chưa có lệnh đối ứng (ES6776)",
  ERROR_6777: "Tài khoản chưa đăng nhập (ES6777)",
  ERROR_6778: "Mã xác thực không đúng (ES6778)",
  ERROR_6779: "Người dùng không có quyền (ES6779)",
  ERROR_6780: "Mã chứng khoán không tồn tại (ES6780)",
  ERROR_6781: "Chứng khoán ngừng giao dịch (ES6781)",
  ERROR_6782: "Chứng khoán không được phép giao dịch (ES6782)",
  ERROR_6783: "Khối lượng không hợp lệ (ES6783)",
  ERROR_6784: "Đơn vị khối lượng không hợp lệ (ES6784)",
  ERROR_6786: "Không được phép đặt lệnh ngoài giờ (ES6786)",
  ERROR_6787: "Không được phép đặt lệnh ATC (ES6787)",
  ERROR_6788: "Không được phép đặt lệnh MP (ES6788)",
  ERROR_6789: "Giá lớn hơn giá trần (ES6789)",
  ERROR_6790: "Giá nhỏ hơn giá sàn (ES6790)",
  ERROR_6791: "Khối lượng không hợp lệ (ES6791)",
  ERROR_6792: "Lỗi không tìm thấy dữ liệu (ES6792)",
  ERROR_6793: "Thị trường đang đóng cửa chưa được phép đặt lệnh (ES6793)",
  ERROR_6795: "Chứng khoán đã bị ngừng giao dịch (ES6795)",
  ERROR_6796:
    "Gửi lệnh không thành công: Mã CK đã hết room dành cho NĐTNN (ES6796)",
  ERROR_6800: "Không được đặt lệnh PLO khi mã chưa có khớp lệnh (ES6800)",
  ERROR_6801: "Không được đặt lệnh PLO đối với sàn HSX/Upcom (ES6801)",
  ERROR_6802: "Đơn vị khối lượng không hợp lệ (ES6802)",
  ERROR_6901: "Tài khoàn này không được phép mua (ES6901)",
  ERROR_6902: "Tài khỏan này không được phép bán (ES6902)",
  ERROR_6998: "Chưa được phép đặt lệnh ngoài giờ (ES6998)",
  ERROR_6999: "Kênh đặt lệnh này đã bị khóa (ES6999)",
  ERROR_9001: "Lệnh này hệ thống chưa hỗ trợ (ES9001)",
  ERROR_9007: "Nghiệp vụ này chưa hỗ trợ (ES9007)",
  ERROR_9566: "Không được phép sửa lệnh này (ES9566)",
  ERROR_9900: "Lệnh chưa có xác nhận từ sở giao dịch (ES9900)",
  ERROR_9901: "Lệnh này hệ thống chưa hỗ trợ (ES9901)",
  ERROR_999: "Lỗi không xác định (ES999)",
  ERROR_9991: "Khối lượng không hợp lệ (ES9991)",
  ERROR_9999: "Lỗi cơ sở dữ liệu (ES9999)",
  ERROR7: "PIN không hợp lệ!",
  ERROR6605: "Mật khẩu không đúng",
  ERROR1001: "Tài khoản không tồn tại",
  ERROR249: "Quý khách chưa nhập mã PIN để xác nhận",
  ERROR250: "Hạn mức yêu cầu mới không được phép lớn hơn hạn mức tối đa",
  ERROR251: "Hạn mức yêu cầu mới không được phép nhỏ hơn hạn mức tối thiểu",
  ERROR252: "Giá trị hạn mức mới phải khác giá trị hạn mức hiện tại",
  ERROR253: "",
  ERROR254:
    "Hợp đồng margin chưa được duyệt, Quý khách không thể thực hiện thay đổi hạn mức!",
  ERROR_8001: "Không có quyền truy nhập A/O",
  ERROR_8003: "Chi nhánh không tồn tại",
  ERROR_8004: "PGD không tồn tại",
  ERROROLS0000: "Thành Công ",
  ERROROLS0001: "Xử lý thành công, dữ liệu rỗng ",
  ERROROLS0002: "Lỗi hệ thống ",
  ERROROLS0003: "Không tìm thấy yêu cầu ",
  ERROROLS0004: "Lỗi xử lý dữ liệu ",
  ERROROLS0005:
    "Quyền truy cập lỗi. Kiểm tra trạng thái đăng ký dịch vụ hoặc liên hệ hỗ trợ ",
  ERROROLS0006: "Lỗi dữ liệu ",
  ERROROLS0011: "Sai tài khoản hoặc mật khẩu ",
  ERROROLS0012: "Chưa đăng nhập hoặc đã hết phiên làm việc ",
  ERROROLS0013: "Mật khẩu cũ sai ",
  ERROROLS0014: "Mã an toàn không hợp lệ ",
  ERROROLS0015: "Lỗi session ",
  ERROROLS0016: "Mật khẩu hết hạn, bạn phải thay đổi mật khẩu ",
  ERROROLS0017: "Thay đổi mật khẩu ",
  ERROROLS0018: "Tải khoản của bạn đang bị khóa giao dịch trực tuyến ",
  ERROROLS0019: "Tài khoản không xác định ",
  ERROROLS0020: "Lỗi số tiền rút không được lớn hơn số tiền có thể rút ",
  ERROROLS0021: "Lỗi số tiền rút không hợp lệ ",
  ERROROLS0022: "Tài khoản %s bị khóa giao dịch %s",
  ERROROLS0023: "Mã PIN không hợp lệ ",
  ERROROLS0024: "Tài khoản chưa đăng ký giao dịch trực tuyến ",
  ERROROLS0025: "Email không hợp lệ ",
  ERROROLS0026: "Số điện thoại không hợp lệ ",
  ERROROLS0027: "Lỗi giá ngoài biên độ trần, sàn",
  ERROROLS0028: "Ngoài giờ giao dịch tiền ",
  ERROROLS0036: "Giá kích hoạt phải nhỏ hơn giá thị trường ",
  ERRORDHSIEF0106: "Giá kích hoạt phải lớn hơn giá thị trường",
  ERRORHSIEF0009: "Mã phái sinh không hợp lệ",
  ERRORHSIEF0025: "Không cho phép hủy lệnh",
  ERRORHSIEF0033: "Tài khoản đang chờ VSD duyệt",
  ERRORHSIEF0035:
    "Dịch vụ Giao dịch điện tử đã bị khóa. Vui lòng liên hệ tổng đài 19009088 hoặc Nhân viên chăm sóc",
  ERRORHSIEF0039: "Khối lượng sửa không hợp lệ",
  ERRORHSIEF0088: "Khối lượng đặt vượt quá giới hạn thị trường",
  ERRORHSIEF0093: "Vượt giới hạn vị thế của tài khoản",
  ERRORHSIEF0106: "Giá kích hoạt không hợp lệ ",
  ERRORHSIEF0114: "Thị trường không hợp lệ",
  ERRORHSIEF0154: "Giá cao hơn giá trần",
  ERRORHSIEF0155: "Giá thấp hơn giá sàn",
  ERRORHSIEF0161: "Tiền ký quỹ không đủ để thực hiện giao dịch",
  ERRORHSIEF0162: "Tiền có thể nộp ký quỹ không đủ",
  ERRORHSIEF0163: "Sai bước giá",
  ERRORHSIEF0173: "Mã hợp đồng không tồn tại",
  ERRORHSIEF0178: "Lệnh sửa không thành công. Giá và khối lượng đặt không đổi",
  ERRORHSIE90196: "Số tiền có thể nộp không hợp lệ",
  ERRORHSIE90197: "Số tiền có thể rút không hợp lệ",
  ERROR001: "Trả ra khi mã số xác thực của client gửi lên không khớp",
  ERROR002: "Mẫu template gửi SMS OTP chưa khai báo",
  ERROR003: "Thông tin người dùng không hợp lệ",
  ERROR004: "Mã thiết bị không hợp lệ",
  ERROR005: "Mã SMS OTP không hợp lệ",
  ERROR006: "Không tìm thấy thông tin thiết bị (NEW)",
  ERROR007: "Không tìm thấy thông tin SMS Otp để thực hiện xác thực",
  ERROR008: "Mã SMS OTP đã hết hạn",
  ERROR009: "Mã xác thực chưa chính xác",
  ERROR010: "Mã PIN không hợp lệ",
  ERROR011: "Không tìm thấy thông tin thiết bị (VERIFY)",
  ERROR012: "Không tìm thấy thông tin thiết bị (ACTIVE)",
  ERROR013: "Mã PIN không đúng",
  error_message_default:
    "Có lỗi kết nối đến server, Quý khách vui lòng thử lại",
  ERRORMSG2001: "Mã xác thực không hợp lệ",
  ERRORMSG2002: "Mẫu SMS OTP không hợp lệ",
  ERRORMSG2003: "UserId không hợp lệ",
  ERRORMSG2004: "Id thiết bị không hợp lệ",
  ERRORMSG2005: "SMS OTP không hợp lệ",
  ERRORMSG2006: "Không tìm thấy thiết bị",
  ERRORMSG2007: "SMS OTP không tìm thấy",
  ERRORMSG2008: "Mã xác thực đã hết hạn",
  ERRORMSG2009: "Mã xác thực chưa chính xác",
  ERRORMSG2010: "Mã pin không hợp lệ",
  ERRORMSG2011: "Không tìm thấy thiết bị (VERIFY)",
  ERRORMSG2012: "Không tìm thấy thiết bị (ACTIVE)",
  ERRORMSG2013: "PinCode không chính xác",
  ERRORMSG2014: "Smart OTP không chính xác",
  ERROR_1011:
    "Vượt quá 30 ngày. Quý KH chọn Từ ngày Đến ngày tương ứng 30 ngày trong bất kỳ thời gian nào.",
  ERROR_1012: "Không có ngày giao dịch bán hợp lệ",
  ERROR_8101: "Token đã hết thời gian chờ",
  ERROR_8102: "Token không hợp lệ",
  ERRORHSIEF0066: "Giá đặt không hợp lệ",
  ERROR999202: "Hệ thống tạm ngưng phục vụ",
  ERROR1: "Không thể kết nối được với Server!",
  ERROR4: "Không thể cập nhật TRANSACTION LOG.",
  ERROR5: "Tài khoản thụ hưởng đã tồn tại.",
  ERROR6:
    "Hệ thống đang tạm dừng cung cấp dịch vụ. Quí khách vui lòng quay lại sau ít phút nữa.",
  ERROR8: "Tài khoản không tồn tại hoặc ĐÃ ĐÓNG.",
  ERROR9: "Tài khoản CHƯA DUYỆT hoặc ĐANG SỬA ĐỔI.",
  ERROR10: "Tài khoản ĐÃ ĐÓNG.",
  ERROR11: "Tài khoản không có quyền thực hiện chức năng này.",
  ERROR12: "Tài khoản chuyển tiền không thuộc danh sách TK ủy quyền.",
  ERROR13: "Tài khoản nhận không thuộc danh sách TK thụ hưởng.",
  ERROR14: "Đã ngoài giờ giao dịch. Bạn không thể thực hiện.",
  ERROR15: "Tài khoản không được ủy quyền chuyển tiền.",
  ERROR16: "Chủ tài khoản không có quyền thực hiện do đã Ủy quyền.",
  ERROR17: "CMT/Hộ chiếu/Passport/ĐKKD đã hết hạn sử dụng.",
  ERROR18: "Số dư tiền gửi không đủ.",
  ERROR19: "Tài khoản nhận và tài khoản chuyển không được giống nhau.",
  ERROR20: "Bút toán không ở trạng thái CHƯA XỬ LÝ, nên bạn không thể sửa.",
  ERROR21: "Tài khoản đang bị hạn chế chuyển/rút tiền.",
  ERROR22: "Tài khoản đang bị hạn chế ứng trước tiền bán.",
  ERROR23: "Lỗi nghiệp vụ.",
  ERROR24: "Lỗi khi cập nhật ứng.",
  ERROR25: "Lỗi khi duyệt ứng.",
  ERROR26: "Đã ngoài thời gian giao dịch.",
  ERROR27: "Không được nhỏ hơn số tiền tối thiểu.",
  ERROR28: "Không được quá số tiền tối đa.",
  ERROR29: "Đã quá số lần chuyển trong 1 ngày.",
  ERROR30: "Đã quá số tiền chuyển tối đa trong 1 ngày.",
  ERROR31: "Tài khoản margin không nợ lãi margin",
  ERROR32: "Dịch vụ BCC+ không thực hiện giải ngân sau 16h và ngày nghỉ.",
  ERROR33:
    "Đã ngắt kết nối online, mời bạn sử dụng chức năng 'Chuyển tiền ra ngoài'.",
  ERROR34:
    "Hệ thống tạm dừng phục vụ, Quý khách vui lòng quay lại sau ít phút nữa.",
  ERROR35:
    "Hệ thống tạm dừng phục vụ, Quý khách vui lòng quay lại sau ít phút nữa.",
  ERROR36:
    "Đã ngắt kết nối online, mời bạn sử dụng chức năng 'Chuyển tiền ra ngoài'",
  ERROR37:
    "Đã ngắt kết nối online với ngân hàng BIDV, mời bạn lựa chọn ngân hàng liên kết khác.",
  ERROR38:
    "Đã kết nối chuyển tiền online, mời bạn sử dụng chức năng 'Chuyển khoản ra MB'.",
  ERROR39: "Hạn mức giải ngân của MBS đã hết.",
  ERROR40: "Tài khoản đã vượt quá hạn mức cho vay tối đa.",
  ERROR41: "Hợp đồng BCC+ đã bị khóa.",
  ERROR42: "Hồ sơ đã được duyệt.",
  ERROR43: "Không được ứng quá số tiền có thể ứng.",
  ERROR44: "Số dư tài khoản không đủ để thực hiện",
  ERROR45:
    "Nguồn ứng online ngày hôm nay tạm thời hết hạn mức, Quý khách vui lòng thực hiện ứng trực tiếp tại các quầy giao dịch của MBS",
  ERROR46: "Cập nhật nguồn ứng bị lỗi.",
  ERROR47: "Không được đăng ký quá số lượng được mua.",
  ERROR48: "Đã ngoài thời gian đăng ký mua.",
  ERROR49: "Mỗi CK chỉ được thiết lập một cảnh báo.",
  ERROR50:
    "Xin vui lòng liên hệ với các Phòng MG (PCS) để làm thủ tục chuyển tiền do bạn đang sử dụng dịch vụ tài chính của MBS.",
  ERROR51: "Mã chứng khoán không tồn tại.",
  ERROR52: "Tài khoản không hợp lệ.",
  ERROR53: "Tài khoản chuyển CK không hợp lệ.",
  ERROR54: "Tài khoản nhận CK không hợp lệ.",
  ERROR55: "Tài khoản chuyển và tài khoản nhận không hợp lệ.",
  ERROR56: "Nội dung không được viết tiếng Việt có dấu",
  ERROR57: "Cập nhật chuyển khoản CK bị lỗi.",
  ERROR58: "Duyệt chuyển khoản CK bị lỗi.",
  ERROR60: "Tài khoản chuyển còn dư nợ margin/BCC+",
  ERROR62: "Tài khoản chuyển còn dư nợ margin+",
  ERROR63: "Không được chuyển khoản phần cổ phiếu lẻ",
  ERROR64: "Tài khoản nhận không nằm trong danh sách tài khoản đã đăng ký",
  ERROR65: "Không được cập nhật TK thụ hưởng khi sử dụng chuyển khoản hạn chế",
  ERROR66: "Tài khoản sức mua ứng trước không được ứng khi chưa import lệnh.",
  ERROR67: "Không thể kết nối tới Ngân hàng. Đề nghị kiểm tra lại bút toán",
  ERROR68: "Không cho phép thêm mới tài khoản NH thụ hưởng",
  ERROR69: "Chuyển tiền qua liên kết BIDV không thành công",
  ERROR70:
    "Chuyển khoản không thành công: hệ thống mất kết nối với liên kết BIDV, khách hàng vui lòng chọn ngân hàng liên kết khác",
  ERROR71: "Tên tài khoản nhận không hợp lệ. Vui lòng kiểm tra lại.",
  ERROR72: "Vượt hạn mức tối đa một lần ứng online",
  ERROR73: "Không thể ứng online nguồn ngân hàng tại ngày T+2.",
  ERROR74: "Hạn mức ứng ngân hàng đã hết",
  ERROR75: "Hạn mức hợp đồng đã hết",
  ERROR76: "",
  ERROR77: "Thời gian chuyển tiền 2 lần liên tiếp quá gần nhau",
  ERROR78: "Đã có lỗi xảy ra, vui lòng kiểm tra lại",
  ERROR79: "",
  ERROR80: "",
  ERROR81: "",
  ERROR82: "",
  ERROR83: "",
  ERROR84: "",
  ERROR85: "",
  ERROR86: "",
  ERROR87: "",
  ERROR88:
    "Hệ thống đang tạm dừng cung cấp dịch vụ. Quí khách vui lòng quay lại sau ít phút nữa.",
  ERROR89: "Tài khoản nhận không tồn tại hoặc ĐÃ ĐÓNG.",
  ERROR99: "Bạn không thể thực hiện. Hãy THOÁT và đăng nhập lại.",
  ERROR800001: "Thông tin không hợp lệ",
  ERROR800002: "Đổi mật khẩu không thành công, mật khẩu cũ không hợp lệ",
  ERROR262: "Tài khoản MB không tồn tại",
  ERROR263: "Tài khoản MB đã bị khóa",
  ERROR264: "Tài khoản MB đã bị đóng",
  ERROR265: "Lý do khác từ MB",
  ERROR266: "Đang có sự cố xảy ra kết nối với MB",
  ERROR98: "Hệ thống MBS không kết nối được",
  ERROR268:
    "Số Tài khoản không đúng với tên chủ Tài khoản. Quý khách vui lòng nhập lại thông tin!",
  ERROR_999899: "Hệ thống đang tạm ngưng nhận lệnh",
  ERROR802001:
    "Tiện ích được thực hiện trong thời gian 08:30:00 đến 15:45:00 các ngày làm việc",
  ERROR802002: "Đã ngoài giờ giao dịch. Bạn không thể thực hiện chuyển tiền",
  ERROR907100:
    "Hợp đồng margin chưa được duyệt/đang sửa đổi, quý khách không thể thực hiện thay đổi hạn mức!",
  ERROR907101: "Đã có lỗi phát sinh",
  ERROR907102: "Đã có lỗi phát sinh",
  ERROR907103: "Đã có lỗi phát sinh",
  ERROR907104: "Đã có lỗi phát sinh",
  ERROR907105: "Đã có lỗi phát sinh",
  ERROR907106: "Đã có lỗi phát sinh",
  ERROR907107:
    "ID nhân viên chăm sóc không hợp lệ. Vui lòng liên hệ tổng đài 1900 9088 để được hướng dẫn",
  ERROR907108: "",
  ERROR907109: "Đã ngoài thời gian cho phép thực hiện chức năng này",
  ERROR907110: "",
  ERROR907111: "",
  ERROR907112: "Ngày gia hạn mới không được nhỏ hơn ngày hiện tại!",
  ERROR907113: "Ngày gia hạn mới không được vào ngày nghỉ!",
  ERROR907114: "Lãi suất gia hạn không hợp lệ!",
  ERROR907115: "Khoản vay hiện tại đang bị chặn gia hạn!",
  ERROR907116: "Tài khoản hiện tại đang bị chặn gia hạn tất cả các khoản vay!",
  ERROR907117: "Không được gia hạn cho khoản vay theo lãi bậc thang!",
  ERROR907118:
    "Số ngày gia hạn vượt quá số ngày gia hạn tối đa cho phép, nếu quý KH có nhu cầu gia hạn thì quý KH vui lòng gửi yêu cầu gia hạn bằng đề nghị văn bản tới MBS để thẩm định!",
  ERROR907119:
    "Số ngày gia hạn vượt quá số ngày gia hạn online tối đa cho phép, nếu quý KH có nhu cầu gia hạn thì quý KH vui lòng gửi yêu cầu gia hạn bằng đề nghị văn bản tới MBS để thẩm định!",
  ERROR907120: "Không được gia hạn cho khoản vay có dự nợ ngoài hạn!",
  ERROR907121:
    "Món vay đang có trạng thái CHƯA KIỂM SOÁT, quý khách vui lòng liên hệ nhân viên chăm sóc hoặc Trung tâm chăm sóc KH (1900 9088) để được hỗ trợ và giải đáp",
  ERROR907122:
    "Món vay đang có trạng thái CHƯA DUYỆT, quý khách vui lòng liên hệ nhân viên chăm sóc hoặc Trung tâm chăm sóc KH (1900 9088) để được hỗ trợ và giải đáp",
  ERROR907124: "Hạn mức yêu cầu mới không được phép nhỏ hơn hạn mức tối thiểu",
  ERROR907125: "Hạn mức yêu cầu giống hạn mức hiện tại",
  ERROR907126:
    "Hợp đồng margin chưa được duyệt, quý khách không thể thực hiện thay đổi hạn mức!",
  ERROR907123: "Hạn mức yêu cầu mới không được phép lớn hơn hạn mức tối đa",
  ERROR802039:
    "Quý khách chỉ được thực hiện quyền online 1 lần/ngày cho 1 mã CK",
  ERROR802038: "Tài khoản chuyển tiền không tồn tại.",
  ERROR802037: "Tài khoản không được ủy quyền chuyển tiền.",
  ERROR802036: "ACCOUNT_NOT_APPROVE",
  ERROR802035: "ACCOUNT_CLOSE",
  ERROR802034: "Tài khoản chưa duyệt/không tồn tại hoặc ĐÃ ĐÓNG.",
  ERROR802033: "Số dư tiền gửi không đủ.",
  ERROR802032: "Đã ngoài thời gian đăng ký mua",
  ERROR802031: "Không được đăng ký quá số lượng được mua.",
  ERROR802030: "Tài khoản đang bị hạn chế chuyển tiền.",
  ERROR802029: "Tài khoản chuyển tiền không thuộc danh sách TK ủy quyền.",
  ERROR802028: "Tài khoản không có quyền thực hiện chức năng này.",
  ERROR802027: "BCC_PLUS_CONTRACT_BLOCK",
  ERROR802026: "OVER_CUSTOMER_CREDIT",
  ERROR802025: "OVER_TOTAL_VVF_CREDIT",
  ERROR802000: "Khối lượng chuyển vượt quá Số tối đa có thể chuyển",
  ERROR802003: "CODE_ERROR_GET_SHARE_TRANSFER_STATUS",
  ERROR802004: "CODE_ERROR_GET_SHARE_TRANSFER_STATUS_HISTORY",
  ERROR802005: "CODE_ERROR_GET_RIGHT_REGISTER",
  ERROR802006: "CODE_ERROR_GET_RIGHT_REGISTER_HISTORY",
  ERROR802007: "Đã có lỗi xảy ra. Vui lòng thử lại",
  ERROR802008: "",
  ERROR802009: "",
  ERROR802010: "Trạng thái chứng khoán không hợp lệ",
  ERROR802011: "Tài khoản chuyển là TK BCC hoặc TK còn dư nợ BCC",
  ERROR802012: "Tài khoản chuyển CK không hợp lệ.",
  ERROR802013: "Tài khoản nhận CK không hợp lệ.",
  ERROR802014: "Tài khoản chuyển và tài khoản nhận không hợp lệ",
  ERROR802015: "Tài khoản chuyển còn dư nợ margin+",
  ERROR802016: "Tài khoản chuyển còn dư nợ margin/BCC+",
  ERROR802017: "Mã chứng khoán không tồn tại.",
  ERROR802018: "Không được chuyển khoản phần cổ phiếu lẻ",
  ERROR802020: "Bạn không thể thực hiện. Hãy THOÁT và đăng nhập lại.",
  ERROR802021:
    "Xin vui lòng liên hệ với các Phòng MG (PCS) để làm thủ tục chuyển tiền do bạn đang sử dụng dịch vụ tài chính của MBS.",
  ERROR802022: "Hệ thống đang khớp lệnh. Bạn không thể thực hiện.",
  ERROR802023: "Đã ngoài giờ giao dịch. Bạn không thể thực hiện.",
  ERROR802024: "Hệ thống đã bị KHÓA. Bạn không thể thực hiện.",
  ERROR802050: "Tài khoản chưa đăng ký dịch vụ xác nhận lệnh online.",
  ERROR802040: "Đã có lỗi phát sinh",
  ERROR802041: "Đã có lỗi phát sinh",
  ERROR802042: "Đã có lỗi phát sinh",
  ERROR802043: "CMT/Hộ chiếu đã quá hạn (15 năm)",
  ERROR802044: "Hồ sơ đã được duyệt.",
  ERROR802045: "Không thể cập nhật TRANSACTION LOG.",
  ERROR802046:
    "Mã CK còn số dư lô lẻ trên các trạng thái khác. Quý khách vui lòng chuyển chứng khoán về trạng thái Thường trước khi thực hiện.",
  ERROR802047:
    "Không thể bán cổ phiếu đã bị hủy niêm yết hoặc thuộc diện kiểm soát.",
  ERROR802048:
    "Mã CK còn số dư lô lẻ trên các tiểu khoản khác. Quý khách vui lòng chuyển về tiểu khoản thường (tiểu khoản đuôi 1) trước khi thực hiện.",
  ERROR802049:
    "Quý khách có lệnh bán CP lẻ khác đang được xử lý. Quý khách vui lòng liên hệ nhân viên chăm sóc hoặc tổng đài để được hướng dẫn.",
  ERROR802051: "Tài khoản chưa duyệt đăng ký dịch vụ xác nhận lệnh online",
  error_switch_stock: "TK {} chuyển trạng thái",
  error_switch_tranfer: "TK {} chuyển khoản sang {}",
  ERROR802052: "Tài khoản phát sinh lệnh FAL. Không cho phép thực hiện",
  ERROR802053: "Tài khoản còn dư nợ FAL. Không cho phép thực hiện",
  ERROR802054: "Khối lượng chuyển vượt qua số tối đa có thể chuyển ",
  ERROR802055: "Chỉ được chuyển khoản giữa các tài khoản của một khách hàng",
  ERROR802056: "Không cho phép với trạng thái nhận này",
  ERROR802057: "Không cho phép chuyển giữa 2 trạng thái này",
  ERROR802058: "Chỉ được chuyển khoản chứng khoán trạng thái 02, 60, 61, 68",
  ERROR802059:
    "Các tiểu khoản dịch vụ chỉ được chuyển các trạng thái 02, 60, 61, 68",
  ERROR802060: "Mã chứng khoán hết room",
  ERROR907139: "Mã nhân viên giới thiệu không phải nhân viên tư vấn",
  ERROR907130: "Tài khoản chưa duyệt/không tồn tại hoặc ĐÃ ĐÓNG.",
  ERROR907127: "Bạn không thể thực hiện. Hãy THOÁT và đăng nhập lại.",
  ERROR907128: "Hệ thống đã bị KHÓA. Bạn không thể thực hiện.",
  ERROR907129: "Tài khoản không có quyền thực hiện chức năng này.",
  ERROR907131: "Tài khoản chuyển tiền không thuộc danh sách TK ủy quyền.",
  ERROR907132:
    "Tài khoản đang còn sử dụng dịch vụ BCC/T+. Bạn không thể thực hiện.",
  ERROR907133:
    "Tài khoản không được ủy quyền toàn phần, bạn không thể thực hiện",
  ERROR907134: "Tài khoản đã có hợp đồng khung. Bạn không thể thực hiện.",
  ERROR907135: "Hợp đồng  này đã thanh lý không thể sửa đổi",
  ERROR907137: "Chưa chọn kỳ hạn auto",
  ERROR907138: "kỳ hạn lựa chọn không tồn tại hoặc không được phép lựa chọn",
  ERRORHSIEF0185: "Giá điều kiện không hợp lệ",
  ERROR802061: "Số dư chứng khoán không đủ",
  ERROR802062: "Tài khoản MB không tồn tại",
  ERROR802063: "MB account is locked",
  ERROR802064: "Tài khoản MB đã bị đóng",
  ERROR802065: "Lý do khác từ MB",
  ERROR802066: "Đang có sự cố xảy ra kết nối với MB",
  ERROR802067: "Hệ thống MBS không kết nối được",
  ERROR802068:
    "Số Tài khoản không đúng với tên chủ Tài khoản. Quý khách vui lòng nhập lại thông tin!",
  ERROR905100: "Đã có lỗi phát sinh",
  ERROR905101: "Loại KH không hợp lệ",
  ERROR905102: "Loại KH không hợp lệ",
  ERROR905103: "Khách hàng đã tồn tại",
  ERROR905104: "Tài khoản đã tồn tại",
  ERROR905105: "Tài khoản không hợp lệ",
  ERROR905106: "Dải số tài khoản được cấp đã hết",
  ERROR905107: "Tài khoản không thể cập nhật",
  ERROR905108: "Lỗi nghiệp vụ khi cập nhật dữ liệu",
  ERROR905109: "Số TK đã được sử dụng",
  ERROR905110: "Người dùng không thể thực hiện cập nhật",
  ERROR905111: "Khách hàng không tồn tại",
  ERROR905112: "Tài khoản không tồn tại",
  ERROR905113: "Tài khoản hoặc số điện thoại đã tồn tại liên kết",
  ERROR905114: "Mật khẩu đăng nhập không đúng",
  ERROR905115: "Liên kết tài khoản đã bị hủy hoặc không tồn tại liên kết",
  ERROR905116: "Kích hoạt sự kiện không thành công",
  ERROR905117: "Tài khoản đã liên kết với một số điện thoại khác",
  ERROR905118: "Số điện thoại đã liên kết với một tài khoản khác",
  ERROR905119: "Loại giấy tờ không hợp lệ",
  ERROR905120: "Loại giấy tờ không cho phép ở kênh này",
  ERROR905121: "Kênh nhập không hợp lệ",
  ERROR905122: "Tài khoản tạm khóa",
  ERROR905123: "Tài khoản đã đóng",
  ERROR905124: "Không tìm thấy số tham chiếu này",
  ERROR905125: "Ngoài giờ giao dịch",
  ERROR905126: "Giá trị chuyển khoản sang MBS nhỏ hơn giá trị tối thiểu",
  ERROR905127: "Giá trị chuyển khoản sang MBS lớn hơn giá trị tối đa",
  ERROR905128: "Quý khách chưa đủ 18 tuổi để mở TKCK",
  ERROR905129:
    "ID Nhân viên chăm sóc không hợp lệ. Vui long liên hệ tổng đài 1900 9088  để được hướng dẫn!",
  ERROR905130: "907138",
  ERROR905131:
    "Mã giới thiệu không hợp lệ. Người giới thiệu không đồng thời là Nhân viên quản lý tài khoản hoặc Nhân viên phát triển KH tại MBS!",
  ERROR905132: "Người giới thiệu đã đăng ký!",
  ERROR905133:
    "Mã giới thiệu không hợp lệ. Người giới thiệu không đồng thời là BDS-3!",
  ERROR905134:
    "Mã giới thiệu không hợp lệ. Người giới thiệu phải là khách hàng cá nhân trong nước!",
  ERROR905199: "Lỗi ngoại lệ, lỗi khác",
  ERROR802069: "Mã chứng quyền chưa được duyệt hoặc đã đáo hạn!",
  ERROR900099: "Chức năng đang phát triển",
  ERROR297: "Không thể hủy HĐ sức mua ứng trước",
  error_mqtt_connect: "Lỗi kết nối dữ liệu Realtime",
  ERROR_11000: "Hệ thống đang bị khóa",
  ERROR_11001: "Không tìm thấy thông tin Trái Phiếu",
  ERROR_11002: "Không tìm thấy Lãi suất theo kỳ hạn",
  ERROR_11003: "Không tính được giá MUA trái phiếu",
  ERROR_11004: "Không tìm thấy thông tin Khách hàng",
  ERROR_11005: "Trái phiếu chưa được DUYỆT hoặc ĐÃ_ĐÓNG",
  ERROR_11006: "Không tìm thấy hợp đồng MUA",
  ERROR_11007:
    "Không được phép xóa hợp đồng MUA. Chỉ được phép xóa khi ĐẶT_CHỖ",
  ERROR_11008: "Không duyệt được hợp đồng MUA",
  ERROR_11009: "Chỉ được duyệt hợp đồng khi ngày giao dịch = ngày duyệt",
  ERROR_11010: "Không tìm thấy thông tin liên kết tài khoản",
  ERROR_11011: "Không tìm thấy hợp đồng BÁN",
  ERROR_11012:
    "Không được phép xóa hợp đồng BÁN. Chỉ được phép xóa khi đang CHỜ",
  ERROR_11013: "Tài khoản không đủ số dư",
  ERROR_11014: "Mã Khách hàng không được để trống",
  ERROR_11015: "Mã trái phiếu không được để trống",
  ERROR_11016: "Mã kỳ hạn không được để trống",
  ERROR_11017: "Lãi suất cho vay phải > 0",
  ERROR_11018: "Trái phiếu không tồn tại hoặc CHƯA DUYỆT",
  ERROR_11019: "Số dư trái phiếu không được <0",
  ERROR_11020: "Không tìm thấy mã TRANSACTION",
  ERROR_11021: "Phải có ngày giao dịch hợp đồng",
  ERROR_11022: "Ngày giao dịch hợp đồng không nhỏ hơn ngày hiện tại",
  ERROR_11023: "Không được mua và bán cùng ngày",
  ERROR_11024: "Ngày BÁN không được nhỏ hơn ngày lập hợp đồng",
  ERROR_11025: "Ngày BÁN không lớn hơn ngày hết hạn trái phiếu",
  ERROR_11026: "Ngày BÁN trái phiếu phải <= ngày nhận coupon kỳ cuối",
  ERROR_11027: "Ngày BÁN trái phiếu phải < ngày nhận coupon kỳ cuối",
  ERROR_11028: "Yêu cầu bắt buộc tick box nếu có ngày hoàn thiện hồ sơ",
  ERROR_11029: "Không tìm thấy thông tin Khách hàng MUA",
  ERROR_11030: "Không tìm thấy thông tin khách hàng BÁN",
  ERROR_11031: "Ngày mở hợp đồng MUA không được để trống",
  ERROR_11032: "Ngày mở hợp đồng BÁN không được để trống",
  ERROR_11033: "Ngày đáo hạn hợp đồng MUA không được để trống",
  ERROR_11034: "Mã hợp đồng BÁN đã tồn tại. Hãy thử lại lần sau.",
  ERROR_11035: "Mã hợp đồng MUA đã tồn tại. Hãy thử lại lần sau.g",
  ERROR_11036: "Số lượng trái phiếu BÁN phải > 0",
  ERROR_11037: "Số lượng trái phiếu MUA phải > 0",
  ERROR_11038: "Số dư BÁN không đủ",
  ERROR_11039: "Không tìm thấy thông tin hợp đồng BÁN",
  ERROR_11040: "Không được phép thay đổi thông tin hợp đồng",
  ERROR_11041: "Chưa khai báo Room kỳ hạn",
  ERROR_11042: "Không ghi nhận số âm cho room trái chủ",
  ERROR_11043: "Kênh giao dịch không được để trống",
  ERROR_11044: "Room kênh trái phiếu chưa được định nghĩa",
  ERROR_11045: "Không ghi nhận số âm cho room đặt chỗ",
  ERROR_11046: "Room kỳ hạn đã hết",
  ERROR_11047: "Không tìm thấy room kỳ hạn",
  ERROR_11048: "Không ghi nhận số âm khi unblock",
  ERROR_11049: "Không ghi nhận số âm cho room duyệt mua",
  ERROR_11050: "Không thể unblock",
  ERROR_11051: "Chưa khai báo Room khách hàng",
  ERROR_11052: "Đã hết room trái chủ được phép MUA",
  ERROR_11053: "Không duyệt được hợp đồng BÁN",
  ERROR_11054: "Không xác định được hợp đồng là BÁN BUÔN hay BÁN LẺ",
  ERROR_11055: "Click đúp",
  ERROR_11056: "Ngày MUA không được nhỏ hơn ngày lập hợp đồng",
  ERROR_11057: "Ngày MUA không được thực hiện vào ngày nghỉ/ngày lễ",
  ERROR_11058:
    "Số lượng trái phiếu BÁN lại không lớn hơn số lượng trái phiếu MUA",
  ERROR_11059: "Lãi suất MUA phải > 0",
  ERROR_11060: "Ngày đáo hạn hợp đồng phải lớn hơn ngày giao dịch",
  ERROR_11061: "Ngày đáo hạn hợp đồng pha",
  ERROR_11062: "Ngày đáo hạn hợp đồng phải <= ngày nhận coupon kỳ cuối",
  ERROR_11063: "Ngày đáo hạn hợp đồng  phải < ngày nhận coupon kỳ cuối",
  ERROR_11064: "Ngày đáo hạn hợp đồng  phải < ngày đáo hạn trái phiếu",
  ERROR_11065: "Ngày đáo hạn hợp đồng không được để trống",
  ERROR_11066: "Ngày giao dịch hợp đồng phải nhỏ hơn ngày đáo hạn trái phiếu",
  ERROR_11067: "Ngày giao dịch hợp đồng phải nhỏ hơn ngày nhận coupon kỳ cuối",
  ERROR_11068: "Thông tin trái phiếu chưa được duyệt",
  ERROR_11069: "Ngày mở hợp đồng MUA không được để trống",
  ERROR_11070: "Ngày đặt chỗ chỉ trước 3 ngày giao dịch",
  ERROR_11071: "Mã hợp đồng không được để trống",
  ERROR_11072: "Không được phép hủy hợp đồng",
  ERROR_11073: "Không tìm thấy hợp đồng kênh phân phối",
  ERROR_11074: "hông tìm thấy thông tin kỳ hạn",
  ERROR_11075: "Không tìm thấy biểu lãi suất phù hợp",
  ERROR_11076: "Xảy ra lỗi khi tính giá BÁN",
  ERROR_11077: "Giá trị MUA phải > 0",
  ERROR_11078: "Số lượng MUA phải lớn hơn số lượng tối thiểu theo quy định",
  ERROR_11079: "Chưa thiết lập room để khách hàng có thể bán trái phiếu",
  ERROR_11080: "Đã hết room Khách hàng có thể bán. Hãy thử 1 ngày Bán khác.",
  ERROR_11081: "Không được nhập số âm (<0)",
  ERROR_11082: "Hiện tại không phải ngày giao dịch trái phiếu.",
  ERROR_11083: "Đang ngoài giờ giao dịch trái phiếu",
  ERROR_11084: "Ngày tạo hợp đồng không được để trống",
  ERROR_11085: "Ngày đặt mua/bán tương lai phải lớn hơn ngày hiện tại",
  ERROR_11086: "Vượt quá giá trị giao dịch tối đa trên 1 lần giao dịch",
  ERROR_11087: "Tài khoản nhận tiền không hợp lệ",
  ERROR_11088:
    "Người mua không phải là NĐT chuyên nghiệp hoặc đã hết hạn là NĐT chuyên nghiệp",
  ERROR_11089: "Không thể thay đổi thông tin tk nhận tiền",
  ERROR_11090: "Hợp đồng đang sửa giao dịch, không hỗ trợ giao dich",
  ERROR_11091: "Không tồn tại nhân viên quản lý ",
  ERROR_11092: "Không tồn tại nhân viên tư vấn",
  ERROR_11093:
    "Đã quá thời gian đăng ký BÁN/SỬA BÁN, bạn không thể thực hiện Giao dịch. Vui lòng liên hệ MBS để được hỗ trợ",
  ERROR_11100: "Xác thực OTP chưa chính xác",
  ERROR_11101: "Chưa thiết lập OTP",
  ERROR_11102: "Lỗi chưa xác định",
  error0_cancel_sell:
    "Hợp đồng đang sửa đổi, Quý Khách chưa thể thực hiện giao dịch",
  error_1_cancel_sell:
    "Hợp đồng đang được cấp Giấy chứng nhận sở hữu, Quý Khách chưa thể thực hiện giao dịch",
  ERROR_11103:
    "Hợp đồng đang được cấp Giấy chứng nhận sở hữu, Quý Khách chưa thể thực hiện giao dịch.",
  ERROR258: "Chưa đến thời gian đăng ký trong ngày",
  ERROR259: "Hết thời gian đăng ký trong ngày",
  ERROR260: "Số lượng đăng ký nhỏ hơn số lượng đăng ký mua tối thiểu",
  ERROR261: "Số lượng đăng ký lớn hơn số lượng đăng ký mua tối đa",
  ERROR298: "Bạn đang thao tác quá nhanh. Vui lòng thử lại sau 30s",
  ERROR1080: "Lệnh đặt không hợp lệ",
  ERROR100003: "(100003) Ngày có hiệu lực lớn hơn ngày hết hạn",
  ERRORFOSEO207: "Vi phạm cảnh báo vị thế",
  ERRORPRO_INVESTOR_ACCOUNT_NOT_FOUND: "Không tìm thấy tài khoản",
  ERRORPRO_INVESTOR_REGISTED: "Đã đăng ký làm NDT chuyên nghiệp",
  ERRORPRO_INVESTOR_INVALID_MBS_SHARE_ROOM: "Hết room TP MBS",
  ERRORPRO_INVESTOR_INVALID_INFO:
    "Giao dịch không thành công, đề nghị thực hiện lại",
  ERRORPRO_INVESTOR_INVALID_VOL:
    "Giao dịch không thành công, khối lượng không hợp lệ",
  ERRORPRO_INVESTOR_CASH_NOT_ENOUGH: "Tiền mặt không đủ mua",
  ERRORPRO_INVESTOR_SET_MBS_SHARE_ROOM_ERROR: "Block room TP MBS lỗi",
  ERRORPRO_IVT_CASH_BLOCK_ERROR: "Block tiền của khách hàng lỗi",
  ERROR_1014:
    "Vượt quá 180 ngày. Quý KH chọn Từ ngày Đến ngày tương ứng 180 ngày trong bất kỳ thời gian nào.",
  ERROR200045:
    "Tài khoản đang có sao chép. Vui lòng dừng sao chép trước khi đóng Copier.",
  ERROR200021: "Tài khoản leader đã đóng. Quý khách vui lòng không nộp tiền.",
  ERROR200001: "Tài khoản đã đăng ký leader",
  ERROR200002: "Quý khách vui lòng nhập tài khoản",
  ERROR200003: "Không xác định giá trị tối thiểu NAV (CS_LEAD_NAV_MIN_VALUE)",
  ERROR200004:
    "Không xác định số tháng tối thiểu đã giao dịch (CS_LEAD_TRADE_MONTH_NO)",
  ERROR200005:
    "Không xác định số tháng xét hiệu quả đầu tư (CS_LEAD_TRADINGDIARY_MONTH_NO)",
  ERROR200006:
    "Không xác định tỷ lệ hiệu quả đầu tư tối thiểu (CS_LEAD_TRADINGDIARY_RATE)",
  ERROR200007: "Không xác định điểm số danh mục (CS_LEAD_PORTFOLIO_POINT)",
  ERROR200008: "Không xác định Mức độ rủi ro (CS_LEAD_RISK)",
  ERROR200009: "Giá trị đầu tư NAV phải lớn hơn 0",
  ERROR200010: "Số tháng giao dịch phải lớn hơn 0",
  ERROR200011: "Không được để trống điểm số danh mục",
  ERROR200012: "Không được để trống Tên tài khoản",
  ERROR200013: "Tài khoản đã đăng ký Copier",
  ERROR200014: "Không được để trống Tài khoản master leader",
  ERROR200015: "Không được để trống Tài khoản leader",
  ERROR200016: "Không được để trống Tên tài khoản leader",
  ERROR200017: "Không được để trống Tài khoản",
  ERROR200018: "Không được để trống Tiểu khoản",
  ERROR200019: "Không được để trống Tên tài khoản",
  ERROR200020: "Tài khoản đã theo dõi",
  ERROR200022: "Tài khoản  không theo dõi tài khoản",
  ERROR200023: "Tài khoản Copier không có hiệu lực",
  ERROR200024: "Tài khoản đang copy tài khoản",
  ERROR200025: "Tỷ lệ dừng lỗ phải <10000",
  ERROR200026: "Tỷ lệ chốt lãi phải <10000",
  ERROR200027: "Tài khoản đã dừng sao chép hoặc đang xử lý dừng sao chép",
  ERROR200028: "Có nhiều hơn 1 cặp copier - leader",
  ERROR200029: "Không tìm thấy thông tin tài khoản tiền",
  ERROR200030: "Không tìm thấy thông tin tài khoản chứng khoán",
  ERROR200031: "Số tiền phải là số nguyên",
  ERROR200032: "Không xác định số tháng lên biểu đồ NAV (NAV_CHART_DURATION)",
  ERROR200033: "Tài khoản không đáp ứng Điểm số danh mục",
  ERROR200034: "Tài khoản không đáp ứng Giá trị NAV tối thiểu",
  ERROR200035: "Tài khoản không đáp ứng Số tháng giao dịch tối thiểu",
  ERROR200036: "Tài khoản không đáp ứng Hiệu quả đầu tư tối thiểu",
  ERROR200037: "Có lỗi khi đồng bộ tài sản ",
  ERROR200038: "Không tìm thấy thông tin phí",
  ERROR200039: "Có lỗi khi thực hiện thực hiện thanh toán tại MBS. ",
  ERROR200040: "Có lỗi khi thực hiện thực hiện rút tiền tại MBS. ",
  ERROR200041: "Tài khoản không phải là leader",
  ERROR200042: "Tài khoản đã tạo yêu cầu đóng",
  ERROR200043: "Trạng thái phí đã thay đổi ",
  ERROR200044: "Có lỗi khi đăng ký Copier tài khoản",
  ERROR200046: "Số ngày xử lý đóng (CLOSED_DISTANCE_DAYS) không xác định",
  ERROR200047: "Hiệu quả đầu tư phải nhỏ hơn 10000",
  ERROR200048: "Hiệu quả đầu tư (CS_LEAD_TRADINGDIARY_RATE) phải nhỏ hơn 10000",
  ERROR200049: "Giá đặt chênh lệch tối đa phải nhỏ hơn 10000",
  ERROR200050: "Tỷ trọng tối đa 1 mã phải nhỏ hơn 10000",
  ERROR200051: "Số tiền cần lớn hơn 0",
  ERROR200052: "Có lỗi khi gửi SMS/Email tới Copier khi đóng Leader",
  ERROR200053:
    "Hệ thống đang tạm KHOÁ để xử lý dữ liệu cuối ngày. Quý khách vui lòng thực hiện sau",
  ERROR200054: "Có lỗi khi tạo sao chép tại MBS Copie",
  ERROR200055: "Không tìm thấy tài khoản tiền",
  ERROR200056: "Not compute BOUGHT_AMT in ORDTORDER",
  ERROR200057: "Không tìm thấy phí sao chép của Tài khoản",
  ERROR200058: "Không tìm thấy dữ liệu sao chép",
  ERROR200059: "Có lỗi khi thu phí sao chép",
  ERROR200060: "Có lỗi khi thu phí sao chép",
  ERROR200061: "Có lỗi khi thu phí sao chép",
  ERROR200062: "Có lỗi khi thu phí sao chép",
  ERROR200063: "Khả dụng không đủ để thực hiện",
  ERROR200064: "Vượt quá khả dụng chuyển khoản. Xin vui lòng thử lại!",
  ERROR200065: "Khả dụng không đủ để thực hiện",
  ERROR200066: "Có lỗi khi lấy thông tin số tháng giao dịch",
  ERROR200067: "Có lỗi khi lấy hạng leader",
  ERROR200068: "Khả dụng không đủ để thực hiện",
  ERROR200069: "Đang có yêu cầu chờ đóng",
  ERROR200070: "Có lỗi khi tạo yêu cầu đóng",
  ERROR200071: "Có lỗi khi đăng ký Leader",
  ERROR200072: "Ngày mới phải lớn các ngày cũ!",
  ERROR200073: "Tài khoản vẫn còn số dư chứng khoán",
  ERROR200074: "Tài khoản vẫn còn số dư chứng tiền",
  ERROR200091: "Không tìm thấy thông tin tài khoản",
  ERROR200097: "Tài khoản có chứng khoán chờ về",
  ERROR200075: "Không tìm thấy thông tin tài khoản",
  ERROR200076: "Đã tồn tại tài khoản. Vui lòng kiểm tra lại!",
  ERROR200077: "Call api to MBS register copier failed. Service limited.",
  ERROR200078:
    "Call api to MBS register copier failed. Initialize account is error.",
  ERROR200079: "Có lỗi khi đăng ký Copier",
  ERROR200080: "Không tìm thấy thông tin tài khoản",
  ERROR200081: "Đã tồn tại tài khoản. Vui lòng kiểm tra lại!",
  ERROR200082: "Register leader failed,service limited.",
  ERROR200083:
    "Call api to MBS register leader failed. Initialize account is error.",
  ERROR200084: "Có lỗi khi đăng ký leader",
  ERROR200085: "Không tìm thấy thông tin tài khoản",
  ERROR200086:
    "Call api to MBS for cash payment. Only transfer from account 1 to account 9.",
  ERROR200087: "Số tiền chuyển khoản vượt quá số dư tài khoản",
  ERROR200088: "Không tìm thấy thông tin tài khoản",
  ERROR200089:
    "Call api to MBS for cash withdraw. Only transfer from account 9 to account 1.",
  ERROR200090: "Số tiền chuyển khoản vượt quá số dư tài khoản",
  ERROR200092: "Tồn tại tài khoản tiền không cân.",
  ERROR200093: "Tồn tại tài khoản chứng khoán không cân.",
  ERROR200094: "Tồn tại lệnh khớp chưa được đối chiếu.",
  ERROR200095: "Tồn tại lệnh khớp chưa hạch toán T0.",
  ERROR200096: "Tồn tại lệnh khớp chưa được hạch toán T2.",
  ERRORMSG1047: "Đã tồn tại tài khoản Copier/Leaer",
  ERROR908101:
    "Chức năng đang trong quá trình chạy thử nghiệm nội bộ. Tài khoản quý khách không được phép thực hiện chức năng này.",
  ERROR_1010: "Vượt quá giới hạn tìm kiếm 180 ngày",
  ERROR299: "Giá trị phụ lục > Room tổng còn lại!",
  ERROR300: "Số ngày vay không hợp lệ!",
  ERROR301: "Không tìm thấy thông tin nhân viên BDS3 nội bộ",
  ERROR302: "Số tiền nhỏ hơn giá trị tối thiểu của kỳ hạn!",
  ERROR303: "Số tiền lớn hơn giá trị vay tối đa của kỳ hạn!",
  ERROR304: "Không cho phép PL vay tính sức mua!",
  ERROR_10001:
    "Bạn đã nhập mã xác thực OTP sai quá số lần quy định. Vui lòng thao tác lại sau 5 phút.",
  ERROR_6043: "Không đặt lệnh mua bán cùng phiên",
  ERROR_6785: "Giá đặt không hợp lệ",
  ERROR_20606: "Chứng khoán hết hạn mức vay",
  ERROR_5008: "Mã chứng khoán hết room",
};
