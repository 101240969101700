import { toast } from "react-toastify";
import { ChannelItem } from "src/domain/models/channel";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxData, ReduxStateType } from "src/redux-store/types";
import { FilterState } from "./types";
import { ACTION_CREATE, STATUS_INACTIVE } from "../const";
import { DEFAULT_LIMIT_OFFSET } from "src/helper/const";

export type ChannelState = {
  channels: ChannelItem[];
  updateItem: ChannelItem;
  createItem: ChannelItem;
  filter: FilterState;
  actionUpdate: string;
  hasMore: boolean;
  checkValidForm: boolean;
};

const initialState: ReduxData<ChannelState> = {
  data: {
    channels: [],
    updateItem: {
      id: 0,
      channelCode: "",
      channelName: "",
      channelDetailList: "",
      channelDetailDefault: "",
      companyCode: "",
      rate: "",
      type: "",
      status: STATUS_INACTIVE,
      bankAccount: "",
      bankCode: "",
    },
    createItem: {
      id: 0,
      channelCode: "",
      channelName: "",
      channelDetailList: "",
      channelDetailDefault: "",
      companyCode: "MBS",
      rate: "",
      type: "",
      status: STATUS_INACTIVE,
      bankAccount: "",
      bankCode: "",
    },
    filter: {
      offset: DEFAULT_LIMIT_OFFSET.OFFSET,
      limit: DEFAULT_LIMIT_OFFSET.LIMIT,
      channelCode: "",
      channelName: "",
      type: "",
      status: "",
    },
    actionUpdate: ACTION_CREATE,
    hasMore: true,
    checkValidForm: false,
  },
  status: ReduxStateType.INIT,
};

const channelSlice = createSlice({
  name: "channel",
  initialState,
  reducers: {
    checkValidate: (state, action: PayloadAction<boolean>) => {
      state.data.checkValidForm = action.payload;
    },
    updateAction: (state, action: PayloadAction<string>) => {
      state.data.actionUpdate = action.payload;
    },
    updateFilter: (state, action: PayloadAction<FilterState>) => {
      Object.assign(state.data.filter, action.payload);
    },
    resetFilter: (state) => {
      Object.assign(state.data.filter, initialState.data.filter);
    },
    updateUpdateItem: (state, action: PayloadAction<ChannelItem>) => {
      Object.assign(state.data.updateItem, action.payload);
    },
    resetUpdateItem: (state) => {
      Object.assign(state.data.updateItem, initialState.data.updateItem);
    },
    updateCreateItem: (state, action: PayloadAction<ChannelItem>) => {
      Object.assign(state.data.createItem, action.payload);
    },
    resetCreateItem: (state) => {
      Object.assign(state.data.createItem, initialState.data.createItem);
    },
    getChannelsStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    callApiFailed: (state, action: PayloadAction<Error>) => {
      state.status = ReduxStateType.ERROR;
      toast.error("Đã có lỗi xảy ra");
    },
    getChannelsSuccess: (
      state,
      action: PayloadAction<{
        items: ChannelItem[];
      }>
    ) => {
      let { items } = action.payload;
      let { channels, filter } = state.data;
      if (!items.length || items.length < (state?.data?.filter?.limit || 0)) {
        state.data.hasMore = false;
      } else {
        state.data.hasMore = true;
      }
      state.status = ReduxStateType.SUCCESS;
      state.data.channels =
        filter.offset && filter.offset > 1
          ? [...channels, ...action.payload.items]
          : action.payload.items;
    },
    createChannelStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    updateChannelStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    deleteChannelStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    deleteChannelSuccess: (state) => {
      state.status = ReduxStateType.SUCCESS;
      toast.success("Xóa thành công");
    },
  },
});

export const {
  checkValidate,
  updateAction,
  updateUpdateItem,
  resetUpdateItem,
  updateFilter,
  resetFilter,
  updateCreateItem,
  resetCreateItem,
  getChannelsStart,
  callApiFailed,
  getChannelsSuccess,
  createChannelStart,
  updateChannelStart,
  deleteChannelStart,
  deleteChannelSuccess,
} = channelSlice.actions;

export default channelSlice.reducer;
