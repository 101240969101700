import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux-store/store";

export const getlistProfitLossState = (state: RootState) =>
  state.profitLoss.reportPortfolioRealizeSlice;

export const getlistProfitLossSelector = createSelector(
  getlistProfitLossState,
  (state) => ({
    listProfitLoss: state.data.listProfitLoss,
    accountCode: state.data.filter.accountCode,
    fromDate: state.data.filter.fromDate,
    toDate: state.data.filter.toDate,
  })
);
