import { AccountState, FilterState } from "./types";
import { ReduxData, ReduxStateType } from "src/redux-store/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { DEFAULT_LIMIT_OFFSET, validateSumTable } from "src/helper/const";
import {
  CashHistoryItem,
  CreateTransferBody,
  ResponseCreateTransfer,
} from "src/domain/models/transferMoney";
import moment from "moment";

export type TransferMoneyState = {
  filter: FilterState;
  cashHistory: CashHistoryItem[];
  createCash: CreateTransferBody;
  selectedCash: CashHistoryItem;
  hasMore: boolean;
  checkValidForm: boolean;
  listAccount: AccountState[];
  currentTransfer: ResponseCreateTransfer;
  cashWithdrawable: number;
};

const initialState: ReduxData<TransferMoneyState> = {
  data: {
    filter: {
      offset: DEFAULT_LIMIT_OFFSET.OFFSET,
      limit: DEFAULT_LIMIT_OFFSET.LIMIT,
      accountCode: "",
      accountName: "",
      fromDate: moment().subtract(7, "days").toDate(),
      toDate: moment().toDate(),
      status: "",
    },
    cashHistory: [
      {
        transId: 0,
        accountName: "",
        transactionDate: "",
        accountCode: "",
        side: "",
        content: "",
        creatorCode: "",
        status: "W",
        cash: 0,
        cashTransCode: "",
      },
    ],
    createCash: {
      accountCode: "",
      cash: 0,
      side: "",
      content: "",
      businessCode: "",
      businessDetailCode: "",
      transactionDate: moment().toDate(),
    },
    selectedCash: {
      transId: 0,
      accountName: "",
      transactionDate: "",
      accountCode: "",
      side: "",
      content: "",
      creatorCode: "",
      status: "W",
      cash: 0,
      cashTransCode: "",
    },
    hasMore: true,
    checkValidForm: false,
    listAccount: [],
    currentTransfer: {
      cashTransCode: "",
      cash: 0,
      side: "OUT",
    },
    cashWithdrawable: 0,
  },
  status: ReduxStateType.INIT,
};

const transferMoneySlice = createSlice({
  name: "transferMoney",
  initialState,
  reducers: {
    updateAccountList: (state, action: PayloadAction<AccountState[]>) => {
      state.data.listAccount = action.payload;
    },
    checkValidate: (state, action: PayloadAction<boolean>) => {
      state.data.checkValidForm = action.payload;
    },
    updateFilter: (state, action: PayloadAction<FilterState>) => {
      Object.assign(state.data.filter, action.payload);
    },
    resetFilter: (state) => {
      Object.assign(state.data.filter, initialState.data.filter);
    },
    updateCreateCash: (state, action: PayloadAction<CreateTransferBody>) => {
      Object.assign(state.data.createCash, action.payload);
    },
    resetCreateCash: (state) => {
      Object.assign(state.data.createCash, initialState.data.createCash);
      state.data.checkValidForm = false;
    },
    getTransferMoneyStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    callApiFailed: (state, action: PayloadAction<Error>) => {
      state.status = ReduxStateType.ERROR;
      toast.error("Đã có lỗi xảy ra");
    },
    getTransferMoneySuccess: (
      state,
      action: PayloadAction<{
        items: CashHistoryItem[];
      }>
    ) => {
      let { items } = action.payload;
      let { cashHistory, filter } = state.data;
      if (!items.length || items.length < (state?.data?.filter?.limit || 0)) {
        state.data.hasMore = false;
      } else {
        state.data.hasMore = true;
      }
      state.status = ReduxStateType.SUCCESS;

      state.data.cashHistory =
        filter.offset && filter.offset > 1
          ? [
              ...cashHistory.filter(
                (item) => item.accountCode !== validateSumTable.SUM_ALL
              ),
              ...action.payload.items,
            ]
          : action.payload.items;
    },
    createTransferMoneyStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    updateSelectedCash: (state, action: PayloadAction<CashHistoryItem>) => {
      Object.assign(state.data.selectedCash, action.payload);
    },
    resetSelectedCash: (state) => {
      Object.assign(state.data.selectedCash, initialState.data.selectedCash);
    },
    approveTransferMoneyStart: (state, action: PayloadAction<number>) => {
      Object.assign(state.data.selectedCash, { transId: action.payload });
      state.status = ReduxStateType.LOADING;
    },
    cancelTransferMoneyStart: (state, action: PayloadAction<number>) => {
      Object.assign(state.data.selectedCash, { transId: action.payload });
      state.status = ReduxStateType.LOADING;
    },
    updateCurrentTransfer: (
      state,
      action: PayloadAction<ResponseCreateTransfer>
    ) => {
      Object.assign(state.data.currentTransfer, action.payload);
    },
    clearCurrentTransfer: (state) => {
      Object.assign(
        state.data.currentTransfer,
        initialState.data.currentTransfer
      );
    },
    getCashWithdrawableStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    getCashWithdrawableSuccess: (state, action: PayloadAction<number>) => {
      state.data.cashWithdrawable = action.payload;
      state.status = ReduxStateType.SUCCESS;
    },
  },
});

export const {
  updateAccountList,
  checkValidate,
  updateFilter,
  resetFilter,
  updateCreateCash,
  resetCreateCash,
  updateSelectedCash,
  resetSelectedCash,
  getTransferMoneyStart,
  callApiFailed,
  getTransferMoneySuccess,
  createTransferMoneyStart,
  approveTransferMoneyStart,
  cancelTransferMoneyStart,
  updateCurrentTransfer,
  clearCurrentTransfer,
  getCashWithdrawableStart,
  getCashWithdrawableSuccess,
} = transferMoneySlice.actions;

export default transferMoneySlice.reducer;
