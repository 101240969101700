import { all, call, put, takeLatest } from "redux-saga/effects";
import { orderSessionConnectApi } from "src/core/http/service/managementConnect";
import { OrderSessionConnectParams } from "src/core/http/service/managementConnect/types";
import {
  orderSessionConnectFailed,
  orderSessionConnectStart,
  orderSessionConnectSuccess,
} from "../redux/orderSessionConnect";

function* orderSessionConnectStartWork() {
  // store lấy giá trị mbsManageCode truyền lên

  const params: OrderSessionConnectParams = {};

  try {
    const res = yield call(orderSessionConnectApi, params);
    yield put({
      type: orderSessionConnectSuccess,
      payload: res,
    });
  } catch (error: any) {
    yield put({
      type: orderSessionConnectFailed,
    });
  }
}

function* watchOrderSessionConnect() {
  yield takeLatest(orderSessionConnectStart.type, orderSessionConnectStartWork);
}

export default function* orderSessionConnectSaga() {
  yield all([watchOrderSessionConnect()]);
}
