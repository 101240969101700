import { createSlice } from "@reduxjs/toolkit";

import { ThemeType } from "./types";

const initialState = {
  mode: ThemeType.LIGHT,
};

const themesSlice = createSlice({
  name: "themesSlice",
  initialState,
  reducers: {
    changeTheme: (state, action) => {
      state.mode = action.payload;
    },
  },
});

export const { changeTheme } = themesSlice.actions;

export default themesSlice.reducer;
