import { all, call, put, takeLatest } from "redux-saga/effects";
import { listSharesApi } from "src/core/http/service/staticInfo";
import {
  listSharesStaticBuySuccess,
  listSharesStaticFailed,
  listSharesStaticSellSuccess,
  listSharesStaticStart,
} from "../redux/listSharesStatic";

function* listSharesStaticStartWork(action) {
  // const isSellSide = action.payload;
  // true thì gọi làm mới cả 2
  // false thì chỉ gọi update mã cho bên side mua
  const paramsB = {
    orderType: "B",
  };

  const paramsS = {
    orderType: "S",
  };
  try {
    const resB = yield call(listSharesApi, paramsB);
    // Có phần cờ chặn mua mã chứng khoán nên phải tách riêng 2 list mã => khi ở trạng thái mua các mã có thể update

    // Ở bên Bán thì mặc định sẽ lấy tất cả các mã vì ko có gì thay đổi
    const optionslistSharesStaticB = resB.map((item) => ({
      value: item?.shareCode,
      label: item?.shareCode,
    }));

    yield put({
      type: listSharesStaticBuySuccess,
      payload: optionslistSharesStaticB,
    });
    const resS = yield call(listSharesApi, paramsS);
    const optionslistSharesStaticS = resS.map((item) => ({
      value: item?.shareCode,
      label: item?.shareCode,
    }));
    yield put({
      type: listSharesStaticSellSuccess,
      payload: optionslistSharesStaticS,
    });
  } catch (error: any) {
    yield put({
      type: listSharesStaticFailed,
    });
  }
}

function* watchlistSharesStatic() {
  yield takeLatest(listSharesStaticStart.type, listSharesStaticStartWork);
}

export default function* listSharesStaticSaga() {
  yield all([watchlistSharesStatic()]);
}
