import numeral from "numeral";
import { toast } from "react-toastify";
import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";
import { orderTradingApi } from "src/core/http/service/stockTrading";
import { orderTradingParams } from "src/core/http/service/stockTrading/types";
import { errorCodeNew } from "src/languages/errorCodeNew";
import { RootState } from "src/redux-store/store";
import { resetInputFormOrder } from "../redux/formOrder";
import { listOrdersStart } from "../redux/listOrders";
import {
  orderTradingFailed,
  orderTradingStart,
  orderTradingSuccess,
} from "../redux/orderTrading";
import { reportBuyingPowerStart } from "../redux/reportBuyingPower";

function* orderTradingStartWork() {
  yield delay(400);
  const { accountCode, shareCode, price, volume } = yield select(
    (state: RootState) => state.stockTrading.formOrder.inputForm
  );
  const { side } = yield select(
    (state: RootState) => state.stockTrading.switchControl
  );
  const { optionsChannelList } = yield select(
    (state: RootState) => state.stockTrading.channelDetail
  );
  // Lấy kênh đầu tiên vì đã ưu tiên kênh mặc định và thứ tự ưu tiên lên đầu trong mảng
  const params: orderTradingParams = {
    account: optionsChannelList[0]?.label,
    channelId: optionsChannelList[0]?.value,
    traderCode: accountCode?.value,
    accountCode: accountCode?.value,
    shareCode: shareCode?.value,
    price:
      price !== "ATC" || price !== "ATO"
        ? Math.round(Number(price) * 1000)
        : "",
    quantity: Number(volume),
    orderType: price === "ATC" || price === "ATO" ? price : "",
    side,
  };

  try {
    const res = yield call(orderTradingApi, params);
    const quantity = res?.quantity;
    if (Number(volume) > quantity) {
      toast.success(
        "Lệnh vào 1 phần, Quý khách vui lòng liên hệ Admin để được hỗ trợ!"
      );
    } else if (Number(volume) === quantity) {
      toast.success(
        `Đặt lệnh với khối lượng ${numeral(quantity).format("0,0")} thành công!`
      );
    } else if (!quantity) {
      toast.success(
        "không thể đặt lệnh tự động, Quý khách vui lòng liên hệ admin để đc hỗ trợ!"
      );
    }
    yield put({
      type: orderTradingSuccess,
    });
    yield put(resetInputFormOrder());
    yield put(listOrdersStart());
    yield put(reportBuyingPowerStart());
  } catch (error: any) {
    yield put({
      type: orderTradingFailed,
    });
    const errCode = error?.response?.data?.code;
    const errMessage = error?.response?.data?.message;
    toast.error(errorCodeNew[errCode] ?? errMessage);
  }
}

function* watchOrderTrading() {
  yield takeLatest(orderTradingStart.type, orderTradingStartWork);
}

export default function* orderTradingSaga() {
  yield all([watchOrderTrading()]);
}
