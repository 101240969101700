import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_LIMIT_OFFSET } from "src/helper/const";
interface InitialState {
  inputFilter: {
    accountCode: string;
    accountName: string;
    role: string;
    status: string;
    limit: number;
    offset: number;
    hasMore: boolean;
  };
  showModal: boolean;
}

const initialState: InitialState = {
  inputFilter: {
    accountCode: "",
    accountName: "",
    role: "",
    status: "",
    limit: DEFAULT_LIMIT_OFFSET.LIMIT,
    offset: DEFAULT_LIMIT_OFFSET.OFFSET,
    hasMore: true,
  },
  showModal: false,
};

const filterTableSlice = createSlice({
  name: "filterTable",
  initialState,
  reducers: {
    updateFilterTable: (state, action) => {
      Object.assign(state.inputFilter, action.payload);
    },
    controlModal: (state, action) => {
      state.showModal = action.payload;
    },
    resetLimitFilter: (state) => {
      state.inputFilter.limit = 30;
      state.inputFilter.offset = 0;
    },
    resetFilterTable: () => initialState,
  },
});

export const {
  updateFilterTable,
  controlModal,
  resetLimitFilter,
  resetFilterTable,
} = filterTableSlice.actions;

export default filterTableSlice.reducer;
