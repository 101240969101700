import { PostGDKHQParams } from "../../../core/http/service/financeService/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ReduxData, ReduxStateType } from "src/redux-store/types";

export type InsertFinanceServiceState = {
  importGDKHQ: PostGDKHQParams[];
};

const initialState: ReduxData<InsertFinanceServiceState> = {
  data: {
    importGDKHQ: [],
  },
  status: ReduxStateType.INIT,
};

const importListGDKHQSlice = createSlice({
  name: "insertFinanceService",
  initialState,
  reducers: {
    importListGDKHQStart: (state, action: PayloadAction<PostGDKHQParams[]>) => {
      state.data.importGDKHQ = action.payload;
      state.status = ReduxStateType.LOADING;
    },
    importListGDKHQSuccess: (state) => {
      state.status = ReduxStateType.SUCCESS;
    },
    importListGDKHQFailed: (state, action: PayloadAction<Error>) => {
      state.status = ReduxStateType.ERROR;
      toast.error("Đã có lỗi xảy ra.");
    },
  },
});

export const {
  importListGDKHQStart,
  importListGDKHQSuccess,
  importListGDKHQFailed,
} = importListGDKHQSlice.actions;

export default importListGDKHQSlice.reducer;
