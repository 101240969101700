import HttpClient from "src/core/http";
export default class userAssetsHttp extends HttpClient {
  private static classInstance?: userAssetsHttp;
  private constructor() {
    super(process.env.REACT_APP_RESTFUL_BASE_URL || "");
  }
  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new userAssetsHttp();
    }
    return this.classInstance;
  }
}
