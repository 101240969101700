import { toast } from "react-toastify";
import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";
import { resetPasswordApi } from "src/core/http/service/auth";
import { RootState } from "src/redux-store/store";
import { updateInputAuth } from "../redux/formAuth";
import {
  resetPasswordFailed,
  resetPasswordStart,
  resetPasswordSuccess,
} from "../redux/resetPassword";

function* resetPasswordStartWork() {
  yield delay(500);
  const { emailForgetPass } = yield select(
    (state: RootState) => state.authenication.formForgetPass.inputFormForgetPass
  );
  const params = {
    accountEmail: emailForgetPass,
  };
  try {
    yield call(resetPasswordApi, params);
    yield put({
      type: resetPasswordSuccess.type,
    });
    yield put({
      type: updateInputAuth.type,
      payload: {
        showForgetPass: false,
      },
    });
    toast.success("Mật khẩu mới đã được gửi đến Email của bạn!", {
      autoClose: 4000,
    });
  } catch (error: any) {
    const errMess = error?.response?.data?.message;
    toast.error(errMess, { autoClose: 4000 });
    yield put({
      type: resetPasswordFailed.type,
    });
  }
}

function* watchResetPassword() {
  yield takeLatest(resetPasswordStart.type, resetPasswordStartWork);
}

export default function* resetPasswordSaga() {
  yield all([watchResetPassword()]);
}
