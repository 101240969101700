// import { toast } from "react-toastify";
import { all, call, put, takeLatest, select, delay } from "redux-saga/effects";
import { createSelector } from "@reduxjs/toolkit";
import { getDividendFilterApi } from "src/core/http/service/dividend";
import {
  getDividendFilterFailed,
  getDividendFilterStart,
  getDividendFilterSuccess,
} from "../redux/getDividendFilter";
import { dividendFilterState } from "../redux/selector";
import { DividendFilterParams } from "src/core/http/service/dividend/types";

const dividendFilterSelector = createSelector(dividendFilterState, (state) => ({
  filter: state.data.filter,
}));

function* getDividendFilter(action: ReturnType<typeof getDividendFilterStart>) {
  try {
    const { filter } = yield select(dividendFilterSelector);
    const params: DividendFilterParams = {
      ...filter,
    };
    delete params.isLoading;
    yield delay(200);
    const res = yield call(getDividendFilterApi, params);
    yield put({
      type: getDividendFilterSuccess.type,
      payload: {
        items: res,
      },
    });
  } catch (error: any) {
    yield put(getDividendFilterFailed(error));
  }
}

function* watchGetDividendFilter() {
  yield takeLatest(getDividendFilterStart.type, getDividendFilter);
}
export default function* getDividendFilterSaga() {
  yield all([watchGetDividendFilter()]);
}
