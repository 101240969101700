import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux-store/store";

const getOrderCommissionState = (state: RootState) =>
  state.reportOrderCommissions.getOrderCommissionSlice;
export const getOrderCommissionSelector = createSelector(
  getOrderCommissionState,
  (state) => state
);

//filter
export const filterOrderCommissionState = (state: RootState) =>
  state.reportOrderCommissions.filterSlice;
export const filterOrderCommissionSelector = createSelector(
  filterOrderCommissionState,
  (state) => state
);
