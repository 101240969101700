import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux-store/store";

export const auth = (state: RootState) => state.authenication.auth;
export const authSelector = createSelector(auth, (state) => state.dataAuth);
export const register = (state: RootState) => state.authenication.register;
export const resetPassword = (state: RootState) =>
  state.authenication.resetPassword;
export const formAuth = (state: RootState) => state.authenication.formAuth;
export const formRegister = (state: RootState) =>
  state.authenication.formRegister;
export const formForgetPass = (state: RootState) =>
  state.authenication.formForgetPass;
export const auth2faSelector = (state: RootState) =>
  state.authenication.auth2fa;
