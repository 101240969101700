import { lazy } from "react";
import { roles } from "src/helper/const";
import paths from "src/routes/pathRoutes";
const ManagementAccountLazy = lazy(() => import("./pages"));
export const managementAccountRoutes = [
  {
    page: ManagementAccountLazy,
    path: paths.managementAccount,
    roleSide: roles.ROLE_ADMIN,
  },
];
