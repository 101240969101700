import { toast } from "react-toastify";
import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { createSelector } from "@reduxjs/toolkit";
import moment from "moment";

import {
  getHistoryApi,
  createTransferMoneyApi,
  approveTransferMoneyApi,
  cancelTransferMoneyApi,
  getCashWithDrawableApi,
} from "src/core/http/service/transferMoney";

import { transferMoneyState } from "../redux/selector";
import { auth } from "src/modules/Auth/redux/selector";
import {
  approveTransferMoneyStart,
  callApiFailed,
  cancelTransferMoneyStart,
  createTransferMoneyStart,
  getTransferMoneyStart,
  getTransferMoneySuccess,
  resetCreateCash,
  resetFilter,
  resetSelectedCash,
  updateCurrentTransfer,
  getCashWithdrawableStart,
  getCashWithdrawableSuccess,
} from "../redux/transferMoney";
import {
  CashHistoryItem,
  GetCashWithdrawable,
  GetHistoryTransferParams,
  ResponseCreateTransfer,
  ResponseGetCashWithdrawable,
} from "src/domain/models/transferMoney";

const transferMoneySelector = createSelector(transferMoneyState, (state) => ({
  filter: state.data.filter,
  createCash: state.data.createCash,
  selectedCash: state.data.selectedCash,
}));
const authSelector = createSelector(auth, (state) => ({
  accountCode: state.dataAuth.accountCode,
}));

function* getTransferMoneyHistory(
  action: ReturnType<typeof getTransferMoneyStart>
) {
  try {
    const { filter } = yield select(transferMoneySelector);
    let fromDate = moment(filter.fromDate).format("DD/MM/YYYY");
    let toDate = moment(filter.toDate).format("DD/MM/YYYY");
    const params: GetHistoryTransferParams = {
      ...filter,
      fromDate: fromDate,
      toDate: toDate,
    };
    const data: CashHistoryItem[] = yield call(getHistoryApi, params);
    yield put({
      type: getTransferMoneySuccess.type,
      payload: {
        items: data,
      },
    });
  } catch (error: any) {}
}

function* createTransferMoney(
  action: ReturnType<typeof createTransferMoneyStart>
) {
  try {
    const { createCash } = yield select(transferMoneySelector);
    const params = {
      ...createCash,
      transactionDate: moment(createCash.transactionDate).format("YYYY-MM-DD"),
    };
    const data: ResponseCreateTransfer = yield call(
      createTransferMoneyApi,
      params
    );
    yield put({
      type: updateCurrentTransfer.type,
      payload: data,
    });
    toast.success("Thêm thành công");
    yield put(resetCreateCash());
    yield put(resetFilter());
    yield put(getTransferMoneyStart());
  } catch (error: any) {
    yield put(callApiFailed(error));
    yield put(resetFilter());
    yield put(getTransferMoneyStart());
  }
}

function* approveTransferMoney(
  action: ReturnType<typeof approveTransferMoneyStart>
) {
  try {
    const { selectedCash } = yield select(transferMoneySelector);
    const { accountCode } = yield select(authSelector);
    const params = {
      accountCode: accountCode,
      id: selectedCash.transId,
    };

    yield call(approveTransferMoneyApi, params);
    toast.success("Duyệt thành công");
    yield put(resetSelectedCash());
    yield put(resetFilter());
    yield put(getTransferMoneyStart());
  } catch (error: any) {
    yield put(callApiFailed(error));
    yield put(resetFilter());
    yield put(resetSelectedCash());
    yield put(getTransferMoneyStart());
  }
}

function* cancelTransferMoney(
  action: ReturnType<typeof cancelTransferMoneyStart>
) {
  try {
    const { selectedCash } = yield select(transferMoneySelector);
    const { accountCode } = yield select(authSelector);
    const params = {
      accountCode: accountCode,
      id: selectedCash.transId,
    };

    yield call(cancelTransferMoneyApi, params);
    toast.success("Hủy thành công");

    yield put(resetFilter());
    yield put(resetSelectedCash());
    yield put(getTransferMoneyStart());
  } catch (error: any) {
    yield put(callApiFailed(error));
    yield put(resetFilter());
    yield put(resetSelectedCash());
    yield put(getTransferMoneyStart());
  }
}

function* getCashWithdrawable(
  action: ReturnType<typeof getCashWithdrawableStart>
) {
  try {
    const { createCash } = yield select(transferMoneySelector);
    const params: GetCashWithdrawable = {
      accountCode: createCash.accountCode,
    };
    const data: ResponseGetCashWithdrawable = yield call(
      getCashWithDrawableApi,
      params
    );
    yield put({
      type: getCashWithdrawableSuccess.type,
      payload: data.precision,
    });
  } catch (error: any) {}
}

function* watchGetTransferMoneyHistory() {
  yield takeLatest(getTransferMoneyStart.type, getTransferMoneyHistory);
}
function* watchCreateTransferMoney() {
  yield takeLatest(createTransferMoneyStart.type, createTransferMoney);
}
function* watchApproveTransferMoney() {
  yield takeLatest(approveTransferMoneyStart.type, approveTransferMoney);
}
function* watchCancelTransferMoney() {
  yield takeLatest(cancelTransferMoneyStart.type, cancelTransferMoney);
}
function* watchGetCashWithdrawable() {
  yield takeLatest(getCashWithdrawableStart.type, getCashWithdrawable);
}

export default function* getChannelsSaga() {
  yield all([
    watchGetTransferMoneyHistory(),
    watchCreateTransferMoney(),
    watchApproveTransferMoney(),
    watchCancelTransferMoney(),
    watchGetCashWithdrawable(),
  ]);
}
